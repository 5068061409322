import gql from "graphql-tag";


export const SUBMIT_REVIEW_MUTATION = gql `
    mutation createReview($reviewData: [ReviewsInput]!){
        createReview(reviewData:$reviewData){
            reviews{
                id
            }
        }
    }
`