import gql from "graphql-tag";

export const CREATE_BADGE_AWARD_REACTION =gql`
mutation createBadgeAwardReaction($baReactionInput: BadgeAwardReactionInput!){
    createBadgeAwardReaction(baReactionInput:$baReactionInput){
        baReaction{
            id
        }
    }
}
`
export const CREATE_BADGE_AWARD_COMMNET =gql`
mutation createBadgeAwardComment($badgeAwardCommentData: BadgeAwardCommentInput!){
    createBadgeAwardComment(badgeAwardCommentData:$badgeAwardCommentData){
      badgeAwardComment{
        id
      }
    }
  }
`

export const CREATE_BADGE_AWARD_COMMNET_REACTION =gql`
mutation createBadgeAwardCommentReaction($baCommentReactionInput: BadgeAwardCommentReactionInput!){
    createBadgeAwardCommentReaction(baCommentReactionInput:$baCommentReactionInput){
        baCommentReaction{
            id
        }
    }
}
`
export const UPDATE_BADGE_AWARD_REACTION =gql`
mutation updateBadgeAwardReaction($id:ID!,$reaction: String!){
    updateBadgeAwardReaction(id:$id,reaction:$reaction){
        baReaction{
            id
        }
    }
}
`