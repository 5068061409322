// @ts-nocheck
import { Card, Col, Input, Modal, Row, Select, Spin } from "antd";
import { getIntFromString, getMomentUserTimezoneWithFormat, MetaTags, TextField } from "modules/look";
import { BackButton } from "modules/look-v2";
import moment from "moment-timezone";
import Back_icon from '../../assets/soul-back.svg'
import React from "react";
import { Line } from "react-chartjs-2";
import Search_icon from '../../assets/search-icon.svg'
import '../css/OneOneOneEffectiveness.css'
import yellowStart from '../../assets/yellowStart.svg'
import graystar from '../../assets/graystar.svg'
import Account from '../../assets/Account.svg'
import Home_soul from '../../assets/home-icon-soul.svg'
import Right_arow from '../../assets/right-arrow-soul.svg'
import {
  SearchOutlined

} from "@ant-design/icons";
import EmployeeFilterForFeedbacks from "./EmployeeFilterForFeedbacks";
import { useHistory } from "react-router-dom";
import { ROLEGOAL_HOME_TOOL_ID } from "config";
import { GOAL_HOME_TOOL_ID } from "config";
import styled from "styled-components";

const OneOnOneEffectivenssFeedbackHome = props => {

  const { feedbacks, getAllFeedbacks, getDetailOfFeedback, feedbackDetail, me, allMembers, loading } = props
  const [feedbacksInView, setFeedbacksInView] = React.useState();
  const [firstHalfFeedbacks, setFirstHalfFeddbacks] = React.useState();
  const [secondHalfFeedbacks, setSecondHalfFeddbacks] = React.useState();
  const [graphData, setGraphData] = React.useState();
  const [feedbackDetailModalVisible, setFeedbackDetailModalVisible] = React.useState(false);
  const [activeFilter, setActiveFilter] = React.useState('All');
  const [allMeetingMembers, setAllMeetingMembers] = React.useState();
  const [selectedemp, setSelectedEmp] = React.useState();
  const [stars, setStars] = React.useState([1, 2, 3, 4, 5])


  const history = useHistory()

  React.useEffect(() => {

    if (feedbacks) {

      let sortedByDate = feedbacks?.edges?.sort((date1, date2) =>
        date1?.node?.createdAt?.localeCompare(date2?.node?.createdAt)
      );
      let firstRowOfGraph = [{ node: { hostSkills: '0', meetingEffectiveness: '0', createdAt: '' } }]
      let consolidatedData = firstRowOfGraph?.concat(sortedByDate)
      setGraphData(consolidatedData)
      let emptySuggestionsFiltered = feedbacks?.edges?.filter((element) => element?.node?.suggestions.trim() !== "");
      const middleIndex = Math.ceil(emptySuggestionsFiltered?.length / 2);
      let secondColumnFeedbacks = emptySuggestionsFiltered?.slice(0, middleIndex).reverse()

      let firstColumnFeedbacks = emptySuggestionsFiltered?.slice(secondColumnFeedbacks.length, feedbacks?.edges?.length).reverse()


      setFirstHalfFeddbacks(firstColumnFeedbacks);
      setSecondHalfFeddbacks(secondColumnFeedbacks);
    }

  }, [feedbacks])


  React.useEffect(() => {

    if (allMembers) {
      let members = allMembers?.edges?.map((node) =>
        node?.node?.member).filter((element => element !== null))

      let uniqueMembers = [...new Set(members)]
      console.log("uniqueMembers",uniqueMembers)
      setAllMeetingMembers(uniqueMembers);
    }

  }, [allMembers])

  React.useEffect(() => {
    if (selectedemp) {
      getAllFeedbacks({ meetingHost: me?.employee?.id, orderBy: ["-createdAt"], meetingMember: selectedemp });
    }
  }, [selectedemp])


  const FeedbackCard = (node) => {

    return (

      <div>
        {
          // (node?.data?.suggestions !== "") &&
          <Card style={{ marginTop: '1em', marginBottom: '1em' }}>
            <Row align="middle">
              <Col>
                <div style={{ marginTop: '-9px', color: 'rgba(0, 0, 0, 0.38)' }}>
                  {getMomentUserTimezoneWithFormat(node?.data?.createdAt, 'DD MMM YY')}
                </div>
              </Col>
              <ReceivedUserColumn userString={(node?.data?.member?.user?.firstName + node?.data?.member?.user?.lastName).length} >
                {
                  !node?.data?.anonymous ? <div className="received-header" style={{ color: 'rgba(0, 0, 0, 0.38)' }} >Received : <span> {`${node?.data?.member?.user?.firstName}` + ' ' + `${node?.data?.member?.user?.lastName}`}</span></div> :
                    <div className="received-header" style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Anonymous</div>
                }
              </ReceivedUserColumn>
            </Row>

            <Col className="feedback-card-suggestions">
              <div style={{ color: '#000000', fontWeight: '500', cursor: 'pointer', wordWrap: 'break-word' }} onClick={(e) => { getDetailOfFeedback(node?.data?.id); setFeedbackDetailModalVisible(true) }} >{node?.data?.suggestions}</div>
            </Col>

          </Card>

        }



      </div>



    )


  }


  const handleFeedbackFilterChange = (e) => {
    setActiveFilter('EmpDropDown')
    if (e?.target?.value !== "") {
      let filteredFirstHalf = firstHalfFeedbacks?.filter((element) => element?.node?.suggestions?.includes(e?.target?.value))
      let filteredSecondHalf = secondHalfFeedbacks?.filter((element) => element?.node?.suggestions?.includes(e?.target?.value))
      setFirstHalfFeddbacks(filteredFirstHalf)
      setSecondHalfFeddbacks(filteredSecondHalf)
    } else {
      let emptySuggestionsFiltered = feedbacks?.edges?.filter((element) => element?.node?.suggestions.trim() !== "");
      const middleIndex = Math.ceil(emptySuggestionsFiltered?.length / 2);
      let secondColumnFeedbacks = emptySuggestionsFiltered?.slice(0, middleIndex).reverse()
      let firstColumnFeedbacks = emptySuggestionsFiltered?.slice(secondColumnFeedbacks.length, emptySuggestionsFiltered?.length).reverse()
      setFirstHalfFeddbacks(firstColumnFeedbacks);
      setSecondHalfFeddbacks(secondColumnFeedbacks);
    }


  }


  const handleEmpFilterChange = (e) => {

    console.log('')


  }



  const options = {
    responsive: true,
    maintainAspectRatio: false,

    scales: {
      y: {
        stacked: false,
        ticks: {
          beginAtZero: true
        },
        grid: {
          display: false
        }
      },
      x: {
        ticks: {
          beginAtZero: true
        },
        grid: {
          display: false
        }
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        title: {
          display: true,
          padding: 5,
        },
      }


    },
  };



  const labels = graphData?.map((dataNode, index) => index !== 0 ? moment(dataNode?.node?.createdAt).format('DD-MM-YY') : "");


  const data = {
    labels,
    datasets: [
      {
        label: 'Host Skills',
        data: graphData?.map((dataNode) => dataNode?.node?.hostSkills),
        borderColor: '#00FF0A',
        backgroundColor: '#00FF0A',


      },
      {
        label: 'Meeting Experience',
        data: graphData?.map((dataNode) => dataNode?.node?.meetingEffectiveness),
        borderColor: '#FFB300',
        backgroundColor: '#FFB300',

      },
    ],
  };



  const back = () => {
    history.push(`/page/goal/home/${GOAL_HOME_TOOL_ID}`)
  };

  const backToRoleGoal = () => {
    history.push(`/page/role_goal/home/${ROLEGOAL_HOME_TOOL_ID}`)
  };


  const goHome = () => {
    history.push('/')
  }




  return (
    <>
      <>
        <Row justify="center" align="middle" className="effective-header back-arrow-global" style={{ flexDirection: 'column', gap: "10px", position: 'relative' }} >
          <img className="back-icon-one-on-one-effectiveness back-arrow-global " style={{ cursor: "pointer" }} src={Back_icon} alt="" onClick={(e) => history.goBack()} />
          <div style={{ display: "flex", flexDirection: "row", gap: "10px", justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
              <div className="effectiveness-title" align="center">1:1 Effectiveness Feedback</div>
            </div>
          </div>
        </Row>
        <div className="one-on-one-effectiveness-root">
          <MetaTags
            title="One on one effectiveness Feedback"
          />



          <Row justify="space-between" align="middle" className="filter-panel">
            <Col >
              <Row style={{ gap: '20px' }} align='middle' wrap={false} className='emp-search-filter'>
                <div style={{ cursor: 'pointer' }} className={activeFilter === "All" ? "effective-filter-all-active" : "effective-filter-all-non-active"} onClick={(e) => {
                  setActiveFilter('All');
                  getAllFeedbacks({ meetingHost: me?.employee?.id, orderBy: ["-createdAt"] })
                }}>All</div>
                {allMeetingMembers?.length>0&&<div className={activeFilter === "EmpDropDown" ? "effective-emp-filter-all-active" : "effective-emp-filter-all-non-active"}>
                  <EmployeeFilterForFeedbacks setSelectedEmp={setSelectedEmp} activeFilter={activeFilter} allMeetingMembers={allMeetingMembers} setActiveFilter={setActiveFilter} />
                </div>}
              </Row>
            </Col>
            <Col className="feedback-title-search-box">

              <Input autoComplete='off' prefix={<SearchOutlined />} onChange={(e) => handleFeedbackFilterChange(e)} />
            </Col>
          </Row>

          <div>

            {/* <Row > */}

            {
              feedbacks?.edges?.length ?

                <Row className="effectiveness-feedback-body" justify="center" >
                  {/* Graph */}
                  <Col xs={24} sm={24} md={(!secondHalfFeedbacks?.length || !firstHalfFeedbacks?.length) ? 11 : 7} lg={(!secondHalfFeedbacks?.length || !firstHalfFeedbacks?.length) ? 11 : 7} style={{ margin: '0em auto auto 0em ' }}>
                    <Spin spinning={loading}>
                      <div className="graph-container-effective">
                        <Line options={options} data={data} />
                      </div>
                    </Spin>
                  </Col>
                  {/* <Spin spinning={loading}> */}


                  {/* Opportunities */}

                  {

                    firstHalfFeedbacks?.length ?
                      <Col xs={24} sm={24} md={(!secondHalfFeedbacks?.length) ? 11 : 8} lg={(!secondHalfFeedbacks?.length) ? 11 : 8} className='feedback-column'>
                        {/* <div className="feedback-column-header">Opportunities </div> */}
                        <Spin spinning={loading}>
                          <div className="feedback-column-content" >
                            {
                              firstHalfFeedbacks?.length ?
                                firstHalfFeedbacks?.map((node) =>
                                  <FeedbackCard data={node?.node} />
                                )
                                :
                                <div></div>
                            }
                          </div>
                        </Spin>
                      </Col>
                      :
                      <div></div>
                  }




                  {/* Strengths */}

                  {
                    secondHalfFeedbacks?.length ?
                      <Col xs={24} sm={24} md={(!firstHalfFeedbacks?.length) ? 11 : 8} lg={(!firstHalfFeedbacks?.length) ? 11 : 8} className='feedback-column strengths-column' >

                        <Spin spinning={loading}>
                          <div className="feedback-column-content" >
                            {
                              secondHalfFeedbacks?.length ?
                                secondHalfFeedbacks?.map((node) =>
                                  <FeedbackCard data={node?.node} />
                                )
                                :
                                <div></div>
                            }
                          </div>
                        </Spin>
                      </Col>
                      :
                      <div></div>
                  }

                </Row>
                :
                <div>
                  {
                    !loading &&
                    <div align='center' style={{ fontWeight: '600', fontSize: '20px', marginTop: '100px' }}>No Feedbacks Found</div>

                  }
                </div>

            }



            {/* </Row> */}



          </div>

          <Modal
            className="feedback-details-modal"
            visible={feedbackDetailModalVisible}
            footer={null}
            onCancel={(e) => setFeedbackDetailModalVisible(false)}
            height={30}
            width={650}
          >

            <Spin spinning={feedbackDetail ? false : true}>
              <Col style={{ position: 'relative', top: '2em', fontFamily: 'poppins' }}>
                <Row justify="space-between" >
                  <Row align="middle" className="modal-feedback-header-for-user" wrap={false}>
                    <img src={Account} style={{ marginRight: '10px', marginTop: '-10px' }} />
                    {
                      !feedbackDetail?.anonymous ? <div className="received-header" style={{ color: 'rgba(0, 0, 0, 0.38)' }} >Received : <span> {`${<span className="text-transform-capitalize">{feedbackDetail?.member?.user?.firstName}</span>}` + ' ' + `${<span className="text-transform-capitalize">{feedbackDetail?.member?.user?.lastName}</span>}`}</span></div> :
                        <div className="received-header" style={{ color: 'rgba(0, 0, 0, 0.38)' }}>Anonymous</div>
                    }
                    {/* <div className="modal-user-name"> {`${feedbackDetail?.member?.user?.firstName}` + ' ' + `${feedbackDetail?.member?.user?.lastName}`} </div> */}
                  </Row>

                  <div className="modal-feedback-date">{moment(feedbackDetail?.createdAt).format('DD-MMM-YYYY')}</div>
                </Row>

                <Col>
                  <div className="modal-section-header">1:1 Experience</div>
                  <Row>
                    <div>
                      {
                        stars?.map((star, index) =>
                          <img className="star-rating-effectivness-feedback" src={((feedbackDetail?.meetingEffectiveness / 20) >= (index + 1)) ? yellowStart : graystar} />
                        )
                      }

                    </div>


                  </Row>
                </Col>

                <Col>
                  <div className="modal-section-header">Your Skills</div>
                  <Row>
                    <div>
                      {
                        stars?.map((star, index) =>
                          <img className="star-rating-effectivness-feedback" src={((feedbackDetail?.hostSkills / 20) >= (index + 1)) ? yellowStart : graystar} />
                        )
                      }
                    </div>
                  </Row>
                </Col>

                <Col>
                  <div className="modal-section-header">Feedback Notes -  </div>
                  <div className="feedback-note">{feedbackDetail?.suggestions}</div>
                </Col>
              </Col>
            </Spin>

          </Modal>

        </div>
      </>
    </>
  );
};

export default OneOnOneEffectivenssFeedbackHome;




const ReceivedUserColumn = styled(Col)`
 
  position: absolute;
  right: 1em;
  width: ${props => props.userString > 20 ? '80%' : 'auto'};

@media screen and (min-width:381px) {
    width: auto !important;
 }

 @media screen and (min-width:768px) and (max-width:1200px) {
    width: 80% !important;
  }

 `;
