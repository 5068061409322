import gql from "graphql-tag";

export const POC_360_TOKEN_INVITE = gql`
  query getAllTokensList(
    $first: Int
    $offset: Int
    $orderBy: [String]
    $user: ID
    $survey: ID
    $createdBy: ID
    $invited: Boolean
    $valid: Boolean
    $responseSourceFor:ID
    $message_Icontains:String
    $user_FirstName_Icontains:String
    $after:String 
  ) {
    getAllTokensList(
      first: $first
      offset: $offset
      orderBy: $orderBy
      user: $user
      survey: $survey
      createdBy: $createdBy
      invited: $invited
      valid: $valid
      responseSourceFor:$responseSourceFor
      message_Icontains:$message_Icontains
      user_FirstName_Icontains:$user_FirstName_Icontains
      after:$after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node{ 
          id
          user{
            id
            firstName
            lastName
            email
          }
          createdBy{
            id
            email
            firstName
            lastName 
          }
          valid
          respondentType
          responseSourceFor{
            id
          }
        }
      }
    }
  }
`;

export const POC_RESPONSE_FOR_TOKEN = gql`
  query responseByResponseId(
    $id: ID!
    $first:Int
    $after:String 
  ) {
     responseByResponseId(id:$id){
      id
      multisourceResponseSet(first:$first,after:$after){
       pageInfo{
          hasNextPage
          endCursor
        }
        edges{
          node{
            id
            responseDate
            user{
              id
            }
          }
        }
      }
    }
  }
`;