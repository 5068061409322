import React from "react";
import TeamsDashboardView from "../components/teamsDashboardView";
import { DASHBOARD_TEAMS_BY_ID, LAST_FEEDBACK_GIVEN, ONE_ON_ONE_EFFECTIVENESS_FEEDBACK, TEAMS_ONE_ON_ONE_LAST_DATE, TEAM_LIST_SUBSCRIPTION } from "modules/home/graphql/teamDashboardQueries.gql";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { getTeamBase64StringFromInt, globalPermissionValidator } from "modules/look";
import moment from "moment-timezone";
import { teams_dashboard_permission } from "Permissions/teamsDashboard.permissions";
import {Spin} from 'antd'

const TeamsDashboardContainer = ( props ) => {
    const { me, client, userPermission,match } = props
    const [ team_employees, setTeamEmployees ] = React.useState( [] )

    const [ Permission, setPermission ] = React.useState()
    const [ loading, setLoading ] = React.useState( false )
    const [ one_on_one_status, setOneOnOneStatus ] = React.useState()
    const [ meeting_effectiveness, setMeetingEffectiveness ] = React.useState()
    const [team_employee_loading,setTeamEmployeeLoading] = React.useState(false)
    const [show_last_feedback_status,setShowLastFeedbackStatus] = React.useState()
    const [team_id,setTeamId]=React.useState()
    const mountedRef = React.useRef( true )
    let teamSub = undefined

    // code to setup the permissions validator

    React.useEffect( () => {
        if (userPermission?.length ) {
            setLoading( true )
            let permission = globalPermissionValidator(
                teams_dashboard_permission,
                userPermission );
            // @ts-ignore
            setPermission( permission )
        }
    }, [ userPermission ] )

    React.useEffect(()=>{
        if(match?.params){
            setTeamId(getTeamBase64StringFromInt(match?.params?.id))
        }
    },[match])

    React.useEffect( () => {
        if ( me?.employee&&Permission&&team_id ) {
            if ( Permission?.oneonone_permission ) {
                lastOneOnOneDetails( me?.employee?.id )
                oneOnOneEffectivenessFeedback( me?.employee?.id )
                LastFeedback(me?.employee?.id )
            }
            getTeamsById(team_id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ me,Permission,team_id ] )

    React.useEffect( () => {
        return () => {
            if ( teamSub ) {
                teamSub.unsubscribe();
            }
        }
    } )

    React.useEffect( () => {
        return () => {
            mountedRef.current = false
        }
    }, [] )

    React.useEffect( () => {
        mountedRef.current = true
    }, [] )

    const oneOnOneEffectivenessFeedback = async ( id ) => {
        setLoading( true )
        const { data } = await client.query( {
            query: ONE_ON_ONE_EFFECTIVENESS_FEEDBACK,
            fetchPolicy: 'network-only',
            variables: { meetingHost: id }
        } );
        if ( data?.allMeetingFeedbacks ) {
            setMeetingEffectiveness(
                {
                    hot_skills: average( data.allMeetingFeedbacks?.edges?.map( ( { node } ) => node?.hostSkills ) ),
                    meeting_effectiveness: average( data.allMeetingFeedbacks?.edges?.map( ( { node } ) => node?.meetingEffectiveness ) )
                }
            )
            setLoading( false )
        }

    }

    const LastFeedback =async (id)=>{
        const { data } = await client.query( {
            query: LAST_FEEDBACK_GIVEN,
            fetchPolicy: 'network-only',
            variables: { feedbackFrom: id }
        } );
        if(data?.allFeedback?.edges?.length){
            let feedback_date = data?.allFeedback?.edges[0]?.node?.createdAt
            
            if(moment(feedback_date).isBefore(moment().subtract(3, 'days'))){
                setShowLastFeedbackStatus(true)
            }
            else{
                setShowLastFeedbackStatus(false)
            }
        }
    }

    const average = ( numbers ) => {
        const sum = numbers.reduce( ( acc, num ) => acc + num, 0 );
        const average = sum / numbers.length;
        return average;
    }

    const getTeamsById = async ( id ) => {
        setTeamEmployeeLoading( true )
        const { data } = await client.query( {
            query: DASHBOARD_TEAMS_BY_ID,
            fetchPolicy: 'network-only',
            variables: { id: id }
        } );
        if ( data?.Team ) {
            setTeamEmployees( data?.Team?.employees?.edges?.map( ( { node } ) => node ) )
        }
        teamSub = client.subscribe( {
            query: TEAM_LIST_SUBSCRIPTION,
            variables: { id: id }
        } ).subscribe( {
            next( result ) {
                console.log("result.data.teamSubscription.mutation",result.data.teamSubscription.mutation)
                switch ( result.data.teamSubscription.mutation ) {
                    
                    case 'UPDATE':
                        setTeamEmployees( result.data.teamSubscription?.team?.employees?.edges?.map( ( { node } ) => node ) )
                        break
                    default:
                        break
                }
            }
        } )
        setTeamEmployeeLoading(false)
    }

    const lastOneOnOneDetails = async ( id ) => {
        setLoading( true )
        const { data } = await client.query( {
            query: TEAMS_ONE_ON_ONE_LAST_DATE,
            fetchPolicy: 'network-only',
            variables: { owner: id }
        } );
        if ( data?.allMeetings ) {
            let last_one_on_one = {}
            if ( moment( data?.allMeetings?.edges[ 0 ]?.node?.when ).isBefore( moment() ) ) {
                let last_meeting_date = moment( data?.allMeetings?.edges[ 0 ]?.node?.when )
                let time_dif = moment().diff( last_meeting_date, 'days' )
                last_one_on_one = {
                    difference: time_dif,
                    date: data?.allMeetings?.edges[ 0 ]?.node?.when,
                    id: data?.allMeetings?.edges[ 0 ]?.node?.id
                }
            }
            else {
                last_one_on_one = {
                    difference: 0,
                    date: data?.allMeetings?.edges[ 0 ]?.node?.when,
                    id: data?.allMeetings?.edges[ 0 ]?.node?.id
                }
            }
            setOneOnOneStatus( last_one_on_one )
            setLoading( false )
        }
    }
    const RelaodData =()=>{
        getTeamsById(team_id)
    }
    return (
        <>
            {Permission?<TeamsDashboardView
                permission={Permission}
                team_employees={team_employees}
                one_on_one_status={one_on_one_status}
                meeting_effectiveness={meeting_effectiveness}
                loading={loading}
                team_employee_loading={team_employee_loading}
                team_id={team_id}
                show_last_feedback_status={show_last_feedback_status}
                dataReload ={()=>RelaodData()}
                {...props} />:<Spin spinning={true} size='large'><div style={{width:"100%",height:"80vh"}}></div></Spin>}
        </>
    )
}

export default compose( withApollo )( TeamsDashboardContainer )