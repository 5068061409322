import expression_less from '../../assets/expressionless-face.svg'
import neutral_smile from '../../assets/neutral-face.svg'
import slightly_smile from '../../assets/slightly-smiling-face.svg'
import smiling_face from '../../assets/smiling-face.svg'
import grinning_face from '../../assets/grinning-face-with-smiling-eyes.svg'

export const review_smiles =[
    {
        icon:expression_less,
        title:'Not satisfied'
    },
    {
        icon:neutral_smile,
        title:'Satisfied'
    },
    {
        icon:slightly_smile,
        title:"Average"
    },
    {
        icon:smiling_face,
        title:"Good"
    },
    {
        icon:grinning_face,
        title:"Amazing"
    }
]