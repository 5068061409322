import React from 'react'
import { compose } from '../../../core';
import { withApollo } from '@apollo/client/react/hoc';
import { ALL_MEETING_COUNT } from 'modules/Objective-keyresult/graphql/MeetingCount.gql';
import DiscussRequest from '../RequestDiscussView'
import moment from 'moment-timezone'; 
import { withCreateActionItem } from '../../container/objective-keyresult-operations';
import { getIntFromString } from 'modules/look';
const RequestDiscussContainer =(props)=>{
    const {me,client,requestDetail}=props
    const [loading,setLoading]=React.useState(false)
    const [Allmeeting,setAllmeeting]=React.useState()
    const AllmeetingCount = async () => {
        setLoading(true)
        let currentDate = moment().utc()
        let weekendDate = new Date(moment().format())
        let dif = 6-weekendDate.getDay()
        weekendDate.setDate(weekendDate.getDate()+dif)
        let IsoWeekendDate = moment(moment(weekendDate).endOf('day'))
        const { data } = await client.query({
            query: ALL_MEETING_COUNT,
            variables: {when_Range:[currentDate,IsoWeekendDate], meetingType:'ONE_ON_ONE',orderBy:['when'],endedAt_Isnull:true,member:getIntFromString(me?.employee?.id)},
            fetchPolicy: 'network-only'
        });
        if(data?.allMeetings){
            
            let all_meetings = data?.allMeetings?.edges?.filter(({ node }) => 
            node?.owner?.id === requestDetail?.createdBy?.id ||
            node?.member?.edges?.some(item => item?.node?.id === requestDetail?.createdBy?.id)
          );
            setAllmeeting(all_meetings?.sort((a,b)=>a.when-b.when))
            setLoading(false)
        }
        
    }

    React.useEffect(()=>{
        setLoading(true)
        AllmeetingCount()
    },[me])

    return (
        <>
            <DiscussRequest {...props} Allmeeting={Allmeeting} loading={loading}/>
        </>
    )
}

export default compose(withApollo,withCreateActionItem)(RequestDiscussContainer)