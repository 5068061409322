import React from "react"
import "../css/ai-tips-style.css"
import ai_tips_icon from "../../assets/ai-tips-icon.svg"
import grey_star from "../../assets/grey_star.svg"
import green_star from "../../assets/green_star.svg"
import { displayUtcToUserTimezone, DownloadRunpodTip, getIntFromString, globalPermissionValidator } from "modules/look"
import ai_module_route from "../route"
import { compose } from "modules/core"
import { withUpdateAiTip } from "../container/ai-tip-operations"
import { Dropdown,Switch } from "antd"
import settings_gear from '../../assets/settings-gear-black-icon.svg'
import close_icon from "../../assets/close-icon-without-bg.svg"
import { ai_tip_settings_permission } from "Permissions/aiTipSettings.permissions"
import { TIP_SETTINGS } from "../query/ai-tip-query.gql"
import { withCreateSettingsTip, withUpdateSettingsTip } from "modules/user/myProfile/containers/profile-opertations"
import CustomLoader from "../../look-v2/Components/customLoader"
const AITipsView = (props) => {
    const { navigateRoute, preview_tip, SetPreviewTip, tip_list, updateRunpodtips, module, userPermission, client, createTipsettings, updateTipsettings, me, stopAILoader, casper, tipsExpanded, casperExpanded, globalPlacement } = props
    const [tips_expanded, SetTipsExpanded] = React.useState(false) 
    const [placement, SetPlacement] = React.useState("bottom-right")
    const [tip_lists, setTipLists] = React.useState()
    const [dropdownVisible, setDropDownVisible] = React.useState(false)
    const [permission, SetPermission] = React.useState()
    const [selected_setting, setSelectedSetting] = React.useState()
    const [checked, setChecked] = React.useState(true)
    React.useEffect(() => {

        if (userPermission?.length) {
            let permission = globalPermissionValidator(
                ai_tip_settings_permission,
                userPermission);
            SetPermission(permission)

        }
    }, [userPermission])

    React.useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setDropDownVisible(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    React.useEffect(() => {
        if (permission?.required_permission) {
            getSettings({ module: module })
        }
    }, [permission, me])

    React.useEffect(() => {
        if (tip_list) {
            setTipLists(tip_list)
        }
    }, [tip_list])

    const changeTipView = () => {
        if (!tips_expanded) {
            SetPreviewTip(null)
            SetTipsExpanded(true)
        }
    }
    const getSettings = async (filter) => {
        try {
            const { data } = await client.query({
                query: TIP_SETTINGS,
                fetchPolicy: 'network-only',
                variables: { ...filter }
            })
            if (data?.tipSettingsByUser?.edges?.length) {
                let data_set = data?.tipSettingsByUser?.edges[0]?.node
                setSelectedSetting(data_set)
                setChecked(data_set?.active)
            }
            else {
                setChecked(true)
            }
        }
        catch (e) {
        }
    }
    const handleDragStart = (e) => { 
        // Hide the default drag image by setting an empty Image
        const img = new Image();
        img.src = '';
        e.dataTransfer.setDragImage(img, 0, 0);
    };

    const handleDragEnd = (e) => { 
        const container = e.target.parentNode.getBoundingClientRect();
        const leftPos = e.clientX - container.left;

        // Determine the position based on the dragged coordinates
        if (leftPos < container.width / 3) {
            SetPlacement('bottom-left'); // Snap to bottom-left
        } else if (leftPos < (2 * container.width) / 3) {
            SetPlacement('bottom-center'); // Snap to bottom-center
        } else {
            SetPlacement('bottom-right'); // Snap to bottom-right
        }
    }

    const bookmarkTip = async (value, type) => {
        try {
            let response = await updateRunpodtips({ favourite: value?.saved, id: getIntFromString(value.id) });
            if (response?.id) {
                if (type === 'list') {
                    const newSections = tip_lists?.map((section) =>
                        section.id === value?.id ? { ...section, is_saved: value?.saved } : section
                    );
                    setTipLists(newSections)
                    SetPreviewTip(null)
                }
                else {
                    let updatedTip = { ...preview_tip, is_saved: value?.saved };
                    SetPreviewTip(updatedTip);
                }
              
            }
        }
        catch (error) {

        }

    }

    const handleToggleChange = (e) => {
        setChecked(e)
        if (selected_setting?.id) {
            updatesettings(e)
        }
        else {
            createsettings(e)
        }
    };
    const createsettings = async (value) => {
        let response = await createTipsettings({ active: value, module: module })
        if (response) {
            getSettings({ module: module })
        }
    }

    const updatesettings = async (value) => {
        let response = await updateTipsettings({ id: getIntFromString(selected_setting?.id), active: value })
        if (response) {
            getSettings({ module: module })
        }
    }
    const isMounted = React.useRef(false);
    React.useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            return;
        }

        if (casper) {
            if (tipsExpanded) {
                SetTipsExpanded(true)
                SetPreviewTip(null)
            } else {
                SetTipsExpanded(false)
            }
            if (casperExpanded) {
                SetPreviewTip(null)
                SetTipsExpanded(false)
            }
        }
    }, [tipsExpanded, casperExpanded])

    const filterMenu = () => (
        <div className="custom-filter-container" style={{ width: "100%", minWidth: "25em", marginTop: "1em", boxShadow: "4px 4px 28.7px 0px rgba(170, 186, 198, 0.25)", padding: ".5em" }}>
            <div className="ai-tip-card tip-section-container" style={{ display: "flex", flexDirection: "column", background: "#fff" }}>
                <div className="message-action-container " style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em", borderBottom: "none", padding: "0px" }}>
                    <h3 style={{ margin: "0px", fontSize: "1.1em", fontWeight: "500" }}>Turn {checked ? 'off' : 'on'} the Ai tips for {module}</h3>
                    <Switch
                        className="tip-action-switch"
                        style={{ backgroundColor: "#F6FBFF !important" }}
                        checked={checked}
                        onChange={(e) => {
                            handleToggleChange(e);
                        }}
                    />
                </div>
                <h3 style={{ margin: "0px", cursor: "pointer", fontSize: "1.1em", fontWeight: "500" }} onClick={() => navigateRoute('/user/profile?tip_settings')}>Tips setting</h3>
            </div>

        </div>
    )

    const oncloseTip = () => {
        if (stopAILoader) {
            stopAILoader()
        }
        SetPreviewTip(null)
    }

    return (
        <>
            {/* preview tip section */}
            <div className={preview_tip ? "show-preview-tip" : "hide-preview-tip"}>
                <div className="tip-header-section">
                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "start" }}>
                        <img src={ai_tips_icon} style={{ width: "70px" }} alt="" />
                        <h4 className="ai-tip-header-title" style={{ color: "rgba(0, 0, 0, 1)", paddingTop: "15px" }}>{preview_tip?.loader ? "Tip" : preview_tip?.title}</h4>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center" }}>
                        <img src={close_icon} onClick={() => { oncloseTip() }} style={{ cursor: "pointer", width: "16px" }} alt="" />
                    </div>
                </div>
                {
                    preview_tip?.loader ?
                        <div className="ai-tip-loader-container">
                            <CustomLoader />
                        </div>
                        :
                        <div className="tip-preview-content">
                            <h4>{preview_tip?.content}</h4>
                        </div>
                }

                {
                    !preview_tip?.loader && (

                        <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                            <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", cursor: "pointer" }}>
                                {/* <img src={green_star} style={{ cursor: "pointer" }} alt="" /> */}
                                <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", cursor: "pointer" }} onClick={() => DownloadRunpodTip(preview_tip)}>Download</p>
                            </div>
                            {
                                preview_tip?.is_saved ?
                                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", cursor: "pointer" }}>
                                        <img src={green_star} style={{ cursor: "pointer" }} alt="" onClick={() => bookmarkTip({ saved: false, id: preview_tip?.id })} />
                                        <p style={{ margin: "0px", color: "#0DD671", cursor: "pointer" }} onClick={() => bookmarkTip({ saved: false, id: preview_tip?.id })}>Bookmarked</p>
                                    </div> :
                                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", cursor: "pointer" }} >
                                        <img src={grey_star} style={{ cursor: "pointer" }} alt="" onClick={() => bookmarkTip({ saved: true, id: preview_tip?.id })} />
                                        <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", cursor: "pointer" }} onClick={() => bookmarkTip({ saved: true, id: preview_tip?.id })}>Bookmark</p>
                                    </div>
                            }
                        </div>

                    )
                }


            </div>
            <div className="ai-tip-fixed-container">
                <div className="ai-tip-relative-container">
                    <div className={tips_expanded ? `tip-view-mode ${casper ? globalPlacement : placement}` : `tip-hide-mode ${casper ? globalPlacement : placement}`}>
                        <div className="tips-view-container">
                            <div className="tip-header-section">
                                <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center" }}>
                                    <img src={ai_tips_icon} style={{ width: "80px" }} alt="" />
                                    <h4 className="ai-tip-header-title">MApp Tips</h4>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", gap: "1em", alignItems: "center", paddingRight: ".5em" }}>
                                    {permission && permission?.required_permission && <Dropdown overlayStyle={{ zIndex: 1500 }} className="ai-tip-settings-dropdown" visible={dropdownVisible} overlay={filterMenu} placement={"bottomRight"}>
                                        <img src={settings_gear} style={{ cursor: "pointer" }} alt="settings_gear" onClick={() => setDropDownVisible(!dropdownVisible)} />
                                    </Dropdown>}
                                    {dropdownVisible ? (<div className='overlay-dynamic-island' onClick={() => { setDropDownVisible(false) }} />) : null}
                                    <img src={close_icon} onClick={() => { SetTipsExpanded(false) }} style={{ cursor: "pointer", width: "15px" }} alt="" />
                                </div>
                            </div>

                            {/* ai tip list section */}
                            <div className="ai-tip-list">
                                {
                                    tip_lists?.map(i => (
                                        <div className="ai-tip-card">
                                            <h4 style={{ margin: "0px", fontSize: "1em", fontWeight: "700", color: "rgba(170, 186, 198, 1)" }}>{i?.module}</h4>
                                            <h3>{i?.title}</h3>
                                            <h4>{i?.content}</h4>
                                            {
                                                i?.is_saved ?
                                                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                                                            <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", cursor: "pointer" }} onClick={() => DownloadRunpodTip(i)}>Download</p>
                                                            <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", cursor: "pointer" }}
                                                            >
                                                                <img src={green_star} style={{ cursor: "pointer" }} alt="" onClick={() => bookmarkTip({ saved: false, id: i?.id }, 'list')} />
                                                                <p style={{ margin: "0px", color: "#0DD671", cursor: "pointer" }} onClick={() => bookmarkTip({ saved: false, id: i?.id }, 'list')}>Bookmarked</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: ".5em", alignItems: "center" }}>
                                                            <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)" }}>{displayUtcToUserTimezone(i?.created_at, "DD-MM-yyyy")}</p>
                                                            <div className="border-sepeartion"></div>
                                                            <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)" }}>{displayUtcToUserTimezone(i?.created_at, "hh:mm a")}</p>
                                                        </div>

                                                    </div> :
                                                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", justifyContent: "space-between" }}>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                                                            <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", cursor: "pointer" }} onClick={() => DownloadRunpodTip(i)}>Download</p>
                                                            <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center" }}  >
                                                                <img src={grey_star} style={{ cursor: "pointer" }} alt="" onClick={() => bookmarkTip({ saved: true, id: i?.id }, 'list')} />
                                                                <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", cursor: "pointer" }} onClick={() => bookmarkTip({ saved: true, id: i?.id }, 'list')}>Bookmark</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "row", gap: ".5em", alignItems: "center" }}>
                                                            <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)" }}>{displayUtcToUserTimezone(i?.created_at, "DD-MM-yyyy")}</p>
                                                            <div className="border-sepeartion"></div>
                                                            <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)" }}>{displayUtcToUserTimezone(i?.created_at, "hh:mm a")}</p>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            <p style={{ fontWeight: "500", fontSize: "1em", marginTop: "0.5em", cursor: "pointer" }} onClick={() => { navigateRoute(ai_module_route.tip) }}>See all the AI tips</p>
                        </div>
                    </div>

                    {!casper && <img src={ai_tips_icon} className={`ai-tips-icon ${placement}`} onDragStart={handleDragStart} onDragEnd={handleDragEnd} draggable={true} alt="" onClick={() => { changeTipView() }} />}
                </div>
            </div>
        </>
    )
}

export default compose(withUpdateAiTip, withCreateSettingsTip, withUpdateSettingsTip)(AITipsView)
