import { Button, Modal, Progress, Select, Spin, Tooltip, Input } from "antd";
import { cc_graduation_test } from "../../../../config";
import { displayUtcToUserTimezone, getIntFromString } from "modules/look";
import { dashboard_home_icons } from "modules/poc-dashboard/icons";
import poc_dashboard_routes from "modules/poc-dashboard/routes";
import React from "react";
import styled from "styled-components";
import Growpagination from "modules/grow-model/components/growPagination";
import InvitationListContainerPOC from "../../containers/poc_report/invitationListContainer"
const PocReprtListView = (props) => {
    const { org_survey_lists, selected_survey_response, allEmployeeNon360SurveyResponse, table_data_loading, navigateRoute, sortScore, total_score, response_page_info, survey_list_loading, allEmployee360SurveyResponse, GetDataForcsvOf360Survey, export_loader, download_progress, setSurveyResponseSet, GetDataForcsvOfNon360Survey } = props
    const [filter, setFilter] = React.useState()
    const [defult_filter, setDefaultFilter] = React.useState()
    const [invitation_list_detail, SetInvitationListDetail] = React.useState()

    let search_timer = React.useRef(null)
    let search_ref = React.useRef(null)

    React.useEffect(() => {
        if (org_survey_lists?.length > 0) {
            let default_value = org_survey_lists?.find(item => cc_graduation_test.includes(getIntFromString(item?.id)))
            let selected_survey = JSON.parse(localStorage.getItem('selected_survey_response'))

            if (selected_survey) {
                setFilter({ id: selected_survey?.id, name: selected_survey?.name, is_360: selected_survey?.is_360, max_score: selected_survey?.max_score })
                setDefaultFilter(selected_survey)
            }
            else {
                if (default_value) {
                    setFilter({ id: default_value?.id, name: default_value?.name, is_360: default_value?.multisourceResponse, max_score: default_value?.max_score })
                    setDefaultFilter({ ...default_value, is_360: default_value?.multisourceResponse })
                }
                else {
                    setFilter({ id: org_survey_lists[0]?.id, name: org_survey_lists[0]?.name, is_360: org_survey_lists[0]?.multisourceResponse, max_score: org_survey_lists[0]?.max_score })
                    setDefaultFilter({ ...org_survey_lists[0], is_360: org_survey_lists[0]?.multisourceResponse })
                }
            }

        }
    }, [org_survey_lists])

    React.useEffect(() => {
        if (filter != undefined) {
            setSurveyResponseSet([])
            handleSurveySelect()
        }
    }, [filter])

    const handleSurveySelect = () => {
        if (filter?.is_360) {
            allEmployee360SurveyResponse({ survey: filter?.id, nameIcontains: search_ref.current })
        } else {
            allEmployeeNon360SurveyResponse({ survey: filter?.id, nameIcontains: search_ref.current })
        }
    }


    const seeReport = (item) => {
        localStorage.setItem('selected_survey_response', JSON.stringify(filter))
        navigateRoute(poc_dashboard_routes?.poc_user_report.replace(':survey_id', getIntFromString(filter?.id)).replace(':response_id', getIntFromString(item?.response_id)))
    }

    const View360InvitationList = (item) => {
        let detail = {
            ...item,
            survey: filter
        }
        SetInvitationListDetail(detail)
    }

    const displayResponseStatus = (response_id) => {
        if (response_id) {
            return (
                <div className="s-i-complete-button" style={{ width: "8em" }}>
                    Completed
                </div>
            );
        } else
            return (
                <div className='s-i-pending-button' style={{ width: "8em" }}>
                    Pending
                </div>
            );
    }

    const display360Status = (item) => {
        let is_pending = false
        if (!item?.response_id) {
            is_pending = true
        }
        else if (!item?.total_invitation) {
            is_pending = true
        }
        else if (item?.total_invitation !== item?.completed_invitation) {
            is_pending = true
        }
        if (is_pending) {
            return (
                <div className='s-i-pending-button' style={{ width: "8em" }}>
                    Pending
                </div>
            )
        } else {
            return (
                <div className='s-i-complete-button' style={{ width: "8em" }}>
                    Completed
                </div>
            );
        }

    }

    const onPagination = () => {
        if (filter) {
            if (filter?.is_360) {
                allEmployee360SurveyResponse({ survey: filter?.id, after: response_page_info?.after, nameIcontains: search_ref.current })
            } else {
                allEmployeeNon360SurveyResponse({ survey: filter?.id, after: response_page_info?.after, nameIcontains: search_ref.current })
            }
        }

    }

    const exportData = () => {
        if (filter) {
            if (filter?.is_360) {
                GetDataForcsvOf360Survey({ survey: filter?.id, survey_name: filter?.name })
            } else {
                GetDataForcsvOfNon360Survey({ survey: filter?.id, survey_name: filter?.name })
            }
        }
    }


    const searchData = (search) => {
        if (search_timer.current) {
            clearTimeout(search_timer?.current)
        }
        search_timer.current = setTimeout(() => {
            search_ref.current = search?.trim()
            if (filter?.is_360) {
                allEmployee360SurveyResponse({ survey: filter?.id, nameIcontains: search?.trim() })
            } else {
                allEmployeeNon360SurveyResponse({ survey: filter?.id, nameIcontains: search?.trim() })
            }
        }, 500);
    }

    const getScore = (id) => {
        let selected_survey = org_survey_lists?.find(i => i?.id === id)
        return (
            <h4 className="org-table-header-label" style={{ color: "#000" }}>Max Score : {selected_survey?.max_score || 0}</h4>
        )
    }

    return (
        <div>

            <Spin spinning={survey_list_loading || table_data_loading}>
                {
                    org_survey_lists?.length ?
                        <div className="poc-org-root poc-survey-report-main-container" style={{ height: "100%", maxHeight: "100%" }}>
                            <div style={{ padding: "0.5em", display: "flex", justifyContent: "center" }}>
                                <h4 className="poc-dashboard-card-title" style={{ textAlign: "center" }}>Survey Report</h4>
                            </div>

                           <div className="row-layout-wrap" style={{ margin: "0.5em 0px", padding: "0em 2em" }} >

                                <div className="poc-survey-selector-report">
                                    <p className="poc-dashboard-card-title" style={{ fontSize: '.85em', fontWeight: "500" }}>Choose survey to see the reports</p>
                                    {defult_filter && <CustomSelectSurvey dropdownClassName="content-dropdown" placeholder="Select Survey" onChange={(e, item) => { setFilter({ id: item?.key, name: item?.value, is_360: item?.label, max_score: item?.max_score }) }} defaultValue={defult_filter?.name}>
                                        {org_survey_lists?.map(item => (
                                            <Select.Option className='dashobard-performance-table-heading' style={{ color: '#A3A3A3', fontFamily: "Poppins", fontWeight: 500 }} key={item?.id} value={item?.name} label={item?.multisourceResponse}>{item?.name}</Select.Option>
                                        ))}
                                    </CustomSelectSurvey>}
                                </div>

                                <div className="poc-report-action-detail-section">
                                    <div className="poc-360-search-container">
                                        <img src={dashboard_home_icons?.search_icon} alt="search_icon" style={{ height: "20px" }} />
                                        <Input style={{ width: "100%", border: "none", height: "2.15em", fontSize: "1em", fontFamily: "Poppins", backgroundColor: "transparent" }} placeholder="Search" onChange={(e) => searchData(e?.target?.value)} />
                                    </div>

                                    {!table_data_loading && selected_survey_response?.length ?
                                        <div className="poc-report-export-score-scetion">

                                            {!export_loader ?
                                                <Button className="poc-report-export-btn" onClick={() => exportData()}  ><img src={dashboard_home_icons?.export_icon} alt="export_icon" style={{ height: "1.25em", marginRight: "1em" }} /> Export</Button> :
                                                <div className='org-export-btn-parent'>
                                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: 'center', gap: ".5em" }}>
                                                        <Spin spinning={true} tip='Exporting...'></Spin>
                                                        <Progress type="circle" percent={download_progress} strokeColor="#4CCB1F" strokeWidth={10} />
                                                    </div>
                                                </div>
                                            }
                                            {cc_graduation_test.includes(getIntFromString(filter?.id)) && (getScore(filter?.id))}
                                        </div>
                                        : <div></div>}
                                </div>
                            </div>
                            <div className='org-table-container poc_table_scroll poc-report-table-height' style={{ width: '100%', padding: "0em 2em", marginTop: "0em" }}>
                                {
                                    !filter?.is_360 ?
                                        <table className='org-table'>
                                            <thead>
                                                <tr className='org-row-header'>
                                                    <th className='org-table-row-padding-left org-left-radious' >
                                                        <h4 className='org-table-header-label'>Name</h4>
                                                    </th>
                                                    <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                                        <h4 className='org-table-header-label'>Email</h4>
                                                    </th>
                                                    <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                                        <h4 className='org-table-header-label'>Response Date</h4>
                                                    </th>
                                                    <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                                        <h4 className='org-table-header-label'>Status</h4>
                                                    </th>
                                                    {cc_graduation_test.includes(getIntFromString(filter?.id)) &&
                                                        <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                                            <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: 'center', gap: '0.25em' }}>
                                                                <h4 className='org-table-header-label' style={{ margin: "0em" }}>Score</h4>
                                                                <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: 'center', gap: '0.25em' }}>
                                                                    <svg width="20" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ height: ".55em", cursor: "pointer" }} onClick={() => sortScore("ascending")}>
                                                                        <path d="M4.7 25L20 9.54791L35.3 25L40 20.2429L20 0L0 20.2429L4.7 25Z" fill="#fff" fill-opacity="1" />
                                                                    </svg>
                                                                    <svg width="20" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ height: ".55em", cursor: "pointer" }} onClick={() => sortScore("descending")}>
                                                                        <path d="M4.7 0L20 15.4521L35.3 0L40 4.75709L20 25L0 4.75709L4.7 0Z" fill="#fff" fill-opacity="1" />
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                        </th>

                                                    }
                                                    <th className='org-table-header-label org-table-row-padding-rigth org-content-center org-table-header-label org-right-radious' style={{ textAlign: "center" }}>
                                                        <h4 className='org-table-header-label'>Action</h4>

                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    selected_survey_response?.map(item => (
                                                        <tr className='org-row-body'>
                                                            <td className='org-left-radious org-table-row-padding-left table-border-left' style={{ borderRight: "none" }}>
                                                                <h4 className='org-table-body-label' style={{ fontWeight: '500' }}><span className="text-transform-capitalize">{item?.firstName}</span> <span className="text-transform-capitalize">{item?.lastName}</span></h4>
                                                            </td>
                                                            <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                                <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.email}</h4>
                                                            </td>
                                                            <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                                <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.responseDate ? displayUtcToUserTimezone(item?.responseDate, 'MMM DD YYYY, hh:mm') : ""}</h4>
                                                            </td>
                                                            <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                                <div className="poc-table-action-td">
                                                                    {displayResponseStatus(item?.response_id)}
                                                                </div>
                                                            </td>
                                                            {cc_graduation_test.includes(getIntFromString(filter?.id)) && <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                                <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.response_id ? item?.totalScore : ""}</h4>
                                                            </td>}
                                                            <td className='org-table-row-padding-rigth org-content-center org-right-radious table-border-right' style={{ borderLeft: "none", marginLeft: "1em" }}>
                                                                {
                                                                    item?.response_id && (
                                                                        <Tooltip title={"View Report"}>
                                                                            <img src={dashboard_home_icons?.report_icon} alt="report_icon" style={{ cursor: "pointer" }} onClick={() => seeReport(item)} />
                                                                        </Tooltip>
                                                                    )
                                                                }

                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table> :
                                        <table className='org-table'>
                                            <thead>
                                                <tr className='org-row-header'>
                                                    <th className='org-table-row-padding-left org-left-radious' >
                                                        <h4 className='org-table-header-label'>Name</h4>
                                                    </th>
                                                    <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                                        <h4 className='org-table-header-label'>Email</h4>
                                                    </th>

                                                    <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                                        <h4 className='org-table-header-label'>Status</h4>
                                                    </th>

                                                    <th className='org-table-header-label org-table-row-padding-rigth org-content-center org-table-header-label org-right-radious' style={{ textAlign: "center" }}>
                                                        <h4 className='org-table-header-label'>Action</h4>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    selected_survey_response?.map(item => (
                                                        <tr className='org-row-body'>
                                                            <td className='org-left-radious org-table-row-padding-left table-border-left' style={{ borderRight: "none" }}>
                                                                <h4 className='org-table-body-label' style={{ fontWeight: '500' }}><span className="text-transform-capitalize">{item?.firstName}</span> <span className="text-transform-capitalize">{item?.lastName}</span></h4>
                                                            </td>
                                                            <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                                <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.email}</h4>
                                                            </td>


                                                            <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                                <div className="poc-table-action-td">
                                                                    {display360Status(item)}
                                                                </div>
                                                            </td>
                                                            <td className='org-table-row-padding-rigth org-content-center org-right-radious table-border-right' style={{ borderLeft: "none", marginLeft: "1em" }}>

                                                                <div className="poc-table-action-td">
                                                                    {
                                                                        (item?.response_id && item?.completed_invitation) ?
                                                                            <Tooltip title={"View 360 Report"}>
                                                                                <Button className="border-action-btn" onClick={() => seeReport(item)}>
                                                                                    <img src={dashboard_home_icons?.report_360} alt="360_report_icon" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                            : <Button className="report-action-btn-disabled"  >
                                                                                <img src={dashboard_home_icons?.report_360} alt="360_report_icon" />
                                                                            </Button>
                                                                    }
                                                                    {
                                                                        (item?.response_id && item?.total_invitation) ?
                                                                            <Tooltip title={"View 360 detail"}>
                                                                                <Button className="border-action-btn" onClick={() => { View360InvitationList(item) }}>
                                                                                    <img src={dashboard_home_icons?.invite_360} alt="360_invite" />
                                                                                </Button>
                                                                            </Tooltip> : <Button className="report-action-btn-disabled" >
                                                                                <img src={dashboard_home_icons?.invite_360} alt="360_invite" />
                                                                            </Button>
                                                                    }

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                }


                                {
                                    (!selected_survey_response?.length && !table_data_loading) ?
                                        <div className='rd-nodata' style={{ minHeight: "10em" }}>
                                            <h4 className='rd-nodata-label'>No data found</h4>
                                        </div> : ''
                                }
                                {(response_page_info?.hasNextPage && response_page_info?.after && !table_data_loading) && <div align='center'>
                                    <Growpagination on_pagination={() => onPagination()} />
                                </div>}
                            </div>

                        </div> : ""
                }

            </Spin>
            {(!survey_list_loading && !org_survey_lists?.length) ? <div className='rd-nodata' style={{ minHeight: "70vh" }}>
                <h4 className='rd-nodata-label'>No Survey found</h4>
            </div> : ""}

            <Modal
                visible={invitation_list_detail}
                destroyOnClose={true}
                footer={false}
                closable={false}
                centered={true}
                width="1100px"
                style={{ maxWidth: "90%" }}
            >
                <InvitationListContainerPOC invitation_list_detail={invitation_list_detail} onClose={() => { SetInvitationListDetail(null) }} {...props} />

            </Modal>
        </div>
    )
}


export default PocReprtListView;
const CustomSelectSurvey = styled(Select)`
.ant-select-selector{
    border: 1px solid #AABAC6 !impoortant;
    color:  #000;
    font-weight: 500;
    font-family: Poppins;
    font-size: 1em;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: left;
    border-radius:.5em !important;
    width:20em !important;
    height: 40px !important;
    padding-top: 4px !important;
}
`;