import { casper_permission } from "./casper.permissions";
import { kudos_permission } from "./kudos.permission";
import { okr_permission } from "./okr.permission";
import { runpod_permission } from "./runpod.permission";

export const feedback_permission= {
    requried_permission:['view_feedback', 'add_feedback', 'change_feedback', 'delete_feedback'],
    kudos_permission: ['view_badge', 'view_badgefamily' , 'view_badgeaward', 'add_badgeaward', 'change_badgeaward', 'delete_badgeaward'],
    okr_permission:okr_permission?.requried_permission,
    runpod_permission:runpod_permission?.required_permission,
    casper_permissions: casper_permission?.required_permission
}