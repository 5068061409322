import React from 'react';
import { Modal, Form, Row, Col, Button } from 'antd';
import { getIntFromString } from '../functions';
import TextField from './TextField';
import VerticalHeadField from './VerticalHeadField';
import CKEditorField from './CKEditorField';
import VerticalTransferField from './VerticalTransferField';
const CKEditorFieldName = 'description';
const AddSubVerticalField = props => {
  const { initialValues, organizationId, handleSubmit } = props;

  const [visible, setVisible] = React.useState(false);

  const initialValue = {
    subverticalId: initialValues && initialValues.subVertical && Number(getIntFromString(initialValues.subVertical.id))
  };
  return (
    <>
      <Button type="primary" onClick={() => setVisible(true)}>
        Add SubVertical
      </Button>
      <Modal
        destroyOnClose
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        title="Subertical"
        width="80%"
        // bodyStyle={{ padding: 12 }}
      >
        <div>
          <Form
            layout="vertical"
            onFinish={async values => {
              await handleSubmit(values);
              setVisible(false);
            }}
            initialValues={initialValue}
            required={false}
            scrollToFirstError={true}
          >
            <Row>
              <Col span={10} offset={1}>
                <Row>
                  <Col lg={14} md={20}>
                    <TextField name="name" label="Name" rules={[{ required: true, message: 'Name is required!' }]} />
                  </Col>
                </Row>
              </Col>
              <Col span={10}>
                <Row>
                  <Col span={24}>
                    <VerticalHeadField
                      orgId={organizationId}
                      name="headId"
                      label="Head"
                      rules={[{ required: true, message: 'Head is required!' }]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={3} />
              <Col span={22} offset={1}>
                <CKEditorField
                  name={CKEditorFieldName}
                  label="Description"
                  // initialValue={verticalByVerticalId && verticalByVerticalId.description}
                  // rules={[{ required: true, message: 'Description is required!' }]}
                />
              </Col>
            </Row>
            {/* <MarkdownField
            name="description"
            label="Description"
            initialValue={verticalByVerticalId && verticalByVerticalId.description}
            // rules={[{ required: true, message: 'Description is required!' }]}
          /> */}
            <Row justify="start">
              <Col span={24}>
                <VerticalTransferField
                  name="employees"
                  label="Employees"
                  // rules={[{ required: true, message: 'Employees are required!' }]}
                  initialValues={initialValue && initialValue.employees}
                  // onAdd={ROUTE.addEmployee}
                  orgId={Number(organizationId)}
                />
              </Col>
            </Row>
            <Row justify="end">
              <Button
                type="primary"
                htmlType="submit"
                // onClick={async () => {
                //   setVisible(false);
                // }}
              >
                Add
              </Button>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddSubVerticalField;
