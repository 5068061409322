// @ts-nocheck
import { Row, Tooltip } from 'antd';
import React from 'react';
import '../css/scape-report-table.css';
import '../css/scape-report.css';
import { SCAPE_REPORT_TABLE_ICON } from '../icons';
import { Capitalize } from 'modules/look';


const ScapeReportTablePersonalityView = (props) => {

    const { parametric_groups, table_data, selected_personality_type } = props
    console.log("parametric_groups",parametric_groups,table_data)
    const [responses, setResponses] = React.useState([]);
    const [parameterSet, setParameterSet] = React.useState([]);
    // Set of all personalities
    const [allPersonalities, setAllPersonalities] = React.useState([
        { title: "ISTJ", count: 0 },
        { title: "ISFJ", count: 0 },
        { title: "INFJ", count: 0 },
        { title: "INTJ", count: 0 },
        { title: "ISTP", count: 0 },
        { title: "ISFP", count: 0 },
        { title: "INFP", count: 0 },
        { title: "INTP", count: 0 },
        { title: "ESTP", count: 0 },
        { title: "ESFP", count: 0 },
        { title: "ENFP", count: 0 },
        { title: "ENTP", count: 0 },
        { title: "ESTJ", count: 0 },
        { title: "ESFJ", count: 0 },
        { title: "ENFJ", count: 0 },
        { title: "ENTJ", count: 0 },

    ]);

    const [parametersWithCount, setParameterWithCount] = React.useState([]);
    const [parametersWithTotalCount, setParameterWithTotalCount] = React.useState([]);



    // retrieving parametric groups and parameters 
    React.useEffect(() => {

        let groups = parametric_groups?.map(({ node }) => { return { ...node } });

        let parameters = groups?.map((value) =>

            value?.parameterSet?.edges?.map(({ node }) => {
                //  return { ...node?.choice, answer: node?.choice?.value, sequence: 0 } 
                const baseObject = {
                    ...node?.choice,
                    answer: node?.choice?.value,
                    sequence: 0,
                };
                if (node?.id !== '1') {
                    baseObject.show = node?.show;
                }
    
                return baseObject;
                })

        )?.flat();

        setParameterSet(parameters)
      


    }, [parametric_groups, table_data])






    

    React.useEffect(() => {

        if (responses?.length && parametersWithCount?.length) {
            let arr = [];
            let count = responses?.forEach((value) =>
                arr.push(value?.responses[value?.responses?.length - 1].personality)
            )?.filter((value) => value !== "")?.length

            arr = arr?.filter((value) => value !== "")?.length
            let data = [...parametersWithCount];
            data[data?.length - 1].sequence = arr;
            setParameterWithTotalCount(data);

        }

    }, [responses, parametersWithCount])



    // Build the string to display personality in table
    const getPersonality = (answers) => {

        let personality = "";

        if (answers?.length) {

            return answers?.map((value) => {
                switch (value?.answer) {
                    case "Introvert":
                        return "I"
                    case "Extrovert":
                        return "E"
                    case "Intuitive":
                        return "N"
                    case "Observant":
                        return "S"
                    case "Thinking":
                        return "T"
                    case "Feeling":
                        return "F"
                    case "Judging":
                        return "J"
                    case "Prospecting":
                        return "P"
                    case "Turbulent":
                        return "T"
                    case "Assertive":
                        return "A"
                }
            }


            )

        }



        return personality

    }

    // trimming the last letter of personality if last letter is based on assertive or turbulent nature
    const personalityTrim = (index, personality) => {
        let string;
        let data = responses[index]?.responses[responses[index]?.responses?.length - 1]?.personality
        
        if (data?.length > 4) {
            string = data?.substring(0, data.length - 1);
        } else {
            string = data;
        }


        return string;
    }

    // Generate responses from data received 
    const getResponses = (data, index) => {
        let answerSet;

        let personality
        if (data) {
            let responseSet = data?.user?.responseSet?.edges?.map(({ node }) => node);

            answerSet = responseSet?.map((value) =>
                value?.answerSet?.edges?.map(({ node }) => { return { ...node, sequence: node?.sequence ? node?.sequence : "-" } }))?.flat();

            personality = getPersonality(answerSet)?.slice(0,5)
        }

        let groups = parametric_groups?.map(({ node }) => node);



        let parameters = groups?.map((value) =>

            value?.parameterSet?.edges?.map(({ node }) =>
                //  node?.choice
            {
                const baseObject = {
                    ...node?.choice,
                };
        
                if (value?.id !== '1') {
                    baseObject.show = node?.show;
                }
                return baseObject;
            }
        )

        )?.flat();


        if (personality?.length) {
            personality = personality?.filter((value) => value !== undefined)?.join("")
        }

        let responseData = [];

        // get data generated for every parameter available in backend
        responseData = parameters?.map((parameter) => {
            let info = answerSet?.find((value) => parameter?.value?.trim() === value?.answer?.trim())

            if (info) {
                return { ...info, choice: info?.answer, personality: personality,show:parameter?.show }
            } else {
                return { ...parameter, answer: parameter?.value, personality: personality,show:parameter?.show }
            }

        }
        )?.flat();

        responseData.splice(0, 1)

        // This custom row have been added for showing username in table with all there responses

        let group = { id: "1", answer: "Name", value: "Name", choice: `${Capitalize(data?.user?.firstName)} ${Capitalize(data?.user?.lastName)}` }

        responseData.splice(0, 0, group);


        return responseData;
    }


    // Calculating the counts of every parameter of personality
    const getCounts = (data, params) => {

        let counts;

        if (data) {

            counts = params?.map((parameter, index) =>

                data?.map((node) => {
                    if (node?.answer?.trim() === parameter?.value?.trim()) {
                        params[index].sequence = params[index].sequence + 1;
                    }
                })
            )
        }

        params[0].sequence = 'Count'
        data = data.concat([{ responses: counts }])
        setParameterWithCount(params);


    }







    React.useEffect(() => {


        if (parameterSet?.length) {


            let data = [...responses];

            let params = [...parameterSet];
            let allResponses = table_data?.map((row) => row?.user?.responseSet?.edges?.map(({ node }) => node?.answerSet?.edges?.map(({ node }) => node)))?.flat()?.flat();

            getCounts(allResponses, params)





        }

    }, [parameterSet])


    // Calculating the counts of every personality type for e.g INTJ have count 3
    const getCountOfPersonalities = (data, personalitiesInfo) => {

        if (data) {

            let info = allPersonalities?.forEach((personality, index) =>

                data?.forEach((value, i) => {

                    let string = value?.responses[value?.responses?.length - 1].personality

                    if (string?.length > 4) {
                        string = string?.substring(0, string.length - 1);
                    }


                    if (string === personality?.title) {
                        personalitiesInfo[index].count = personalitiesInfo[index].count + 1
                    }
                }


                )

            )
        }

        return personalitiesInfo;

    }


    React.useEffect(() => {

        // setting responses with there count based on data
        if (table_data) {
            let employeeesWithResponses = [];

            let data = table_data?.forEach((user, index) => {
                employeeesWithResponses.push({ user: user?.user, responses: getResponses(user, index) })
            }
            )?.flat();

            let personalitiesInfo = [...allPersonalities]

            let countOfPersonalities = getCountOfPersonalities(employeeesWithResponses, personalitiesInfo)

            setAllPersonalities(countOfPersonalities);
            setResponses(employeeesWithResponses)
        }

    }, [table_data])



    


    // rendering parameter of personality with the letter denoted
    const renderChoice = (node) => {

        switch (node?.choice?.value) {

            case "Introvert":
                return "I"
            case "Extrovert":
                return "E"
            case "Intuitive":
                return "N"
            case "Observant":
                return "S"
            case "Thinking":
                return "T"
            case "Feeling":
                return "F"
            case "Judging":
                return "J"
            case "Prospecting":
                return "P"
            case "Turbulent":
                return "-T"
            case "Assertive":
                return "-A"
            case "Personality":
                return "Personality"

        }

    }


    // Show green tick or "-" if personality type is not matching if personality type does not match with 16 types of personality
    const getPersonalityRendered = (index, personality) => {

        let string = personalityTrim(index, personality)

        return <div className='table-response-row scape-report-table-radius 
       table-cell-scape-report-overview scape-report-table-padding 
       scape-label-font table-border-left-none' align='center'>{string?.toString()?.trim() === personality?.toString()?.trim() ?
                <img style={{ width: '1.2em' }} src={SCAPE_REPORT_TABLE_ICON.green_tick} />
                :
                "-"}</div>
    }










    const renderNonScrollableColumn = () => (
        <div className='scape-report-table-container'>

            {
                <Row className='scape-report-data-container' wrap={false}>


                    {
                        // Paramteres with personality types in detailed viwe in header
                        selected_personality_type?.id === 1 && parametersWithTotalCount[0]?.sequence &&
                        parametric_groups?.map((group, i) =>
                            i === 0 &&
                            <div className='scape-report-personality-group'>
                                <div className={`scape-report-personality-group${i} scape-report-group-row scape-report-table-radius
        table-border-bottom-none table-border-left-none scape-report-table-padding`} align='center'>

                                    <div className={`scape-report-personality-text${i}`}  >{group?.node?.name}</div>
                                </div>
                                <Row className={`scape-report-parameter-row${i}`} wrap={false} >
                                    {
                                        group?.node?.parameterSet?.edges?.map(({ node }, index) =>
                                            <div
                                                style={{ width: i === 0 ? '11em' : '9em' }}
                                                className={`scape-report-parameter${i}${index} scape-report-parameter scape-report-table-radius 
                     table-cell-scape-report scape-report-table-padding table-border-bottom-none table-border-left-none  scape-label-font`} align='center'>
                                                <Tooltip title={node?.choice?.value}>
                                                    {renderChoice(node)}
                                                </Tooltip>

                                            </div>
                                        )
                                    }
                                </Row>

                                <Row wrap={false}>
                                    {
                                        group?.node?.parameterSet?.edges?.map(({ node }, parameterIndex) =>
                                            <div>
                                                <div className={`parameter-column parameter-column-${i}`}>
                                                    {

                                                        responses?.map((value) => {

                                                            if (value?.responses?.length) {
                                                                return value?.responses?.map((response, index) => {

                                                                    if (response?.answer?.trim() === node?.choice?.value?.trim()) {
                                                                        return <div style={{
                                                                            width: index === 0 ? '11em' : '9em', fontWeight: '500', textOverflow: 'ellipsis'
                                                                            , whiteSpace: 'nowrap', overflow: 'hidden',
                                                                        }}
                                                                            className='table-response-row scape-report-table-radius 
                                                table-cell-scape-report scape-report-table-padding 
                                                scape-label-font table-border-bottom-none table-border-left-none' align='center'>
                                                                            {

                                                                                response?.answer === "Personality" ?
                                                                                    <div style={{
                                                                                        textOverflow: 'ellipsis'
                                                                                        , whiteSpace: 'nowrap', overflow: 'hidden'
                                                                                    }}>{response?.personality ? response?.personality : "-"}</div>
                                                                                    :
                                                                                    response?.choice ?

                                                                                        <div>

                                                                                            {
                                                                                                index === 0 ?
                                                                                                    <div className='personality-scape-ellipsis'> {response?.choice}</div>
                                                                                                    :
                                                                                                    <img style={{ width: '1.25em' }} src={SCAPE_REPORT_TABLE_ICON.green_tick} />

                                                                                            }

                                                                                        </div> :
                                                                                        <div>-</div>
                                                                            }

                                                                        </div>
                                                                    }
                                                                }

                                                                )
                                                            }
                                                        }

                                                        )
                                                    }
                                                </div>

                                                <div>
                                                    {

                                                        parametersWithTotalCount?.length && parametersWithTotalCount?.map((value, index) =>
                                                            index === 0 &&
                                                            <div>
                                                                {
                                                                    value?.value?.trim() === node?.choice?.value?.trim() ?
                                                                        <div style={{ width: index === 0 ? '11em' : '9em' }} className={`table-cell-scape-report
                                                scape-report-table-padding scape-label-font scape-table-count table-border-left-none parameter-counting-${index}`} align='center'>{value?.sequence}</div>
                                                                        :
                                                                        null
                                                                }

                                                            </div>

                                                        )

                                                    }

                                                </div>
                                            </div>
                                        )
                                    }

                                </Row>




                            </div>
                        )
                    }


                </Row>
            }





            {
                // Paramteres with personality types  overview in header
                selected_personality_type?.id === 2 &&

                <div className='scape-report-data-container-overview'>

                    <div style={{ display: 'flex', flexWrap: "nowrap", alignItems: "end" }}>
                        <div className='scape-label-font scape-overview-name scape-report-table-radius 
            scape-report-table-padding '>Name</div>
                      
                    </div>


                    {

                        responses?.map((value, index) =>

                            <div style={{ display: 'flex', flexWrap: "nowrap" }}>
                                <div className='scape-overview-user 
                    scape-report-table-padding  scape-label-font
                    scape-report-table-radius table-border-bottom-none 
                   ' >{value?.responses[0]?.choice}</div>

                            </div>

                        )
                    }


                    <Row wrap={false}>
                        <div style={{ borderRight: '0.0825em solid #A5E58F',zIndex:1500 }}
                            className='table-cell-scape-report scape-overview-user
                                        scape-report-table-padding scape-label-font scape-table-count'>Count</div>




                    </Row>




                </div>



            }


        </div>
    )








    const renderScrollableColumn = () => (
        <div className='scape-report-table-container'>

            {
                <Row wrap={false}>


                    {
                        // Paramteres with personality types in detailed viwe in header
                        selected_personality_type?.id === 1 && parametersWithTotalCount[0]?.sequence &&
                        parametric_groups?.map((group, i) =>
                            i !== 0 &&
                            <div className='scape-report-personality-group'>
                                <div className={`scape-report-personality-group${i} scape-report-group-row scape-report-table-radius
        table-border-bottom-none table-border-left-none scape-report-table-padding`} align='center'>

                                    <div className={`scape-report-personality-text${i}`}  >{group?.node?.name}</div>
                                </div>
                                <Row className={`scape-report-parameter-row${i}`} wrap={false} >
                                    {
                                        group?.node?.parameterSet?.edges?.map(({ node }, index) =>
                                            <div
                                                style={{ width: i === 0 ? '11em' : '9em' }}
                                                className={`scape-report-parameter${i}${index} scape-report-parameter scape-report-table-radius 
                     table-cell-scape-report scape-report-table-padding table-border-bottom-none table-border-left-none  scape-label-font`} align='center'>
                                                <Tooltip title={node?.choice?.value}>
                                                    {renderChoice(node)}
                                                </Tooltip>

                                            </div>
                                        )
                                    }
                                </Row>

                                <Row wrap={false}>
                                    {
                                        group?.node?.parameterSet?.edges?.map(({ node }, parameterIndex) =>
                                            <div>
                                                <div className={`parameter-column parameter-column-${i}`}>
                                                    {

                                                        responses?.map((value) => {

                                                            if (value?.responses?.length) {
                                                                return value?.responses?.map((response, index) => {

                                                                    if (response?.answer?.trim() === node?.choice?.value?.trim()) {
                                                                        return <div style={{
                                                                            width: index === 0 ? '11em' : '9em', fontWeight: '500', textOverflow: 'ellipsis'
                                                                            , whiteSpace: 'nowrap', overflow: 'hidden',
                                                                        }}
                                                                            className='table-response-row scape-report-table-radius 
                                                table-cell-scape-report scape-report-table-padding 
                                                scape-label-font table-border-bottom-none table-border-left-none' align='center'>
                                                                            {

                                                                                response?.answer === "Personality" ?
                                                                                    <div style={{
                                                                                        textOverflow: 'ellipsis'
                                                                                        , whiteSpace: 'nowrap', overflow: 'hidden'
                                                                                    }}>{response?.personality ? response?.personality : "-"}</div>
                                                                                    :
                                                                                    response?.choice ?

                                                                                        <div>

                                                                                            {
                                                                                                index === 0 ?
                                                                                                    <div className='personality-scape-ellipsis'> {response?.choice}</div>
                                                                                                    :
                                                                                                    <img style={{ width: '1.25em' }} src={SCAPE_REPORT_TABLE_ICON.green_tick} />

                                                                                            }

                                                                                        </div> :
                                                                                        <div>-</div>
                                                                            }

                                                                        </div>
                                                                    }
                                                                }

                                                                )
                                                            }
                                                        }

                                                        )
                                                    }
                                                </div>

                                                <div>
                                                    {

                                                        parametersWithTotalCount?.length && parametersWithTotalCount?.map((value, index) =>
                                                            index !== 0 &&
                                                            <div>
                                                                {
                                                                    value?.value?.trim() === node?.choice?.value?.trim() ?
                                                                        <div style={{ width: index === 0 ? '11em' : '9em' }} className={`table-cell-scape-report
                                                scape-report-table-padding scape-label-font scape-table-count table-border-left-none parameter-counting-${index}`} align='center'>{value?.sequence}</div>
                                                                        :
                                                                        null
                                                                }

                                                            </div>

                                                        )

                                                    }

                                                </div>
                                            </div>
                                        )
                                    }

                                </Row>




                            </div>
                        )
                    }


                </Row>
            }





            {
                // Paramteres with personality types  overview in header
                selected_personality_type?.id === 2 &&

                <div>

                    <div style={{ display: 'flex', flexWrap: "nowrap", alignItems: "end" }}>

                        <div style={{ display: 'flex', flexWrap: "nowrap" }} >

                            {

                                allPersonalities?.map((personality, personalityIndex) =>

                                    <div className='table-response-row scape-report-table-radius 
                    table-cell-scape-report-overview scape-report-table-padding 
                    scape-label-font table-border-bottom-none table-border-left-none' align='center'>{personality?.title}</div>
                                )

                            }

                        </div>
                    </div>


                    {

                        responses?.map((value, responseIndex) =>
                            <div style={{ display: 'flex', flexWrap: "nowrap" }}>


                                {
                                    allPersonalities?.map((personality, index) =>

                                        <Row wrap={false}>
                                            {

                                                getPersonalityRendered(responseIndex, personality?.title)

                                            }
                                        </Row>
                                    )

                                }


                            </div>

                        )
                    }



                    {/* Count of personalities in the data received */}
                    <Row wrap={false}>


                        <Row wrap={false}>
                            {

                                allPersonalities?.length && allPersonalities?.map((value, index) =>
                                    <Row wrap={false}>

                                        {

                                            <div style={{ width: '4.75em' }}
                                                className='table-cell-scape-report
                            scape-report-table-padding scape-label-font scape-table-count table-border-left-none' align='center'>{value?.count}</div>

                                        }

                                    </Row>

                                )
                            }
                        </Row>


                    </Row>

                </div>



            }


        </div>
    )

    return (




        <Row justify='center' align='bottom' wrap={false} style={{ gap: '0.5em' }}>

            {/* Non scrollable column */}

            <div>{renderNonScrollableColumn()}</div>


            {/* Scrollable column */}
            <div className='scrollable-column'>{renderScrollableColumn()}</div>


        </Row>


    )
}
export default ScapeReportTablePersonalityView





