import { Spin } from "antd"
import { displayUtcToUserTimezone } from "modules/look"
import { organization_icons } from "modules/poc-dashboard/icons"
import React from "react"


const InvitationListViewPOC = (props) => {
    const { onClose, invitation_list, invitation_list_loader, invitation_list_detail, page_info, getSurveyTokenList } = props

    const displayStatus = (response_id) => {
        if (response_id) {
            return (
                <div className="s-i-complete-button" style={{ width: "8em" }}>
                    Completed
                </div>
            );
        } else
            return (
                <div className='s-i-pending-button' style={{ width: "8em" }}>
                    Pending
                </div>
            );
    };

    return (
        <div style={{ minHeight: "50vh" }}>
            <div className="poc-360-invite-pop-head" style={{marginBottom:"1.5em"}}>
                <div>
                    <h4 className="poc-dashboard-card-title text-transform-capitalize" style={{ fontWeight: "600" }}>
                        360 status of {invitation_list_detail?.firstName} {invitation_list_detail?.lastName || ""} for {invitation_list_detail?.survey?.name}
                    </h4>
                    {/* <h4 className="poc-dashboard-card-title" style={{ fontWeight: "400", color: "rgba(137, 152, 162, 1)", fontSize: ".75em" }}>Response Date : {displayUtcToUserTimezone(invitation_list_detail?.responseDate, 'DD/MM/YYYY')}</h4> */}
                </div>
                <img src={organization_icons.close_icon} style={{ cursor: "pointer" }} onClick={() => { onClose() }} alt="close_icon" />
            </div>
            <Spin spinning={invitation_list_loader}>
                <div className='org-table-container poc_table_scroll container-alignment' style={{ width: '100%', padding: "0em 2em", marginTop: "0em" }}>
                    {
                        invitation_list?.length ?
                            <table className='org-table'>
                                <thead>
                                    <tr className='org-row-header'>
                                        <th className='org-table-row-padding-left org-left-radious' >
                                            <h4 className='org-table-header-label'>Name</h4>
                                        </th>
                                        <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                            <h4 className='org-table-header-label'>Email</h4>
                                        </th>
                                        <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                            <h4 className='org-table-header-label'>Response Date</h4>
                                        </th>
                                        <th className='org-table-header-label' style={{ textAlign: "center" }}>
                                            <h4 className='org-table-header-label'>Respondent Type</h4>
                                        </th>

                                        <th className='org-table-header-label org-table-row-padding-rigth org-content-center org-table-header-label org-right-radious' style={{ textAlign: "center" }} >
                                            <h4 className='org-table-header-label'>Status</h4>
                                        </th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        invitation_list?.map(item => (
                                            <tr className='org-row-body'>
                                                <td className='org-left-radious org-table-row-padding-left table-border-left' style={{ borderRight: "none" }}>
                                                    <h4 className='org-table-body-label' style={{ fontWeight: '500' }}><span className="text-transform-capitalize">{item?.user?.firstName}</span> <span className="text-transform-capitalize">{item?.user?.lastName}</span></h4>
                                                </td>
                                                <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                    <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.user?.email}</h4>
                                                </td>
                                                <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                    <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.response_date ? displayUtcToUserTimezone(item?.response_date, 'MMM DD YYYY, hh:mm') : ""}</h4>
                                                </td>
                                                <td className='table-border-up-down' style={{ textAlign: "center", borderRight: "none", borderLeft: "none" }}>
                                                    <h4 className='org-table-body-label' style={{ fontWeight: '500' }}>{item?.respondentType?.split("_")?.join(" ")}</h4>
                                                </td>

                                                <td className='org-table-row-padding-rigth org-content-center org-right-radious table-border-right' style={{ borderLeft: "none", marginLeft: "1em" }}>
                                                    <div className="poc-table-action-td">
                                                        {displayStatus(item?.response_id)}
                                                    </div>
                                                </td>


                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table> : ""
                    }

                    {
                        (!invitation_list?.length && !invitation_list_loader) ?
                            <div className='rd-nodata'  >
                                <h4 className='rd-nodata-label'>No Invitations Found</h4>
                            </div>
                            : ""
                    }

                    {(page_info?.hasNextPage && page_info?.after && !invitation_list_loader) && (
                        <div align="center" style={{ margin: "1em" }}>
                            <h4 onClick={() => { getSurveyTokenList({ after: page_info?.after }) }} className='rd-pagination'>View More</h4>
                        </div>
                    )}

                </div>
            </Spin>
        </div>
    )
}

export default InvitationListViewPOC