import { EyeOutlined, MenuOutlined } from '@ant-design/icons';
import { withApollo } from '@apollo/client/react/hoc';
import { Button, Card, Col, Divider, Empty, Row, Switch, Tooltip } from 'antd';
import arrayMove from 'array-move';
import { FORM_SECTION_SUBSCRIPTION } from 'modules/form-section/graphql/FormSectionSubscription.gql';
import { TEXT_SECTION_SUBSCRIPTION } from 'modules/text-section/graphql/TextSectionSubscription.gql';
import React from 'react';
import { useParams } from 'react-router-dom';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import styled from 'styled-components';
import { compose } from '../../core';
import { withEditFormSection } from '../../form-section/containers/FormSectionOperation';
import SimpleReferenceComponent from '../../form-section/containers/SimpleReferenceComponent';
import { DeleteIcon, getIntFromString, ReactMarkdown } from '../../look';
import TextSectionComponent from '../../text-section/containers/TextSectionComponent';
import { withEditTextSection } from '../../text-section/containers/TextSectionOperations';
import ROUTE from '../route';
import AddComponent, { TargetedDiv } from './AddComponent';

const TextFormSectionComponent = props => {
  const {
    formsection,
    textsection,
    onTextSectionDelete,
    onFormSectionDelete,
    setActionLoading,
    editTextSection,
    editFormSection,
    subscribeToMore,
    handleEditTool,
    client,
    me
  } = props;
  const [enableSorting, setEnableSorting] = React.useState(false);
  const { id } = useParams();
  let formSectionSubscription = undefined;
  const formSectionDataRef = React.useRef(null);
  const textSectionDataRef = React.useRef(null);
  const m = React.useRef(true)

  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])

  React.useEffect(() => {
    return () => {
      if (formSectionSubscription) {
        formSectionSubscription.unsubscribe();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])




  const getSubscribedToFormSections = async () => {
    await client.subscribe({
      query: FORM_SECTION_SUBSCRIPTION,
      fetchPolicy: 'network-only',
    }).subscribe({
      next(result) {

        switch (result?.data?.toolsFormSectionSubscription?.mutation) {

          case 'CREATE':

             
            let formSectionSet = [{ ...result?.data?.toolsFormSectionSubscription.formSection, type: 'formsection' },...formSectionDataRef.current?.edges.map(({ node }) => ({ ...node, type: 'formsection' }))]
            
            

            let newData = 
            [
              ...textsection?.edges.map(({ node }) => ({ ...node, type: 'textsection' })),
              ...formSectionSet
            ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))

             setData(newData);

            break;

          case 'UPDATE':

            let indexOfFormSection = formsection?.edges?.findIndex((value) =>
              getIntFromString(value?.node?.id) === getIntFromString(result?.data?.toolsFormSectionSubscription?.formSection?.id))

            let formSectionsSet = formsection;
            if (formSectionsSet.edges?.length) {
              formSectionsSet.edges[indexOfFormSection] = { node: { ...result?.data?.toolsFormSectionSubscription?.formSection, type: 'formsection' } }
            }

            let updatedData =
              [
                ...textsection?.edges.map(({ node }) => ({ ...node, type: 'textsection' })),
                ...formSectionsSet?.edges.map(({ node }) => ({ ...node }))
              ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))

            setData(updatedData)

            break;
          case 'DELETE':
            
            let newFormSectionSet;
            newFormSectionSet = formSectionDataRef.current?.edges?.filter((node) =>

              getIntFromString(node?.node?.id) !== getIntFromString(result?.data?.toolsFormSectionSubscription?.formSection?.id)

            )

            let components = [
              ...textSectionDataRef.current?.edges.map(({ node }) => ({ ...node, type: 'textsection' })),
              ...newFormSectionSet?.map(({ node }) => ({ ...node, type: 'formsection' }))
            ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))

            
            setData(components);

            formSectionDataRef.current = { edges: newFormSectionSet };

            break
          default:
            break
        }
      }
    })
  }



  const setUpdatedTextSections = (textSectionsSet) => {

    let updatedData =
      [
        ...textSectionsSet?.edges?.map(({ node }) => ({ ...node, type: 'textsection' })),
        ...formSectionDataRef.current?.edges?.map(({ node }) => ({ ...node, type: 'formsection' }))
      ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))


    textSectionDataRef.current = textSectionsSet;
    setData(updatedData)

  }


  const getSubscribedToTextSections = async () => {


    await client.subscribe({
      query: TEXT_SECTION_SUBSCRIPTION,
      fetchPolicy: 'network-only',
    }).subscribe({
      next(result) {




        switch (result?.data?.toolsTextSectionSubscription?.mutation) {

          case 'CREATE':
            
          let textSectionSet = [{ ...result?.data?.toolsTextSectionSubscription.textSection, type: 'textsection' },...textSectionDataRef.current?.edges.map(({ node }) => ({ ...node, type: 'textsection' }))]
          
            let components = [
              ...textSectionSet,
              ...formsection?.edges.map(({ node }) => ({ ...node, type: 'formsection' }))
            ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
            setData(components);


            break;

          case 'DELETE':

            let newTextSectionSet;
            newTextSectionSet = textSectionDataRef.current?.edges?.filter((node) =>

              node?.node?.id !== result?.data?.toolsTextSectionSubscription?.textSection?.id

            )

            setUpdatedTextSections({ edges: newTextSectionSet })
            textSectionDataRef.current = { edges: newTextSectionSet };
            break
          default:
            break
        }
      }
    })
  }


  React.useEffect(() => {

    if (me) {
      getSubscribedToFormSections()
      getSubscribedToTextSections()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);




  const [data, setData] = React.useState(
    [
      ...textsection?.edges.map(({ node }) => ({ ...node, type: 'textsection' })),
      ...formsection?.edges.map(({ node }) => ({ ...node, type: 'formsection' }))
    ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
  );




  React.useEffect(() => {
    textSectionDataRef.current = textsection;
    formSectionDataRef.current = formsection
  }, [formsection, textsection]);



  const onSort = async sortedItems => {
    setActionLoading(true);
    try {
      await Promise.all(
        sortedItems.map(async (i, index) => {
          // const response = await
          i.type === 'textsection'
            ? editTextSection({ id: getIntFromString(i.id), title: i.title, sequence: index + 1 })
            : editFormSection({ id: getIntFromString(i.id), title: i.title, sequence: index + 1 });
        })
      );
      setActionLoading(false);
    } catch (e) {
      setActionLoading(false);
      throw Error(e.message);
    }
  };

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999', margin: '-16px', padding: '16px' }} />
  ));

  const addComponent = (node, isForw = true) => (
    <AddComponent
      formSection={formsection}
      sequence={isForw ? node.sequence + 1 : node.sequence - 1}
      onSubmit={async (e, d) => {
        const response = await handleEditTool(e, d);
        onSort(
          arrayMove(
            [
              ...response?.textsection?.edges.map(({ node }) => ({ ...node, type: 'textsection' })),
              ...response?.formsection?.edges.map(({ node }) => ({ ...node, type: 'formsection' }))
            ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0)),
            node.sequence,
            d === 'formsection' ? node.sequence + 1 : node.sequence
          )
        );
      }}
    />
  );

  const SortableItem = SortableElement(({ node, idx }) =>
    node.type === 'textsection' ? (
      <StyledCol span={24}>
        {idx === 0 && addComponent(node, false)}
        <Card>
          <Row>
            <Col span={1} align="left">
              {enableSorting && <DragHandle />}
            </Col>

            <Col span={19} align="left">
              <Row gutter={24}>
                <Col>
                  <h3>{node.title}</h3>
                </Col>
              </Row>
            </Col>

            <Col span={4} align="right">
              <TextSectionComponent textSection={node} />
              <Divider type="vertical" />
              <DeleteIcon
                tooltipSuffix="Text Section"
                type="link"
                size="sm"
                onClick={() => onTextSectionDelete(getIntFromString(node.id))}
              />
            </Col>

            <Col span={24}>
              <ReactMarkdown>{node.renderedText}</ReactMarkdown>
              <br />
            </Col>
          </Row>
        </Card>
        {addComponent(node)}
      </StyledCol>
    ) : (
      <Col span={24}>
        {idx === 0 && addComponent(node, false)}

        <SimpleReferenceComponent
          node={node}
          enableSorting={enableSorting}
          onFormSectionDelete={onFormSectionDelete}
          simplefield={node?.simpleField ? node?.simpleField : node?.simplefield}
          referencefield={node?.referencefield}
          setActionLoading={setActionLoading}
          subscribeToMore={subscribeToMore}
          me={me}
        />
        {addComponent(node)}
      </Col>
    )
  );

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Row gutter={[24, 24]}>
        {items.length > 0 ? (
          <>
            {items.map((node, index) => (
              <SortableItem key={`item-${node.id}`} index={index} node={node} idx={index} />
            ))}
          </>
        ) : (
          <Col span={24} align="center">
            <br />
            <br />
            <br />
            <br />
            <Empty />
            <br />
            <br />
            <br />
            <br />
          </Col>
        )}
      </Row>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setData(arrayMove(data, oldIndex, newIndex));
    onSort(arrayMove(data, oldIndex, newIndex));
  };

  return (
    <Row>
      <Col lg={12} xs={24} align="left">
        <h2>Components</h2>
      </Col>

      <Col lg={12} xs={24} align="right">
        <Tooltip title={'Preview'}>
          <Button
            /* onClick={openPrivewTab} */ href={`${ROUTE.genericToolAdminLink}${id}`}
            target="_blank"
            disabled={data?.length === 0}
            icon={<EyeOutlined />}
            shape="circle"
          />
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip title={'Sort Sequence'}>
          <Switch checked={enableSorting} onChange={setEnableSorting} />
        </Tooltip>
        <Divider type="vertical" />
        <AddComponent formSection={formsection} onSubmit={(e, d) => handleEditTool(e, d)} />
      </Col>

      <Divider style={{ width: '100%', marginTop: '10px' }} />
      <Col lg={24}>
        <SortableList useDragHandle helperClass="row-dragging" items={data} onSortEnd={onSortEnd} />
      </Col>
    </Row>
  );
};

export default compose(withEditTextSection, withEditFormSection, withApollo)(TextFormSectionComponent);

const StyledCol = styled(Col)`
  &:hover ~ ${TargetedDiv} {
    opacity: 1;
  }
`;
