import { feedback_permission } from "./feedback.permission";
import { okr_permission } from "./okr.permission";
import { oneonone_permission } from "./oneonone.permission";

export const kudos_permission= {
    requried_permission:['view_badge', 'add_badge', 'change_badge', 'delete_badge', 'view_badgefamily', 'add_badgefamily', 'change_badgefamily', 'delete_badgefamily'],
    can_award_badge:['view_badge','view_badgefamily', 'view_badgeaward', 'add_badgeaward', 'change_badgeaward', 'delete_badgeaward','view_user','view_employee','view_organization'],
    required_permission_profile:['view_badge', 'view_badgefamily' , 'view_badgeaward', 'add_badgeaward', 'change_badgeaward', 'delete_badgeaward'],
    badgeawardcomment:["add_badgeawardcomment","change_badgeawardcomment","delete_badgeawardcomment","view_badgeawardcomment"],
    badgeawardcommentreaction:["add_badgeawardcommentreaction","change_badgeawardcommentreaction","delete_badgeawardcommentreaction","view_badgeawardcommentreaction"],
    badgeawardreaction:["add_badgeawardreaction","change_badgeawardreaction","delete_badgeawardreaction","view_badgeawardreaction"],
    one_on_one_permission:oneonone_permission.requried_permission,
    okr_permission:okr_permission.requried_permission,
    feedback_permission:feedback_permission.requried_permission
}