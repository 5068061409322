import { Button, Col, Drawer, Dropdown, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import svg_log from '../../assets/new-map-logo.svg'
import '../css/SalesPageStyles.css'
import dropdown_sales_image from '../../assets/dropdown_sales_image.svg'
import { SalesPageToolSet } from "../containers/SalesPageToolSet";
import { MenuOutlined } from '@ant-design/icons';
import Nav_close from '../../assets/nav-close-icon.svg'



const SalesPageNavbar = props => {


    const history = useHistory()

    const [navVisible, setNavVisible] = React.useState(false)
    const [dropdownVisible, setDropDownVisible] = React.useState(false)

    // remove developed tools logic from line no 26 once all tools gets developed
    const develoedTools = [1,2,3,4,6,5]

    const NavBarmenu = () => (
        <>
            <Row justify='center' align='top' className='poppins navbar-menu-container'>
                <Col lg={6} md={6} sm={24} xs={24} style={{ padding: '10px' }}>
                    <Row justify='center' align='middle' onClick={(e) => history.push('/intro/soul')} style={{ flexDirection: 'column', cursor: 'pointer' }}>
                        <img src={dropdown_sales_image} alt=''/>

                        <div style={{ fontSize: '16px', fontWeight: '600', marginTop: "10px", marginBottom: '10px' }}>Soul Identity</div>
                        <div style={{ textAlign: 'center' }}>It helps managers understand and engage each team member uniquely.</div>
                    </Row>

                </Col>
                <Col lg={18} md={18} sm={24} xs={24}>
                    <Row>
                        {
                            SalesPageToolSet.map((tool) =>
                                <Col span={8} style={{ padding: '20px' }} onClick={(e)=>setDropDownVisible(false)}>
                                    <div key={tool.id} onClick={(e) => {

                                        // Remove if condition when all tools are developer
                                        if (tool?.id) {
                                            history.push(`/intro/tool/${tool.type}/${tool.id}`)
                                        }
                                    }

                                    } >
                                        <Row align='middle' style={{ cursor: develoedTools?.includes(tool?.id) ? "pointer" : "default" , gap: '5px' }} wrap={false}>
                                            <img style={{ width: '35px', height: '35px' }} src={tool.toolImage} alt=''/>
                                            <div style={{ fontSize: '14px', fontWeight: '600', textAlign: 'justify' }}>{tool.title}</div>
                                        </Row>
                                    </div>
                                </Col>
                            )
                        }
                    </Row>
                </Col>
            </Row>


        </>
    );





    return (
        <>
            <Row className="sales-navbar-web" justify='space-between' wrap={false} >
                <Col >
                    <a href="/">
                        <img style={{ width: '130px' }} src={svg_log} alt="logo" />
                    </a>
                </Col>
                <Row align='middle' style={{ gap: '50px' }} wrap={false}>
                    <Row style={{ gap: '30px' }} className='sales-page-navlink-container' wrap={false}>
                        <div className='poppins sales-page-link' onClick={(e) => history.push('/intro')}>Home</div>
                        <div onMouseEnter={(e) => setDropDownVisible(true)} onMouseLeave={(e) => setDropDownVisible(false)}>
                            <Dropdown visible={dropdownVisible} overlay={NavBarmenu} placement={"bottomCenter"}   >
                                <Button>
                                    <div className='poppins tool-nav-item-sales'>Tools</div>
                                </Button>
                            </Dropdown>
                        </div>
                        <div className='poppins sales-page-link' onClick={(e) => window.open("https://brainayan.com/flagship-programs/")} >Programs</div>
                    </Row>
                    <Row className='sales-page-navbar-button-panel' style={{ gap: '15px' }} wrap={false}>
                        <Button onClick={(e) => history.push('/intro/getintouch')} className='sales-page-navbar-button poppins'>GET IN TOUCH</Button>
                        <Button className='sales-page-navbar-button poppins' onClick={(e) => history.push('/user/login')}>LOGIN</Button>
                    </Row>
                </Row>
            </Row>

            <div className='sales-nav-phone-icon'>
                <MenuOutlined
                    onClick={() => setNavVisible(true)}
                    style={{
                        // padding: '0px 24px 0px',
                        display: 'flex',
                        fontSize: '18px',
                        lineHeight: '64px',
                        cursor: 'pointer',
                        transition: 'color 0.3s'
                    }}
                />
            </div>

            <Drawer
                width="190px"
                bodyStyle={{ padding: '24px 0px' }}
                placement="right"
                closable={false}
                onClose={() => setNavVisible(false)}
                visible={navVisible}
            >
                <Col style={{ padding: '20px', position: 'relative', zIndex: '100' }}>
                    <img src={Nav_close} style={{ float: 'right' }} alt="" onClick={() => setNavVisible(false)} />
                    <Row justify='space-around' style={{ paddingTop: '25px' }} className='sales-drwaer-item' >
                        <a href="/">
                            <img style={{ width: '130px' }} src={svg_log} alt="logo" />
                        </a>

                    </Row>
                    <Col >
                        <Col style={{ gap: '30px' }} className='sales-page-navlink-container' wrap={false}>
                            <div className='poppins sales-page-link sales-drwaer-item' onClick={(e) => history.push('/intro')}>Home</div>
                            <Dropdown overlay={NavBarmenu} placement={"bottomCenter"}  >
                                <Button>
                                    <div className='poppins tool-nav-item-sales sales-drwaer-item'  >Tools</div>

                                </Button>
                            </Dropdown>
                            <div className='poppins sales-page-link sales-drwaer-item' onClick={(e) => window.open("https://brainayan.com/flagship-programs/")} >Programs</div>
                        </Col>
                        <Col className='sales-page-navbar-button-panel' style={{ gap: '15px' }} wrap={false}>
                            <Button onClick={(e) => history.push('/intro/getintouch')} className='sales-page-navbar-button poppins sales-drwaer-item'>GET IN TOUCH</Button>
                            <Button className='sales-page-navbar-button poppins sales-drwaer-item' onClick={(e) => history.push('/user/login')}>LOGIN</Button>
                        </Col>
                    </Col>
                </Col>
            </Drawer>
        </>
    );
};

export default SalesPageNavbar;



