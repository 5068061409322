
import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { KUDOS_LIST_QUERY } from "../graphql/yourkudos.gql";
import YourKuodsView from "../component/yourKudosView";
import { withApollo } from "@apollo/client/react/hoc";
import { compose } from "modules/core";
import moment from "moment-timezone";


const YourKudosContainer = (props) => {
  const { client, permission, me } = props;
  const [awards, setAwards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quarters, setQuarters] = useState([])
  const [your_badge_page_info, SetYourBadgePageInfo] = useState()

  const your_badge_list_ref = React.useRef([])
  useEffect(() => {
    if (permission?.requried_permission) {
      fetchKudosList({ recipient: me?.employee?.id })
      const list = getYearQuarters(moment().year());
      setQuarters(list);
    }
  }, [permission])

  useEffect(() => {
    your_badge_list_ref.current = awards
  }, [awards])
  const getYearQuarters = (year) => {
    const quarters = [];
    for (let i = 1; i <= 4; i++) {
      const startDate = moment()
        .year(year)
        .quarter(i)
        .startOf("quarter").utc()
      const endDate = moment()
        .year(year)
        .quarter(i)
        .endOf("quarter").utc()
      quarters.push({
        key: `quarter ${i}`,
        label: `Quarter ${i}`,
        value: { startDate, endDate },
      });
    }
    return quarters;
  };
  const fetchKudosList = async (filter) => {
    setLoading(true);
    try {
      const { data } = await client.query({
        query: KUDOS_LIST_QUERY,
        variables: { ...filter },
        fetchPolicy: "network-only",
      });
      if (data) {
        SetYourBadgePageInfo(data?.badgeAwards?.pageInfo)
        let awards = data?.badgeAwards?.edges.map(({ node }) => node) || [];
        if (filter?.cursor) {
          awards = your_badge_list_ref.current.concat(awards);
        }
        setAwards(awards);
        setLoading(false);
      }
    }
    catch (error) {
      console.error('Error fetching awards:', error);
      setLoading(false);
    }

  };
  return (
    <>

      <YourKuodsView {...props}
        fetchKudos={fetchKudosList}
        loading={loading}
        awards={awards}
        quarters={quarters}
        your_badge_page_info={your_badge_page_info} />
    </>
  )
}

export default compose(withApollo)(YourKudosContainer)