import React from 'react';
import { useHistory } from 'react-router-dom';
import { ca_detail_icons } from '../icon';
import '../css/competency-assessment-detail.css'
import CompetencyUserTableView from './competency-user-table-view';
import CompetencyBarChart from './ca-bar-chart';
import { Spin, Dropdown, Menu, Button } from 'antd';
import CustomYearPicker from './custom-year-picker';
import GraphAdvanceFilterContainer from '../containers/GraphAdvanceFilterContainer';
import ConversationDetailView from 'modules/grow-model/components/ConversationDetailView';
import { generteRandomColor, getIntFromString } from 'modules/look';
import CompetencyLineChart from './ca-line-chart';
import { threshold_colors } from '../color-config';
import moment from 'moment-timezone';
import ca_route from '../route/index'
const CompetecnyAssessmentDetailView = (props) => {
    const { assessment_details, page_loading,permission, category_by_id, rating_list, competecnyAssessmentDetail } = props
    const history = useHistory()
    const [graph_type, SetGraphType] = React.useState('bar')
    const [bar_graph_data, SetBarGraphData] = React.useState({
        graph_data_set: [],
        labels: []
    })
    const [line_graph_data, SetLineGraphData] = React.useState({
        graph_data_set: [],
        labels: []
    })
    const onDateChange = (date) => {
        let createdAt_Range = date
        competecnyAssessmentDetail({ createdAt_Range })
    }
    React.useEffect(() => {
        if (assessment_details?.length && rating_list) {
            let competencyassessmentSet = assessment_details?.map(i => i?.competencyassessmentSet?.edges?.map(({ node }) => { return { ...node, created_at: i?.createdAt,employee:i?.employee } }))
            competencyassessmentSet = [].concat.apply([], competencyassessmentSet)?.filter(i => i?.competency)?.map(asmt => { return { ...asmt, assessmentbarsSet: asmt?.assessmentbarsSet?.edges[0]?.node } })
            let employee_ids = competencyassessmentSet?.filter(i=>i?.employee)?.map(i => i?.employee.id)
            employee_ids = [...new Set(employee_ids)]
            let data_set = {}
            employee_ids.forEach(i => {
                let list = competencyassessmentSet?.filter(asmt => asmt?.employee?.id === i)
                let all_assessment = list?.map(asmt => asmt?.assessmentbarsSet)
                let most_repeated = mostRepeatedKey(all_assessment)
                data_set[i] = {
                    id: i,
                    title: list[0]?.employee?.user?.firstName,
                    competency: list[0]?.competency,
                    competencyassessmentSet: all_assessment,
                    behaviourExample: most_repeated?.behaviourExample,
                    qualifyingLevelName: most_repeated?.qualifyingLevelName,
                    value: most_repeated?.qualifyingLevelSequence,
                    employee:list[0]?.employee,
                    color: threshold_colors[`threshold_${most_repeated?.qualifyingLevelSequence}`] ? `rgba(${threshold_colors[`threshold_${most_repeated?.qualifyingLevelSequence}`]},0.5)` : generteRandomColor()
                }
            })
            let graph_data_set = Object.values(data_set)
            let labels = rating_list || []
            graph_data_set.forEach(i => {
                let rating = labels?.find(scale => scale?.name === i.qualifyingLevelName)
                if (!rating) {
                    labels.push({ name: i.qualifyingLevelName, sequence: labels?.length + 1 })
                }
            })
            labels = labels?.sort((a, b) => a.sequence - b.sequence)
            labels = labels?.map((i, index) => {
                return {
                    ...i,
                    value: index + 1
                }
            })
            lineChartHandle(competencyassessmentSet, labels)
            graph_data_set = graph_data_set?.map(i => {
                let rating = labels?.find(label => label?.name === i?.qualifyingLevelName)
                return {
                    ...i,
                    sequence: rating?.value ?? i?.value
                }
            })
            SetBarGraphData({ ...{ graph_data_set, labels } })
        } else {
            // let bar_data = bar_graph_data
            // bar_data['graph_data_set'] = []
            // SetBarGraphData({ ...bar_data })
            // let line_data = line_graph_data
            // line_data['graph_data_set'] = []
            // SetLineGraphData({ ...line_data })
        }
    }, [assessment_details, rating_list])
    const months = {
        0: 'Jan',
        1: 'Feb',
        2: 'Mar',
        3: 'Apr',
        4: 'May',
        5: 'Jun',
        6: 'Jul',
        7: 'Aug',
        8: 'Sep',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec'
    }
    const lineChartHandle = (dataSet, labels) => {
        labels = labels?.map((i, index) => {
            return {
                ...i,
                value: index
            }
        })
        let employee_ids = dataSet?.filter(i=>i?.employee)?.map(i => i?.employee.id)
        employee_ids = [...new Set(employee_ids)]
        let data_set = {}
       
        employee_ids.forEach(i => {
            let list = dataSet?.filter(asmt => asmt?.employee?.id === i)
            let month_wise_data = Object.keys(months)?.map(value => {
                let monthly_data = list?.filter(a => moment(a?.created_at).get('month')?.toString() === value)
                let assessment_list = monthly_data?.map(asmt => asmt?.assessmentbarsSet)
                let most_repeated
                let rating_scale
                if (assessment_list?.length) {
                    most_repeated = mostRepeatedKey(assessment_list)
                    rating_scale = labels?.find(rating => rating?.name == most_repeated?.qualifyingLevelName)
                }
                return {
                    id: i,
                    title: list[0]?.employee?.user?.firstName,
                    competency: list[0]?.competency,
                    month: months[value],
                    monthly_data,
                    most_repeated,
                    employee:list[0]?.employee,
                    value: rating_scale?.value || 0,
                    created_at:monthly_data[0]?.created_at
                }
            })
            let year_data = month_wise_data?.find(yr=>yr?.created_at)
            let year = moment(year_data?.created_at).get('year')
            let current_year = moment().get('year')
            if(year==current_year){
                let current_month = moment().get('month')
                for (let m = current_month+1; m <=11; m++) {
                    delete month_wise_data[m] 
                }
            }
            data_set[i] = month_wise_data
        })
        let graph_data_set = Object.values(data_set)?.map(i => {
            let pointStyle = ['circle','rect', 'triangle']
            let random_number = Math.floor(Math.random() * 3);
            let color=generteRandomColor()
            return {
                data: i?.map(monthly => monthly?.value),
                backgroundColor: color,
                borderColor: color,
                pointStyle: pointStyle[random_number] || pointStyle[0],
                pointRadius: 10,
                barThickness: 50,
                barPercentage: 0.5, // Adjust the bar width
                borderRadius: 8, // Set the border radius for all four corners
                data_set: i,
                employee:i[0]?.employee,
                legend:{label:i[0]?.employee?.user?.firstName}
            }
        })
        SetLineGraphData({ ...{ graph_data_set, labels } })
    }
    const mostRepeatedKey = (arr) => {
        let list = {}
        arr.forEach(item => {
            if (list[`id_${item?.qualifyingLevelId}`]) {
                list[`id_${item?.qualifyingLevelId}`].count++
            } else {
                list[`id_${item?.qualifyingLevelId}`] = {
                    count: 1,
                    ...item
                }
            }
        })
        let higest_count = Object.values(list)?.sort((a, b) => b.count - a.count)[0]?.count
        let data = Object.values(list)?.filter(item => item?.count === higest_count).sort((a, b) => b.qualifyingLevelSequence - a.qualifyingLevelSequence)
        return data[0]
    }
    const changeGraph = (e) => {
        if (e) {
            SetGraphType('line')
        } else {
            SetGraphType('bar')
        }
    }
    return (
        <div className="ca-root">
            <Spin spinning={page_loading}>
                <div className="ca-heading-container">
                    <img onClick={(e) => history.push(ca_route?.competency_assessment_home)} style={{ paddingLeft: ".5em", cursor: "pointer" }} src={ca_detail_icons?.new_back_arrow} alt="" />
                    <h4 className="ca-main-title">{category_by_id?.title} Competency Assessment</h4>
                    <div></div>
                </div>
                <div className='ca-detail-data-container'>
                    <div className='ca-chart-container'>
                        <div className='ca-chart-filter-container'>
                            <GraphAdvanceFilterContainer permission={permission} {...props} onApplyfilter={(e)=>{competecnyAssessmentDetail(e)}} />
                            <CustomYearPicker {...props} onDateChange={(e) => { onDateChange(e) }} toAnnual={(e) => { changeGraph(e) }} />
                        </div>
                        {
                            graph_type === 'bar' && (
                                <CompetencyBarChart {...props} bar_graph_data={bar_graph_data} graph_type={graph_type} />
                            )
                        }
                        {
                            graph_type === 'line' && (
                                <CompetencyLineChart  {...props} graph_data={line_graph_data} graph_type={graph_type} />
                            )
                        }
                    </div>
                    <CompetencyUserTableView show_extra_column={true}  {...props} />
                </div>
            </Spin>
        </div>
    )
}
export default CompetecnyAssessmentDetailView