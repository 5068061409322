import React from 'react'
import { Col, DatePicker, Row, Spin,Dropdown,Menu,Button,Input,Form, Tooltip,Modal } from 'antd';
import styled from "styled-components";
import moment from 'moment-timezone';
import { displayUtcToUserTimezone,customTabSelectionForDate } from 'modules/look/components/functions';
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
import {CloseSquareOutlined} from '@ant-design/icons'
const KudosCustomPickerView =(props)=>{
    const {open_date_picker,disabledRange,defaultValue,selectedDateRange,disabledType,name,currentSelected,onClose}=props

    const FormItem = Form.Item;
    const { RangePicker } = DatePicker;
    const [dateRageOpen,setRageOpen]=React.useState(true)
    const [currentQuarterList,setcurrentQuarterList]=React.useState([])
    const [loadedYear,setLoadedYear]=React.useState(5)
    const [currentYearsList,setcurrentYearsList]=React.useState([])
    const [selectedValue,setSelectedValue]=React.useState(defaultValue)
    const [selectedQuarter,setSelectedQuarter]=React.useState()
    const [selectedYear,setSelectedYear]=React.useState(currentSelected?new Date().getFullYear():null)
    const [selectedHalfValue,setSelectedHalf]=React.useState()
    const [selectedStartDate,setSelectedStartDte]=React.useState()
    const [selecionClear,setSelectionClear]=React.useState(false)
    const [LoadMoreYear,setLoadMoreYear]=React.useState(false)
    const [selected_range,setselectedDateRange]=React.useState()
    const rangePickerRef = React.useRef(null);
    React.useEffect(()=>{
        setRageOpen(open_date_picker)
    },[open_date_picker])
    React.useEffect(()=>{
        customTabSelectionForDate()
        let today 
        let current_quarter
        let current_year
        let loopCount
        if(disabledRange!=undefined){
            today=new Date(disabledRange?.startdate)
            current_quarter= Math.ceil(((new Date(disabledRange?.startdate).getMonth()+1)/3))
            current_year=today.getFullYear()-3
            if(new Date(disabledRange?.duedate).getFullYear()==current_year){
                loopCount=Math.ceil(((new Date(disabledRange?.duedate).getMonth()+1)/3))
            }
            else{
                loopCount=4
            }
        }
        else{
            today = new Date()
            current_quarter = Math.ceil(((today.getMonth()+1)/ 3));
            current_year=today.getFullYear()-3
            loopCount=4
        }
        let quaterly_list=[{
            label:`Q${current_quarter}-${current_year}`,
            key:current_quarter,
            year:current_year
        }]
            if( current_quarter!=4){
                for(let i=current_quarter+1;i<=loopCount;i++){
                    quaterly_list.push({
                        label:`Q${i}-${current_year}`,
                        key:i,
                        year:current_year
                    })
                }
            }
            else {
                for(let i=1;i<=loopCount;i++){
                    quaterly_list.push({
                        label:`Q${i}-${current_year+1}`,
                        key:i,
                        year:current_year+1
                    })
                }
            }
        setcurrentQuarterList(quaterly_list)
    },[disabledRange])
    const getHalfYear = (year) => {
        let today, current_half, loopCount;
    
        // Determine start and loopCount based on disabledRange or default to current date
        if (disabledRange !== undefined) {
            today = new Date(disabledRange?.startdate);
            current_half = Math.ceil((new Date(disabledRange?.startdate).getMonth() + 1) / 6); // Calculate H1 or H2
            const dueDate = new Date(disabledRange?.duedate);
    
            // Check if only H1 applies for the year or both H1 and H2
            if (dueDate.getFullYear() === year && dueDate.getMonth() + 1 <= 6) {
                loopCount = 1; // Only H1
            } else {
                loopCount = 2; // H1 and H2
            }
        } else {
            today = new Date();
            current_half = Math.ceil((today.getMonth() + 1) / 6); // Default to current H1 or H2
            loopCount = 2; // Default to both halves
        }
    
        // Generate H1 and H2 for the given year
        let half_list = [];
        for (let i = 1; i <= loopCount; i++) {
            half_list.push({
                label: `H${i}-${year}`,
                key: i,
                year: year,
            });
        }

        console.log("half_list",half_list)
        return half_list;
    };
    
    
    React.useEffect(()=>{
        let years=[]
        let today = new Date()
        let current_year=today.getFullYear()-3
        if(disabledRange!=undefined){
            today=new Date(disabledRange?.startDate)
            let lastYear =new Date(disabledRange?.duedate).getFullYear()
            if(!LoadMoreYear){
                if(lastYear > new Date().getFullYear()){
                    setLoadedYear(lastYear-new Date().getFullYear())
                }
                else{
                    setLoadedYear(0)
                }
            }
        }
            for(let i=0;i<=loadedYear;i++){
                years.push({
                    year:current_year+i,
                    half:getHalfYear(current_year+i),
                    key:current_year+i
                })
            }
        setcurrentYearsList(years)
        
    },[loadedYear])
    const selecteQuaterlyDate =(quarter)=>{
        setSelectedYear()
        setSelectedHalf()
        setSelectedQuarter(quarter)
        let start_date
        let end_date
        if(disabledRange!=undefined){
            start_date=moment().year(quarter?.year).quarter(quarter?.key).startOf("quarter")<moment(disabledRange?.startdate)?moment(disabledRange?.startdate):moment().year(quarter?.year).quarter(quarter?.key).startOf("quarter")
            end_date= end_date=moment().year(quarter?.year).quarter(quarter?.key).endOf("quarter")>moment(disabledRange?.duedate)?moment(disabledRange?.duedate):moment().year(quarter?.year).quarter(quarter?.key).endOf("quarter")
        }
        else{
            if(moment(moment().year(quarter?.year).quarter(quarter?.key).startOf("quarter")).isBefore(moment())&&disabledType){
                start_date=moment()
                end_date=moment().year(quarter?.year).quarter(quarter?.key).endOf("quarter")
            }
            else{
                start_date=moment().year(quarter?.year).quarter(quarter?.key).startOf("quarter")
                end_date=moment().year(quarter?.year).quarter(quarter?.key).endOf("quarter")
            }
        }
        setSelectedValue([start_date,end_date])
        SaveSelectedDate([start_date,end_date])
    }

    const selectedYearDate =(value)=>{
        setSelectedQuarter()
        setSelectedHalf()
        setSelectedYear(value?.year)
        let start_date
        let end_date
        if(disabledRange!=undefined){
            start_date=moment().year(value?.year).startOf("year")<moment(disabledRange?.startdate)?moment(disabledRange?.startdate):moment().year(value?.year).startOf("year")
            end_date= end_date=moment().year(value?.year).endOf("year")>moment(disabledRange?.duedate)?moment(disabledRange?.duedate):moment().year(value?.year).endOf("year")
        }
        else{
            if(disabledType){
                start_date=moment()
                end_date=moment().year(value?.year).endOf("year")
            }
            else{
                start_date=moment().year(value?.year).startOf("year")
                end_date=moment().year(value?.year).endOf("year")
            }
        }
        setSelectedValue([start_date,end_date])
        SaveSelectedDate([start_date,end_date])
    }
    const selectedHalf=(value)=>{
        setSelectedQuarter()
        setSelectedYear()
        setSelectedHalf(value)
        let start_date
        let end_date
        if(value?.key===1){
            if(disabledRange!=undefined){
                start_date=moment().year(value?.year).quarter(value?.key).startOf("quarters")<moment(disabledRange?.startdate)?moment(disabledRange?.startdate):moment().year(value?.year).quarter(value?.key).startOf("quarters")
                end_date= moment().year(value?.year).quarter(2).endOf("quarters")>moment(disabledRange?.duedate)?moment(disabledRange?.duedate):moment().year(value?.year).quarter(2).endOf("quarters")
            }
            else{
                if(moment(moment().year(value?.year).quarter(value?.key).startOf('quarters')).isBefore(moment())&&disabledType){
                    start_date=moment()
                    end_date=moment().year(value?.year).quarter(2).endOf("quarters")
                }
                else{
                    start_date=moment().year(value?.year).quarter(value?.key).startOf("quarters")
                    end_date=moment().year(value?.year).quarter(2).endOf("quarters")
                }
            }
            
        }
        else if(value?.key===2){
            if(moment(moment().year(value?.year).quarter(3).startOf('quarters')).isBefore(moment())){
                start_date=moment()
                end_date=moment().year(value?.year).quarter(4).endOf("quarters")
            }
            else{
                start_date=moment().year(value?.year).quarter(3).startOf("quarters")
                end_date=moment().year(value?.year).quarter(4).endOf("quarters")
            }
        }
        
        setSelectedValue([start_date,end_date])
        SaveSelectedDate([start_date,end_date])
    }
    const loadMoreQuartor =(yearList)=>{
        let selected_years =yearList?.map((item)=>item?.year)
        let quaterly_list=[]
        if(disabledRange!=undefined){
            if(new Date(disabledRange?.duedate).getFullYear()>Math.max(...selected_years)+1){
                for(let i=1;i<=4;i++){
                    quaterly_list.push({
                        label:`Q${i}-${Math.max(...selected_years)+1}`,
                        key:i,
                        year:Math.max(...selected_years)+1
                    })
                }
            }
            else if(new Date(disabledRange?.duedate).getFullYear()==Math.max(...selected_years)+1){
                let loopCount=Math.ceil(((new Date(disabledRange?.duedate).getMonth()+1)/3))
                for(let i=1;i<=loopCount;i++){
                    quaterly_list.push({
                        label:`Q${i}-${Math.max(...selected_years)+1}`,
                        key:i,
                        year:Math.max(...selected_years)+1
                    })
                }
            }
        }
        else{
            for(let i=1;i<=4;i++){
                quaterly_list.push({
                    label:`Q${i}-${Math.max(...selected_years)+1}`,
                    key:i,
                    year:Math.max(...selected_years)+1
                })
            }
        }
        
        setcurrentQuarterList([...currentQuarterList,...quaterly_list])
    }

    const datefilter=(e)=>{
        if(e&&e?.length==2){
            let start_date = moment(e[0]).startOf('day')
            let end_date = moment(e[1]).endOf('day')
            setSelectedValue([start_date,end_date])
            SaveSelectedDate([start_date,end_date])
        }
      }
    const SaveSelectedDate=(value)=>{
        if(value && value?.length===2){
            let selected_range = [moment(value[0]),moment(value[1])]
            setselectedDateRange(selected_range)
        }
    }
    const findCurrentYear=(yearList)=>{
        let selected_years =yearList?.map((item)=>item?.year)
        return Math.max(...selected_years)
    }
    const checkReset =()=>{
        if(!selectedValue?.length){
            setSelectedValue()
            setTimeout(() => {
                if (rangePickerRef.current) {
                    rangePickerRef.current.focus();
                }
              }, 100);
        }
    }
    return (
        <>
            <Modal
            footer={null}
            centered
            visible={open_date_picker}
            destroyOnClose={true}
            //  onCancel={() => {setquickMeeting(false)}}
            closable={false}
            width={"640px"}
            
        >
            <div style={{maxWidth:"600px",position:"relative",minWidth:"600px",height:'350px'}}>
                <CloseSquareOutlined style={{position:'absolute',right:"5px",top:"5px",cursor:"pointer",zIndex:1000,fontSize:"20px",color: 'gray'}}  onClick={() => {checkReset();setRageOpen(false);onClose(false)}}/>
                <Row  style={{display:'flex',flexDirection:"row",justifyContent:"space-between"}}>
                            <Col className='custom-datePicker-container' span={19}>
                                <RangePicker
                                    open={dateRageOpen}
                                    ref={rangePickerRef}
                                    allowClear={selecionClear}
                                    dropdownClassName = {'custom-meeting-picker'}
                                    className="date-range-meeting"
                                    defaultValue={defaultValue}
                                    defaultPickerValue={defaultValue}
                                    value={selectedValue}
                                    format={'DD/MMM/YY'}
                                    // disabledDate={current => {
                                    //     return current.isAfter(moment())}}
                                    onChange={datefilter}
                                />  
                            </Col>
                            <Col  span={5} style={{display:"flex",flexDirection:"column",gap:"10px",marginTop:"20px",paddingRight:"5px",height:'100%'}}>
                            <div className='overflow-selection' style={{minHeight:"110px"}}>
                                <div style={{display:'flex',flexDirection:"column",width:"100%",justifyContent:'center',alignContent:"center",textAlign:'center'}}>
                                    {currentQuarterList?.map((item)=>(
                                        <div className={item?.key===selectedQuarter?.key&&item?.year===selectedQuarter?.year?'seletion-title-selected':'seletion-title'} onClick={()=>selecteQuaterlyDate(item)}>{item?.label}</div>
                                    ))}
                                </div>
                            </div>
                            {disabledRange==undefined?<h4 className='see-more' onClick={()=>loadMoreQuartor(currentQuarterList)}>Show More</h4>: (new Date(disabledRange?.duedate).getFullYear() >findCurrentYear(currentQuarterList))?<h4 className='see-more' onClick={()=>loadMoreQuartor(currentQuarterList)}>Show More</h4>:<div style={{height:"19px",width:"119px"}}></div>}
                            <div style={{width:"100%",height:"1px",background:"#303030"}}></div>
                            <div className='overflow-selection'  style={{minHeight:"110px"}}>
                            {currentYearsList?.map((item)=>(
                                <>
                                <div>
                                    {item?.half?.map((data)=>(
                                    <Tooltip title={data?.key==1?`1st Half of ${data?.year}`:`2nd Half of ${data?.year}`}><div style={{whiteSpace:'nowrap'}}  className={data?.key===selectedHalfValue?.key&&data?.year===selectedHalfValue?.year?'seletion-title-selected':'seletion-title'}  onClick={()=>selectedHalf(data)}>{data?.label}</div></Tooltip>
                                    ))}
                                </div>
                                <div style={{whiteSpace:'nowrap'}}  className={item?.year===selectedYear?'seletion-title-selected':'seletion-title'}  onClick={()=>selectedYearDate(item)}>Annual - {item?.year}</div>
                                </>
                            ))}
                            </div>
                        {disabledRange==undefined?<h4 className='see-more' onClick={()=>setLoadedYear(loadedYear+5)}>Show More</h4>:<div style={{height:"19px",width:"119px"}}></div>}
                        </Col>
                </Row>
                
                <div style={{display:"flex",flexDirection:"row",gap:"20px",justifyContent:'end',alignItems:"start",marginRight:"120px",marginBottom:"20px"}}>
                    <Button className='custon-datepicker-btn-cancel'  onClick={() => {setSelectedValue();setSelectedStartDte();setSelectedQuarter();setSelectedYear();setSelectedHalf();onClose(false)}}>Clear</Button>
                    <Button className='custon-datepicker-btn'  onClick={() => {selectedDateRange(selected_range);setRageOpen(false);checkReset()}} disabled={!selectedValue?.length}>Ok</Button>
                </div>
            </div>
            </Modal>
        </>
    )
}

export default KudosCustomPickerView