import React from 'react';
import { Spin, message, Modal } from 'antd';
import AddUserView from '../components/AddUserView';
import { withRegister } from './UserOperations';
import { getIntFromString } from '../../look';
import { withAddEmployeeOptimized } from 'modules/organization/containers/OrganizationOperation';
import { compose } from 'modules/core';
import { LIST_USERS_BY_EMAIL } from '../graphql/ListAllUsersQuery.gql';
import { withApollo } from '@apollo/client/react/hoc';
import AddUserToOrgContainer from './AddUserToOrgContainer';

const AddUser = props => {
  const { client, register, addEmployee, navigateRoute} = props;
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [org_confirmation, SetOrgConfirmation] = React.useState();

  const handleSubmit = async (values, item) => {
    setSubmitLoading(true);
    try {
      const response = await register(values);

      if (response?.errors !== null) {
        setSubmitLoading(false);
        if (response?.errors?.email?.length > 0) {
          message.error(response?.errors?.email[0]?.message)
        }
        else if (response?.errors?.username?.length > 0) {
          message.error(response?.errors?.username[0]?.message)
        }
        else if (response?.errors?.username?.length > 0 && response?.errors?.email?.length > 0) {
          message.error(`${response?.errors?.email[0]?.message} and ${response?.errors?.username[0]?.message}`)
        }
        else if (response?.errors?.nonFieldErrors[0]?.message === "Failed to send email.") {
          message.error("Please enter a valid email address")
        }
      }
      else {
        userProfile(values)
      }

    } catch (e) {
      message.error('failed to add user')
      setSubmitLoading(false);
    }

  };


  const userProfile = async (values) => {
    try {
      setSubmitLoading(true);

      const { data } = await client.query({
        query: LIST_USERS_BY_EMAIL,
        variables: { email: values?.email },
        fetchPolicy: 'network-only'
      });

      if (data?.listAllUsers?.edges?.length) {
        let user = data?.listAllUsers?.edges[0]?.node
        if (user?.email === values?.email && !user?.employee) {
          registerAsEmployee(user)
        } else {
          message.error('failed to add user')
          setSubmitLoading(false);
        }
      } else {
        message.error('failed to add user')
        setSubmitLoading(false);
      }
    } catch (error) {
      message.error('failed to add user')
      setSubmitLoading(false);
    }
  }

  const registerAsEmployee = async (user) => {
    try {
      setSubmitLoading(true);

      const response = await addEmployee({ employeeData: { userId: getIntFromString(user?.id) } });
      if (response?.id) {
        setSubmitLoading(false);
        SetOrgConfirmation({ ...user, employee: response })
      }else{
        message.error('failed to register as employee')
        setSubmitLoading(false);
      }
    } catch (error) {
      message.error('failed to register as employee')
      setSubmitLoading(false);
    }
  }

  const goBack=()=>{
    SetOrgConfirmation(null)
    navigateRoute("-1")
  }

  return (
    <>
      <Modal
        visible={org_confirmation}
        closable={false}
        footer={false}
        centered={true}
        >
           <AddUserToOrgContainer {...props} goBack={goBack} org_confirmation={org_confirmation} />
      </Modal>
      <Spin spinning={submitLoading} size="large">
        <AddUserView onSubmit={handleSubmit} {...props} />
      </Spin></>

  );
};

export default compose(withRegister, withAddEmployeeOptimized, withApollo)(AddUser);
