// @ts-nocheck
import { DownOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Dropdown, Form, Input, Menu, message, Radio, Row, Select, Space, Spin, Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import new_back_arrow from '../../assets/new_back_arrow.svg';
import { CKEditorField, getIntFromString, MetaTags, scrolltoview, withMarkdownFieldValue } from "../../look";
import ReactMarkdown from '../../look/components/ReactMarkdown';
import GenericTool from "../../tool/containers/GenericTool";
import { replaceAnswer1, replaceAnswer2, replaceAnswer3, replaceAnswer4, replaceAnswer5Feedback1, replaceAnswer5Feedback2 } from '../containers/textReplace';
import feedback_route from '../route/index';
const FeedbackPost = props => {
 const {getToolById,history,allOrganizationEmployees,SurveyById,createFeedback,okrDetails,onSelectRecepiant,me,updateMeeting,handleSearch,okrByrecipiant,okrSearch}=props
 const [loading, setloading] = React.useState(false); 
 const [keyResultsActive,setKeyResultActive]= React.useState(false); 
 const [Recipient,setRecipient]= React.useState();
 const [recipientName,setrecipientName]= React.useState();
 const [form] = Form.useForm();
const [okrList,setokrList]= React.useState([])
 const [okrid,setokrid]= React.useState();
 const [feedBackType,setFeedBackType]= React.useState();
 const [fillInOption,setfillInOption]= React.useState(false);
 const [questionList,setquestionList]= React.useState([]);
 const [answersList,setAnswers]= React.useState([]);
 const [question1Field1,setquestion1Field1]= React.useState();
 const [question1Field2,setquestion1Field2]= React.useState();
 const [question2Field1,setquestion2Field1]= React.useState();
 const [question2Field2,setquestion2Field2]= React.useState();
 const [question2Field3,setquestion2Field3]= React.useState();
 const [question3Field1,setquestion3Field1]= React.useState();
 const [question3Field2,setquestion3Field2]= React.useState();
 const [question4Field1,setquestion4Field1]= React.useState();
 const [question5Field2,setquestion5Field2]=React.useState()
 const [question5Field1,setquestion5Field1]=React.useState()
 const [navigationFromOkr,setnavigationFromOkr]= React.useState(false);
 const hideselection = false;
 const [employeeList,setemployeeList]= React.useState([]);
 const feedBackTypeEnum = {
  1:'Motivational/Recognition',
  2:'Developmental/Constructive'
 }

const navigateTo = () => {
  history.push(feedback_route.feedback?.replace(":type","received"))
};
 
React.useEffect(()=>{
  if(okrDetails && okrDetails?.owner?.user?.employee?.id){
    // new to do navigation from okr
    setRecipient(okrDetails?.owner?.user?.employee?.id)
    setrecipientName(okrDetails?.owner?.user?.firstName)
    setnavigationFromOkr(true)
  }
},[okrDetails])

React.useEffect(()=>{

  if(allOrganizationEmployees && me){
    let filtered = allOrganizationEmployees?.edges.filter(item=>item?.node?.user?.employee?.id!==me?.employee?.id)
    setemployeeList(filtered)
  }

},[allOrganizationEmployees,me])

React.useEffect(()=>{
  if(okrByrecipiant){
    setokrList(okrByrecipiant?.edges?.map(({node})=>{return {label:node?.okr?.title,id:node?.okr?.id}}))
  }
},[okrByrecipiant])

React.useEffect(()=>{
  if(SurveyById){
  let list =  SurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges?.map(({node})=>node)
  setquestionList(list)
  }
},[SurveyById])

function handleMenuClick(value) {

  let userData = employeeList?.find(item => item?.node?.user?.employee?.id===value?.key)
  let node
  if(userData){
   node =userData?.node
  }
  if(node){
 onSelectRecepiant({employee:node?.user?.employee?.id})
  setRecipient(node?.user?.employee?.id)
  setrecipientName(`${node?.user?.firstName} ${node?.user?.lastName}`)

  }
 
}




const deselect = ()=>{
  setokrid(null)
}


const FeedBackTypemenu =()=> (
  <Menu >
     <Menu.Item key="1" onClick={()=>HandleTypeChange("1")}>
        Motivational/Recognition
      </Menu.Item>
      <Menu.Item key="2" onClick={()=>HandleTypeChange("2")}>
        Developmental/Constructive
      </Menu.Item>
  </Menu>
);

const HandleTypeChange=(type)=>{
if(type==="1"){
  setFeedBackType("1")
  if(!question5Field1){
    setquestion5Field1("You should try and do this more often and if possible, even try and help the team adopt this behavior.")
  }
}
else if(type==="2"){
  setFeedBackType("2")
  if(!question5Field2){
    setquestion5Field2("If it’s helpful, I can continue to observe and provide feedback if I see you try something differently. I am happy to catch up again in some time, so you can bounce off any challenges you may be facing. Let me know.")
  }
}
}

const submitAnswer =(data,value)=>{

 let feed = withMarkdownFieldValue("answer",{answer:value})
 let answerSet = {
  questionId:getIntFromString(data?.id),
  ...feed
 }

  let tempAnswer = [].concat(answersList)
  let previous = tempAnswer.find(item=> getIntFromString(item?.questionId)===getIntFromString(data?.id))
  if(previous){
    let index = tempAnswer.indexOf(previous)
    tempAnswer.splice(index,1)
    tempAnswer.push(answerSet)
  }
  else{
    tempAnswer.push(answerSet)
  }
  setAnswers(tempAnswer)

}


const handleValidationwithFillin=()=>{
  if(Recipient && feedBackType){
    let answerdata =[]
    if(feedBackType==="1"){
      if(!question1Field1 || !question1Field2 || !question2Field1 || !question2Field2 || !question2Field3 || !question3Field1 || !question3Field2){
        message.error('please fill in the required field')
        return
      }
    }
    else if(feedBackType==='2'){
      if(!question1Field1 || !question1Field2 || !question2Field1 || !question2Field2 || !question2Field3 || !question3Field1 || !question3Field2 || !question4Field1){
        message.error('please fill in the required field')
        return
      }
    }

    questionList.forEach(question => {
      let typeValidations = question?.questionAddress?.split(':')
      if(!question?.questionAddress || typeValidations?.includes(feedBackType)){
          let response ={
            questionId:getIntFromString(question?.id),
            answer:''
          }
          if(getIntFromString(question?.id)===683){
            response.answer=replaceAnswer1(question1Field1,question1Field2)
          }
          else if(getIntFromString(question?.id)===684){
            response.answer=replaceAnswer2(question2Field1,question2Field2,question2Field3)
          }
          else if(getIntFromString(question?.id)===685){
            response.answer=replaceAnswer3(question3Field1,question3Field2)
          }
          else if(getIntFromString(question?.id)===686){
            response.answer=replaceAnswer4(question4Field1)
          }
          else if(getIntFromString(question?.id)===687){
            response.answer=feedBackType==="1"? replaceAnswer5Feedback1(question5Field1):feedBackType==="2"?replaceAnswer5Feedback2(question5Field2):''
          }
          answerdata.push(response)
        }   
      
    });
    handleSubmit(answerdata)
  }
  else{
    message.error('please fill in the required field')
  }
}

const handleValidation=()=>
 {
   if(Recipient && feedBackType){
    let answerdata =[]
    questionList.forEach(data => {
     if(!data?.questionAddress){
       let answer = answersList.find(item=> getIntFromString(item?.questionId)===getIntFromString(data?.id))
       answerdata.push(answer)
     }
     else{
       let typeValidations = data?.questionAddress?.split(':')
       if(typeValidations?.includes(feedBackType)){
         let answer = answersList.find(item=> getIntFromString(item?.questionId)===getIntFromString(data?.id))
         answerdata.push(answer)
       }
     }
    });
 
     
    let valid=true
    questionList.forEach(data => {
     if(!data?.questionAddress){
       if(data?.required){
         let answer = answerdata.find(item=> getIntFromString(item?.questionId)===getIntFromString(data?.id) && item?.answer?.trim()!=='')
         if(!answer){
           valid =false
         }
       }
       
     }
     else{
       let typeValidations = data?.questionAddress?.split(':')
       if(typeValidations?.includes(feedBackType) && data?.required){
         let answer = answerdata.find(item=> getIntFromString(item?.questionId)===getIntFromString(data?.id) && item?.answer?.trim()!=='')
         if(!answer){
           valid =false
         }
       }
     }
    });
    if(valid){
     handleSubmit(answerdata)
    }
    else{
     message.error('please fill in the required field')
    }
   }
   else{
    message.error('please fill in the required field')
   }
 }

 async function handleSubmit(value) {
    setloading(true)
   let feedbackData ={
    feedbackToId:getIntFromString(Recipient),
    okrId:getIntFromString(okrid),
    feedbackType:feedBackTypeEnum[feedBackType],
    detailFillIn:fillInOption
   }
  const response = await createFeedback({answers:value,feedbackData:feedbackData,surveyId:15});
  if(response && response?.id){

    if(okrDetails?.keyResult){
      setloading(false)
      history.goBack();
    }
    else if(okrDetails?.meetingid){
      let feedback = okrDetails?.feedbackList || []
      feedback.push(getIntFromString(response?.id))
      await updateMeeting({id:getIntFromString(okrDetails?.meetingid),feedbacks:feedback})
      setloading(false)
      history.push(`/role/one-on-one/meeting/${getIntFromString(okrDetails?.meetingid)}`)
    }
    else{
      setloading(false)
      history.push(feedback_route.feedback?.replace(":type","submitted"))
    }
    
  }else{
    setloading(false)
  }
 }

const showQuestion = (data)=>{

  let createCustomID='fb_'+getIntFromString(data?.id)
  if(!data?.questionAddress){
    return (
      <Col span={24}>
         <Row style={{marginTop:'15px'}}>
          {data?.required && (
           <span style={{color:'#ff4d4f'}}>*</span>
          )}
         <ReactMarkdown >{data?.questionText}</ReactMarkdown>
         </Row>
          <div id={createCustomID} style={{paddingRight:'20px' }} onClick={()=>{scrolltoview(createCustomID)}}>
              <CKEditorField 
                changeHandler={(e)=>{submitAnswer(data,e)}}
                /> 
            </div>
          {/* <textarea style={{width:'100%',height:'150px',padding:'10px',resize:'none'}} id={createCustomID} onClick={()=>{scrolltoview(createCustomID)}} onChange={(e)=>{submitAnswer(data,e?.target?.value)}}></textarea> */}
      </Col>
      )
  }
  else{
    let validations = data?.questionAddress?.split(':')
    if(validations?.includes(feedBackType)){
      return (
        <Col span={24}>
            <Row style={{marginTop:'15px'}}>
              {data?.required && (
              <span style={{color:'#ff4d4f'}}>*</span>
              )}
            <ReactMarkdown >{data?.questionText}</ReactMarkdown>
             </Row>
             <div id={createCustomID} style={{paddingRight:'20px' }} onClick={()=>{scrolltoview(createCustomID)}}>
              <CKEditorField 
                changeHandler={(e)=>{submitAnswer(data,e)}}
                /> 
            </div>
            {/* <textarea style={{width:'100%',height:'150px',padding:'10px',resize:'none'}} id={createCustomID} onClick={()=>{scrolltoview(createCustomID)}} onChange={(e)=>{submitAnswer(data,e?.target?.value)}}></textarea> */}
        </Col>
        )
    }
  }
  
}



const WithNoOption =(
  <Col span={24}>
  {
    questionList?.length && (
      questionList.map((data)=>
      showQuestion(data)
      )
    )
  }
  
  </Col>
)

const WithfillOption =(
  <Col span={24}>
     <div style={{marginTop:'15px'}}>
     <Row>
          <span style={{color:'#ff4d4f'}}>*</span>
          <ReactMarkdown >Create Safety</ReactMarkdown>
          </Row>
  
      <Col span={24} style={{marginTop:'15px'}}>
        <div style={{display:'flex',flexDirection:'raw',gap:'10px'}}>
          <p style={{whiteSpace:'nowrap',fontSize:'15px'}}>I want to commend you for</p>    
          <Tooltip title={'doing/saying something of value'} overlayStyle={{zIndex:1160}}>
          <Input
            style={{
              border: '1px solid #CACACA',
              width:'100%',
              height:'30px'          
            }}
            autoComplete='off'
            onChange={e => (setquestion1Field1(e.target.value))}  
             
                    />
            </Tooltip>
        </div>
        <div style={{display:'flex',flexDirection:'raw',gap:'10px'}}>
          <p style={{whiteSpace:'nowrap',fontSize:'15px'}}>in / during</p>    
          <Tooltip title={'a specific meeting, presentation, project'} overlayStyle={{zIndex:1160}}>
          <Input
            style={{
              border: '1px solid #CACACA',
              width:'100%',
              height:'30px'          
            }}
            autoComplete='off'
            onChange={e => (setquestion1Field2(e.target.value))} 
                    />
            </Tooltip>
            <p style={{whiteSpace:'nowrap',fontSize:'15px'}}>. Is this a good time?</p>    
        </div>
      </Col>
     </div>
     {/* question 2 */}
     <div style={{marginTop:'15px'}}>
     <Row>
          <span style={{color:'#ff4d4f'}}>*</span>
          <ReactMarkdown >Lay Out Context</ReactMarkdown>
          </Row>
     
      <Col span={24} style={{marginTop:'15px'}}>
        <div style={{display:'flex',flexDirection:'raw',gap:'10px'}}>
          <p style={{whiteSpace:'nowrap',fontSize:'15px'}}>I am referring to the</p>    
          <Tooltip title={'event referred to above with more details if needed to make it clearly identifiable'} overlayStyle={{zIndex:1160}}>
          <Input
            style={{
              border: '1px solid #CACACA',
              width:'100%',
              height:'30px'          
            }}
            autoComplete='off'
            onChange={e => (setquestion2Field1(e.target.value))}   
                    />
            </Tooltip>
        </div>
        <div style={{display:'flex',flexDirection:'raw',gap:'10px'}}>
          <p style={{whiteSpace:'nowrap',fontSize:'15px'}}>with</p>    
          <Tooltip title={'names of people that participated in the event'} overlayStyle={{zIndex:1160}}>
          <Input
            style={{
              border: '1px solid #CACACA',
              width:'100%',
              height:'30px'          
            }}
            autoComplete='off'
            onChange={e => (setquestion2Field2(e.target.value))}   
                    />
            </Tooltip>
         
        </div>
        <div style={{display:'flex',flexDirection:'raw',gap:'10px'}}>
          <p style={{whiteSpace:'nowrap',fontSize:'15px'}}>on</p>    
          <Tooltip title={'Optional date and time'} overlayStyle={{zIndex:1160}}>
          <Input
            style={{
              border: '1px solid #CACACA',
              width:'50%',
              height:'30px'          
            }}
            autoComplete='off'
            onChange={e => (setquestion2Field3(e.target.value))}   
                    />
            </Tooltip>
         
        </div>
      </Col>
     </div>
     {/* question 3 */}
     <div style={{marginTop:'15px'}}>
     <Row>
          <span style={{color:'#ff4d4f'}}>*</span>
          <ReactMarkdown >Articulate behavioral evidence and then, impact</ReactMarkdown>
          </Row>
    
      <Col span={24} style={{marginTop:'15px'}}>
        <div style={{display:'flex',flexDirection:'raw',gap:'10px'}}>
          <p style={{whiteSpace:'nowrap',fontSize:'15px'}}>You</p>    
          <Tooltip title={'write verbatim what the person said or did, not your interpretation of it'} overlayStyle={{zIndex:1160}}>
          <Input
            style={{
              border: '1px solid #CACACA',
              width:'100%',
              height:'30px'          
            }}
            autoComplete='off'
            onChange={e => (setquestion3Field1(e.target.value))}   
                    />
            </Tooltip>
        </div>
        <div style={{display:'flex',flexDirection:'raw',gap:'10px'}}>
          <p style={{whiteSpace:'nowrap',fontSize:'15px'}}>. It felt like</p>    
          <Tooltip title={'describe the impact of their behavior on you, others and/or business, e.g. you were being disrespectful.'} overlayStyle={{zIndex:1160}}>
          <Input
            style={{
              border: '1px solid #CACACA',
              width:'100%',
              height:'30px'          
            }}
            autoComplete='off'
            onChange={e => (setquestion3Field2(e.target.value))}
                    />
            </Tooltip>
       
        </div>
      </Col>
     </div>
      {/* question 4 */}
    {
      feedBackType==='2' && (
        <div style={{marginTop:'15px'}}>
          <Row>
          <span style={{color:'#ff4d4f'}}>*</span>
          <ReactMarkdown >Probe for possible alternatives</ReactMarkdown>
          </Row>
        
         <Col span={24} style={{marginTop:'15px'}}>
           <div style={{display:'flex',flexDirection:'raw',gap:'10px'}}>
             <p style={{fontSize:'15px'}}>How else could you have handled that? Here are my suggestions.</p>    
           </div>
           <div style={{display:'flex',flexDirection:'raw',gap:'10px'}}>
             <Tooltip title={'Alternative ways of saying or doing it differently.'} overlayStyle={{zIndex:1160}}>
             <Input
               style={{
                 border: '1px solid #CACACA',
                 width:'100%',
                 height:'30px'          
               }}
               autoComplete='off'
               onChange={e => (setquestion4Field1(e.target.value))}  
                       />
               </Tooltip>
          
           </div>
         </Col>
        </div>
      )
    }

       {/* question 5 */}
       <div style={{marginTop:'15px'}}>
     <ReactMarkdown >Support for next steps and commitments</ReactMarkdown>
      <Col span={24} style={{marginTop:'15px'}}>
        <div style={{display:'flex',flexDirection:'raw',gap:'10px'}}>
          {feedBackType==="1"?
            <Input
            style={{
              border: '1px solid #CACACA',
              width:'100%',
              height:'30px' ,
            }}
            value={question5Field1}
            autoComplete='off'
            onChange={e => (setquestion5Field1(e.target.value))}  
                    />
            // <p style={{fontSize:'15px'}}>You should try and do this more often and if possible, even try and help the team adopt this behavior.</p>  
            :  feedBackType==="2"?
            <Input
            style={{
              border: '1px solid #CACACA',
              width:'100%',
              height:'30px' ,
            }}
            value={question5Field2}
            autoComplete='off'
            onChange={e => (setquestion5Field2(e.target.value))}  />:""
            // <p style={{fontSize:'15px'}}>If it’s helpful, I can continue to observe and provide feedback if I see you try something differently. I am happy to catch up again in some time, so you can bounce off any challenges you may be facing. Let me know.</p> :''
        }  
        </div>
       
      </Col>
     </div>
  </Col>
)
  return (
    <>
       <>
       <MetaTags
        title="Give Feedback"
        description="This is Create Feedback page."
      />
      
    <Spin spinning={loading}>
        {/* Page Title and Back Arrow */}
        <div className="main-title-container back-arrow-global" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', position: 'relative', margin: '0em 1em', paddingBottom: '1.5em' }}>
          <img onClick={navigateTo} src={new_back_arrow} alt="back-arrow" style={{ alignContent: 'center', height: '21%', cursor: 'pointer' }} />
          <p className="goalheader poppins" style={{ margin: 'auto', textAlign: 'center', color: 'black' }}>Give Feedback</p>
          <img  src={new_back_arrow} alt="back-arrow" style={{ alignContent: 'center', height: '21%', visibility: 'hidden' }} />
        </div>
      {
        SurveyById && (
          <div style={{padding:'0px 20px'}}>
          <Form
              form={form}
              name={"value"}
              onFinish={() =>  !fillInOption?handleValidation():handleValidationwithFillin()}>
          <Row gutter={5} style={{borderBottom:"1px solid #d3d3d3",paddingBottom:'10px'}}>
              <ColScroll lg={{ span: 15 }}  md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}  style={{height:'70vh',overflow:'auto',marginBoottom:'10px'}}>
    
                  {
                    navigationFromOkr?
                    <Row>
                      <p style={{fontSize:'15px'}}>Recipient : {recipientName}</p>
                       
                    </Row>:
                       <Col>
                       <p style={{fontSize:'15px'}}><span style={{color:'#ff4d4f'}}>*</span> Recipient</p>
                    
                          <Select
                      showSearch={true}
                      onSearch={(e)=>{handleSearch(e)}}
                      style={{
                          width: '250px'               
                      }}
                      placeholder="Select Recipient"
                      onChange={(value,options)=>handleMenuClick(options)}
                      id="fd_recipient" onClick={()=>{scrolltoview('fd_recipient')}}
                      >
              
                    {
                        employeeList?.map(item => 
                            {
                                return <Select.Option key={item?.node?.user?.employee?.id} value={`${item?.node?.user?.firstName} ${item?.node?.user?.lastName}`} >{item?.node?.user?.firstName} {item?.node?.user?.lastName}</Select.Option>
                            }
                        )
                    }

                </Select>
                     </Col>

                  }
                  {
                    !navigationFromOkr && !hideselection && (
                      <Col style={{marginTop:'20px'}} >
                      <p style={{fontSize:'15px'}}>Is this Feedback related to OKR</p>
                      <Radio.Group style={{marginLeft:'15px'}} defaultValue={false} disabled={Recipient?false:true} onChange={(e)=>{setKeyResultActive(e.target.value);deselect()}}>
                         <Space direction="vertical">
                           <Radio value={true}>Yes</Radio>
                           <Radio value={false}>No</Radio>
                         </Space>
                       </Radio.Group>
                       {
                         keyResultsActive && Recipient && (
                           <Row gutter={10} style={{marginTop:'15px'}}>
                             <Col lg={{ span: 12 }}  md={{ span: 12 }} xs={{ span: 24 }} sm={{ span: 24 }} >
                             <p style={{fontSize:'15px'}}>OKR</p>
                             <Select
                      showSearch={true}
                      onSearch={(e)=>{okrSearch({employee:Recipient,okr_Goalstatement_Icontains:e})}}
                      style={{
                          width: '250px'               
                      }}
                      filterOption={(input,option)=> option}
                      placeholder="Select OKR"
                      onChange={(value,options)=>{setokrid(value)}}
                      id="fd_okr" onClick={()=>{scrolltoview('fd_okr')}}
                      >
              
                    {
                        okrList?.map(item => 
                            {
                                return <Select.Option key={item?.id} value={item?.id} >{item?.label}</Select.Option>
                            }
                        )
                    }

                </Select>
                             </Col>
                           
                           </Row>
                         )
                       }
                     </Col>
                    )
                  }
                 
                  <Row style={{marginTop:!keyResultsActive?'30px':'50px'}} >
                          <p style={{fontSize:'15px',marginRight:'15px'}}><span style={{color:'#ff4d4f'}}>*</span> This feedback is :</p>
                          <Dropdown overlay={FeedBackTypemenu}  >
                              <DropdownButton >
                          
                                  <p style={{marginTop:'10px',width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left'}}>{feedBackType?feedBackTypeEnum[feedBackType]:'Select Feedback type'}</p>
                                  <DownOutlined />
                            
                              </DropdownButton>
                            </Dropdown>
                  </Row>
                  <Checkbox style={{marginTop:'20px'}} defaultValue={fillInOption} disabled={!feedBackType?true:false} onChange={()=>{setfillInOption(!fillInOption)}}><p style={{fontSize:'15px'}}>Show Detailed Fill-in Option</p></Checkbox>

                    {/* survey component */}
                  {
                    feedBackType && (
                        <div style={{marginTop:'20px'}}>
                            {
                              !fillInOption && (
                                <div>
                                    {WithNoOption}
                                </div>
                              )
                            }
                         
                      
                             {
                              fillInOption && (
                                <div>
                                   {WithfillOption}
                                </div>
                              )
                            }
                        </div>
                    )
                  }
               
              </ColScroll>
              <ColScroll lg={{ span: 9 }}  md={{ span: 0 }} xs={{ span: 0 }} sm={{ span: 0 }} style={{height:'70vh',overflow:'auto',marginBoottom:'10px'}}>
                  {getToolById?
                      <div >
                          <Col>
                              
                              <GenericTool
                                  {...props}
                                  widthOfContainer={40}
                                  containerBorder={""}
                                  spacing={'0px'}
                                  hideTopDivider={true}
                              ></GenericTool>
                          </Col>
                      </div>:
                      <div align="center">
                          <Spin></Spin>  
                      </div> 
              }
              </ColScroll>
          </Row>
          <div align="center">
              <CancelButton type="default" onClick={navigateTo} style={{marginTop:'30px', margin: '1em 1em 0em 1em'}}>Cancel</CancelButton>
              <CreateButton type="primary" htmlType="submit" style={{marginTop:'30px', margin: '1em 1em 0em 1em'}}>Submit</CreateButton>
          </div>
          </Form>
          </div>
        )
      }
     
    </Spin>
    </>
    </>
  );
};

export default FeedbackPost;

const CreateButton = styled(Button)`
width:150px;
height:40px;
border-radius:5px;
margin-top:10px;
`

const CancelButton = styled(Button)`
width:150px;
height:40px;
color: #B0CB1F;
border-radius:5px;
border-style: solid;
border-color: #B0CB1F;
margin-top:10px;
&:hover {
    background-color: #B0CB1F;
    color: white;
  }
`

const DropdownButton = styled(Button)`
width:230px;
display:flex;
justify-content:space-between;
align-items:center;
gap:5px
`

const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#B0CB1F;
}
`