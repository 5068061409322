import React from 'react'
import TeamDiversityView from '../components/teamDiversityView'
import { compose } from 'modules/core'
import { withApollo } from '@apollo/client/react/hoc';
import {  Diversity_question_ids, diversity_survey_id } from '../../../../config';
import { DASHBOARD_QUESTION_BY_QUESTION_ID, DIVERSITY_SURVEY_RESPONSE } from 'modules/home/graphql/teamDashboardQueries.gql';
import { getIntFromString, getQuestionBase64StringFromInt, getSurveyBase64StringFromInt } from 'modules/look';

const TeamDiversityContainer = ( props ) => {
    const { client, me, team_employees } = props
    const [ choice_list, setChoiceList ] = React.useState()
    const [ diversity_user_response, setDiversityUserResponse ] = React.useState([])
    const [ chert_loading, setChartLoading ] = React.useState( true )
    const [current_filter,setCurrentFilter]=React.useState()
    React.useEffect( () => {
        if ( me&&team_employees?.length&&!current_filter) {
            questionChoiceList( getQuestionBase64StringFromInt(Diversity_question_ids?.diversity_survey_generation_id) )
            let employee_list = team_employees?.map( item => getIntFromString( item?.user?.id ) )
            diversityServeyReasponseList( { survey: getSurveyBase64StringFromInt( diversity_survey_id ), question:getQuestionBase64StringFromInt(Diversity_question_ids?.diversity_survey_generation_id), user_In: employee_list } )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ me,team_employees] )
    const questionChoiceList = async ( id ) => {
        const { data } = await client.query( {
            query: DASHBOARD_QUESTION_BY_QUESTION_ID,
            fetchPolicy: 'network-only',
            variables: { id: id }
        } );
        if ( data?.questionById ) {
            setChoiceList( data?.questionById?.choiceSet?.edges?.map( ( { node } ) => node ) )
        }
    }

    const diversityServeyReasponseList = async ( filter ) => {
        setChartLoading( true )
        const { data } = await client.query( {
            query: DIVERSITY_SURVEY_RESPONSE,
            fetchPolicy: 'network-only',
            variables: { ...filter }
        } );
        if ( data?.surveyResponses ) {
            let responseSet = data?.surveyResponses?.edges?.filter( ( { node } ) => node?.answerSet?.edges?.length > 0 )
            const groupedByText = responseSet.reduce( ( acc, curr ) => {
                const userId = curr?.node?.user?.id;
                if ( !acc[ userId ] ) {
                    acc[ userId ] = [];
                }
                acc[ userId ].push( curr );
                return acc;
            }, {} );

            //Sort each group based on responseDate and get the latest object
            const latestObjectsByUser = Object.values( groupedByText ).map( group => {
                return group.reduce( ( latest, current ) => {
                    return new Date( current.responseDate ) > new Date( latest.responseDate ) ? current : latest;
                } );
            } );
            setDiversityUserResponse( latestObjectsByUser )
        }
        setChartLoading( false )
    }

    return (
        <>
            <TeamDiversityView
                choice_list={choice_list}
                diversity_user_response={diversity_user_response}
                chert_loading={chert_loading}
                getDiversityServeyReasponseList={(filter)=>diversityServeyReasponseList(filter)}
                getquestionChoiceList={(filter=>questionChoiceList(filter))}
                currentFilter ={(filter)=>setCurrentFilter(filter)}
                {...props} />
        </>
    )

}

export default compose( withApollo )( TeamDiversityContainer )