import React from 'react';
import { Button, Col, DatePicker, Modal, Progress, Row, Space, Tooltip } from "antd"
import { displayUtcToUserTimezone } from 'modules/look/components/functions';
import DatePickerField from 'modules/look/components/form-components/DatePickerField';
import styled from 'styled-components';
import { getIntFromString } from 'modules/layout/Layout';
import moment from 'moment-timezone';


const UpdateCorrectionDeadlineModal = (props) => {
    const { correctionDeadline, marginTop ,marginLeft, responsiveMarginLeft,extendOkrCorrectionDeadline,okrById,duedate, startdate } = props;


    const [extendedCorrectionDeadline, setExtendedCorrectionDeadline] = React.useState();
    const [visible, setVisible] = React.useState(false)


    


    const handleDeadlineExtension = async(newCorrectionDeadline) =>{
        let response = await extendOkrCorrectionDeadline({ id: getIntFromString(okrById?.id), correctionDeadline:newCorrectionDeadline})
        if (response) {
            setVisible(false);
          // setEditableInputBox(false);
         
        }
      }

    return (
        <>

            
            <Button  className="extendDeadlineButton" style={{ height: '44px',marginTop:marginTop }} onClick={(e) => setVisible(true)} >Extend correction deadline</Button>

            <UpdateCorrectionDeadliineModal visible={visible} footer={false} cancelText={false} destroyOnClose={true}>
                <div align='center'>
                    <h2 className='poppins'>Extend Correction Deadline</h2>
                    <div style={{fontSize:'16px',marginBottom:'20px'}} className='poppins'>Current Correction Deadline: {displayUtcToUserTimezone(correctionDeadline, 'DD MMM YYYY')}</div>
                    <Row justify='center' style={{alignItems:'baseline',paddingBottom:'20px'}}>
                        <Col className='newCorrectionDeadlineLabel'>
                            <div style={{fontSize:'16px',marginRight:'10px'}} className='poppins'>New correction deadline: </div>
                        </Col>
                        <Col>
                        <DatePickerField 
                         defaultValue={moment.utc(correctionDeadline).local()}
                         dropdownClassName="modal-datepicker-index"
                        onChange={(e) => setExtendedCorrectionDeadline(e)}
                        disabledDate={(current) => {
                            return (current.isBefore(moment(startdate).local()) || current.isAfter(moment(duedate).local()))
                          }} 
                        />
                        </Col>
                    </Row>
                    <Row justify='center'>
                        <Button className='extendDeadlineConfirmationButton' onClick={(e) => handleDeadlineExtension(extendedCorrectionDeadline)} >Extend</Button>
                        <Button className='generic-button' style={{marginLeft:'20px',border:'1px groove'}} onClick={(e) => setVisible(false)} >Cancel</Button>
                    </Row>
                </div>
            </UpdateCorrectionDeadliineModal>

        </>
    )
}

export default UpdateCorrectionDeadlineModal


const UpdateCorrectionDeadliineModal = styled(Modal)`

.ant-modal-close{
    display:none;
}
`;
