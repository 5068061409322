// @ts-nocheck
import React from 'react'
import _ from "lodash";
import styled from "styled-components";
import {
    Carousel,
    Progress,
    Row,
    Button,
    Col,
    Form,
    Tooltip,
    Space,
    Spin,
    Modal,
    message
} from "antd";
import {
    UpCircleTwoTone,
    DownCircleTwoTone,
} from "@ant-design/icons";
import {
    TIME_LIMIT,
} from "../../survey/components/SectionComponent";
import {
    MetaTags,
    getIntFromString,
    getQuestionBase64StringFromInt,
    getUniqueListBy,
    objectSanitizer,
} from "../../look";
import DiversityGroupView from './DiversityGroupView'
import survey_shotcuts from '../../assets/survey-shotcuts.svg'
import quit_icon from '../../assets/quit-survey-icon.svg'
import warning_icon from '../../assets/warning-icon.svg'
import QuizLoading from 'modules/survey/components/QuizLoading';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import HOME_ROUTE from '../../home/route';
import { Diversity_question_ids } from '../../../config';
import moment from 'moment-timezone';
const DiversityQuizView = ( props ) => {
    const { onSubmit, SurveyById,navigateRoute } =
        props;
    const ref = React.useRef();
    const history = useHistory()
    const mobileRef = React.useRef();
    const currentSectionRef = React.useRef();
    const [ formValues, setFormValues ] = React.useState( [] );
    const [ currentSection, setCurrentSection ] = React.useState( 1 );
    const [ showOk, setShowOk ] = React.useState( [ false ] );
    const [ timeoutId, setTimeoutId ] = React.useState( null );
    const [ startTime, setStartTime ] = React.useState( new Date().getTime() );
    const [ isForw, setIsForw ] = React.useState( true );
    const [ slectedGroupChoiceList, setSelectedGroupChoiceList ] = React.useState( [] )
    const [ currentslectedGroupChoiceList, setCurrentSelectedGroupChoiceList ] = React.useState( [] )
    const [ slectedCkeckboxList, setSlectedCkeckboxList ] = React.useState( {} )
    const [ quit_survey, setQuitSurvey ] = React.useState( false )
    const [ form ] = Form.useForm();
    const [ selected_choice, setSelectedChoice ] = React.useState( [] )


    const question = [].concat.apply(
        [],
        SurveyById?.groupSet?.edges
            .sort( function ( a, b ) {
                return a.node.sequence - b.node.sequence;
            } )
    );

    
    React.useEffect( () => {
        setTimeoutId( () => {
            if ( !formValues.length ) {
                localStorage.removeItem( 'oldValue' )
            }
        }, 400 )
    }, [ formValues ] )


    const totalLength = question.length;
    const carouselSettings = {
        accessibility: false,
        // ...carouseSettingChange,
        beforeChange,
        // afterChange,
        // ref,
        ref: mobileRef,
        dotPosition: "left",
        dots: false,
        autoplay: false,
        infinite: false,
    };


    function beforeChange( current ) {

        if ( !isForw && current === 0 ) {
            return false;
        }
        setCurrentSection( isForw ? current + 2 : current );
        setCurrentSectionFromRef( isForw ? current + 2 : current );
        // setCurrentMatrixQuestion(0);
    }


    function setCurrentSectionFromRef( value ) {
        currentSectionRef.current.innerHTML = Number( value );
    }


    function timeoutFunc( srNo ) {
        const id = setTimeout(
            () => nextStep( form.getFieldsValue( true ), srNo ),
            TIME_LIMIT * 1000
        );
        setTimeoutId( id );
    }


    function closetimer() {
        clearTimeout( timeoutId );
    }

    const nextStep = ( values ) => {
       
        let submit_section = question[currentSection-1]?.node?.questionSet?.edges?.map(({node})=>node)
        
        if(submit_section?.length){
            let required_question_set_no_address = submit_section?.filter(i=>i?.required && !i?.questionAddress)?.map(i=>i?.id)
            let values_question_id = Object.keys(values)
            let choices_q = submit_section?.filter(i=>values_question_id?.includes(i?.id) && i?.choiceSet?.edges?.length) || []

            let depend_q_address = []
            choices_q.forEach(i=>{
                let q = getIntFromString(i?.id)?.toString()
                let choice = i?.choiceSet?.edges?.map(({node})=>node)
                let type = values[i?.id]
                if(type){
                    let t_of = typeof(type)
                    choice.forEach(c=>{
                        if(t_of=='object' && type?.includes(c?.value) ){
                            let c_id = getIntFromString(c?.id)?.toString()
                            depend_q_address.push(`${q}:${c_id}`)
                        }else if(type == c?.value ){
                            let c_id = getIntFromString(c?.id)?.toString()
                            depend_q_address.push(`${q}:${c_id}`)
                        }
                   
                        
                    })
                }
            
            })
            
            let required_question_set_with_address = submit_section?.filter(i=>i?.required && depend_q_address?.includes(i?.questionAddress))?.map(i=>i?.id) || []
            let required_question_set = required_question_set_no_address?.concat(required_question_set_with_address)
            let valid_submit = objectSanitizer(values)
            let req_ans = Object.keys(valid_submit)
            let form_valid = required_question_set?.every(i=>req_ans?.includes(i))
            if(!form_valid && required_question_set?.length){
                message.error('Fill in all the required questions')
                return
            }
        }
        if(submit_section?.length && values[getQuestionBase64StringFromInt(Diversity_question_ids?.diversity_survey_generation_id)] && values[getQuestionBase64StringFromInt(Diversity_question_ids?.age)]){
            
            let age = parseInt(values[getQuestionBase64StringFromInt(Diversity_question_ids?.age)])
            let age_list = [age,age+1]
            let valid_age = false
            let generation_q = submit_section?.find(i=>i?.id==getQuestionBase64StringFromInt(Diversity_question_ids?.diversity_survey_generation_id))
            if(generation_q){
                let option_list = generation_q?.choiceSet?.edges?.map(({node})=>node)
                let selected_option = option_list?.find(i=>i?.value == values[getQuestionBase64StringFromInt(Diversity_question_ids?.diversity_survey_generation_id)])
                if(selected_option){
                    let year_range = Diversity_question_ids.generation_options.find(i=>i.id == getIntFromString(selected_option?.id))
                    if(year_range){
                        age_list.forEach(i=>{
                            let y = moment().get('year') - i
                            if(y>=year_range?.min_year && y<=year_range?.max_year){
                                valid_age = true
                            }
                        })
                    }else{
                        valid_age = true
                    }
                }else{
                    valid_age = true
                }
            }else{
                valid_age = true
            }
            if(!valid_age){
                message.error('Age you entered doesnot match with the generation')
                return
            }
            
        }
        setIsForw( true );
        if ( currentSection !== totalLength ) ref.current && ref.current.next();
        if ( currentSection !== totalLength ) mobileRef.current && mobileRef.current.next();
        closetimer();
        setShowOk( question.map( () => false ) );
        const endTime = new Date().getTime();

        let input = [];
        Object.keys( values ).map( ( k, i ) => {
            input.push( {
                questionId: getIntFromString( k ),
                answer: String( getValue( values[ k ], k ) ),
                sequence: selected_choice?.find( item => item?.questionId === getIntFromString( k ) )?.sequence || 1,
                responseDuration: getResponseDuration( endTime, values[ k ], k )
            } );
        } );
        setFormValues( getUniqueListBy( [ ...formValues, ...input ], 'questionId' ) );
        if ( document ) {
            if ( document.body ) {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }
        }
        setStartTime( endTime );
        let result = getUniqueListBy( [ ...formValues, ...input ], "questionId" );
        localStorage.setItem(
            "divesityValues",
            JSON.stringify( { submitValues: result } )
        );
        if ( currentSection != totalLength ) {
            setCurrentSection( currentSection + 1 )
        }
        else {
            navigateTo( result )
        }
        return result;
    };


    function getValue( value, name ) {
        return value || 0;
    }


    function getResponseDuration( endTime, value, k ) {
        let time = 0;
        const existingValue = formValues.filter(
            ( f ) => f.questionId === getIntFromString( k )
        )[ 0 ];

        time = existingValue
            ? existingValue?.answer === "" || existingValue?.answer === undefined
                ? value !== "" || value !== undefined
                    ? 0
                    : ( endTime - startTime ) / 1000
                : existingValue?.responseDuration
            : value === "" || value === undefined
                ? 0
                : ( endTime - startTime ) / 1000;

        return time;
    }


    const navigateTo = ( result ) => {
        onSubmit( result )
        localStorage.removeItem( 'oldValue' )
    };

    const prevStep = () => {
        if ( currentSection === 0 ) {
            return false;
        }
        setCurrentSection( currentSection - 1 )
    };


    const choiceList = ( e ) => {
        let list = []
        let selctChoice = list.concat( e )
        setSelectedGroupChoiceList( selctChoice )

    }


    return (
        <>
            <MetaTags title="Diversity" description="This is Diversity survey page." />
            <Spin spinning={question.length ? false : true} size="large">
                {/* {question.length ? <Col span={24}>
                    <h4>Progress</h4>
                    <Progress
                        percent={Math.round( ( currentSection / totalLength ) * 100 )}
                        strokeColor={{
                            "0%": "#108ee9",
                            "100%": "#87d068",
                        }}
                        style={{
                            display: "block",
                        }}
                        showInfo={false}
                        status="active"
                    />
                </Col> : null} */}

                <Form
                    form={form}
                    name={"value"}
                    // layout={'vertical'}
                    onFinish={( values ) =>
                        nextStep( values )
                    }
                >
                    {question.map( ( grpSet, idx ) => (
                        <>
                            <Col style={idx + 1 === currentSection ? { display: 'inherit' } : { display: 'none' }} >
                                <DiversityGroupView
                                    survey={SurveyById}
                                    key={idx}
                                    srNo={idx + 1}
                                    form={form}
                                    form_values={form.getFieldsValue( true )}
                                    currentSection={currentSection}
                                    sectionLabel="value"
                                    sectionLabel={grpSet?.node?.label}
                                    sectionSequence={grpSet.node?.sequence}
                                    timeoutFunc={timeoutFunc}
                                    closetimer={closetimer}
                                    subMittedAnswer={e => nextStep( e )}
                                    nextStep={( e ) => nextStep( form.getFieldsValue( true ), e )}
                                    questionList={[ grpSet ]}
                                    questionData={question}
                                    showOk={showOk[ currentSection - 1 ]}
                                    setShowOk={e =>
                                        setShowOk( question.map( ( q, qi ) => ( e ? qi === currentSection - 1 : false ) ) )
                                    }
                                    selectedChoiceList={e => choiceList( e )}
                                    choiceList={slectedGroupChoiceList}
                                    selectedChoicevalues={( e ) => setSelectedChoice( e )}
                                />
                            </Col>
                        </>

                    ) )}
                    {/* New survey Action Buttons */}

                    {question.length > 0 && <Row className='generic-survey-skil-stepper' justify='space-between' align='center'>
                        <Button className='generic-survey-quit-btn' style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px", marginTop: "10px", cursor: "pointer" }} onClick={() => setQuitSurvey( true )}>
                            <img src={quit_icon} alt="" />
                            Quit survey
                        </Button>

                        {currentSection !== totalLength ?
                            // <div style={{ display: 'flex', flexDirection: "row", gap: "1em", alignItems: "center" }}>
                            //     {currentSection > 1 && <button className='generic-survey-action-btn' onClick={() => prevStep()}>
                            //         PREVIOUS
                            //     </button>}
                                <button className='generic-survey-action-btn' htmlType="submit">
                                    NEXT
                                </button>
                            // </div>
                            :
                            <button className='generic-survey-action-btn' htmlType="submit">
                                SUBMIT
                            </button>}
                        <div></div>
                    </Row>}
                </Form>
            </Spin>
            <Modal
                footer={null}
                style={{ padding: 10, borderRadius: "20px", maxWidth: "600px" }}
                visible={quit_survey}
                closable={false}
                width='100%'
                centered={true}
                className="custom-modal"
            >
                <Row justify='center' align='center' style={{ padding: "1rem", flexDirection: 'column', justifyContent: 'center', alignItems: "center", gap: '10px' }}>
                    <img src={warning_icon} alt="" />
                    <h3 className="generic-survey-quit-title" style={{ marginTop: "1rem" }}>Are you sure you want to exit this survey ?</h3>
                    {/* <p className="generic-survey-quit-para">We will save all your current responses. you can choose to continue from here the next time you start this survey.</p> */}
                    <Row justify="center" align="center" >
                        <button className="generic-survey-remove-btn" style={{ margin: "5px" }} onClick={() => setQuitSurvey( false )}>NO</button>
                        <button className="generic-survey-submit-btn" style={{ margin: "5px" }} onClick={() =>  navigateRoute( HOME_ROUTE.userdashboard )}>YES</button>
                    </Row>
                </Row>
            </Modal>
        </>
    )
}

export default DiversityQuizView
