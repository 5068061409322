// @ts-nocheck
import { Col, Tooltip, Form, Button, message, Row } from "antd";
import React from "react";
import objective_expansion_arrow from '../../assets/objective_expansion_arrow.svg'
import close from "../../assets/close.svg";
import { Capitalize, changeToCurrentTime, displayUtcToUserTimezone, getIntFromString } from "modules/look";
import collabrator_disable from '../../assets/collabrator_disable.svg'
import RangeDatePicker from "modules/look-v2/Components/date-range-picker";
import gear_disabled from '../../assets/gear_disabled.svg'
import moment from 'moment-timezone';
import TableRowMenu from "modules/look-v2/Components/TableRowMenu";
import view from '../../assets/view.svg'
import delete_outline_black from '../../assets/delete_outline_black.svg'
import black_discard from '../../assets/black_discard.svg'
import RequestDiscardView from "./requestDiscardView";
import ProgressUpdateContainer from "../formComponents/Container/progressUpdateContainer";
import black_eye from "../../assets/black_eye.svg";
import blue_eye from "../../assets/blue_eye.svg";
import AddWeightContainer from "../formComponents/Container/AddWeightContainer";
import CommaSeparateComponent from "../formComponents/CommaSeparatedComponent";
import CustomProgress from "modules/look-v2/Components/Progress";
import ObjDetailMileStoneTableContainer from "../container/DetailpageMilestoneTable"
import ExpandableDropdownContainer from "modules/look-v2/Container/ExpandableDropdownContainer";
import DeleteOkrDetails from "./deleteOkrDetails.";
import menu_cascade from '../../assets/menu_cascade.svg'
import menu_del_info from '../../assets/menu_del_info.svg'
import grey_expansion from '../../assets/grey_expansion.svg'
import blue_expansion from '../../assets/blue_expansion.svg'
import TextInputField from "../formComponents/OkrInputField";
import CustomOkrRangeTable from "modules/look-v2/Components/customOkrDatePickerForTable";

import kodos_award from '../../assets/kodos-award-icon.svg'
import AwardBadgeContainer from "modules/kudos-user/container/awardBadgeContainer";
import okr_route from "../route";
import edit_pencil from '../../assets/edit_pencil.svg'
import { employee } from "modules/organization/route";
const ObjectiveDetailTableView = (props) => {
  const {  me, createOkr, addChild,allKr,objId, updateOkr, removeAdd, isDiscarded, okrById, okrDiscarded ,set_okr_tree,permission,updateKRRef,navigateRoute,getWightList} = props


  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [expandedRow, setexpandedRow] = React.useState([]);
  const [createKRCounter, setcreateKRCounter] = React.useState([]);
  const [discardPopupvisibleKR, setDiscardPopupVisibleKR] = React.useState(false);
  const [okr, setOkr] = React.useState();
  const [dataSource, setDataSource] = React.useState([]);
  const [deleteRecord, setDeleteRecord] = React.useState();
  const [pageloading, setpageloading] = React.useState(false)
  const [deleteModal, setDelateModal] = React.useState(false);
  const [addAward,setAddAward]=React.useState(false)
  const [selectedKudos,setSelectedKudos]=React.useState()
  const [show_pop_over,setPopOver]=React.useState(false)


  React.useEffect(() => {

    if (allKr?.edges?.length) {
 
     
      let incomingData = allKr?.edges?.map(({ node }) => {
        return {
          id: node?.okr?.id,
          title: node?.okr?.title,
          okrType: node?.okrType,
          startdate: node?.okr?.startdate,
          duedate: node?.okr?.duedate,
          goalStatement: node?.okr?.goalStatement,
          correctionDeadline: node?.okr?.correctionDeadline,
          contributors:node?.okr?.contributorSet?.edges?.map(({node})=>
          {
              return{
                  ...node?.employee?.user,role:node?.role,employee_id:node?.employee?.id
              }
          }),
          progress: node?.okr?.progress ?? 0,
          childSet:node?.okr?.childSet?.totalCount?true:false,
          discardRequestRaised:node?.okr?.discardRequestRaised?.totalCount>0?true:false,
          isDiscarded: {
            status: (isDiscarded === "APPROVED") ? isDiscarded :
              node?.okr?.isDiscarded?.status ?
                node?.okr?.isDiscarded?.status :
                node?.okr?.discardRequest?.totalCount ? "PENDING" : ''
            
          }
        }
      })
      setDataSource(incomingData)
      setpageloading(false)
    }
    else {
      setDataSource([])
    }
  }, [allKr])



React.useEffect(()=>{
  if(dataSource?.length){
    getWightList()
  }
},[dataSource])


  const expand = (id) => {
    let list = [].concat(expandedRow);
    if (!list?.includes(id)) {
      list.push(id);
    } else {
      list = list.filter((item) => item !== id);
    }
    setexpandedRow(list);
  };

  const addToKR = () => {
    if (!createKRCounter?.length) {
      let list = [].concat(createKRCounter);
      list.push({ key: `kr_${list?.length + 1}` });
      setcreateKRCounter(list);
    }
  };

  const onFinish = async (key) => {

    try {
      let value = form.getFieldsValue()
      //  new to do change parent id from props
      if (value[`${key}_goalStatement`] && value[`${key}_goalStatement`]?.length<=150 && value[`${key}_duedate`]?.length === 2) {
        let due_date = changeToCurrentTime(value[`${key}_duedate`][1])
        let okrData = {
          goalStatement: value[`${key}_goalStatement`],
          duedate: due_date?.toISOString(),
          startdate: value[`${key}_duedate`][0].toISOString(),
          parentId: objId
        }
        const response = await createOkr({ okrData })
        if (response && response?.id) {
          setcreateKRCounter([])
          removeAdd(false);
          removecreateKR({ key }, true)
          form.resetFields()
        }
      }
    }
    catch (err) {
      console.log('kr create catch', err);
    }
  };

  const removecreateKR = (value, force_remove = false) => {
    if (dataSource?.length || force_remove) {
      let list = [].concat(createKRCounter)
      setcreateKRCounter(list.filter(item => item?.key !== value?.key))
    }
  }

  const onUpdate = async (okr) => {
    let formValue = updateForm.getFieldsValue()
    let data = {}
    if (formValue[`${okr?.id}_goalStatement`]) {
      data['goalStatement'] = formValue[`${okr?.id}_goalStatement`]
    }

    if(data['goalStatement'] && data['goalStatement']?.length>150){
      return
    }

    if (formValue[`${okr?.id}_duedate`]?.length === 2) {
      let dates = formValue[`${okr?.id}_duedate`]
      data['startdate'] = dates[0].toISOString()
      let due_date = changeToCurrentTime(dates[1])
      data['duedate'] = due_date.toISOString()
    }

    if (!Object.keys(data)?.length) {
      message.error('update the form')
    } else {
      data['id'] = okr?.id
      let response = await updateOkr(data)
      if (response?.id) {
        let list = [].concat(dataSource)
        let item = list?.find(val => val?.id === okr?.id)
        if (item) {
          item['duedate'] = response?.duedate
          item['startdate'] = response?.startdate
          item['title'] = response?.title
          item['goalStatement'] = response?.goalStatement
          item.isUpdate = false
          let update_data ={
            id:response?.id,
            title:response?.title,
            startdate:response?.startdate,
            duedate:response?.duedate,
            goalStatement:response?.goalStatement
          }
          updateKRRef({record:update_data,type:'UPDATE'})
        }
        setDataSource(list)
      } else {
        message.error('failed to update')
      }
    }
  }



  const onTableMenuClickAction = (record, action) => {


    if (action === 'view') {
      navigateRoute( okr_route.keyResultDetail.replace(":okrId",getIntFromString(record?.id)))
    } else if (action === 'edit') {
      updateQue(record, true)
    } else if (action === 'discard') {
      setOkr(record?.id);
      setDiscardPopupVisibleKR(true);
    } else if (action === 'delete') {
      // DeleteSelected(record?.id);

      setDeleteRecord(record);
      setDelateModal(true)
    }else if (action === 'tree') {
      set_okr_tree(record);
    } else if(action==='kodos'){
      setAddAward(true)
      setSelectedKudos(record)
  }
  }




  React.useEffect(() => {
    if (addChild) {
      addToKR()
    }
  }, [addChild])

  const updateDelete = (id) => {
    setDeleteRecord(null)
    let list = [].concat(dataSource)
    list = list.filter(item => item?.id !== id)
    updateKRRef({record:{id},type:'DELETE'})
    setDataSource(list)
  }

  const updateQue = (record, isUpdate) => {
    let list = [].concat(dataSource)
    let item = list?.find(okr => okr?.id === record?.id)
    item['isUpdate'] = isUpdate
    setDataSource(list)
  }


  const addContributor=(contributor,item)=>{
    let list = [].concat(dataSource)
    let record = list?.find(val=>val?.id===item?.id)
    if(record){
      let filter_list =record?.contributors?.filter(user=>user?.id===me?.employee?.user?.id)
      let updates = Object.values(contributor?.data)?.map(({contributor})=>contributor?.employee?.user)
      if(updates?.length){
        record.contributors=[...filter_list,...updates]
        setDataSource(list)
      }

    }
  }


  const updateDiscardRow = (record) => {
    setDiscardPopupVisibleKR(false);
  }

  const update_progress=(okrid,progress)=>{
    let data_source_progress = [].concat(dataSource)
    let okr = data_source_progress.find(item=>item?.id===okrid)
    
    if(okr){
        okr.progress=progress
    }
    setDataSource(data_source_progress)
  }

  return (
    <>
      {
        deleteRecord &&
        <DeleteOkrDetails visible={deleteModal} type="KeyResult" deletesuccess={(e) => { updateDelete(e) }} closeModal={() => { setDeleteRecord(null) }} okr={deleteRecord?.id} {...props} />
      }

      <Col className="custom-table-v2" >

        {
          !okrDiscarded &&
          <Form
            form={form}
            name={"key_result"}
            autoComplete="off"
          >
            {createKRCounter?.map((item, index) => (

              <div className="row">
                <div className="milestone-table-expand-table-sider">
                  <div style={{ width: "10px", height: "10px" }}></div>
                  KR
                </div>
                <div
                  className="custom-table-tr table-body-tr-border"
                  style={{ borderLeft: "5px solid #B4E045" }}
                >
                  <div
                    className="table-section-td"
                    style={{ width: "275px", minWidth: "275px" }}
                  >
                    <div style={{ width: "10px", height: "10px" }}></div>
                    <TextInputField
                      name={`${item?.key}_goalStatement`}
                      show_pop_over = {show_pop_over}
                      setPopOver = {setPopOver}
                      placeholder="Measured by..."
                      rules={[
                        { required: true, message: "title required" },
                      ]}
                    />
                  </div>

                  <div
                    className="table-section-td center"
                    style={{ width: "280px", minWidth: "280px" }}
                  >
                    <div>
                        <div className="custom-okr-picker">
                          <CustomOkrRangeTable name={`${item?.id}_duedate`} 
                            disabledRange={{
                              duedate: okrById?.duedate,
                              startdate: moment(okrById?.startdate).isAfter(moment()) ? okrById?.startdate : moment()?._d
                            }}
                            selectedDateRange={(e)=>form.setFieldsValue({
                              [`${item?.key}_duedate`]: e,
                            })} 
                            currentSelected={false}
                            disabledType={true}
                            rules={[{ required: true, message: 'dates required' }]}
                          />
                        </div>
                        <div className="okr-picker">
                        <RangeDatePicker
                          name={`${item?.key}_duedate`}
                          dateFormat="DD/MM/YY"
                          rules={[{ required: true, message: 'dates required' }]}
                          disabledRange={{
                            duedate: okrById?.duedate,
                            startdate: moment(okrById?.startdate).isAfter(moment()) ? okrById?.startdate : moment()?._d
                          }}
                        />
                        </div>
                      
                      </div>
                    
                  </div>

                  <div
                    className="table-section-td center"
                    style={{ width: "200px", minWidth: "200px" }}
                  >
                    <div className="row text-transform-capitalize" style={{ color: "#D9D9D9" }}>
                      {me?.firstName}
                      <img src={collabrator_disable} alt="" />
                    </div>
                  </div>

                  <div
                    className="table-section-td"
                    style={{ width: "350px", minWidth: "350px" }}
                  >
                    <img src={gear_disabled} alt="" style={{ cursor: "pointer" }} />
                    <div style={{ width: "100%" }} align="center">
                      <Button className="table-save-btn" disabled={show_pop_over} onClick={() => onFinish(item?.key)}>Save</Button>
                    </div>
                    <img
                      src={close}
                      alt=""
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        removecreateKR(item);
                      }}
                    />
                  </div>
                </div>
              </div>

            ))}
          </Form>

        }


        {

          <Form
            form={updateForm}
            name="Objective_update"
            autoComplete="off"
          >
            {dataSource?.map(item => (
              <Col >
                <div className="custom-table-tr table-body-tr-border" style={{
                  borderLeft: (item?.isDiscarded?.status === 'APPROVED') ? "5px solid #141414" :
                    (item?.isDiscarded?.status === 'PENDING') ? "5px solid #009AF1" : "5px solid #4CCB1F",
                  position: (item?.isDiscarded?.status === 'APPROVED' || item?.isDiscarded?.status === 'PENDING') ? "relative" : ""
                }}>
                  {
                    (item?.isDiscarded?.status === 'APPROVED') ?
                      <div className="table-overlay">
                        <img src={item?.isDiscarded?.status === 'APPROVED' ? black_eye : blue_eye} className="table-eye" alt="" onClick={() => { navigateRoute( okr_route.keyResultDetail.replace(":okrId",getIntFromString(item?.id))) }} style={{ marginLeft: "370px", cursor: "pointer" }} />
                      </div> : ''
                  }
                  <div className="table-section-td" style={{ width: "350px", minWidth: "350px" }}>
                    {/* <img src={objective_expansion_arrow} alt="" style={{ zIndex: "1" }} onClick={(e) => { expand(item?.id) }} className={`expansion-arrow ${expandedRow?.includes(item?.id) ? 'expansion-arrow-down' : ''}`} /> */}
                    <img src={ item?.isDiscarded?.status==='PENDING'?
                                                blue_expansion:
                                                item?.isDiscarded?.status==='APPROVED'?
                                                grey_expansion:
                                                objective_expansion_arrow} alt="" style={{zIndex:"1"}} onClick={(e)=>{expand(item?.id)}} className={`expansion-arrow ${expandedRow?.includes(item?.id)?'expansion-arrow-down':''}`} />
                    {
                      (item?.isUpdate && !okrDiscarded) ?
                        <TextInputField
                          name={`${item?.id}_goalStatement`}
                          show_pop_over = {show_pop_over}
                          setPopOver = {setPopOver}
                          placeholder="I want that to achieve ..."
                          defaultValue={item?.goalStatement}
                        /> :
                        <Tooltip title={item?.title}>
                          <span style={{cursor:'pointer'}} onClick={()=>{onTableMenuClickAction(item,'view')}}>{item?.title}</span>
                        </Tooltip>
                    }



                  </div>

                  <div className="table-section-td center" style={{ width: "280px", minWidth: "280px" }}>

                    {
                      (item?.isUpdate && !okrDiscarded) ?
                        <div>
                          <div className="custom-okr-picker">
                            <CustomOkrRangeTable name={`${item?.id}_duedate`} 
                              defaultValue={[moment(item?.startdate), moment(item?.duedate)]}
                              selectedDateRange={(e)=>updateForm.setFieldsValue({
                                [`${item?.id}_duedate`]: e,
                              })} 
                              currentSelected={false}
                              disabledType={true}
                            />
                          </div>
                          <div className="okr-picker">
                            <RangeDatePicker
                              name={`${item?.id}_duedate`}
                              dateFormat="DD/MM/YY"
                              defaultValue={[moment(item?.startdate), moment(item?.duedate)]}
                            /> 
                          </div>
                        </div>
                        :
                        displayUtcToUserTimezone(item?.duedate, 'DD MMM YYYY')
                    }


                  </div>

                  <div
                    className="table-section-td center"
                    style={{ width: "300px", minWidth: "300px" }}
                  >

<CommaSeparateComponent showCount={1} textList={item?.contributors?.map(user => { return { id: user?.id, firstName: Capitalize(user?.firstName),lastName:Capitalize(user?.lastName),role:user?.role } })} />
                    <ExpandableDropdownContainer okrId={item?.id} response={(e) => { addContributor(e,item) }} record={item} {...props} />
                  </div>


                  <div className="table-section-td" style={{ width: "300px", minWidth: "300px" }}>

                    {item?.childSet &&(<AddWeightContainer {...props} okrId={item?.id} />)}
                    {!item?.childSet &&(<ProgressUpdateContainer editable={ moment(item?.duedate)?.endOf('day').isAfter(moment())} update_progress={(okrid,progress)=>update_progress(okrid,progress)} okr={item} {...props} />)}
                    
                    {
                      (item?.isUpdate && !okrDiscarded) ?
                        <div style={{ width: "100%" }} align="center"><Button className="table-save-btn" onClick={(e) => { onUpdate(item) }}>Update</Button></div>
                        :
                        <CustomProgress showTooltip={true} progress={item?.progress} />
                    }

                    {
                      (item?.isUpdate && !okrDiscarded) ?
                        <img src={close} alt="" onClick={() => { updateQue(item, false) }} style={{ cursor: "pointer", marginRight: "10px" }} />
                        :
                        // <img src={dots} alt="" style={{cursor:"pointer"}} />
                        <TableRowMenu options={[{ label: 'view', key: 'view', icon: view, postLabelIcon: {}, disabled: false },
                        { label: 'Cascade', key: 'tree', icon: menu_cascade, postLabelIcon: {}, disabled: false },
                        { label: 'Edit', key: 'edit', icon: edit_pencil, postLabelIcon: 'icon', disabled: (moment(item?.correctionDeadline).isBefore(moment())|| moment(item?.duedate).isBefore(moment())) },
                        { label: 'Kudos', key: 'kodos', icon: kodos_award, postLabelIcon: 'icon', disabled: (permission?.can_award_badge && permission?.okr_permission && item?.contributors?.length >1)?false:true},
                        { label: 'Discard', key: 'discard', icon: black_discard, postLabelIcon: 'icon',post_icon:(item?.discardRequestRaised && item?.isDiscarded?.status!=='PENDING')?menu_del_info:'', tooltip:(item?.discardRequestRaised && item?.isDiscarded?.status!=='PENDING')?"You have already raised the discard request":'', disabled: (item?.discardRequestRaised || item?.isDiscarded?.status==='PENDING'?true:false) },
                        { label: 'Delete', key: 'delete', icon: delete_outline_black, postLabelIcon: 'icon',post_icon:menu_del_info, tooltip:"okr will be deleted permenetly", disabled: (item?.discardRequestRaised||moment(item?.correctionDeadline).isBefore(moment())|| moment(item?.duedate).isBefore(moment())) }]}
                          onClick={(e) => onTableMenuClickAction(item, e)}
                        />
                    }

                  </div>
                </div>

                <Row >
                  {
                    expandedRow?.includes(item?.id) && (
                      <div style={{ width: "100%" }}>
                        <ObjDetailMileStoneTableContainer displayConnectionLine={true} set_okr_tree={set_okr_tree} isDiscarded={item?.isDiscarded?.status} okrDiscarded={okrDiscarded} keyResultDetail={item} {...props} />
                      </div>
                      
                    )
                  }
                </Row>
              </Col>
            ))}
          </Form>
        }

      </Col>
      <RequestDiscardView onClose={()=>{setDiscardPopupVisibleKR(false)}} visible={discardPopupvisibleKR} {...props} discardData={{ id: okr }} discardedSuccess={(e) => { updateDiscardRow(e) }}  />
      {
          addAward&&
          <AwardBadgeContainer {...props} visible={addAward} onClose={(e)=>setAddAward(e)} type={'OKR'} selectedItem={selectedKudos} from_detail={true}/>
      }
    </>
  );
};

export default ObjectiveDetailTableView;