import React from 'react';

import { compose } from '../../../core';

import { withApollo } from '@apollo/client/react/hoc'
import { getEmployeeBase64StringFromInt, getIntFromString, getResponsesBase64StringFromInt } from 'modules/look/components/functions';
import { ORG_SURVEY_360_REPORTS, ORG_USER_DETAIL } from 'modules/poc-dashboard/graphql/pocDashboardQuery.gql';
import AssessmentReportView from 'modules/poc-dashboard/components/dashboard/AssessmentReportView';
import { SURVEY_WHOLE_IDENTITY } from 'config';

const Survey360ReportContainer = props => {
    
  const { client } = props
  const [SurveyReport,setSurveyReport]=React.useState()
  const [user_details,setUserDetails]=React.useState()
  const [response_loading,setResponseLoading]=React.useState(false)
  
  const getSurveyReport = async (filterData) => {
      const { data } = await client.query({
          query: ORG_SURVEY_360_REPORTS,
          variables: filterData,
          fetchPolicy: 'network-only'
      });

      
      if(data?.responseByResponseId){
          let detail = {
            ...data?.responseByResponseId
          } 
          if(getIntFromString(detail?.survey?.id) == SURVEY_WHOLE_IDENTITY){
            detail["direct_ui_render"] = true  
          }
          else if(typeof(detail?.report360) === 'string'){
            detail.report360 = JSON.parse(detail?.report360)
            detail["direct_ui_render"] = false
          }
          setSurveyReport(detail)
  
      }
      
  }
  const getUserDetail =async (user_id)=>{
    const { data } = await client.query({
      query: ORG_USER_DETAIL,
      variables:{ id:getEmployeeBase64StringFromInt(user_id)},
      fetchPolicy: 'network-only'
  });
  if(data?.employeeById){
    setUserDetails(data?.employeeById?.user)
  }
  } 

  React.useEffect(()=>{
    if(props?.match?.params?.responseid){
      setResponseLoading(true)
        let id = getResponsesBase64StringFromInt( props?.match?.params?.responseid )
        getSurveyReport({ id: id})
        getUserDetail(props?.match?.params?.user_id)
    }
  },[props?.match?.params])

  return (
      <>
      <AssessmentReportView user_details={user_details} SurveyReport={SurveyReport} permission={false} response_loading={response_loading}{...props}/>
      </>
  )
};

export default compose(withApollo)(Survey360ReportContainer);
