// @ts-nocheck
import { Row, Spin } from 'antd';
import React from 'react';
import '../css/scape-report-table.css';
import '../css/scape-report.css';
import { Capitalize } from 'modules/look';
import { SCAPE_REPORT_TABLE_ICON } from '../icons';

const ScapeReportView = (props) => {
    
    const { parametric_groups, table_data, data_loading } = props
    const [responses, setResponses] = React.useState([]);
    const [parametricGroupSet, setParametricGroupSet] = React.useState([]);
    const [parameterSet, setParameterSet] = React.useState([]);
    const [groupSet, setGroupSet] = React.useState([])

    const [groupsWithTotalCount, setGroupsWithTotalCount] = React.useState([]);
    const [parametersWithCount, setParameterWithCount] = React.useState([]);




    // Generating state variable for groups and parameters
    React.useEffect(() => {

        let groups = parametric_groups?.map(({ node }) => { return { ...node } });

        setParametricGroupSet(groups)

        let parameters = groups?.map((value) =>

            value?.parameterSet?.edges?.map(({ node }) => {
                const baseObject = {
                    ...node?.choice,
                    answer: node?.choice?.value,
                    sequence: 0,
                };
        
                if (node?.id !== '1') {
                    baseObject.show = node?.show;
                }
        
                return baseObject;
             })

        )?.flat();

        setGroupSet(parametric_groups)
        setParameterSet(parameters)
       
    }, [parametric_groups, table_data])





    // Generate responses out of data received

    const getResponses = (data, index) => {
        let answerSet;


        if (data) {
            let responseSet = data?.user?.responseSet?.edges?.map(({ node }) => node);

            answerSet = responseSet?.map((value) =>
                value?.answerSet?.edges?.map(({ node }) => { return { ...node, sequence: node?.sequence ? node?.sequence : "-" } }))?.flat();

        }

        let groups = parametric_groups?.map(({ node }) => node);

        setParametricGroupSet(groups)

        let parameters = groups?.map((value) =>

            value?.parameterSet?.edges?.map(({ node }) => {
                const baseObject = {
                    ...node?.choice,
                };
        
                if (value?.id !== '1') {
                    baseObject.show = node?.show;
                }
                return baseObject;
            })

        )?.flat();
        let responseData = [];
        responseData = parameters?.map((parameter) => {
            let info = answerSet?.find((value) => parameter?.value?.trim() === value?.answer?.trim())
            if (info) {
                return { ...info,show:parameter?.show }
            } else {
                return { ...parameter, answer: parameter?.value,show:parameter?.show }
            }
        }
        )?.flat();
        responseData.splice(0, 1)
        

        // specific row added for username with survey responses
        let group = { id: "1", answer: "Name", value: "Name", sequence: `${Capitalize(data?.user?.firstName)} ${Capitalize(data?.user?.lastName)}` }

        responseData.splice(0, 0, group);
        return responseData;
    }


    // calculating count of all responses
    const getCounts = (data, params) => {

        let counts;
        if (data) {

            counts = params?.map((parameter, index) =>

                data?.map((node) => {
                    if (node?.sequence && node?.answer?.trim() === parameter?.value?.trim()) {
                        params[index].sequence = params[index].sequence + 1;
                    }
                })
            )
        }

        params[0].sequence = 'Count'
        data = data.concat([{ responses: params }]);

        let tempGroupSet = [...parametricGroupSet]


        if (tempGroupSet?.length) {

            let data;

            let customGroup = {
                node: {
                    id: "1", name: "Name", parameterSet: {
                        edges:
                            [{ node: { id: "1", show: "ORDER", choice: { id: "1", value: "Name" } } }]
                    }
                }


            }


            setGroupSet((previousState) => {

                const newGroupSet = previousState?.map((group) => {

                    return {
                        ...group, node: {
                            ...group?.node,
                            parameterSet: {
                                edges:
                                    group?.node?.parameterSet?.edges?.map(({ node }) => { 
                                            return { node }
                                    })    
                            }
                        }
                    }

                }
                )

                data = [...newGroupSet]
                data.splice(0, 1)
                data.splice(0, 0, customGroup);
                return data;
            }

            )

        }


        setParameterWithCount(params);

    }




    // calculating total count of all responses
    const getGroupTotal = (data) => {

        let groups = parametricGroupSet?.map((group) => { return { ...group, total: 0 } });

        if (data) {

            let groupsWithTotal = groups?.forEach((group, index) =>

                group?.parameterSet?.edges?.forEach((node, i) =>

                    data?.forEach((response) => {

                        if (node?.node?.choice?.value?.trim() === response?.answer?.trim()) {
                            // groups[index].total = groups[index].total + (response?.sequence)
                            groups[index].total += 1;
                        }
                    }
                    )
                )
            )?.flat();
        }

        groups[0].total = 'Total'
        console.log("groupsWithTotal",groups)
        setGroupsWithTotalCount(groups);

    }



    React.useEffect(() => {

        if (parametricGroupSet?.length && parameterSet?.length) {
            let data = [...responses];

            let params = [...parameterSet];
            let allResponses = table_data?.map((row) => row?.user?.responseSet?.edges?.map(({ node }) => node?.answerSet?.edges?.map(({ node }) => node)))?.flat()?.flat();

            getCounts(allResponses, params)

            getGroupTotal(allResponses)

        }

    }, [parametricGroupSet, parameterSet])



    React.useEffect(() => {

        if (table_data) {
            let employeeesWithResponses = [];

            let data = table_data?.forEach((user, index) => {
                employeeesWithResponses.push({ user: user?.user, responses: getResponses(user, index) })
            }
            )?.flat();
            setResponses(employeeesWithResponses)
        }

    }, [table_data])



    return (
        <>

            {
                (!data_loading && groupsWithTotalCount?.length && parametersWithCount?.length && groupSet?.length) ?

                    <div style={{width:parametersWithCount?.length > 9 ? "100%" : "90%",margin:'auto'}}>

                        {
                            groupSet?.length > 1 ?
                                <Row wrap={false} style={{ gap: '0.5em' }} justify={parametersWithCount?.length > 9 ? "center" : "start"}>

                                    {/* non  Scrollable column */}
                                    <div className='scape-report-table-container'>

                                        {
                                            <Row className='scape-report-data-container' wrap={false}>

                                                {
                                                    // Paramteres with groups in header
                                                    groupSet?.length && groupSet?.map((group, i) =>
                                                        i === 0 &&
                                                        <div className='scape-report-group'>
                                                            <div className={`scape-report-group${i} scape-report-group-row scape-report-table-radius scape-report-table-padding table-border-left-none`} align='center'>{group?.node?.name}</div>
                                                            <Row className={`scape-report-parameter-row${i}`} wrap={false} >
                                                                {

                                                                    group?.node?.parameterSet?.edges?.map(({ node }, index) =>
                                                                        <div
                                                                            style={{ width: i === 0 ? '11em' : '9em' }}
                                                                            className={`scape-report-parameter${i}${index} scape-report-parameter scape-report-table-radius table-border-top-none
                  table-border-left-none  table-cell-scape-report scape-report-table-padding  scape-label-font`} align='center'>{node?.choice?.value}</div>
                                                                    )
                                                                }
                                                            </Row>

                                                            <Row className={`response-content response-content-${i}`} wrap={false}>

                                                                {/* <p>node :{JSON.stringify(node)}</p> */}
                                                                {

                                                                    i === 0 &&
                                                                    group?.node?.parameterSet?.edges?.map(({ node }, parameterIndex) =>
                                                                        <div>

                                                                            <div className={`response-survey-${i}`}>
                                                                                {
                                                                                    responses?.map((value, responseIndex) => {


                                                                                        if (value?.responses?.length) {
                                                                                            return value?.responses?.map((response, index) => {

                                                                                                if (response?.answer?.trim() === node?.choice?.value?.trim()) {
                                                                                                    return <div style={{
                                                                                                        width: index === 0 ? '11em' : '9em', fontWeight: '500', textOverflow: 'ellipsis'
                                                                                                        , whiteSpace: 'nowrap', overflow: 'hidden',
                                                                                                    }}
                                                                                                        className='table-response-row scape-report-table-radius
                                          table-cell-scape-report scape-report-table-padding
                                          scape-label-font table-border-top-none table-border-left-none' align='center'>{response?.sequence ? response?.sequence : "-"}</div>
                                                                                                }
                                                                                            }

                                                                                            )
                                                                                        }
                                                                                    }

                                                                                    )
                                                                                }
                                                                            </div>


                                                                            <div>

                                                                            </div>

                                                                            {/* Parameter with counts */}
                                                                            <div>
                                                                                {

                                                                                    parametersWithCount?.length && parametersWithCount?.map((value, countIndex) =>

                                                                                        <div className="table-count">
                                                                                            {countIndex === 0 &&
                                                                                                value?.value?.trim() === node?.choice?.value?.trim() ?
                                                                                                <div style={{ width: countIndex === 0 ? '11em' : '9em' }} className={`table-cell-scape-report
                                          scape-report-table-padding scape-label-font scape-table-count table-border-left-none parameter-count-cell parameter-counting-${countIndex} `} align='center'>{value?.sequence}</div>
                                                                                                :
                                                                                                null
                                                                                            }

                                                                                        </div>

                                                                                    )

                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                            </Row>


                                                            {/* Groups with total counts */}
                                                            <Row justify='center'
                                                                className="scape-report-table-padding scape-label-font scape-table-total"
                                                                align='middle'>
                                                                {

                                                                    groupsWithTotalCount?.length && groupsWithTotalCount?.map((value, totalIndex) =>
                                                                        totalIndex === 0 &&
                                                                        <div>
                                                                            {
                                                                                value?.id === group?.node?.id ?
                                                                                    <div align='center'>
                                                                                        {value?.total}</div>
                                                                                    :
                                                                                    null
                                                                            }

                                                                        </div>

                                                                    )

                                                                }

                                                            </Row>

                                                        </div>
                                                    )
                                                }


                                            </Row>
                                        }

                                    </div>





                                    {/* scrollable column */}
                                    <div className='scape-report-table-container scrollable-column'>

                                        {
                                            <Row wrap={false}>

                                                {
                                                    // Paramteres with groups in header for first scrollable column
                                                    groupSet?.length && groupSet?.map((group, i) =>
                                                        i !== 0 &&
                                                        <div className='scape-report-group'>
                                                            <div className={`scape-report-group${i} scape-report-group-row scape-report-table-radius scape-report-table-padding table-border-left-none`} align='center'>{group?.node?.name}</div>
                                                            <Row className={`scape-report-parameter-row${i}`} wrap={false} >
                                                                {

                                                                    group?.node?.parameterSet?.edges?.map(({ node }, index) =>
                                                                        <div
                                                                            style={{ width: i === 0 ? '11em' : '9em' }}
                                                                            className={`scape-report-parameter${i}${index} scape-report-parameter scape-report-table-radius table-border-top-none
                  table-border-left-none  table-cell-scape-report scape-report-table-padding  scape-label-font`} align='center'>{node?.choice?.value}</div>
                                                                    )
                                                                }
                                                            </Row>

                                                            <Row className={`response-content response-content-${i}`} wrap={false}>

                                                                {/* <p>node :{JSON.stringify(node)}</p> */}
                                                                {

                                                                    i !== 0 &&
                                                                    group?.node?.parameterSet?.edges?.map(({ node }, parameterIndex) =>
                                                                        <div>

                                                                            <div className={`response-survey-${i}`}>
                                                                                {
                                                                                    responses?.map((value, responseIndex) => {


                                                                                        if (value?.responses?.length) {
                                                                                            return value?.responses?.map((response, index) => {

                                                                                                if (response?.answer?.trim() === node?.choice?.value?.trim()) {
                                                                                                    return <div style={{
                                                                                                        width: index === 0 ? '11em' : '9em', fontWeight: '500', textOverflow: 'ellipsis'
                                                                                                        , whiteSpace: 'nowrap', overflow: 'hidden',
                                                                                                    }}
                                                                                                        className='table-response-row scape-report-table-radius
                                          table-cell-scape-report scape-report-table-padding
                                          scape-label-font table-border-top-none table-border-left-none' align='center'>{response?.sequence ?(response?.show === "ORDER" ? response?.sequence : <img style={{ width: '1.25em' }} src={SCAPE_REPORT_TABLE_ICON.green_tick} />)  : "-"}</div>
                                                                                                }
                                                                                            }

                                                                                            )
                                                                                        }
                                                                                    }

                                                                                    )
                                                                                }
                                                                            </div>


                                                                            <div>

                                                                            </div>

                                                                            {/* Parameter with counts */}
                                                                            <div>
                                                                                {

                                                                                    parametersWithCount?.length && parametersWithCount?.map((value, countIndex) =>
                                                                                        countIndex !== 0 &&
                                                                                        <div className="table-count">
                                                                                            {
                                                                                                value?.value?.trim() === node?.choice?.value?.trim() ?
                                                                                                    <div style={{ width: countIndex === 0 ? '11em' : '9em' }} className={`table-cell-scape-report
                                          scape-report-table-padding scape-label-font scape-table-count table-border-left-none parameter-count-cell parameter-counting-${countIndex} `} align='center'>{value?.sequence}</div>
                                                                                                    :
                                                                                                    null
                                                                                            }

                                                                                        </div>

                                                                                    )

                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                            </Row>


                                                            {/* Groups with total counts */}
                                                            <Row justify='center'
                                                                className="scape-report-table-padding scape-label-font scape-table-total"
                                                                align='middle'>
                                                                {

                                                                    groupsWithTotalCount?.length && groupsWithTotalCount?.map((value, totalIndex) =>
                                                                        totalIndex !== 0 &&
                                                                        <div>
                                                                            {
                                                                                value?.id === group?.node?.id ?
                                                                                    <div align='center'>
                                                                                        {value?.total}</div>
                                                                                    :
                                                                                    null
                                                                            }

                                                                        </div>

                                                                    )

                                                                }

                                                            </Row>

                                                        </div>
                                                    )
                                                }


                                            </Row>
                                        }

                                    </div>

                                </Row>

                                :
                                <div style={{textAlign: "center",
                                    fontWeight: "500",
                                    marginTop: "6em",
                                    fontSize: "1.3em"}}>No data found</div>
                        }
                    </div>



                    :
                    <Row justify='center' style={{ marginTop: '4em' }} align='middle'>
                        <Spin spinning={true} ></Spin>
                    </Row>

            }



        </>

    )
}
export default ScapeReportView