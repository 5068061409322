import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const CompetencyLineChart = (props) => {
    const { graph_data } = props
    console.log('graph_data_disable_data', graph_data);
    const months = {
        0: 'Jan',
        1: 'Feb',
        2: 'Mar',
        3: 'Apr',
        4: 'May',
        5: 'Jun',
        6: 'Jul',
        7: 'Aug',
        8: 'Sep',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec'
    }
    const [chartData,setchartData] = React.useState({
        labels: Object.values(months),
        datasets: graph_data?.graph_data_set
    })
  
    const [disable_data,SetDisableData] = React.useState([])

    React.useEffect(()=>{
        let list = graph_data?.graph_data_set?.filter(i=>!disable_data?.includes(i?.legend?.id))
        setchartData({
            labels: Object.values(months),
            datasets: list
        })
    },[disable_data,graph_data,months])

    const options = {
        responsive: true,
        scales: {
            y: {
                min: 0,
                max: graph_data?.labels?.length - 1,
                ticks: {
                    stepSize: 1,
                    color: '#000',
                    font: {
                        size: 15,
                        weight: 600
                    },
                    callback: function (value, index, ticks) {
                        console.log('value', value);
                        let label = graph_data?.labels?.find(i => i.value == value)

                        return label?.name?.length>10?label?.name?.slice(0,10)+'...':label?.name || '';
                    }
                },
                grid: {
                    display: true,
                    color: "#8C8CA1",
                    borderDash: [8, 8],
                }
            },
            x: {
                grid: {
                    display: true,
                    color: "#8C8CA1",
                    borderDash: [8, 8],
                },
                ticks: {
                    color: '#000',
                    font: {
                        size: 15,
                        weight: 600
                    }
                }
            }
        },
        indexAxis: 'x',
        elements: {
            bar: {
                borderWidth: 0,
                borderHeight: 0
            },
        },
        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            tooltip: {
                backgroundColor: '#0E0E2C',
                callbacks: {
                    label: function (context) {
                        let doc = context?.dataset?.data_set[context?.dataIndex]?.most_repeated?.behaviourExample
                        if (doc?.length > 100) {
                            doc = doc?.slice(0, 100) + '...'
                        }
                        return doc || ''
                        // graph_data?.graph_data_set[context?.dataIndex]?.behaviourExample||'';
                    }
                }
            },
            title: {
                display: false,
            }
        },
    };

    const getColor = (originalColor) => {
        const opacity = 0.1; // 10% opacity
        // Parse the original color to get its RGB values
        const red = parseInt(originalColor.slice(1, 3), 16);
        const green = parseInt(originalColor.slice(3, 5), 16);
        const blue = parseInt(originalColor.slice(5, 7), 16);

        // Create the RGBA color string with the specified opacity
        return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
    }

    const getShape = (data) => {
        if (String(data?.pointStyle) === 'circle') {
            return <div className="shape circle" style={{ background: data?.backgroundColor }}></div>
        }
        else if (String(data?.pointStyle) === 'rect') {
            return <div className="shape rectangle" style={{ background: data?.backgroundColor }}></div>
        }
        else if (String(data?.pointStyle) === 'triangle') {
            return <div className="shape triangle" style={{ borderBottom: `20px solid ${data?.backgroundColor} `}}></div>
        }
    }

    const changeDisabled=(id)=>{
        let list = disable_data.concat([])
        if(list?.includes(id)){
            list = list?.filter(i=>i!==id)
        }else{
            list.push(id)
        }
        SetDisableData(list)
    }

    return (
        <>
            <div  style={{padding:"1.87em 2.19em"}}>
            <Line   options={options} data={chartData} />
            </div>
            
            <div className='line-chart-legend'>

                {
                    graph_data?.graph_data_set?.map(i => (
                        <div onClick={()=>changeDisabled(i?.legend?.id)} style={{ background: !disable_data?.includes(i?.legend?.id)?getColor(i?.backgroundColor):'#fff', border: `1px solid ${!disable_data?.includes(i?.legend?.id)?i?.backgroundColor:'#fff'}` }} className='legend-container'>
                            {getShape(i)} <span className='text-transform-capitalize'>{i?.legend?.label}</span>
                        </div>
                    ))
                }
            </div>
        </>
    )

}
export default CompetencyLineChart