// @ts-nocheck
import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Form, Input, Menu, Row, Spin, Switch, Tooltip } from "antd";
import GivenKudos from "modules/feedback/components/GivenKudos";
import FeedbackQuestion from "modules/look-v2/Components/FeedbackQuestion";
import CKEditorFieldOneOnOne from "modules/look/components/form-components/CKEditor-one-on-one";
import React, { forwardRef } from "react";
import styled from "styled-components";
import green_badge from '../../assets/green_badge.svg'
import info_button from '../../assets/info-button.svg';
import { getIntFromString, getSelectedFeedbackType, MetaTags, placeholderTextForCkeditor, ToolTipTitle, withMarkdownFieldValue } from "../../look";


const OneOnOneFeedbackPostView = forwardRef((props, ref) => {
  const { allOrganizationEmployees, SurveyById, me,
    okrByrecipiant, onCancel, fillInOption, setfillInOption,
    feedBackType, setFeedBackType,
    question1Field1, setquestion1Field1,
    question2Field1, setquestion2Field1,
    question2Field2, setquestion2Field2,
    question2Field3, setquestion2Field3,
    question1Field2, setquestion1Field2,
    question3Field1, setquestion3Field1,
    question3Field2, setquestion3Field2,
    question4Field1, setquestion4Field1,
    question5Field2, setquestion5Field2,
    question5Field1, setquestion5Field1,
    feedBackTypeEnum,
    questionList, setquestionList, answersList, setAnswers,
    tabArray, setTabArray, handleFeedbackPreview, permission, givenKudos, setGivenKudos,
    setAddFeedback, setGiveKudosPopUpVisible, resetFeedbackForm, selectedTabValue, setSelectedTabValue
  } = props

  
  const [loading, setloading] = React.useState(false);

  const [okrList, setokrList] = React.useState([])
  const [employeeList, setemployeeList] = React.useState([]);
  const [invalidFeedbackType, setInvalidFeedbackType] = React.useState(false);


  React.useEffect(()=>{
    if(feedBackType){
        HandleTypeChange(feedBackType)
    }
    else{
        setFeedBackType("1")
        HandleTypeChange("1")
    }
},[feedBackType])

  React.useEffect(() => {
    if (fillInOption && question1Field1 !== undefined && question1Field2 !== undefined) {
      setTabArray(tabArray?.map(obj =>
        obj.value == 'C' ? { ...obj, active: false } : obj
      ))
    }
    if (fillInOption && question2Field1 !== undefined && question2Field2 !== undefined && question2Field3 !== undefined) {
      setTabArray(tabArray?.map(obj =>
        obj.value == 'L' ? { ...obj, active: false } : obj
      ))
    }
    if (fillInOption && question3Field1 !== undefined && question3Field2 !== undefined) {
      setTabArray(tabArray?.map(obj =>
        obj.value == 'A' ? { ...obj, active: false } : obj
      ))
    }
    if (fillInOption && question4Field1 !== undefined) {
      setTabArray(tabArray?.map(obj =>
        obj.value == 'P' ? { ...obj, active: false } : obj
      ))
    }
    if (fillInOption && question4Field1 !== undefined) {
      setTabArray(tabArray?.map(obj =>
        obj.value == 'P' ? { ...obj, active: false } : obj
      ))
    }
  }, [question1Field1, question1Field2, question2Field1, question2Field2, question2Field3, question3Field1, question3Field2, question4Field1])






  React.useEffect(() => {

    if (allOrganizationEmployees && me) {
      let filtered = allOrganizationEmployees?.edges.filter(item => item?.node?.user?.employee?.id !== me?.employee?.id)
      setemployeeList(filtered)
    }

  }, [allOrganizationEmployees, me])

  React.useEffect(() => {
    if (okrByrecipiant) {
      setokrList(okrByrecipiant?.edges?.map(({ node }) => { return { label: node?.okr?.title, id: node?.okr?.id } }))
    }
  }, [okrByrecipiant])

  React.useEffect(() => {
    if (SurveyById) {
      let list = SurveyById?.groupSet?.edges[0]?.node?.questionSet?.edges?.map(({ node }) => node)
      setquestionList(list)
      setloading(false)
    }
  }, [SurveyById])



  React.useEffect(() => {

    if (selectedTabValue && fillInOption) {
      let node = document.getElementById("feedbackContainer")
      if (node) {
        node.scrollIntoView({ block: 'start', inline: 'start', behavior: "smooth" })
      }
    }

  }, [selectedTabValue, fillInOption])


  const FeedBackTypemenu = () => (
    <Menu >
      <Menu.Item key="1" onClick={() => HandleTypeChange("1")}>
        Motivational/Recognition
      </Menu.Item>
      <Menu.Item key="2" onClick={() => HandleTypeChange("2")}>
        Developmental/Constructive
      </Menu.Item>
    </Menu>
  );

  const HandleTypeChange = (type) => {

    if (feedBackType) {
      resetFeedbackForm()
    }

    if (type === "1") {
      setFeedBackType("1")
      if (!question5Field1) {
        setquestion5Field1("You should try and do this more often and if possible, even try and help the team adopt this behavior.")
      }
    }
    else if (type === "2") {

      setFeedBackType("2")
      if (!question5Field2) {
        setquestion5Field2("If it’s helpful, I can continue to observe and provide feedback if I see you try something differently. I am happy to catch up again in some time, so you can bounce off any challenges you may be facing. Let me know.")
      }
    }
    setInvalidFeedbackType(false)
  }

  const submitAnswer = (data, value) => {

    let title = data?.questionText?.replace(/<[^>]*>/g, '')


    // if(feedBackType){
    if (title?.startsWith('C') && value) {
      setTabArray(tabArray?.map(obj =>
        obj.value == 'C' ? { ...obj, active: false } : obj
      ))
    }
    if (title?.startsWith('L') && value) {
      setTabArray(tabArray?.map(obj =>
        obj.value == 'L' ? { ...obj, active: false } : obj
      ))
    }
    if (title?.startsWith('A') && value) {
      setTabArray(tabArray?.map(obj =>
        obj.value == 'A' ? { ...obj, active: false } : obj
      ))
    }
    if (title?.startsWith('S') && value) {
      setTabArray(tabArray?.map(obj =>
        obj.value == 'S' ? { ...obj, active: false } : obj
      ))
    }
    if (feedBackType == '2' && title?.startsWith('P') && value) {
      setTabArray(tabArray?.map(obj =>
        obj.value == 'P' ? { ...obj, active: false } : obj
      ))
    }
    let feed = withMarkdownFieldValue("answer", { answer: value })
    let answerSet = {
      questionId: getIntFromString(data?.id),
      ...feed
    }

    let tempAnswer = [].concat(answersList)
    let previous = tempAnswer.find(item => getIntFromString(item?.questionId) === getIntFromString(data?.id))
    if (previous) {
      let index = tempAnswer.indexOf(previous)
      tempAnswer.splice(index, 1)
      tempAnswer.push(answerSet)
    }
    else {
      tempAnswer.push(answerSet)
    }

    setAnswers(tempAnswer)
    if (!feedBackType) {
      setInvalidFeedbackType(true)
    }

  }








  const checkDisblaeButton = (value, index) => {


    if (feedBackType == '1') {
      if (value === "C") {
        return false
      }
      if (value !== "C" && tabArray[selectedTabValue]?.value === value) {
        return false
      }
      if (value !== "C" && tabArray[selectedTabValue]?.value !== value && !tabArray[index]?.active) {
        return false
      }
      if (value !== "C" && feedBackType == '2' && tabArray[selectedTabValue]?.value !== value) {
        return false
      }

      if (value !== "C" && feedBackType == '2' && tabArray[selectedTabValue]?.value !== value && tabArray[selectedTabValue]?.active) {
        return true
      }

      else {
        return true
      }

    } else if (!feedBackType) {
      return true
    } else if (feedBackType == '2') {

      if (value !== "C" && tabArray[selectedTabValue]?.value === value) {
        return false
      }
      if (value !== "C" && tabArray[selectedTabValue]?.value === value && tabArray[index]?.active) {
        return false
      }
      if (value !== "C" && tabArray[selectedTabValue]?.value !== value && tabArray[index]?.active) {
        return true
      }
    }

  }




  const handleNextSubmit = (e, nextTab) => {

    setSelectedTabValue((feedBackType == '1' && selectedTabValue == 2) ? selectedTabValue + 2 : selectedTabValue + 1)

  }


  const handleDetailedSwitch = () =>{

    setAnswers([]);
    setSelectedTabValue(0);
    setTabArray([
      { id: 0, value: 'C', active: true },
      { id: 1, value: 'L', active: true },
      { id: 2, value: 'A', active: true },
      { id: feedBackType == '1' ? null : 3, value: 'P', active: true },
      { id: feedBackType == '1' ? 4 : 4, value: 'S', active: true }])

  }




  const showQuestion = (data) => {


    if (!data?.questionAddress) {
      return (
        <Col span={24}>
          <Row style={{ marginTop: '15px', width: '100%' }}>
            <FeedbackQuestion data={data} />


          </Row>
          <div className="one-on-one-ck-editor" style={{ paddingRight: '20px', border: " 1px solid #D9D9D9", borderRadius: "8px", width: '100%' }} >
          
            <p></p>
            <CKEditorFieldOneOnOne
              key={data?.id}
              editorPlaceholder={placeholderTextForCkeditor(data)}
              initialValue={answersList?.length ? answersList?.find(item => item?.questionId === getIntFromString(data?.id))?.answer : ''}
              changeHandler={(e) => { submitAnswer(data, e) }}
            />
          </div>
         
        </Col>
      )
    }
    else {
      let validations = data?.questionAddress?.split(':')
      if (validations?.includes(feedBackType)) {
        return (
          <Col span={24}>
            <Row style={{ marginTop: '15px' }}>
              <FeedbackQuestion data={data} />
            </Row>
            <div className="one-on-one-ck-editor with-ques-addr" style={{ paddingRight: '20px', paddingBottom: '15px', border: " 1px solid #D9D9D9", borderRadius: "8px" }}>
              <p></p>
              <CKEditorFieldOneOnOne
                editorPlaceholder={placeholderTextForCkeditor(data)}
                initialValue={answersList?.length ? answersList?.find(item => item?.questionId === getIntFromString(data?.id))?.answer : ''}
                key={data?.id}
                changeHandler={(e) => { submitAnswer(data, e) }}
              />
            </div>

          </Col>
        )
      }
    }

  }



  const WithNoOption = (
    <Col span={24}>
      {
        questionList?.length && (
          showQuestion(questionList[selectedTabValue])
        )
      }

    </Col>
  )

  const WithfillOption = (
    <Col span={24}>
      {selectedTabValue == 0 && <div style={{ marginTop: '15px' }}>
        <Row wrap={false}>
          <FeedbackQuestion data={{ required: true, questionText: "Create Safety" }} />
        </Row>

        <Col span={24} className="meeting-feedback-detailed-box">
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
            <p className="one-on-one-form-title" style={{ marginTop: "10px" }}>I want to commend you for</p>
            <Tooltip overlayStyle={{zIndex:1160}} title={'doing/saying something of value'} >
              <Input
                style={{
                  border: '1px solid #D9D9D9',
                  width: '100%',
                  height: '40px',
                  borderRadius: "8px",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: '#0E0E2C',
                  fontFamily: 'Poppins'
                }}
                autoComplete='off'
                value={question1Field1 ? question1Field1 : ""}
                onChange={e => { (setquestion1Field1(e.target.value)) }}
              />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
            <p className="one-on-one-form-title" style={{ marginTop: "10px" }}>in / during</p>
            <Tooltip overlayStyle={{zIndex:1160}} title={'a specific meeting, presentation, project'} >
              <Input
                style={{
                  border: '1px solid #D9D9D9',
                  width: '100%',
                  height: '40px',
                  borderRadius: "8px",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: '#0E0E2C',
                  fontFamily: 'Poppins'
                }}
                autoComplete='off'
                value={question1Field2 ? question1Field2 : ""}
                onChange={e => (setquestion1Field2(e.target.value))}
              />
            </Tooltip>
            <p className="one-on-one-form-title" style={{ marginTop: "10px" }}>. Is this a good time?</p>
          </div>
        </Col>
      </div>}
      {/* question 2 */}
      {selectedTabValue == 1 && <div style={{ marginTop: '15px' }}>
        <Row wrap={false}>
          <FeedbackQuestion data={{ required: true, questionText: "Lay Out Context" }} />
        </Row>

        <Col span={24} className="meeting-feedback-detailed-box">
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
            <p className="one-on-one-form-title" style={{ marginTop: "10px" }}>I am referring to the</p>
            <Tooltip overlayStyle={{zIndex:1160}} title={'event referred to above with more details if needed to make it clearly identifiable'} >
              <Input
                style={{
                  border: '1px solid #D9D9D9',
                  width: '100%',
                  height: '40px',
                  borderRadius: "8px",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: '#0E0E2C',
                  fontFamily: 'Poppins'
                }}
                autoComplete='off'
                value={question2Field1 ? question2Field1 : ""}
                onChange={e => (setquestion2Field1(e.target.value))}
              />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
            <p className="one-on-one-form-title" style={{ marginTop: "10px" }}>with</p>
            <Tooltip overlayStyle={{zIndex:1160}} title={'names of people that participated in the event'} >
              <Input
                style={{
                  border: '1px solid #D9D9D9',
                  width: '100%',
                  height: '40px',
                  borderRadius: "8px",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: '#0E0E2C',
                  fontFamily: 'Poppins'
                }}
                autoComplete='off'
                value={question2Field2 ? question2Field2 : ""}
                onChange={e => (setquestion2Field2(e.target.value))}
              />
            </Tooltip>

          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0px', width: "100%" }}>
            <p className="one-on-one-form-title" style={{ marginTop: "10px" }}>on</p>
            <Tooltip overlayStyle={{zIndex:1160}} title={'Optional date and time'} >
              <Input
                style={{
                  border: '1px solid #D9D9D9',
                  width: '100%',
                  height: '40px',
                  borderRadius: "8px",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: '#0E0E2C',
                  fontFamily: 'Poppins'
                }}
                autoComplete='off'
                value={question2Field3 ? question2Field3 : ""}
                onChange={e => (setquestion2Field3(e.target.value))}
              />
            </Tooltip>
          </div>
        </Col>
      </div>}
      {/* question 3 */}
      {selectedTabValue == 2 && <div style={{ marginTop: '15px' }}>
        <Row wrap={false}>
          <FeedbackQuestion data={{ required: true, questionText: "Articulate behavioral evidence and then, impact" }} />
        </Row>
        <Col span={24} className="meeting-feedback-detailed-box">
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
            <p className="one-on-one-form-title" style={{ marginTop: "10px" }}>You</p>
            <Tooltip overlayStyle={{zIndex:1160}} title={'write verbatim what the person said or did, not your interpretation of it'} >
              <Input
                style={{
                  border: '1px solid #D9D9D9',
                  width: '100%',
                  height: '40px',
                  borderRadius: "8px",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: '#0E0E2C',
                  fontFamily: 'Poppins'
                }}
                autoComplete='off'
                value={question3Field1 ? question3Field1 : ""}
                onChange={e => (setquestion3Field1(e.target.value))}
              />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
            <p className="one-on-one-form-title" style={{ marginTop: "10px" }}>. It felt like</p>
            <Tooltip overlayStyle={{zIndex:1160}} title={'describe the impact of their behavior on you, others and/or business, e.g. you were being disrespectful.'} >
              <Input
                style={{
                  border: '1px solid #D9D9D9',
                  width: '100%',
                  height: '40px',
                  borderRadius: "8px",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: '#0E0E2C',
                  fontFamily: 'Poppins'
                }}
                autoComplete='off'
                value={question3Field2 ? question3Field2 : ""}
                onChange={e => { (setquestion3Field2(e.target.value)) }}
              />
            </Tooltip>

          </div>
        </Col>
      </div>
      }
      {/* question 4 */}
      {(feedBackType === '2' && selectedTabValue == 3) && (
        <div style={{ marginTop: '15px' }}>
          <Row>
            <FeedbackQuestion data={{ required: true, questionText: "Probe for possible alternatives" }} />
          </Row>

          <Col span={24} className="meeting-feedback-detailed-box">
            <p className="one-on-one-form-title">How else could you have handled that? Here are my suggestions.</p>
            <div style={{ display: 'flex', flexDirection: 'raw', gap: '0px' }}>
              <Tooltip overlayStyle={{zIndex:1160}} title={'Alternative ways of saying or doing it differently.'} >
                <Input
                  style={{
                    border: '1px solid #D9D9D9',
                    width: '100%',
                    height: '40px',
                    borderRadius: "8px",
                    width: "100%",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: '#0E0E2C',
                    fontFamily: 'Poppins'
                  }}
                  autoComplete='off'
                  value={question4Field1 ? question4Field1 : ""}
                  onChange={e => (setquestion4Field1(e.target.value))}
                />
              </Tooltip>

            </div>
          </Col>
        </div>
      )
      }
      {/* question 5 */}
      {selectedTabValue == 4 && <div style={{ marginTop: '15px' }}>

        <FeedbackQuestion data={{ required: true, questionText: "Support for next steps and commitments" }} />
        <Col span={24} className="meeting-feedback-detailed-box">
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {feedBackType === "1" ?
              <Input
                style={{
                  border: '1px solid #D9D9D9',
                  width: '100%',
                  height: '40px',
                  borderRadius: "8px",
                  width: "100%",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: '#0E0E2C',
                  fontFamily: 'Poppins'
                }}
                autoComplete='off'
                value={question5Field1}
                onChange={e => (setquestion5Field1(e.target.value))}
              />
              // <p style={{fontSize:'15px'}}>You should try and do this more often and if possible, even try and help the team adopt this behavior.</p>  
              : feedBackType === "2" ?
                <Input
                  style={{
                    border: '1px solid #D9D9D9',
                    width: '100%',
                    height: '40px',
                    borderRadius: "8px",
                    width: "100%",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: '#0E0E2C',
                    fontFamily: 'Poppins'
                  }}
                  autoComplete='off'
                  value={question5Field2}
                  onChange={e => (setquestion5Field2(e.target.value))} /> : ""
              // <p style={{fontSize:'15px'}}>If it’s helpful, I can continue to observe and provide feedback if I see you try something differently. I am happy to catch up again in some time, so you can bounce off any challenges you may be facing. Let me know.</p> :''
            }
          </div>

        </Col>
      </div>}
    </Col>
  )

  const disabledButon = (value, array) => {

    if (!feedBackType) {
      return true
    } else {
      let filterList = array?.find((item) => item?.id === value)

      return filterList?.active
    }

  }


  return (
    <>
      <>
        <MetaTags
          title="Give Feedback"
          description="This is Create Feedback page."
        />


      <div style={{ minHeight: '300px', overflow: 'auto',width:"100%" }}>   
          <Spin spinning={loading} >
            {
              SurveyById && (
                <div style={{ width: "100%",display:'flex',flexDirection:"column", gap:"1em",marginTop:"1em"}}>
                 
                    <div className="feedback-type-switcher">
                        <div className={feedBackType==="1"?"feedback-type-content-container-active":"feedback-type-content-container"}
                        onClick={()=>HandleTypeChange("1")}>
                            <h4>Motivational/Recognition</h4>
                        </div>
                        <div className={feedBackType==="2"?"feedback-type-content-container-active":"feedback-type-content-container"}
                        onClick={()=>HandleTypeChange("2")}>
                            <h4>Developmental/Constructive</h4>
                        </div>
                    </div>
                    <div style={{display:'flex',width:'100%',justifyContent:'end',alignItems:'end'}}>
                      {
                        givenKudos ?
                          <div style={{marginTop:'5px'}}>
                            <GivenKudos setGivenKudos={setGivenKudos}  fromGiveFeedback={true} givenKudos={givenKudos} {...props} />
                          </div>

                          :
                          feedBackType === "1" ?
                            permission?.kudos_permission && <Button onClick={(e) => { setAddFeedback(false); setGiveKudosPopUpVisible(true) }} className='give-kudos-button'>
                              <div style={{ display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",gap:".5em",padding:".5em" }} >
                              <div className='poppins'> Give kudos</div> <img src={green_badge} /></div> 
                              </Button>
                            : null
                      }
                    </div>
                    
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em", flexWrap: "wrap",width:"100%",marginTop:"1em" }}>
                        <div style={{display:"flex",flexDrection:"row", gap: "1em", flexWrap: "nowrap"}}>
                              {tabArray?.length && tabArray?.map((item, index) => (
                                  item?.value == 'S' ?
                                      <Row className="feedback-tab-array-container">
                                          <Button className={item?.id == selectedTabValue ? "feedback-tab-btn-focus" : !item?.active ? "feedback-tab-btn-active" : "feedback-tab-btn"} disabled={feedBackType == '1' && item?.value == 'P' || checkDisblaeButton(item?.value, index, tabArray) || (!feedBackType && item?.value == 'P')} onClick={() => { setSelectedTabValue(item?.id) }}
                                          >
                                              <Row style={{ paddingLeft: item?.value === "L" ? "2px" : "0px" }}>
                                                  <div>
                                                      {item?.value}
                                                  </div>

                                              </Row>
                                          </Button>
                                          <Tooltip title={ToolTipTitle} placement='topLeft' overlayClassName='feedback-info-tooltip'>
                                              <img style={{ position: 'relative', top: '-48px', left: '40px', width: '15px', zIndex: '1500' }} src={info_button} />
                                          </Tooltip>
                                      </Row>
                                      :
                                      <Button className={item?.id == selectedTabValue ? "feedback-tab-btn-focus" : !item?.active ? "feedback-tab-btn-active" : "feedback-tab-btn"} disabled={feedBackType == '1' && item?.value == 'P' || checkDisblaeButton(item?.value, index, tabArray) || (!feedBackType && item?.value == 'P')} onClick={() => { setSelectedTabValue(item?.id) }}>
                                          <Row style={{ paddingLeft: item?.value === "L" ? "2px" : "0px" }}>
                                              {item?.value}

                                          </Row>
                                      </Button>
                              ))}
                        </div>
                        <div style={{ display: 'flex', flexDirection: "row", justifyContnet: "start", alignItems: 'center', gap: '1em' }}>
                          <p style={{ margin: '0px' }} className="feedback-form-title">Detailed</p>
                          <Switch checked={fillInOption} onChange={(e) => {
                              setfillInOption(!fillInOption);handleDetailedSwitch()
                          }} disabled={!feedBackType ? true : false} defaultValue={fillInOption} />
                        </div>
                    </div>
                      <div id="surveyComponent" style={{ width: "100%" }}>{fillInOption ? WithfillOption : WithNoOption}</div>
                  <div align="center" id="feedbackContainer">
                    {selectedTabValue === questionList?.length - 1 && <Button className="one-on-one-save-btn-modal" onClick={(e) => handleFeedbackPreview()} htmlType="submit" disabled={!fillInOption ? disabledButon(selectedTabValue, tabArray) : false} style={{ marginTop: '30px', padding: '8px 20px' }}>Preview</Button>}
                    {selectedTabValue !== questionList?.length - 1 &&
                      <Row justify="center" align="center" style={{ gap: "10px" }}>
                        <Button className="one-on-one-cancel-btn-modal" type="primary" style={{ marginTop: '30px' }} onClick={() => { onCancel(); setSelectedTabValue(); setAnswers([]); }}>Cancel</Button>
                        <Button className="one-on-one-save-btn-modal" disabled={disabledButon(selectedTabValue, tabArray)} style={{ marginTop: '30px' }} onClick={(e) => handleNextSubmit(e, feedBackType == '1' && selectedTabValue === 2 ? selectedTabValue + 2 : selectedTabValue + 1)
                        }>Next</Button>
                      </Row>}
                  </div>

                </div>
              )
            }

          </Spin>
        </div>
      </>
    </>
  );
});

export default OneOnOneFeedbackPostView;
