import React from "react"
import '../../css/poc-organization-style.css'
import PocOrganizationTreeContainer from "../../containers/organization/organizationTreeContainer"
import OrgEmployeeTableContainer from "../../containers/organization/orgEmployeeTableContainer"
import { Button, Input } from "antd"
import poc_dashboard_routes from "modules/poc-dashboard/routes"
import CustomEmployeeFilterContainer from "modules/poc-dashboard/containers/organization/customEmployeeFilterContainer"
import { dashboard_home_icons } from "modules/poc-dashboard/icons"
const PocOrganizationHomeView = (props) => {
    const {navigateRoute,verticals_count}=props
    const [filter_response,setFilterResponse]=React.useState({})
    const [search,setSearch]=React.useState("**")
    const [current_tab, setCurrentTab] = React.useState(
        localStorage.getItem("poc_admin_tab")?
        JSON.parse(localStorage.getItem("poc_admin_tab")):
        {
            key: 'org_tree',
            label: 'Organization Tree',
        }
    )
    const sidebar_tab = [
        {
            key: 'org_tree',
            label: 'Organization Tree',
        },
        {
            key: 'all_employee',
            label: 'All Employees',
        }
    ]

    React.useEffect(()=>{
        if(current_tab){
            setSearch("**")
            setFilterResponse({})
        }
        if(current_tab){
            localStorage.setItem("poc_admin_tab",JSON.stringify(current_tab))
        }
    },[current_tab])

    const views = {
        org_tree: PocOrganizationTreeContainer,
        all_employee: OrgEmployeeTableContainer,
 
    }
    const CurrentView = views[ Object.keys(views)?.includes(current_tab?.key)?current_tab?.key:'employee_level' ]

    let search_timer = React.useRef()
    const searchEmployee =(value)=>{
        if(search_timer.current){
            clearTimeout(search_timer.current)
        }
        if(current_tab?.key === 'org_tree'){
            setSearch(value)
        }else{
            search_timer.current = setTimeout(() => {
                setSearch(value)
            }, 1000);
        }
     
    }
    return (
        <>
            <div style={{display:'flex',flexDirection:"row",justifyContent:'space-between',alignItems:'center',gap:"1em"}}>
                <Button className="poc-active-btn" style={{textTransform:"capitalize"}} onClick={()=>navigateRoute(poc_dashboard_routes.create_invitation)} disabled={!verticals_count || verticals_count<=0}>Invite Employees</Button>
                <div style={{display:'flex',gap:"1em",alignItems:'center'}}>
                    <div style={{ position: "relative", width: "100%", maxWidth: "12.5em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".25em" }}>
                        <img src={dashboard_home_icons?.search_icon} alt="search_icon" style={{ height: "20px" }} />
                        <Input style={{ width: "100%", border: "none", height: "3em", fontSize: "1em", fontFamily: "Poppins",backgroundColor:"transparent"}} placeholder="Search" onChange={(e) => searchEmployee(e?.target?.value)} />
                    </div>
                    {current_tab?.key === "all_employee" && <CustomEmployeeFilterContainer filter={(filter)=>setFilterResponse(filter)} {...props}/>}
                </div>
               
            </div>
            <div className="poc-org-root poc-org-root-style" style={{marginTop:"1em"}}>
                
                <div className='poc-org-nav-view'>
                    {
                        sidebar_tab?.map(sidebar => (

                            <div className={`rd-sidebar-normal ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar' : ''}`} onClick={() => setCurrentTab(sidebar)}>
                                <h4 className={`rd-sidebar-label ${(current_tab?.key === sidebar?.key || (current_tab?.key?.includes(sidebar?.key))) ? 'rd-active-sidebar-label' : ''}`}>{sidebar?.label}</h4>
                            </div>
                        ))
                    }
                    <div className='border-line'>

                    </div>
                </div>
                <div className="poc-org-router-outlet">
                    <CurrentView {...props} filterResponse={filter_response} search={search} />
                </div>
             
            </div>
        </>
    )

}

export default PocOrganizationHomeView