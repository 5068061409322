import banner_icon from "../assets/user_manual_banner.svg"
import edit_blue_icon from "../assets/edit_blue_icon.svg"
import inactive_compare from '../assets/compare_inactive.svg' 
import active_compare from '../assets/active_compare.svg'
import dropdown_arrow from '../assets/dropdown_Arrow.svg'
export const user_manual_icon = {
    banner_icon,
    edit_blue_icon,
    inactive_compare,
    active_compare,
    dropdown_arrow
}