import gql from "graphql-tag";


export const AWARDED_BADGE_LIST =gql`
query badgeAwards($recipient: ID,
$issuer_User_FirstName_Icontains: String,
$recipient_User_FirstName_Icontains: String,
$badge_Title_Icontains: String,
$issuer_cursor:String,
$badge_cursor:String,
$receipient_cursor:String,
$recipient_TeamMembersEmployee_In: [String],
$recipient_VerticalMembersEmployee_In: [String],
$createdAt_Range: [DateTime]){
    issuer:badgeAwards(recipient:$recipient,
    issuer_User_FirstName_Icontains: $issuer_User_FirstName_Icontains
    ,first:10,after:$issuer_cursor,
    recipient_TeamMembersEmployee_In:$recipient_TeamMembersEmployee_In,
    recipient_VerticalMembersEmployee_In:$recipient_VerticalMembersEmployee_In,
    createdAt_Range:$createdAt_Range) {
        pageInfo{
        hasNextPage
        endCursor
        }
        edges {
            node {
                id
                createdAt
                reactions{
                    totalCount
                    edges{
                    node{
                        id
                        reaction
                        employee{
                        id
                        }
                    }
                    }
                }
                issuer {
                id
                user {
                    id
                    firstName
                    lastName
                }
                }
                recipient {
                    id
                    memberSet(last: 1, role_Isnull: false) {
                        edges {
                        node {
                            id
                            role {
                            id
                            title
                            }
                        }
                        }
                    }
                    user {
                        id
                        firstName
                        profile {
                            id
                            profileImage
                        }
                    }
                }
                evidence
                badge {
                    id
                    title
                    image
                    behaviouralDescription
                    badgeFamily{
                        id
                        title
                    }
                }
            }
        }
    }
    recipient:badgeAwards(recipient:$recipient,
    recipient_User_FirstName_Icontains:$recipient_User_FirstName_Icontains,
    first:10,after:$receipient_cursor,
    recipient_TeamMembersEmployee_In:$recipient_TeamMembersEmployee_In,
    recipient_VerticalMembersEmployee_In:$recipient_VerticalMembersEmployee_In,
    createdAt_Range:$createdAt_Range) {
        pageInfo{
        hasNextPage
        endCursor
        }
        edges {
            node {
                id
                createdAt
                reactions{
                    totalCount
                    edges{
                    node{
                        id
                        reaction
                        employee{
                        id
                        }
                    }
                    }
                }
                issuer {
                id
                user {
                    id
                    firstName
                    lastName
                }
                }
                recipient {
                    id
                    memberSet(last: 1, role_Isnull: false) {
                        edges {
                        node {
                            id
                            role {
                            id
                            title
                            }
                        }
                        }
                    }
                    user {
                        id
                        firstName
                        profile {
                            id
                            profileImage
                        }
                    }
                }
                evidence
                badge {
                    id
                    title
                    image
                    behaviouralDescription
                    badgeFamily{
                        id
                        title
                    }
                }
            }
        }
    }
    badge:badgeAwards(recipient:$recipient,
    badge_Title_Icontains:$badge_Title_Icontains,
    first:10,after:$badge_cursor,
    recipient_TeamMembersEmployee_In:$recipient_TeamMembersEmployee_In,
    recipient_VerticalMembersEmployee_In:$recipient_VerticalMembersEmployee_In,
    createdAt_Range:$createdAt_Range) {
        pageInfo{
        hasNextPage
        endCursor
        }
        edges {
            node {
                id
                createdAt
                reactions{
                    totalCount
                    edges{
                    node{
                        id
                       reaction
                        employee{
                        id
                        }
                    }
                    }
                }
                issuer {
                id
                user {
                    id
                    firstName
                    lastName
                }
                }
                recipient {
                    id
                    memberSet(last: 1, role_Isnull: false) {
                        edges {
                        node {
                            id
                            role {
                            id
                            title
                            }
                        }
                        }
                    }
                    user {
                        id
                        firstName
                        profile {
                            id
                            profileImage
                        }
                    }
                }
                evidence
                badge {
                    id
                    title
                    image
                    behaviouralDescription
                    badgeFamily{
                        id
                        title
                    }
                }
            }
        }
    }
}
`
export const ENGAGEMENT_BOARD_QUERY = gql`
query badgeAwards($recipient: ID,
    $recipient_TeamMembersEmployee_In: [String],
    $recipient_VerticalMembersEmployee_In: [String],
    $createdAt_Range: [DateTime]){
    badgeAwards(recipient:$recipient,
        recipient_TeamMembersEmployee_In:$recipient_TeamMembersEmployee_In,
        recipient_VerticalMembersEmployee_In:$recipient_VerticalMembersEmployee_In,
        createdAt_Range:$createdAt_Range) {
        edges {
            node {
                id
                issuer {
                    id
                    createdAt
                    memberSet(last: 1, role_Isnull: false) {
                        edges {
                        node {
                            id
                            role {
                            id
                            title
                            }
                        }
                        }
                    }
                    user {
                        id
                        firstName
                        lastName
                        profile{
                        id
                        profileImage
                        }
                    }
                }
                recipient {
                    id
                    memberSet(last: 1, role_Isnull: false) {
                        edges {
                        node {
                            id
                            role {
                            id
                            title
                            }
                        }
                        }
                    }
                    user {
                        id
                        firstName
                        lastName
                        profile {
                            id
                            profileImage
                        }
                    }
                }
            }
        }
    }
}
`
export const KUDOS_BADGE_COMMENT_LIST_QUERY = gql`
query badgeAward($id:ID!){
badgeAward(id:$id){
    id
    badgeawardcommentSet{
      edges{
        node{
         id
          employee{
            id
            user{
              id
              firstName
              lastName
              profile{
                id
                profileImage
              }
            }
          }
          content
          createdAt
          parent{
            id
          }
          reactions{
            totalCount
            edges{
              node{
                id
                employee{
                  id
                }
              }
            }
          }
          replies{
            edges{
              node{
                id
                content
                createdAt
                reactions{
                  totalCount
                  edges{
                    node{
                        id
                        employee{
                        id
                        }
                    }
                    }
                }
                employee{
                  id
                  user{
                    id
                    firstName
                    lastName
                    profile{
                      id
                      profileImage
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`