import { Button, Dropdown, Input, Spin } from "antd";
import { dashboard_home_icons } from "modules/poc-dashboard/icons";
import React from "react";

const CustomSoulReportFilterView = (props) => {
    const { values, strengths, knowledge, filter, filter_loading,getFilterData } = props
    const [dropdownVisible, setDropDownVisible] = React.useState(false)
    const [selected_value, setSelectedValues] = React.useState([])
    const [selected_strength, setSelectedStrengths] = React.useState([])
    const [selected_personality, setSelectedPersonality] = React.useState([])
    const [selected_knowledge, setSelectedKnowledge] = React.useState([])
    let search_ref = React.useRef("")
    const clearFilter = () => {
        setSelectedValues([])
        setSelectedStrengths([])
        setSelectedPersonality([])
        setSelectedKnowledge([])
        filter({})
    }

    const selectValue = (value) => {
        let ids = [...selected_value]
        if (ids.includes(value)) {
            let index = ids.indexOf(value)
            ids.splice(index, 1)
        }
        else {
            ids.push(value)
        }
        setSelectedValues(ids)
    }

    const selectStrength = (strength) => {
        let ids = [...selected_strength]
        if (ids.includes(strength)) {
            let index = ids.indexOf(strength)
            ids.splice(index, 1)
        }
        else {
            ids.push(strength)
        }
        setSelectedStrengths(ids)
    }

    const selectKnowledge = (knowledge) => {
        let ids = [...selected_knowledge]
        if (ids.includes(knowledge)) {
            let index = ids.indexOf(knowledge)
            ids.splice(index, 1)
        }
        else {
            ids.push(knowledge)
        }
        setSelectedKnowledge(ids)
    }
    const onFilter = () => {
        filter({
            values_answer: selected_value,
            strength_answer: selected_strength,
            // personality: selected_personality,
            knowledge_answer: selected_knowledge
        })
        setDropDownVisible(false)
    }

    let search_time = React.useRef()

    const searchAnswer =(value,type)=>{
        if (search_time.current) {
            clearTimeout(search_time?.current)
          }
          search_time.current = setTimeout(() => {
            search_ref.current = value
            if(type === 'value'){
                getFilterData({value_Icontains:value})
            }
            else if(type ==='strength'){
                getFilterData({strenght_Icontains:value})
            }
            else if(type ==='knowledge'){
                getFilterData({knowledge_Icontains:value})
            }
          }, 700);
    }

    const filterMenu = () => (
        <div className="custom-filter-container" style={{ width: "100%", minWidth: "30em", minHeight: "20em" }}>
            <Spin spinning={filter_loading}>
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', gap: "1em", width: "100%" }}>
                        <h4 className="filter-title">Filter</h4>
                        {
                            (selected_value?.length || selected_strength?.length || selected_personality?.length || selected_knowledge?.length) ?
                                <Button className="clear-filter-btn" onClick={() => clearFilter()}>Clear filter</Button> : null
                        }

                    </div>
                    <div style={{ display: 'flex', gap: "1em", justifyContent: "start", alignItems: "start", width: "fit-content", paddingTop: ".5em" }}>
                        <div>
                            <h4 className="filter-title" style={{ fontWeight: "500" }}>Values {selected_value?.length > 0 ? `/${selected_value?.length}` : ''} </h4>
                            <div style={{ position: "relative", width: "100%", maxWidth: "12.5em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".25em" }}>
                                <img src={dashboard_home_icons?.search_icon} alt="search_icon" style={{ height: "20px" }} />
                                <Input style={{ width: "100%", border: "none", height: "3em", fontSize: "1em", fontFamily: "Poppins", backgroundColor: "transparent" }} placeholder="Search"onChange={(e)=>searchAnswer(e?.target?.value,'value')} />
                            </div>
                            <div className="custom-filter-scroll" style={{ maxHeight: "10em", overflow: 'auto', paddingRight: "10px" }}>
                                {values?.map(item => (
                                    <div className={`filter-data-container ${selected_value?.includes(item?.value) ? 'filter-data-container-selected' : ''}`} onClick={() => selectValue(item?.value)}>
                                        <h4 style={{ margin: 0, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "10em", fontWeight: "400", color: selected_value?.includes(item?.value) ? '#fff' : '#000', fontSize: ".9em" }}>{item?.value}</h4>
                                    </div>

                                ))}
                            </div>
                        </div>
                        <div>
                            <h4 className="filter-title" style={{ fontWeight: "500" }}>Strengths {selected_strength?.length > 0 ? `/${selected_strength?.length}` : ''}</h4>
                            <div style={{ position: "relative", width: "100%", maxWidth: "12.5em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".25em" }}>
                                <img src={dashboard_home_icons?.search_icon} alt="search_icon" style={{ height: "20px" }} />
                                <Input style={{ width: "100%", border: "none", height: "3em", fontSize: "1em", fontFamily: "Poppins", backgroundColor: "transparent" }} placeholder="Search" onChange={(e)=>searchAnswer(e?.target?.value,'strength')} />
                            </div>
                            <div className="custom-filter-scroll" style={{ maxHeight: "10em", overflow: 'auto', paddingRight: "10px" }}>
                                {strengths?.map(strength => (
                                    <div className={`filter-data-container ${selected_strength?.includes(strength?.value) ? 'filter-data-container-selected' : ''}`} onClick={() => selectStrength(strength?.value)}>
                                        <h4 style={{ margin: 0, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "auto", fontWeight: "400", color: selected_strength?.includes(strength?.value) ? '#fff' : '#000', fontSize: ".9em" }}>{strength?.value}</h4>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div>
                            <h4 className="filter-title" style={{ fontWeight: "500" }}>knowledge & Skills {selected_knowledge?.length > 0 ? `/${selected_knowledge?.length}` : ''}</h4>
                            <div style={{ position: "relative", width: "100%", maxWidth: "12.5em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", gap: ".25em" }}>
                                <img src={dashboard_home_icons?.search_icon} alt="search_icon" style={{ height: "20px" }} />
                                <Input style={{ width: "100%", border: "none", height: "3em", fontSize: "1em", fontFamily: "Poppins", backgroundColor: "transparent" }} placeholder="Search" onChange={(e)=>searchAnswer(e?.target?.value,'knowledge')} />
                            </div>
                            <div className="custom-filter-scroll" style={{ maxHeight: "10em", overflow: 'auto', paddingRight: "10px" }}>
                                {knowledge?.map(know => (
                                    <div className={`filter-data-container ${selected_knowledge?.includes(know?.value) ? 'filter-data-container-selected' : ''}`} onClick={() => selectKnowledge(know?.value)}>
                                        <h4 style={{ margin: 0, whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "auto", fontWeight: "400", color: selected_knowledge?.includes(know?.value) ? '#fff' : '#000', fontSize: ".9em" }}>{know?.value}</h4>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                        <Button className="poc-active-btn" style={{ marginTop: "1em", fontSize: "1em", fontWeight: "500" }} onClick={() => onFilter()}>Apply</Button>
                    </div>
                </div>
            </Spin>
        </div>
    )

    return (
        <>
            <Dropdown visible={dropdownVisible} overlay={filterMenu} placement={"bottomRight"}>
                <Button className="filter-btn" onClick={() => setDropDownVisible(!dropdownVisible)}>Filter</Button>
            </Dropdown>
            {dropdownVisible ? (<div className='overlay-dynamic-island' onClick={() => { setDropDownVisible(false) }} />) : null}
        </>
    )
}

export default CustomSoulReportFilterView;