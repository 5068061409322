import { Button, Col, Divider, Empty, Form, Row, Space, Spin } from 'antd';
import _ from 'lodash';
import React from 'react';

import { CKEditorField, DeleteIcon, getIntFromString, ReactMarkdown, TextField } from '../../look';
import AddCondition from '../../text-section/containers/AddCondition';
import ConditionOperator from '../../text-section/helpers/helpers';
import AddCoachingCommitment from '../containers/AddCoachingCommitment';

export const CKEditorFieldName = 'description';

const FormItem = Form.Item;

const AdminPanelCoachingCommitmentFormComponent = props => {
  const {
    getCommitmentById,
    onSubmit,
    data,
    setData,
    deleteCondition,
    deleteCoachingCommitment,
    handleCancel,
    coachingCommitmentData,
    setCoachingCommitmentData
  } = props;
  const [actionLoading, setActionLoading] = React.useState(false);
  const initialValue = {
    title: getCommitmentById && getCommitmentById?.title,
    description: getCommitmentById && getCommitmentById?.description,
    condition: getCommitmentById && getCommitmentById?.condition
  };

  const handleDelete = async id => {
    setActionLoading(true);
    try {
      const response = await deleteCondition({id:id});
      response && setData(data?.filter(({ node }) => getIntFromString(node.id) !== id));
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw Error(err.message);
    }
  };

  const handleDeleteCoachingCommitment = async (id, index) => {
    if (id) {
      setActionLoading(true);
      try {
        let idToDelete = getIntFromString(id)
        await deleteCoachingCommitment({id:idToDelete});
        
        setActionLoading(false);
      } catch (err) {
        setActionLoading(false);
        throw Error(err.message);
      }

      setCoachingCommitmentData(
        coachingCommitmentData
          ?.filter(({ node }) => node?.id !== id)
          .map(({ node }, idx) => ({ node: { ...node, index: idx } }))
      );
    }else{

      let updatedCommitmentData = coachingCommitmentData?.filter((value,ind)=> ind !== index)
  
      setCoachingCommitmentData(updatedCommitmentData);

    }
    
  };



  const handleConditionOperation = (addMutation,condition) =>{

    if(addMutation){
    setData(data ? [...data, { node: condition }] : [{ node: condition }]);
   }else{
    let conditionSet = data;
    let indexOfCondition = conditionSet?.findIndex((value)=> getIntFromString(value?.node?.id) === getIntFromString(condition?.id ))
    conditionSet[indexOfCondition] = {node:{...condition}}
    setData([...conditionSet])

  }
  }


  const handleEditCommitment = async (condition,addMutation) => {
    

    setActionLoading(true);
    try {
      if (!getCommitmentById?.id) {
        if(addMutation){
          setData(data ? [...data, { node: condition }] : [{ node: condition }]);
        }else{
          handleConditionOperation(addMutation,condition)
        }
        
      } else {
        if(addMutation){
          setData(data ? [...data, { node: condition }] : [{ node: condition }]);
        }else{
          handleConditionOperation(addMutation,condition)
        }
      }
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  };

  const handleEditCoachingCommitment = async coachingCommitment => {
    setActionLoading(true);
     
    try {
      if (typeof coachingCommitment.index === 'undefined') {
        setCoachingCommitmentData(
          coachingCommitmentData
            ? [...coachingCommitmentData, { node: coachingCommitment }].map(({ node }, index) => ({
                node: { ...node, index }
              }))
            : [{ node: { ...coachingCommitment, index: 0 } }]
        );
      }else{
        let data = coachingCommitmentData;
        data[coachingCommitment.index] = {node:coachingCommitment} ;
        setCoachingCommitmentData(data)
      }
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  };

  
  return (
    <Row>
      <Col span={24}>
        <Spin spinning={actionLoading} size="medium">
          <Form
            layout="vertical"
            initialValues={initialValue}
            destroyOnClose={true}
            onFinish={values =>
              onSubmit(values, data ? data?.map(({ node }) => getIntFromString(node.id)) : [], coachingCommitmentData)
            }
            required={false}
            scrollToFirstError={true}
          >
            <TextField name="title" label="Title" rules={[{ required: true, message: 'Title is required!' }]} />
            <CKEditorField
              name={CKEditorFieldName}
              label="Description"
              initialValue={getCommitmentById && getCommitmentById.description}
              // rules={[{ required: true, message: 'All questions are required!' }]}
            />

            <FormItem label={'Conditions'} labelCol={{ span: 24 }} wrapperCol={{ span: 24 /* , offset */ }}>
              {data?.length > 0 ? (
                data?.map(({ node }) => (
                  <>
                    <Row justify="space-between" align="middle">
                      <Col span={7} align="left">
                        <ReactMarkdown>{node?.leftOperand?.note}</ReactMarkdown>
                      </Col>
                      <Col span={6} align="left">
                        {ConditionOperator?.filter(c => c.value === node?.operator)[0]?.label}
                      </Col>
                      <Col span={7} align="left">
                        <span>{node?.rightOperand?.operation}</span>
                      </Col>

                      <Col span={4} align="right">
                        <AddCondition
                          btn="edit"
                          condition={node}
                          onSubmit={handleEditCommitment}
                          btnText={'Add new condition'}
                        />
                        <Divider type="vertical" />
                        <DeleteIcon
                          tooltipSuffix="Condition"
                          type="link"
                          size="sm"
                          onClick={() => handleDelete(getIntFromString(node.id))}
                        />
                      </Col>
                    </Row>
                    <Divider />
                  </>
                ))
              ) : (
                <div align="center">
                  <Empty />
                  <br />
                </div>
              )}
            </FormItem>

            <FormItem label={'Coaching commitment'} labelCol={{ span: 24 }} wrapperCol={{ span: 24 /* , offset */ }}>
              {coachingCommitmentData?.length > 0 ? (
                coachingCommitmentData?.map(({ node }, index) => (
                  <>
                    <Row justify="space-between" align="middle">
                      <Col span={20} align="left">
                        <span>{node?.title}</span>
                      </Col>

                      <Col span={4} align="right">
                        <AddCoachingCommitment
                          btn="edit"
                          coachingCommitment={node}
                          onSubmit={e => handleEditCoachingCommitment(_.set(e, ['index'], index))}
                          btnText={'Add new coaching commitment'}
                        />
                        <Divider type="vertical" />
                        <DeleteIcon
                          type="link"
                          size="sm"
                          onClick={() => handleDeleteCoachingCommitment(node.id, index)}
                        />
                      </Col>
                    </Row>
                    <Divider />
                  </>
                ))
              ) : (
                <div align="center">
                  <Empty />
                  <br />
                </div>
              )}
            </FormItem>

            <Row justify="space-between">
              <Col>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Add
                  </Button>

                  <AddCondition btn="add" onSubmit={handleEditCommitment} btnText={'Add new condition'} />

                  <AddCoachingCommitment
                    btn="add"
                    onSubmit={handleEditCoachingCommitment}
                    btnText={'Add new coaching commitment'}
                  />
                </Space>
              </Col>
              <Col>
                <Button type="primary" ghost onClick={handleCancel}>
                  Cancel
                </Button>
              </Col>
            </Row>
            {/* <ButtonsComponent setButton={setButton} /> */}
          </Form>
        </Spin>
      </Col>
    </Row>
  );
};

export default AdminPanelCoachingCommitmentFormComponent;
