import React from "react";
import bookmark_disabel from '../../assets/bookmark-inactive.svg'
import { Button, Collapse, Dropdown, Menu, Modal, Popover, Row, Spin } from "antd";
import three_dot from '../../assets/three-dot.svg'
import okr from '../../assets/okr-redirection.svg'
import edit_icon from '../../assets/edit-agenda.svg'
import delete_icon from '../../assets/delete-agenda.svg'
import AddAgendaContainer from "../containers/AddAgendaContainer";
import { getIntFromString, ReactMarkdown } from "modules/look";
import { CaretRightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import bookmark_link from '../../assets/bookmark-link.svg'
import { useHistory } from "react-router-dom";
import Agenda_close from '../../assets/agenda-close.svg'
import { OneonOneDeleteModal } from "./one-on-oneDeleteModal";
import { ai_trigger_content } from "modules/ai-tips/ai-trigger-content";

const AgendaListView = (props) => {
  const { agendaList, addbutton, meetingByIdData, me, onSubmit, setcloseAddAgenda, createActionItem, updateActionItem, deleteActionItem, noAddOption, memberslist, noMenu, actionEnable, hidePlaceHolder,permission,SetAITrigger,ai_trigger } = props
  console.log("agenda",agendaList)
  const [memberlist, setmemberlist] = React.useState([]);
  const [addAgenda, setAddAgenda] = React.useState(false)
  const [loading, setloading] = React.useState(false);
  const [ActionItem, setActionsItem] = React.useState();
  const [addActionItem, setaddActionItem] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState()
  const [deleteModal, setDeleteModal] = React.useState(false)
  const [selectedAgenda, setSelectedAgenda] = React.useState()
  const history = useHistory()
  const { Panel } = Collapse;
  React.useEffect(() => {
    let memberlist = meetingByIdData?.member?.edges?.map(({ node }) => node.user)
    memberlist = memberlist?.filter(i => i?.employee?.id !== meetingByIdData?.owner?.user?.employee?.id)
    setmemberlist(memberlist)
  }, [meetingByIdData])

  const SubmitActionItem = async (data) => {
    setloading(true)

    if (data?.id) {
      try {
        const response = await updateActionItem(
          {
            id: getIntFromString(data?.id),
            title: data?.title,
            notes: data?.notes,
            coachingCommitmentId: data?.coachingCommitmentId?.id ? getIntFromString(data?.coachingCommitmentId?.id) : -1,
            commitmentId: data?.commitmentId?.id ? getIntFromString(data?.commitmentId?.id) : -1,
            isAgenda: data?.isAgenda,
            okrId: data?.okrId?.id ? getIntFromString(data?.okrId?.id) : -1,
          });
        if (response && response?.id) {
          setAddAgenda(false)
          setSelectedItem()
          setActionsItem(null)
          setaddActionItem(false)
          actionEnable(false)
        }

      }
      catch {
        setloading(false)
        setActionsItem(null)
        setaddActionItem(false)
      }
      setloading(false)
      setActionsItem(null)
      setaddActionItem(false)
    }
    else {
      try { 
        let meetingid = getIntFromString(meetingByIdData?.id)
        let actionItemData = {
          title: data?.title,
          meetingId: meetingid,
          okrId: data?.okrId?.id ? getIntFromString(data?.okrId?.id) : null,
          commitmentId: data?.commitmentId?.id ? getIntFromString(data?.commitmentId?.id) : null,
          coachingCommitmentId: data?.coachingCommitmentId?.id ? getIntFromString(data?.coachingCommitmentId?.id) : null,
          notes: data?.notes,
          isAgenda: data?.isAgenda,
          employeeId: data?.member?.employee?.id?getIntFromString(data?.member?.employee?.id):getIntFromString(me?.employee?.id)
        }
        const CreateActionItem = await createActionItem({ actionItemData });

        if (CreateActionItem && CreateActionItem?.id) {
          setActionsItem(null)
          setaddActionItem(false)
          setAddAgenda(false)
          actionEnable(false)
        }

      }
      catch {
        setloading(false)
        setActionsItem(null)
        setaddActionItem(false)
      }
      setloading(false)
      setActionsItem(null)
      setaddActionItem(false)
    }

  }

  const RemoveActionItem = async (value) => {
    // setdConfirmationModalVisible(false)
    setSelectedAgenda(value)
    setDeleteModal(true)

  }
  const deleteSelected = async (value) => {
    if (value) {

      setloading(true)
      try {
        const response = await deleteActionItem({ id: getIntFromString(value) });
        setloading(false)
        setDeleteModal(false)

      }
      catch {
        setloading(false)
      }

    }
  }
  const EditActionItem = (item) => {
    setSelectedItem(item)
    setAddAgenda(true)
  }
  const redirectToOkr = (okr) => {
    if (okr?.okrType === 'OBJECTIVE') {
      window.open(`/role/objective-keyresult/view/${getIntFromString(okr?.id)}`, "_blank");

    }
    else if (okr?.okrType === 'KEY_RESULT') {
      window.open(`/role/objective-keyresult/keyresult/view/${getIntFromString(okr?.id)}`, "_blank");

    }
    else if (okr?.okrType === 'MILESTONE') {
      window.open(`/role/objective-keyresult/milestone/view/${getIntFromString(okr?.id)}`, "_blank");

    }
  }



  const text = (item) => {
    return (
      <div className="action-item-marckdown" style={{ background: "#FFF", padding: "10px 20px", border: "1px solid #D9D9D9", borderRadius: "8px", minHeight: '50px' }}>
        <ReactMarkdown >{item?.notes}</ReactMarkdown>
      </div>
    )
  }

  const ai_previous__agenda_triggered_ref = React.useRef({title:""})
  const AiTip =(data)=>{
    let title =data?.value.trim()
    if(!ai_previous__agenda_triggered_ref.current.title !=title && SetAITrigger && title &&title?.trim()!==""){
      if(SetAITrigger){
        ai_previous__agenda_triggered_ref.current ={title}
        let trigger = {
          trigger:ai_trigger_content[data?.key],
          dynamic_data :[title],
          optype:"VIEW",
          objectId:meetingByIdData?.id
        }
        SetAITrigger({...trigger})
      }
    } 
  }

  return (
    <>
      <div className='meeting-overflow agenda-list-container' style={{ width: "100%", maxHeight: "380px", marginTop: "10px" }}>
        {!noAddOption && <div>
          {(addbutton && !addAgenda) && <div className="one-on-one-meeting-items-add-btn" onClick={() => { setAddAgenda(true); actionEnable(true) }}>
            + New Agenda
          </div>}
          {addAgenda &&
            <Spin spinning={loading}>
              {/* while changing the content of header prop be causious because few conditions are validated with the content of header */}
              <AddAgendaContainer
              {...props}
                meetingStarted={true}
                memberlist={memberslist ? memberslist : memberlist}
                header={"Add Agenda"}
                onSubmit={(e) => { SubmitActionItem(e)}}
                me={me}
                close={() => { setAddAgenda(false); setSelectedItem(); actionEnable(false) }}
                agenda={selectedItem}
                isAgenda={true}
                AiTip={(key)=>AiTip(key)}
              />
            </Spin>
          }
        </div>}
        {!addAgenda &&
          <>


            <div className="overflow-feedbacks" style={{ paddingRight: '0px' }}>
              {agendaList?.length ? agendaList?.map((item, index) => (
                <div>
                  {
                    item?.notes?.trim() !== "" ?
                      <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) =>
                          <CaretRightOutlined style={{ padding: '9px 0px' }} rotate={isActive ? 90 : 0} />
                        }
                        style={{ background: '#FFF', }}
                      >
                        <Panel style={{ padding: '0px', marginTop: '10px', marginBottom: "15px", borderBottom: 'none', borderRadius: '8px' }} className="agend-list-div" header={<Row justify="space-between" align="middle" onClick={(e) => e.stopPropagation()} >
                          <div className="one-on-one-agenda-title">{item?.title}</div>
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", flexWrap: "nowrap", gap: "10px" }}>
                            { (item?.okrId?.id && permission?.okr_permission) && <Popover  placement="bottomRight" content={<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", maxWidth: "280px" }}>
                              <h4 className="current-one-on-one-meeting-head" >{`Related to ${<span className="text-transform-capitalize">{item?.okrId?.owner?.user?.firstName}</span> || <span className="text-transform-capitalize">{item?.okr?.owner?.user?.firstName}</span>}'s`}</h4>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px", marginTop: "-10px" }}>
                                <div></div>
                                <h4 className="current-one-on-one-meeting-head" style={{ textTransform: "uppercase", color: "#009AF1", margin: "0" }}>{item?.okrId?.okrType === 'KEY_RESULT' ? 'KEY RESULT' : item?.okrId?.okrType === 'MILE_STONE' ? 'MILESTONE' : item?.okrId?.okrType}</h4>
                                <img src={bookmark_link} alt="" style={{ cursor: "pointer" }} onClick={() => redirectToOkr(item?.okrId)} />
                              </div>
                              <p className="one-on-one-agenda-title" style={{ paddingTop: "10px" }}>{item?.okrId?.title}</p>
                            </div>} trigger="hover">
                              <img src={okr} alt="" style={{ cursor: "pointer" }} />
                            </Popover>}
                            {!noMenu && <Dropdown overlay={
                              <Menu style={{ borderRadius: " 6.5px", boxShadow: " -0.5px 3px 13.5px -4.5px rgba(0, 0, 0, 0.31)", width: "80px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Menu.Item key="edit" style={{ display: "flex", flexDirection: "row", justifyContent: 'left', alignItems: "center", gap: "5px", fontFamily: "Poppins", fontWeight: 500, fontSize: "12px", cursor: "pointer", width: "100%" }} onClick={() => EditActionItem(item)}><img src={edit_icon} alt="" /> Edit</Menu.Item>
                                <Menu.Item key="delete" style={{ display: "flex", flexDirection: "row", justifyContent: 'left', alignItems: "center", gap: "5px", fontFamily: "Poppins", fontWeight: 500, fontSize: "12px", cursor: "pointer", width: "100%" }} onClick={() => RemoveActionItem(item)}><img src={delete_icon} alt="" />Delete</Menu.Item>
                              </Menu>} placement="bottomCenter"><img src={three_dot} alt="" style={{ cursor: "pointer" }} />
                            </Dropdown>}
                          </div>
                        </Row>}


                          key={index + 1}>
                          {
                            item?.notes?.trim() !== "" ?
                              text(item) : null
                          }
                        </Panel>
                      </Collapse>
                      :
                      <div className="agend-list-div" style={{ display: "flex", flexDirection: "row", gap: "20px", justifyContent: "space-between", alignItems: "center", marginTop: "15px" }}>
                        <h4 className="one-on-one-agenda-title" style={{ textAlign: "left" }}>{item?.title}</h4>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", flexWrap: "nowrap", gap: "10px" }}>
                          {(item?.okrId?.id && permission?.okr_permission) && <Popover placement="bottomRight" content={<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", padding: "10px 20px", maxWidth: "300px" }}>
                            <h4 className="current-one-on-one-meeting-head" >{`Related to ${<span className="text-transform-capitalize">{item?.okrId?.owner?.user?.firstName}</span> || <span className="text-transform-capitalize">{item?.okr?.owner?.user?.firstName}</span>}'s`}</h4>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "10px", marginTop: "-10px" }}>
                              <div></div>
                              <h4 className="current-one-on-one-meeting-head" style={{ textTransform: "uppercase", color: "#009AF1", margin: "0" }}>{item?.okrId?.okrType === 'KEY_RESULT' ? 'KEY RESULT' : item?.okrId?.okrType === 'MILE_STONE' ? 'MILESTONE' : item?.okrId?.okrType}</h4>
                              <img src={bookmark_link} alt="" style={{ cursor: "pointer" }} onClick={() => redirectToOkr(item?.okrId)} />
                            </div>
                            <p className="one-on-one-agenda-title" style={{ paddingTop: "10px" }}>{item?.okrId?.title}</p>
                          </div>} trigger="click">
                            <img src={okr} alt="" style={{ cursor: "pointer" }} />
                          </Popover>}
                          {/* <img src={bookmark_disabel} alt="" style={{cursor:"pointer"}} /> */}
                          {!noMenu && <Dropdown overlay={
                            <Menu style={{ borderRadius: " 6.5px", boxShadow: " -0.5px 3px 13.5px -4.5px rgba(0, 0, 0, 0.31)", width: "80px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <Menu.Item key="edit" style={{ display: "flex", flexDirection: "row", justifyContent: 'left', alignItems: "center", gap: "5px", fontFamily: "Poppins", fontWeight: 500, fontSize: "12px", cursor: "pointer", width: "100%" }} onClick={() => EditActionItem(item)}><img src={edit_icon} alt="" /> Edit</Menu.Item>
                              <Menu.Item key="delete" style={{ display: "flex", flexDirection: "row", justifyContent: 'left', alignItems: "center", gap: "5px", fontFamily: "Poppins", fontWeight: 500, fontSize: "12px", cursor: "pointer", width: "100%" }} onClick={() => RemoveActionItem(item)}><img src={delete_icon} alt="" />Delete</Menu.Item>
                            </Menu>} placement="bottomCenter"><img src={three_dot} alt="" style={{ cursor: "pointer" }} />
                          </Dropdown>}

                        </div>
                      </div>
                  }
                  <div>
                  </div>
                </div>

              )


              ) : <div style={{ justifyContent: "center", alignItems: "center", display: "flex", width: "100%", paddingTop: "50px" }}>

                {!hidePlaceHolder ? <h4 className='no-data' style={{color:'#BFBFBF'}}>No Agenda Found</h4> : <div></div>}
              </div>}


            </div>



          </>
        }
      </div>
      <Modal
        footer={null}
        centered
        visible={deleteModal}
        destroyOnClose={true}
        style={{ height: "300px" }}
        closable={false}>
        <div style={{ position: "relative", width: "100%" }} >
          <h2 className="one-on-one-meeting-popup-head" style={{ textAlign: "center" }}>Warning</h2>
          <img src={Agenda_close} alt="" style={{ position: "absolute", right: "0px", top: "0px", cursor: "pointer" }} onClick={() => { setDeleteModal(false); setSelectedItem() }} />
        </div>
        <OneonOneDeleteModal title={'Are you sure you want to delete the agenda?'} selectedData={selectedAgenda} onCancel={() => setDeleteModal(false)} deleteSeletedFeedback={e => deleteSelected(e)} type={'agenda'} />
      </Modal>
    </>
  )
}

export default AgendaListView
