// @ts-nocheck
import React from "react";
import { Input, Tooltip, Button } from "antd";
import req_active from '../../assets/request_active.png'
import req_inactive from '../../assets/request_inactive.png'
import incoming_active from '../../assets/incoming_active.png'
import incoming_inactive from '../../assets/incoming_inactive.png'
import outgoing_active from '../../assets/outgoing_active.png'
import outgoing_inactive from '../../assets/outgoing_inactive.png'
import close from '../../assets/close-small.svg'
import back from '../../assets/soul-back.svg'
import "../Css/survey-comparison-req.css";
import { getIntFromString } from "modules/look/components/functions";
import ComparisonRequestSentContainer from '../Container/ComparisonRequstSend'
import ComparisonRequestReceivedContainer from '../Container/ComparisonRequestReceived'
const SurveyComparisonRequestView = (props) => {
    const { allowComparison, getOrgEmployee, user_list_info, onEmployeeSelect, onClose, createResponseCompareRequest, survey, me } = props
    const [user_request_form, setUserRequestForm] = React.useState()
    const [selected_tab, SetselectedTab] = React.useState("request")
    const [user_request_notes, SetUserRequestNotes] = React.useState('')
    const [search_employee, setSearchEmployee] = React.useState()
    const [employee_list, setEmployeeList] = React.useState([])
    const [has_next_page, setHasNextPage] = React.useState(false)
    const [cursor, setCursor] = React.useState()
    const tab_ref = React.useRef([
        {
            label: "Request",
            key: "request",
            active_icon: req_active,
            inactive_icon: req_inactive,
            tooltip: "Employees"
        },
        {
            label: "Received Requests",
            key: "incoming",
            active_icon: incoming_active,
            inactive_icon: incoming_inactive,
            tooltip: "View Received Requests"
        },
        {
            label: "Sent Requests",
            key: "outgoing",
            active_icon: outgoing_active,
            inactive_icon: outgoing_inactive,
            tooltip: "View Sent Requests"
        }
    ])

    React.useEffect(() => {
        if (user_list_info?.length) {
            getOrgEmployee({ search: search_employee })
        }
    }, [search_employee])

    React.useEffect(() => {
        if (user_list_info?.length) {

            let data = user_list_info[0]
            setHasNextPage(data?.pageInfo?.hasNextPage)
            setCursor(data?.pageInfo?.endCursor)
            if (data?.pagination) {
                let list = employee_list.concat(data?.edges?.map(({ node }) => node))?.filter(val => val?.user?.id != me?.employee?.id)
                setEmployeeList(list)
            } else {
                let list = [].concat(data?.edges?.map(({ node }) => node))?.filter(val => val?.id != me?.employee?.id)
                setEmployeeList(list)
            }

        }
    }, [user_list_info])

    const sentRequestToUser = async () => {
        try {
            let compareRequestData = {
                senderId: getIntFromString(me?.id),
                message: user_request_notes ? `<p>${me?.firstName || ''} ${me?.lastName || ''}: ${user_request_notes}</p>` : '',
                surveyId: getIntFromString(survey?.id),
                receiverId: getIntFromString(user_request_form?.user?.id),
            }
            let response = await createResponseCompareRequest({ compareRequestData })
            if (response?.id) {
                SetselectedTab('outgoing')
                setUserRequestForm(null)
                SetUserRequestNotes('')
            }
        } catch (error) {

        }
    }

    const handleEmployeeSelect = (user) => {
        if (allowComparison) {
            // onEmployeeSelect({ ...user, type: "user_id" })
        } else {
            setUserRequestForm(user)
        }
    }

    const changePage = () => {
        getOrgEmployee({ search: search_employee, pagination: true, endCursor: cursor })
    }

    return (
        <>
            <div className="survey-comp-root">
                <div align="end">
                    <img src={close} onClick={() => { onClose() }} style={{ cursor: "pointer" }} alt="" />
                </div>
                {
                    !user_request_form ?
                        <>
                            <div className="comp-header-nav-section">
                                <div style={{ width: "100%" }}>
                                    {
                                        selected_tab === 'request' ?
                                            <Input className="comp-search-box" autoComplete='off' placeholder="Search" onChange={(e) => { setSearchEmployee(e?.target?.value) }} />
                                            :
                                            <h4>{selected_tab === 'incoming' ? 'Received Requests' : 'Sent Requests'}</h4>
                                    }

                                </div>
                                <div className="comp-tab-section">
                                    {
                                        tab_ref?.current?.map((item) => (

                                            <div>
                                                <Tooltip overlayStyle={{ zIndex: 1160 }} title={item?.tooltip}>
                                                    <img className="comp-tab-icon" onClick={() => { SetselectedTab(item?.key) }} src={selected_tab === item?.key ? item?.active_icon : item?.inactive_icon} alt="" />
                                                </Tooltip>
                                            </div>
                                        )
                                        )
                                    }
                                </div>
                            </div>

                            {
                                selected_tab == 'request' && (
                                    <div className="request-scroll">
                                        <h4 style={{ fontSize: "1.2em" }}>Employees</h4>
                                        {
                                            employee_list?.length ?
                                                <>
                                                    <div className="request-data-container">
                                                        {employee_list?.map(item => (
                                                            <div className="request-main-padding">
                                                                <div className="request-user-card">
                                                                    <div  style={{display:"flex",flexDirection:"column",gap:"0.5em",width:"100%"}}>
                                                                        <div style={{display:"flex",flexDirection:"row",gap:"0.5em",width:"100%",alignItems:"center"}}>
                                                                            <div className={`req-user-image-div ${!item?.user?.profile?.profileImage ? 'req-user-char-div' : ''}`}>
                                                                                {
                                                                                    item?.user?.profile?.profileImage ?
                                                                                        <img src={item?.user?.profile?.profileImage} className="profile-image" alt="" />
                                                                                        :
                                                                                        <h4 className="text-transform-capitalize">{item?.user?.firstName.charAt(0).toUpperCase()}</h4>
                                                                                }

                                                                            </div>
                                                                            <div className="um-request-tab-info">
                                                                                <h4 className="request-user-name text-transform-capitalize">{item?.user?.firstName} {item?.user?.lastName || ""}</h4>
                                                                                <h4 className="request-user-email ">{item?.user?.email} </h4>

                                                                            </div>
                                                                        </div>
                                                                        <div style={{ width: "100%" }} align="center">
                                                                            <Button className="comp-request-btn" onClick={() => { handleEmployeeSelect(item) }}>{allowComparison ? 'View' : 'Request'}</Button>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {
                                                        has_next_page && (
                                                            <div align="center">
                                                                <h4 className="req_pagination_view" onClick={() => { changePage() }}>View more</h4>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                                :
                                                <div align="center">
                                                    <h4>No user found</h4>
                                                </div>
                                        }

                                    </div>
                                )
                            }


                            {
                                selected_tab == 'incoming' && (
                                    <div className="request-scroll">
                                        <ComparisonRequestReceivedContainer {...props} tab={selected_tab} />
                                    </div>
                                )
                            }

                            {
                                selected_tab == 'outgoing' && (
                                    <div className="request-scroll">
                                        <ComparisonRequestSentContainer {...props} tab={selected_tab} />
                                    </div>
                                )
                            }

                        </>
                        :
                        <div>
                            <h4><span><img src={back} onClick={() => { setUserRequestForm(null); SetUserRequestNotes('') }} style={{ cursor: "pointer", marginRight: "10px" }} alt="" /></span> Requesting <span className="text-transform-capitalize">{user_request_form?.user?.firstName || 'employee'}</span> to share their user manual</h4>

                            <textarea className="comp-request-note" value={user_request_notes} onChange={(e) => { SetUserRequestNotes(e?.target?.value) }} placeholder="Say something here... (Optional)"></textarea>

                            <div align="center">
                                <Button className="comp-sent-request-btn" onClick={() => { sentRequestToUser() }}>Send Request</Button>
                            </div>
                        </div>
                }

            </div>
        </>
    )
}

export default SurveyComparisonRequestView;
