import gql from 'graphql-tag';

export const ORG_SURVEY_LIST = gql`
  query allOrgSurveysByImplicitOrgId($last: Int,$first: Int,$user: ID,$listing: Boolean) {
    allOrgSurveysByImplicitOrgId(first:$first,listing:$listing){
      pageInfo{
        hasNextPage
        endCursor
      }
      totalCount
        edges{
          node{
            id
            name
            description
            multisourceResponse
            logo
            reportAvailable
            responseSet(last:$last,user:$user,respondentType:"self"){
              edges{
                node{
                  id
                }
              }
            }
          }
        }
      }
  }
`;
export const GET_SURVEY_BY_ID = gql`
query SurveyById ($id:ID!){
  SurveyById (id:$id){
    id
    name
  }
}
`

export const GET_USER_RESPONSE_LIST = gql`
query surveyResponses($user: ID,$first:Int,$after:String,$survey_Name_Icontains:String,$survey_In:[ID]){
  surveyResponses(user:$user,first:$first,after:$after,survey_Name_Icontains:$survey_Name_Icontains,survey_In:$survey_In,orderBy:["-responseDate"]){
    pageInfo{
      endCursor
      hasNextPage
    }
    edges{
      node{
        id
        responseDate
        survey{
          id
          reportAvailable
          name
          multisourceResponse
        }
        user{
          id
          employee{
            id
          }
        }
      }
    }  
  }
}
`