import React from 'react';
import { dasboardIcons } from '../dashboard-icons';
import { Spin, Button } from 'antd'
import { Capitalize, displayUtcToUserTimezone, getIntFromString } from 'modules/look';
import Growpagination from 'modules/grow-model/components/growPagination';

const Survey360ResponseView = (props) => {
    const { token_loading, survey_360_list, token_page_info, getTokenList,selected_360,setSelected360,SetRemainderData} = props
    const [show_sureveyList, setShowSurveyList] = React.useState(true)

 
    const changeEmployeeList = (value) => {
        let lists = [...selected_360]

        if (lists?.includes(value)) {
            let index = lists?.indexOf(value)
            lists.splice(index, 1)
            setSelected360(lists)
        }
        else {
            lists.push(value)
            setSelected360(lists)
        }

    }
    const onCancel = () => {
        setSelected360([])
    }
    const displayBooleanStatus = (bool) => {
        if (!bool) {
            return (
                <div className="s-i-complete-button{">
                    Accepted
                </div>
            );
        } else
            return (
                <div className='s-i-pending-button'>
                    Pending
                </div>
            );
    };

    const onSubmit = () => {
        let data = survey_360_list?.filter(i => selected_360?.includes(i?.id))
        let data_set = data?.map(i => {
            return {
                id: getIntFromString(i?.survey?.id),
                usr: getIntFromString(i?.user?.id),
                tlink:i?.link||""
            }
        })
        SetRemainderData(data_set)
    }

    return (
        <div className='user-dashboard-okr-performance-container'>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em" }}>
                <h4 className='user-board-section-heading'> 360 response</h4>
                <img src={dasboardIcons?.down_arrow} style={{ width: "1.25em", transform: show_sureveyList ? "rotate(0deg)" : "rotate(-90deg)", cursor: "pointer", transition: ".5s" }} alt="" onClick={() => setShowSurveyList(!show_sureveyList)} />
            </div>
            {show_sureveyList && <div className='dashboard-engagement-overflow'>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: "1em", padding: ".5em" }}>
                    <div style={{ width: "33%" }}>
                        <h4 className='survey-360-table-ttile' style={{ textAlign: "left" }}>Employee Name</h4>
                    </div>
                    <div style={{ width: "33%" }}>
                        <h4 className='survey-360-table-ttile'>Survey</h4>
                    </div>
                    <div style={{ width: "33%" }}>
                        <h4 className='survey-360-table-ttile'>Response </h4>
                    </div>

                </div>
                <Spin spinning={token_loading}>
                    <div style={{ display: 'flex', flexDirection: "column", gap: '1em' }}>
                        {
                            survey_360_list?.length > 0 ? survey_360_list?.map(item => (
                                <div className='survey-360-table-data-container'>
                                    <div style={{ width: "33%", display: 'flex', flexDirection: "row", justifyContent: 'start', alignItems: "center", gap: "1em" }}>
                                        {item.valid ?
                                            <div>
                                                {selected_360?.includes(item?.id) ?
                                                    <img src={dasboardIcons?.check_box} style={{ width: "30px", cursor: "pointer" }} alt="check_box" onClick={() => changeEmployeeList(item?.id)} /> :
                                                    <div className="dashboard-check-box" onClick={() => changeEmployeeList(item?.id)}></div>
                                                }
                                            </div> :
                                            ''
                                            // <div className="dashboard-check-box" style={{ visibility: "hidden" }}></div>
                                            }
                                        <div style={{ display: "flex", flexDirection: "column", gap: ".25em", justifyContent: "start" }}>
                                            <h4 className='survey-360-table-ttile' style={{ textAlign: "left" }}>{Capitalize(item?.user?.firstName)} {Capitalize(item?.user?.lastName)}</h4>
                                            <p className='survey-360-table-data-title' style={{ color: '#AABAC6',textAlign: "left" }}>Respondent is my : {item?.rstype}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: "33%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <h4 className='survey-360-table-data-title' style={{ textAlign: "center" }}>
                                            {/* {displayUtcToUserTimezone(item?.dateCreated, "ddd MMM DD YYYY, hh:mm")} */}
                                            {item?.survey?.name}
                                        </h4>
                                    </div>
                                    <div style={{ width: "33%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {item.valid ? displayBooleanStatus(item.valid) : <h4 className='survey-360-table-data-title' style={{ fontWeight: "500", color: "#4CCB1F" }}>{displayUtcToUserTimezone(item?.survey_response?.responseDate, "ddd MMM DD YYYY, hh:mm")}</h4>}
                                    </div>
                                </div>
                            )) :
                                (!token_loading && <div style={{ width: "100%", paddingTop: "7em" }}>
                                    <h4 className='no-data'>No 360 Responses Found</h4>
                                </div>)
                        }
                        {(token_page_info?.hasNextPage && token_page_info?.cursor) && <div align='center'>
                            <Growpagination on_pagination={() => { getTokenList({ cursor: token_page_info?.cursor }) }} />
                        </div>}
                    </div>
                </Spin>
            </div>}
            {show_sureveyList && selected_360?.length > 0 && <div style={{ width: "100%", marginBottom: "15px", display: 'flex', flexDirection: "row", justifyContent: 'flex-end', alignItems: "center", gap: '1em' }}>
                <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', color: "#8998A2", border: '1px solid #8998A2', width: "130px" }} onClick={() => onCancel()} >
                    CLEAR
                </Button>
                <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', width: "160px" }} onClick={() => onSubmit()}>
                    SEND REMINDER
                </Button>
            </div>}
        </div>
    )
}
export default Survey360ResponseView