
import { Button, Col, Modal, Row, Select, Spin } from "antd";
import { getIntFromString } from "modules/layout/Layout";
import React from "react";
import '../css/BarsHome.css';
import '../css/DefineBars.css';
import { DEFINE_BARS } from "../Icon";
import bars_route from "../route";
import BarsDeleteConfirmation from "./BarDeleteConfirmation";



const BarsDefineView = (props) => {
  const { competencyCategories, CompetenyCaetgoriesLoading,navigateRoute } = props

  const [dropDownCategories, setDropDownCategories] = React.useState(undefined);
  const [selectedCompeteny, setSelectedCompetency] = React.useState(undefined);
  const [completeBars, setCompleteBars] = React.useState(false)
  const [invalidInput, setInvalidInput] = React.useState(false)

  React.useEffect(() => {

    if (competencyCategories) {

      let dropdownItems = competencyCategories?.map((category) =>
      // Add children to competency category
      {
        return {
          label: category?.title,
          key: getIntFromString(category?.id),
          children: category?.competencySet?.edges?.map(({ node }) => { return { label: node?.title, key: getIntFromString(node?.id) } })
        }
      }
      )

      setDropDownCategories(dropdownItems);
    }

       window.scrollTo(0,0);
    

  }, [competencyCategories])

  const handleSelectedCometency = () => {
    if (selectedCompeteny) {
      navigateRoute(bars_route?.bars_create.replace(":competency_id",parseInt(selectedCompeteny)))
  
    } else {
      setInvalidInput(true);
    }
  }

  const { Option } = Select;

  const filterOption = (inputValue, option) => {
    return option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  }

  return (

    <Spin spinning={CompetenyCaetgoriesLoading}>

      <div className="define-bars-root poppins">

        <img src={DEFINE_BARS.new_back_arrow} onClick={(e) => navigateRoute(bars_route?.bars_home)} className="define-bar-back-arrow pointer" alt=""/>

        <Row align="middle" justify="center">
          <div className="define-bars-header">Define <span>BARS</span></div>
        </Row>


        {/* Dropdown for showing competencies */}
        <Col span={20} className='define-bars-drop-down-container'>
          <div className="define-bars-competency-header">Competency</div>

          {
            dropDownCategories &&
            <Select
              className="define-bars-selector"
              showSearch
              placeholder="Select competency"

              style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
              dropdownClassName="content-dropdown"
              value={selectedCompeteny}
              filterOption={filterOption}
              onSelect={e => setSelectedCompetency(e)}
              {...props}
            >
              {dropDownCategories?.map(value => (
                <Option

                  className='define-bars-option'
                  key={value?.key}
                  value={value?.id}
                >
                  {value?.label}
                </Option>
              ))}
            </Select>
          }


          {
            invalidInput &&
            <p className="poppins" style={{color:'red',fontSize:'0.8em',marginTop:'0.8em'}}>Please select a value</p>
          }




        </Col>

        <Row justify="center">
          <Button className="bars-submit-action" onClick={(e) => handleSelectedCometency()}>Submit</Button>
        </Row>

        {/* pop up for showing message for completing main competency test */}

        <Modal
          visible={completeBars}
          footer={null}
          centered
          destroyOnClose={true}
          closable={false}>

          <BarsDeleteConfirmation
            message={"Define the competency before you go to the sub-competencies."}
            confirm_action_label={"Got it"}
            onConfirm={(e) => setCompleteBars(false)}
            confirm_class="bars-confimation-action">

          </BarsDeleteConfirmation>

        </Modal>


      </div>

    </Spin>



  );
};

export default BarsDefineView;

