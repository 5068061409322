// @ts-nocheck
import { Col, Tooltip,Button,Form, Spin,message,DatePicker, Modal , Tabs} from "antd";
import React from "react";
import InfoCircle from '../../assets/InfoCircle.svg'
import objective_expansion_arrow from '../../assets/objective_expansion_arrow.svg'
import OkrKeyResultTableContainer from "../container/KeyResultTableContainer";
import close from '../../assets/close.svg'
import RangeDatePicker from "modules/look-v2/Components/date-range-picker";
import { changeToCurrentTime, displayUtcToUserTimezone, getIntFromString } from "modules/look";
import collabrator_disable from '../../assets/collabrator_disable.svg'
import gear_disabled from '../../assets/gear_disabled.svg'
import moment from 'moment-timezone';
import TableRowMenu from "modules/look-v2/Components/TableRowMenu";
import delete_outline_black from '../../assets/delete_outline_black.svg'
import black_discard from '../../assets/black_discard.svg'
import view from '../../assets/view.svg'
import edit_pencil from "../../assets/edit_pencil.svg";
import okr_route from '../route/index'
import RequestDiscardView from "./requestDiscardView";
import DeleteOkrView from "./deleteOkrView";
import CommaSeparateComponent from "../formComponents/CommaSeparatedComponent";
import black_eye from "../../assets/black_eye.svg";
import blue_eye from "../../assets/blue_eye.svg";
import MineFilter from "./MineTabFilter";
import AddWeightContainer from "../formComponents/Container/AddWeightContainer";
import CustomProgress from "modules/look-v2/Components/Progress";
import grey_expansion from '../../assets/grey_expansion.svg'
import blue_expansion from '../../assets/blue_expansion.svg'
import ProgressUpdateContainer from "../formComponents/Container/progressUpdateContainer";
import ObjectiveTreeContainer from "../container/ObjectiveTreeContainer";
import menu_cascade from '../../assets/menu_cascade.svg'
import menu_del_info from '../../assets/menu_del_info.svg'
import TextInputField from "../formComponents/OkrInputFieldNew";
import CustomOkrRangeTable from "modules/look-v2/Components/customOkrDatePickerForTable";
import CustomOkrRangeForFilter from "modules/look-v2/Components/CustomOkrRangePickerForFilter";
import kodos_award from '../../assets/kodos-award-icon.svg'
import AwardBadgeContainer from "modules/kudos-user/container/awardBadgeContainer";
import ReceivedBadgeMenu from "modules/look-v2/Components/receivedBadgeMenu";
import { ai_trigger_content } from "modules/ai-tips/ai-trigger-content";


const OKRTableView = ( props ) => {
  const {me,createOkr,allobjective,loader,setloader,paginated_objective, setKeyResultDiscardState, updateOkr,navigateRoute,filterOkr,pageInfo,permission,SetAITrigger}=props
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [addOKR, setaddOKR] = React.useState(false)
  const [expandedRow, setexpandedRow] = React.useState([])
  const [dataSource, setDataSource] = React.useState([]);
  const [currentPage, setcurrentPage] = React.useState(1)
  const [pageloading, setpageloading] = React.useState(false)
  const [discardPopupvisible, setDiscardPopupVisible] = React.useState(false);
  const [discardOKRDetail, setdiscardOKRDetail] = React.useState();
  const [deleteRecord, setDeleteRecord] = React.useState();
  const [sort, setSort] = React.useState('des_duedate');
  const [addedOkrCount,setAddedOkrCount] = React.useState(0);
  const [deletedOkrcount,setDeletedOkrCount] = React.useState(0);
  const [okr_tree, set_okr_tree] = React.useState();
  const [addAward,setAddAward]=React.useState(false)
  const [selectedKudos,setSelectedKudos]=React.useState()
  const [krListOpened, setKrListOpened] = React.useState(false)
  const [show_pop_over,setPopOver]=React.useState(false)
  let currentFilter = React.useRef({})

  let dateFilter = React.useRef({okr_Duedate_Range:[moment().startOf('year').utc().toISOString(),moment().endOf('year').utc().toISOString()]})

  const { RangePicker } = DatePicker;
  let cunterRef = React.useRef(0);
  React.useEffect(()=>{
    
    if(allobjective){
      
        let list = [].concat(allobjective?.from_search?[]:dataSource)
         
        // if(allobjective?.from_sub){
        //     setDataSource(sortDatasource(allobjective?.edges))
        // }
        // else if(allobjective?.from_pagination){
        //     setDataSource(sortDatasource([...list,...allobjective?.edges]))
        // }
        // else{      
        //     setDataSource(sortDatasource(allobjective?.edges))
        // }
        setDataSource(sortDatasource(allobjective?.edges))
        setpageloading(false)
    }
    
  },[allobjective])

  React.useEffect(()=>{

    

  },[[pageInfo]])

  const sortDatasource=(data)=>{
    
    let sortData = data?.sort((a,b)=>{
        let field = sort.split('_')
        console.log("field",field,data)
        return field[1]==='duedate'? field[0]==='asc'? new Date(a['duedate'])-new Date(b['duedate']):new Date(b['createdAt'])-new Date(a['createdAt'])
        :field[0]==='asc'? a[field[1]]-b[field[1]]:b[field[1]]-a[field[1]]
    }
    )
    return sortData
  }

  React.useEffect(()=>{
    if(dataSource){
        console.log("dataSource",dataSource)
        setloader(false)
    }
  },[dataSource])

  const headers=[
    {
        title:"objective",
        info_icon:true,
        info_content:"Objectives are your long term goals. What is something that you want to achieve by end of 3 months/6 months/ 1 year? ",
        width:'320px'    
    },
    {
        title:"Due Date",
        info_icon:true,
        info_content:"Due Date is the time line that you are setting for your objectives/goals. By when do you want to complete it? ", 
        width:'280px'     
    },
    {
        title:"Owner(s)",
        info_icon:true,
        info_content:"Owner(s) allows you to assign the task to somebody else or add collaborators for your tasks. ",   
        width:'220px'   
    },
   
    {
        title:"Progress",
        info_icon:true,
        info_content:"Here you'll be able to see the progress that you and your team are making for accomplishing the task.Have you finished a task? Enter your progress here.",
        width:'250px'    
    }
  ]

  const expand=(id)=>{
    let list = [].concat(expandedRow)
    if(!list?.includes(id)){
        // list.push(id)
        list = [id]
    }else{
        list = list.filter(item=>item!==id)
    }
    console.log('expand the kr list')
    setKeyResultDiscardState(false)
    setKrListOpened(!krListOpened)
    setexpandedRow(list)
  }

  const pagination=()=>{
    // new to do change okr pagination number to config 
    let current_page=currentPage
    currentFilter.current['offset']=current_page*10+addedOkrCount+deletedOkrcount
    setcurrentPage(current_page+1)
    setpageloading(true)
    paginated_objective({...currentFilter?.current,from_pagination:true})
  }

  const onFinish=async(value)=>{
   
   try{
    if(value[`goalStatement_${addOKR}`] && value[`goalStatement_${addOKR}`]?.length<=150 && value[`duedate_${addOKR}`]?.length===2 ){
        let due_date = changeToCurrentTime(value[`duedate_${addOKR}`][1])
        let okrData ={
            goalStatement:value[`goalStatement_${addOKR}`],
            duedate:due_date?.toISOString(),
            startdate:value[`duedate_${addOKR}`][0].toISOString()
        }
        const response = await createOkr({okrData})

        if(response && response?.id){
            setaddOKR(null)
            setAddedOkrCount(addedOkrCount+1)
        }
    }
   }
   catch(err){

   }
   
  }


 
  const onUpdate =async(okr)=>{
    let formValue=updateForm.getFieldsValue()
    let data={}
    if(formValue[`${okr?.id}_goalStatement`]){
        data['goalStatement']=formValue[`${okr?.id}_goalStatement`]
    }
    if(data['goalStatement'] && data['goalStatement']?.length>150){
        return
    }

    if(formValue[`${okr?.id}_duedate`]?.length===2){
        let dates =formValue[`${okr?.id}_duedate`]
        data['startdate']=dates[0].toISOString()
        let due_date = changeToCurrentTime(dates[1])
        data['duedate']=due_date?.toISOString()
    }

    if(!Object.keys(data)?.length){
        message.error('update the form')
    }else{
        data['id']=okr?.id
        let response = await updateOkr(data)
        if(response?.id){
            let list = [].concat(dataSource)
            let item = list?.find(val=>val?.id===okr?.id)
            if(item){
                item['duedate']=response?.duedate
                item['startdate']=response?.startdate
                item['title']=response?.title
                item['goalStatement']=response?.goalStatement
                item.isUpdate=false
            }
            setDataSource(list)
        }
    }
  }

  const updateQue=(record,isUpdate)=>{
    let list = [].concat(dataSource)
    let item = list?.find(okr=>okr?.id===record?.id)
    item['isUpdate']=isUpdate
    setDataSource(list)
  }

  const onTableMenuClickAction = (record, action) => {
   
    if (action === 'view') {
        navigateRoute(okr_route?.viewObjective?.replace(':okrId',getIntFromString(record?.id)))
    } else if (action === 'edit') {
        updateQue(record,true)
    } else if (action === 'discard') {
      setdiscardOKRDetail(record);
      setDiscardPopupVisible(true);
    } else if (action === 'delete') {
      setDeleteRecord(record);
    } else if (action === 'tree') {
        set_okr_tree(record);
    }else if(action==='kodos'){
        setAddAward(true)
        setSelectedKudos(record)
    }
  }

  const updateDiscardRow=(record)=>{
    setDiscardPopupVisible(false);
    setdiscardOKRDetail(null)
  }

  const updateDelete=(id)=>{
    setDeleteRecord(null)
    setDeletedOkrCount(deletedOkrcount-1)
  }

  const filterData = [
    {type:'Objective',key:'Objective'},
    {type:'Collaborator',key:'Collaborator'}
  ]


  const SubmitFilter =(e)=>{
    currentFilter['current']=e
    setcurrentPage(1)
    e['offset']=0
    filterOkr({...e,...dateFilter['current'],from_search:true})
  }

  const datefilter=(e)=>{
    if(e && e?.length===2){
        
        let okr_Duedate_Range = [moment(e[0]).startOf('day').utc().toISOString(),moment(e[1]).endOf('day').utc().toISOString()]
        dateFilter['current']={okr_Duedate_Range}
        setcurrentPage(1)
        currentFilter.current['offset']=0
        filterOkr({...dateFilter['current'],...currentFilter['current'],from_search:true})
        // localStorage.setItem('DateFilterOKR',JSON.stringify({startDate:okr_Duedate_Range[0],endDate:okr_Duedate_Range[1]}))
    }else{
        dateFilter['current']={}
        setcurrentPage(1)
        currentFilter.current['offset']=0
        filterOkr({...dateFilter['current'],...currentFilter['current'],from_search:true})
    }
  }


  const updateChildsetforObj =(detail)=>{
    let list = [].concat(dataSource)
    let okr = list?.find(item=>item?.id===detail?.okrId)
    if(okr){
      okr.childSet=detail.childSet
    }

    setDataSource(list)
  }

  const addForm=()=>{
    if(addOKR){
        return
    }
 
    cunterRef["current"] = cunterRef["current"] + 1;
    setaddOKR(cunterRef["current"])
  }


  const getDefultDate=()=>{
    let cachedDate =JSON.parse(localStorage.getItem('DateFilterOKR'))
      let date =cachedDate?[moment(cachedDate?.startDate).startOf('day'),moment(cachedDate?.endDate).endOf('day')]
      :[moment().startOf('year'),moment().endOf('year')]
      let okr_Duedate_Range=
      cachedDate?
      [
        cachedDate?.startDate,cachedDate?.endDate
      ]:
      [
        moment().startOf('year').utc().toISOString(),
        moment().endOf('year').utc().toISOString()
      ]
     
      
      dateFilter['current']={okr_Duedate_Range}
      return date
  }

  const update_progress=(okrid,progress)=>{
    let data_source_progress = [].concat(dataSource)
    let okr = data_source_progress.find(item=>item?.id===okrid)
    
    if(okr){
        okr.progress=progress
    }
    setDataSource(data_source_progress)
  }
  const [visible, setVisible] = React.useState(false);

  // Function to show the modal
  const showModal = () => {
    setVisible(true);
  };

  // Function to close the modal
  const handleCancel = () => {
    setVisible(false);
  };
  const { TabPane } = Tabs;


  return (
    <>
            {okr_tree && (<ObjectiveTreeContainer okr={okr_tree} onClose={()=>{set_okr_tree(null)}} {...props}/>)}
            <DeleteOkrView visible={deleteRecord} type="Objective" deletesuccess={(e)=>{updateDelete(e)}} closeModal={()=>{setDeleteRecord(null)}} okrId={deleteRecord?.id} {...props}/>
            <RequestDiscardView visible={discardPopupvisible} {...props} discardData={discardOKRDetail} onClose={()=>{setDiscardPopupVisible(false);setdiscardOKRDetail(null)}} discardedSuccess={(e)=>{updateDiscardRow(e)}} />
                <div align="middle" className="okr-row-wrapper" >
                    <div style={{marginTop:"20px",marginBottom:"20px", display:'flex', flexDirection:'row', gap:'15px'}}>
                        {/* <AddButton label="Add OKR"  onAdd={(e)=>{addForm()}} /> */}
                        <Button className="add-btn" style={{height:"38px"}} onClick={()=>addForm()}>ADD OKR</Button>
                        <div style={{alignSelf:'center'}}>
                            <span onClick={showModal} style={{ cursor: 'pointer', color: 'blue' ,fontSize:'16px',fontWeight:'500'}}>
                                Demo
                            </span>


                            <Modal
                                title="OKR MODULE GUIDE"
                                visible={visible}
                                onCancel={handleCancel}
                                footer={null}
                                closable={true}
                                width={800}
                            >
                                <Tabs defaultActiveKey="1">
                                <TabPane tab="Add OKR" key="1">
                                <img src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1737544546/MApp/UI/Videos/Learning%20Paths/OKR/Add_OKR_a9x0t9.gif" alt="OKR VID" style={{width:"100%"}}/>
                            </TabPane>

                            <TabPane tab="Add key results" key="2">
                            <img src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1737544546/MApp/UI/Videos/Learning%20Paths/OKR/key_results_wzryes.gif" alt="KR VID" style={{width:"100%"}}/>
                            </TabPane>

                            <TabPane tab="Add Milestones" key="3">
                            <img src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1737544547/MApp/UI/Videos/Learning%20Paths/OKR/add_Milestone_hvsyaw.gif" alt="MILESTONE VID" style={{width:"100%"}}/>
                            </TabPane>
                            </Tabs>
                        </Modal>

                            </div>
                    </div>
                    <div className="row-wrap" >
                        <div className="custom-okr-picker">
                            <CustomOkrRangeForFilter  selectedDateRange={(e)=>datefilter(e)} currentSelected={false}/>
                        </div>
                        <div className="okr-picker">
                            <RangePicker
                                // defaultValue={getDefultDate()}
                                format={'DD/MM/YY'}
                                style={{width:"200px"}}
                                onChange={(e)=>{datefilter(e)}}
                                allowClear={false}
                            />
                        </div>
                    <div className="okrType-filter">
                    <MineFilter  
                        me={me}
                        onfilterChange={(e) => SubmitFilter(e)}
                        filterData={filterData}/> 
                    </div>
                   
                    </div>
                            
                </div>
                <Spin spinning={loader}>
                    <div className="overflow-div">
                        <Col className="custom-table-v2">
                            <div className="custom-table-tr" style={{borderLeft:"5px solid transparent"}}>
                                {
                                    headers?.map(item=>(
                                        <div className="table-header-section" style={{width:item?.width||'150px',minWidth:item?.width||'150px'}}>
                                            {item?.title}
                                            {
                                                item?.info_icon && (
                                                    <Tooltip title={item?.info_content}>
                                                        <img src={InfoCircle} alt="" style={{cursor:"pointer"}} />
                                                    </Tooltip>
                                                )
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="custom-table-body">
                        {
                            addOKR && (
                                <Form
                                form={form}
                                name="Objective"
                                onFinish={onFinish}
                                autoComplete="off"
                                initialValues={{}}
                                
                            >
                                    <div className="custom-table-tr add-okr-table table-body-tr-border" style={{borderLeft:"5px solid #4CCB1F"}}>
                                                
                                        <div className="table-section-td" style={{width:"320px",minWidth:"320px"}}>
                                            <div style={{width:"10px",height:"10px"}}></div>
                                            <TextInputField
                                            name={`goalStatement_${addOKR}`}
                                            show_pop_over = {show_pop_over}
                                            setPopOver = {setPopOver}
                                            placeholder="I want that to achieve ..."
                                            rules={[{ required: true, message: 'title required' }]}
                                            />
                                        </div>
                    
                                        <div className="table-section-td center" style={{width:"280px",minWidth:"280px"}}>
                                            <div className="custom-okr-picker">
                                                <CustomOkrRangeTable  rules={[{ required: true, message: 'dates required' }]} name={`duedate_${addOKR}`}
                                                selectedDateRange={(e)=>form.setFieldsValue({
                                                    [`duedate_${addOKR}`]: e,
                                                    })} currentSelected={false}
                                                    disabledType={true}/>
                                            </div>
                                            <div className="okr-picker">
                                                <RangeDatePicker
                                                dropdownClassName = {'custom-date-small'}
                                                name={`duedate_${addOKR}`}
                                                dateFormat="DD/MM/YY"
                                                rules={[{ required: true, message: 'dates required' }]}
                                                />
                                            </div>
                                        </div>
                    
                                        <div className="table-section-td center" align='center' style={{width:"220px",minWidth:"220px"}}>
                                            <div className="row text-transform-capitalize" style={{color:"#D9D9D9",width:'55%',margin:'auto'}}>
                                            {me?.firstName}
                                            <img src={collabrator_disable} alt="" />
                                            </div>
                                            
                                        </div>
                                        
                                        <div className="table-section-td" style={{width:"250px",minWidth:"250px"}}>
                                        <img src={gear_disabled} alt="" style={{cursor:"pointer"}} />
                                        <div style={{width:"100%"}} align="center"><Button disabled={show_pop_over} className="table-save-btn" htmlType="submit">Save</Button></div>
                                        <img src={close} alt="" style={{cursor:"pointer",marginRight:"10px"}} onClick={(e)=>{setaddOKR(null)}} />
                                        </div>
                                    </div>
                                </Form>
                            )
                        }
                                {
                                    <Form
                                    form={updateForm}
                                    name="Objective_update"
                                    autoComplete="off"
                                    >
                                    {dataSource?.map(item=>(
                                    <Col>
                                        <div className="custom-table-tr table-body-tr-border" style={{
                                        borderLeft:(item?.isDiscarded?.status==='APPROVED')?"5px solid #141414":
                                        (item?.isDiscarded?.status==='PENDING')?"5px solid #009AF1":"5px solid #4CCB1F",
                                        position:(item?.isDiscarded?.status==='APPROVED'||item?.isDiscarded?.status==='PENDING')?"relative":""}}>
                                            {
                                                (item?.isDiscarded?.status==='APPROVED')?
                                                <div className="table-overlay">
                                                {/* <img src={item?.isDiscarded?.status==='APPROVED'?black_eye:blue_eye} onClick={()=>{onTableMenuClickAction(item,'view')}} className="table-eye" alt="" style={{marginLeft:"330px",cursor:"pointer"}}  /> */}
                                                </div>:''
                                            }
                                             {/* Badge icon section */}
                                             {item?.badge_award?.length > 0 && permission?.can_award_badge &&  <ReceivedBadgeMenu menuIcon={item?.badge_award[0]?.node?.badge?.image} optionData ={item?.badge_award}
                                                margin={'330px'}
                                                />}
                                         
                                            <div className="table-section-td" style={{width:"320px",minWidth:"320px"}}>
                                            <img src={ item?.isDiscarded?.status==='PENDING'?
                                                blue_expansion:
                                                item?.isDiscarded?.status==='APPROVED'?
                                                grey_expansion:
                                                objective_expansion_arrow} alt="" style={{zIndex:"1"}} onClick={(e)=>{expand(item?.id)}} className={`expansion-arrow ${expandedRow?.includes(item?.id)?'expansion-arrow-down':''}`} />
                                                {
                                                    item?.isUpdate?
                                                    <TextInputField
                                                    show_pop_over = {show_pop_over}
                                                    setPopOver = {setPopOver}
                                                    name={`${item?.id}_goalStatement`}
                                                    placeholder="I want that to achieve ..." 
                                                    defaultValue={item?.goalStatement}   
                                                    />:
                                                <Tooltip title={`${item?.goalStatement} by ${displayUtcToUserTimezone(item?.duedate,'DD MMM YY')}`}>                            
                                                <span onClick={(e) => onTableMenuClickAction(item, 'view')} style={{cursor:"pointer",zIndex:"1"}}>{`${item?.goalStatement} by ${displayUtcToUserTimezone(item?.duedate,'DD MMM YY')}`}</span>                              
                                                </Tooltip>
                                                }
                                                
                                                
                                            
                                            </div>

                                            <div className="table-section-td center" style={{width:"280px",minWidth:"280px"}}>
                                            
                                            {
                                                item?.isUpdate?<div>
                                                    <div className="custom-okr-picker">
                                                        <CustomOkrRangeTable  rules={[{ required: true, message: 'dates required' }]} name={`${item?.id}_duedate`}
                                                        defaultValue={[moment(item?.startdate),moment(item?.duedate)]}
                                                        selectedDateRange={(e)=>updateForm.setFieldsValue({
                                                            [`${item?.id}_duedate`]: e,
                                                            })} currentSelected={false}
                                                            disabledType={true}/>
                                                    </div>
                                                    <div className="okr-picker">
                                                        <RangeDatePicker
                                                        dropdownClassName = {'custom-date-small'}
                                                        name={`${item?.id}_duedate`}
                                                        dateFormat="DD/MM/YY"
                                                        defaultValue={[moment(item?.startdate),moment(item?.duedate)]}
                                                        />
                                                    </div>
                                                </div>
                                               :
                                                displayUtcToUserTimezone(item?.duedate,'DD MMM YYYY' )
                                            }
                                            
                                            
                                            </div>

                                            <div className="table-section-td center" style={{width:"220px",minWidth:"220px"}}>
                                            <CommaSeparateComponent showCount={1} textList={item?.contributors?.map(data => { return { id: data?.user?.id, firstName: data?.user?.firstName, lastName: data?.user?.lastName,role:data?.role } })} />

                                            </div>
                                            
                                            <div className="table-section-td" style={{width:"250px",minWidth:"250px"}}>
                                            {item?.childSet&&(<AddWeightContainer okrDedline={item} {...props} okrId={item?.id}
                                            tooltipTitle = {"When you have multiple child key Results, by default the weightage will be equally divided amongst all of them. You can adjust the weight according to the value that you would like to assign for each child key Results"}
                                            />)}
                                            
                                            {!item?.childSet && (
                                                <img src={gear_disabled} alt=""  />
                                                // <ProgressUpdateContainer editable={ moment(item?.duedate)?.endOf('day')?.isAfter(moment())} update_progress={(okrid,progress)=>update_progress(okrid,progress)} okr={item} {...props} />
                                                )}
                                            {
                                                item?.isUpdate?
                                                <div style={{width:"100%"}} align="center"><Button className="table-save-btn" onClick={(e)=>{onUpdate(item)}}>Update</Button></div>
                                                : <CustomProgress showTooltip={true} progress={item?.progress}/>
                                            }

                                            {
                                                item?.isUpdate?
                                                <img src={close} alt="" onClick={()=>{updateQue(item,false)}} style={{cursor:"pointer",marginRight:"10px"}} />
                                                :
                                              
                                                <TableRowMenu options={[{ label: 'view', key: 'view', icon: view, postLabelIcon: {}, disabled: false },
                                                { label: 'Cascade', key: 'tree', icon: menu_cascade, postLabelIcon: {}, disabled: false },
                                                { label: 'Edit', key: 'edit', icon: edit_pencil, postLabelIcon: 'icon', disabled: (moment(item?.correctionDeadline).isBefore(moment()) || item?.role!=='OWNER'|| moment(item?.duedate).isBefore(moment()))},
                                                { label: 'Kudos', key: 'kodos', icon: kodos_award, postLabelIcon: 'icon', disabled: (permission?.can_award_badge && permission?.okr_permission && item?.contributors?.length >1)?false:true},
                                                { label: 'Discard', key: 'discard', icon: black_discard, postLabelIcon: 'icon',post_icon:(item?.discardRequestRaised && item?.isDiscarded?.status!=='PENDING')?menu_del_info:'', tooltip:(item?.discardRequestRaised && item?.isDiscarded?.status!=='PENDING')?"You have already raised the discard request":'', disabled: (item?.discardRequestRaised || item?.isDiscarded?.status==='PENDING'?true:false) },
                                                { label: 'Delete', key: 'delete', icon: delete_outline_black, postLabelIcon: 'icon', post_icon:menu_del_info, tooltip:"okr will be deleted permenetly", disabled: (item?.discardRequestRaised?item?.discardRequestRaised:(moment(item?.correctionDeadline).isBefore(moment()) ||moment(item?.duedate).isBefore(moment()) || item?.role!=='OWNER')) },
                                                
                                                ]}
                                                onClick={(e) => onTableMenuClickAction(item, e)}
                                                />
                                            }
                
                                            </div>
                                        </div>
                                        {
                                        expandedRow?.includes(item?.id) && (
                                            
                                            <OkrKeyResultTableContainer krListOpened={krListOpened} set_okr_tree={set_okr_tree} isDiscarded={item?.isDiscarded?.status} updateChildsetobj={(e)=>{updateChildsetforObj(e)}} objectiveDetail={item} {...props} />
                                            
                                        ) 
                                        }
                                    </Col>
                                    ))}
                                    </Form>
                                }
                            </div>
                        </Col>
                    </div>
                </Spin>
                {
                    dataSource?.length?
                    !pageloading?<div align="center">
                    <h4 className="pagination-label">{pageInfo?.nextPage?<span onClick={(e)=>{pagination()}} style={{cursor:"pointer",color:"#B0CB1F"}}><u>View more</u></span>:<span>Viewing</span>} ({dataSource?.length}/{pageInfo?.totalCount})</h4>
                    </div>:<div align="center"><Spin spinning={true}></Spin></div>
                    :''
                }
                {
                    addAward&&<AwardBadgeContainer {...props} visible={addAward} onClose={(e)=>setAddAward(e)} type={'OKR'} selectedItem={selectedKudos}/>
                }
    </>
  );
};

export default OKRTableView