import { Button, Col, Divider, Empty, Form, Modal, Row, Spin } from 'antd';
import React from 'react';
import { compose } from '../../core';
import { CKEditorField, DeleteIcon, EditIcon, getIntFromString, TextField, withMarkdownFieldValue } from '../../look';
import ConditionOperator from '../helpers/helpers';
import AddCondition from './AddCondition';
import { withAddTextSection, withDeleteCondition, withEditTextSection } from './TextSectionOperations';


export const CKEditorFieldName = ['textSectionData', 'text'];

const TextSectionComponent = props => {
  const { btn = 'edit', textSection, editTextSection, createTextSection, deleteCondition, onSubmit, sequence } = props;
  const [visible, setVisible] = React.useState(false);
  const [actionLoading, setActionLoading] = React.useState(false);
  const [data, setData] = React.useState(textSection?.condition?.edges);

  const handleDelete = async id => {
    setActionLoading(true);
    try {
      const response = await deleteCondition({id:id});
      response && setData(data?.filter(({ node }) => getIntFromString(node.id) !== id));
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw Error(err.message);
    }
  };

  const handleEditTextSection = async condition => {
    setActionLoading(true);
    try {
      if (!textSection.id) {
        setData(data ? [...data, { node: condition }] : [{ node: condition }]);
      } else {
        const response = await editTextSection({
          id: getIntFromString(textSection.id),
          condition: [...data?.map(({ node }) => getIntFromString(node.id)), getIntFromString(condition.id)],
          sequence: textSection.sequence
        });
        return response;
      }
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  };

  // const handleLiveUpdate = (conditionId) => {

  // }

  const handleSubmit = async values => {
    setActionLoading(true);
    try {
      if (textSection.id) {
        await editTextSection({
          id: getIntFromString(textSection.id),
          ...withMarkdownFieldValue(CKEditorFieldName, values).textSectionData,
          condition: data?.map(({ node }) => getIntFromString(node.id)),
          sequence: textSection.sequence
        });
      } else {
        const response = await createTextSection({
          condition: data?.map(({ node }) => getIntFromString(node.id)),
          textSectionData: {
            ...withMarkdownFieldValue(CKEditorFieldName, values).textSectionData,
            sequence: textSection?.sequence || sequence || 1
          }
        });
        response && setData(response?.condition?.edges);
        response && onSubmit(getIntFromString(response?.id));
        response && setVisible(false);
      }
      setActionLoading(false);
    } catch (err) {
      setActionLoading(false);
      throw new Error(err.message);
    }
  };
  const initialValue = {
    textSectionData: {
      title: textSection?.title === 'Add' ? '' : textSection?.title,
      text: textSection?.text,
      sequence: textSection?.sequence || sequence || 1
    }
  };

  return (
    <>
      {btn === 'add' ? (
        <Button block type={'primary'} onClick={() => setVisible(true)}>
          Add Condition
        </Button>
      ) : (
        // <Button
        //   type={'primary'}
        //   // htmlType={type}
        //   ghost={true}
        //   size={'small'}
        //   icon={<EyeOutlined />}
        //   shape="circle"
        //   onClick={() => setVisible(true)}
        // />
        <EditIcon size={'sm'} tooltipSuffix="Text Section" onClick={() => setVisible(true)} />
      )}
      <Modal
        destroyOnClose
        centered
        visible={/* true || */ visible || window.location.href.includes('openmodal')}
        onCancel={() => setVisible(false)}
        // footer={null}
        width="50%"
        title={textSection.title}
        footer={null}
      >
        <Spin spinning={actionLoading} size="medium">
          <Form layout="vertical" onFinish={handleSubmit} initialValues={initialValue} scrollToFirstError={true}>
            {true && (
              <TextField
                name={['textSectionData', 'title']}
                label="Name"
                rules={[{ required: true, message: 'Title is required!', whitespace: true }]}
              />
            )}

            <CKEditorField
              name={CKEditorFieldName}
              label="Text"
              initialValue={initialValue && initialValue.textSectionData.text}
              style={{ display: !true && 'none' }}
            />

            {/* Do not delete this */}
            {data?.map(({ node }) => (
              <>
                <Row justify="space-between" align="middle">
                  <Col span={7} align="left">
                    <span>{node?.leftOperand?.weightedQuestions?.edges[0]?.node?.question?.questionText}</span>
                  </Col>
                  <Col span={6} align="center">
                    {ConditionOperator?.filter(c => c.value === node?.operator)[0]?.label}
                  </Col>
                  <Col span={7} align="left">
                    <span>{node?.rightOperand?.note || 'Note not found'}</span>
                  </Col>
                  <Col span={4} align="right">
                    <AddCondition
                      btn="edit"
                      condition={node}
                      onSubmit={handleEditTextSection}
                      btnText={'Add new condition'}
                    />
                    <Divider type="vertical" />
                    <DeleteIcon
                      type="link"
                      tooltipSuffix="Condition"
                      size="sm"
                      onClick={() => handleDelete(getIntFromString(node.id))}
                    />
                  </Col>
                </Row>
                <Divider />
              </>
            ))}
            {!data && (
              <div align="center">
                <Empty />
                <br />
              </div>
            )}
            <br />
            <Row justify="space-between">
              <Col>
                <AddCondition btn="add" onSubmit={handleEditTextSection} btnText={'Add new condition'} />
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">
                  Done
                </Button>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default compose(withDeleteCondition, withEditTextSection, withAddTextSection)(TextSectionComponent);
