import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";

const usePrompt = (
    defaultMessage = "Are you sure you want to leave?",
    defaultIsDirty = false
) => {
    const [isDirty, setIsDirty] = useState(defaultIsDirty);
    const [message, setMessage] = useState(defaultMessage);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isDirty) {
                event.preventDefault();
                event.returnValue = message;
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [isDirty, message]);

    return {
        isDirty,
        setIsDirty,
        message,
        setMessage,
        PromptComponent: <Prompt when={isDirty} message={message} />,
    };
};

export default usePrompt;