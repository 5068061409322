// @ts-nocheck
import { Button, Col, Form, Input, Popover, Row, Select, Spin } from "antd";
import { getIntFromString } from "modules/look";
import React, { useState } from "react";
import Slider from 'react-slick';
import modal_close from '../../assets/badge-modal-close.svg';
import drop_black from '../../assets/drop-arrow-black.svg';
import left_arrow from '../../assets/kudos-left-arrow-icon.svg';
import right_arrow from '../../assets/kudos-right-arrow-icon.svg';
import search_icon from '../../assets/search-icon.svg';
import close_icon from '../../assets/dashboard-kudos-close-icon.svg';
import "../../kudos/kudos.css";
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg';

const GiveKudosView = (props) => {
    const { onSearch, badgeList, loading, setGivenKudos, setGiveKudosPopUpVisible, setFeedbackOperationsModalVisible, setFeedbackEditModalVisible
        , feedbackById, setBadgeAwardId, setNewKudosAdded, getbadgeFailyList, onFilter, getBageFamilyById, badge_family_list, setSelectedFamily, getBadgesByFamily,onCancel } = props

    const [search_toggle, setSearchToggle] = React.useState(false)
    const [filter_value, setFilterValue] = React.useState()
    const [selectedBadge, setSelectedBadge] = React.useState()
    const Option = Select.Option;
    const [form] = Form.useForm();



    const badgeSelectHandler = (node) => {
        setSelectedBadge(node)
        setNewKudosAdded(true)
    }

    const filterSearch = (value) => {
        if (value) {
            setSelectedBadge(null)
            getbadgeFailyList({ title_Icontains: value })
        }
        else {
            getbadgeFailyList({ title_Icontains: "" })
        }

    }

    const handleFamilyFilter = (e) => {

        setFilterValue(e)
        setSelectedBadge(null)
        setSelectedFamily(e)
        getBadgesByFamily({ badgeFamily: e })
        getBageFamilyById({ id: e })
        filterSearch(null)
    }


    const settings = {
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        arrows: true,
        nextArrow: <img src={right_arrow} />,
        prevArrow: <img src={left_arrow} />,
        slidesToShow: 4,
        slidesToScroll: 4,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1050,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 310,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const filter_list = badge_family_list &&
        badge_family_list?.map(({ node }, key) => (
            <Option
                key={key}
                value={node?.id}
            >
                {node?.title}
            </Option>
        ))

    const handleBadgeSearch = (event) => {
        if (event?.target?.value) {
            onSearch({ title_Icontains: event?.target?.value, behaviouralDescription_Icontains: event?.target?.value, badgeFamily: filter_value })
        }
        else {
            onSearch({ title_Icontains: "", behaviouralDescription_Icontains: "", badgeFamily: filter_value })
        }
    }


    const TooltipData = (node) => {
        return (
            <div style={{ width: "100%", maxWidth: "400px", minWidth: "180px" }}>
                <h4 style={{ fontSize: '1.2em', fontWeight: "500", color: "rgba(0, 0, 0, 0.71)", margin: "0" }}>{node?.title}</h4>
                <p style={{ color: "#8C8CA2", fontSize: "1em", margin: "0", textAlign: 'justify' }}>{node?.behaviouralDescription}</p>
            </div>
        )
    }

    const handleSubmit = async (value) => {

        let badgeAwardData = {
            badgeId: getIntFromString(selectedBadge?.id),
            evidence: selectedBadge?.title,
            image: selectedBadge?.image,
            title: selectedBadge?.title,
            behaviouralDescription: selectedBadge?.behaviouralDescription
        }
        let response = setGivenKudos(badgeAwardData)
        if (response) {
            setSelectedBadge()
        }
        if (badgeAwardData) {
            handleModalClosure()
            setBadgeAwardId(feedbackById?.badgeAward?.edges[0]?.node?.id)
        }
    }

    const handleModalClosure = () => {
        setGiveKudosPopUpVisible(false)
        if (feedbackById) {
            setFeedbackEditModalVisible(true)
        } else {
            setFeedbackOperationsModalVisible(true)
        }
        setSelectedBadge(undefined)
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: "center", gap: "1em", cursor: "pointer", padding: '.5em 1em', marginBottom: "1em" }}>
                <h4 className="user-board-section-heading">Give Kudos</h4>
                <img src={close_icon} alt="" style={{ cursor: "pointer" }} onClick={() => handleModalClosure()} />
            </div>
            <div className="kudos-form-group" style={{ marginTop: "1em" }}>
                <h4 className="kudos-card-user-name"><span style={{ color: " rgba(249, 95, 83, 1)", marginTop: "1em" }}>*</span>Kudos Family</h4>
                <div style={{ display: "flex", flexDirection: "column", border: "1px solid rgba(170, 186, 198, 1)", borderRadius: '8px', width: "100%", padding: "2px 0px", marginTop: "1em" }}>
                    <div className="dashboard-user-badge-award-popup-input-div" style={{ display: 'flex', flexDirection: "row", width: "100%" }}>
                        <Select
                            showSearch
                            className="dasboard-filter-select-user-select"
                            name={'filter'}
                            id={'filter'}
                            placeholder={`Select`}
                            suffixIcon={<img src={dropdown_Arrow} alt="" style={{ width: "10px", height: "10px", cursor: "pointer" }} />}
                            style={{ width: '100%', maxWidth: "15em", textOverflow: 'ellipsis', whiteSpace: "nowrap" }}
                            {...props}
                            onSelect={handleFamilyFilter}
                            onSearch={filterSearch}
                            allowClear
                            onClear={() => { onFilter() }}
                            filterOption={(input, option) => option}
                            disabled={false}
                        >
                            {/* {otherOption ? (choices?.length > 0 ? [...choice, otherField] : [otherField]) : choice} */}

                            {filter_list}

                        </Select>
                        <div className="dashboard-search-input-user-kudos" style={{ display: 'flex', flexDirection: 'row', width: "100%", border: "none", paddingRight: "10px", borderLeft: "1px solid rgba(170, 186, 198, 1)", marginLeft: "10px" }}>
                            <img src={search_icon} alt="" style={{ cursor: 'pointer', transform: " rotate(-270deg)", height: '20px', marginTop: "10px", marginRight: '7px', marginLeft: '7px' }} />
                            <Input className="kudos-search-imput-popup" placeholder="Search kudos name here" autoComplete='off' style={{ width: "100%", border: "none !important" }} onChange={(e) => handleBadgeSearch(e)}></Input>
                        </div>
                    </div>
                </div>
                <div className="slider-container new-kudos-badge-award-carosul-container">
                    <Spin spinning={loading}>
                        {loading && <div style={{ width: "100%", minWidth: "42em" }}></div>}
                        {badgeList?.length > 0 && !selectedBadge && <Slider {...settings} style={{ width: "100%", maxWidth: "42em" }}>
                            {badgeList?.length && badgeList?.map(({ node }, index) => (
                                <div key={index} className={`dashboard-user-award-badge-image-selected-container ${node?.id === selectedBadge?.id ? "dashboard-user-award-badge-image-selected-container-selected" : ""}`}
                                    onClick={() => badgeSelectHandler(node)} >
                                    <div style={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: "center", flexDirection: "column" }}>
                                        <img className="dashboard-user-award-badge-image-selected" src={node?.image} alt={`Slide ${index}`} />
                                    </div>
                                    <h4 className="dashboard-user-award-badge-image-selected-title">{node?.title}</h4>
                                    <div key={index} className="dashboard-user-award-badge-image-selected-detail dashboard-show-kudos-detail-card" style={{ textOverflow: "ellipsis", textAlign: "center" }}>
                                        <h4 style={{margin:"0px",textAlign:"left"}}>{node?.title}</h4>
                                        <p style={{margin:"0px"}}>{node?.behaviouralDescription}</p>
                                    </div>
                                </div>
                            ))}
                        </Slider>}
                        {selectedBadge && <div className="dashboard-selected-kudos-card" style={{ position: "relative", width: "100%", border: "none" }}>
                            <img src={close_icon} alt="" style={{ cursor: "pointer", position: "absolute", right: "10px", top: '10px' }} onClick={() => badgeSelectHandler()} />
                            <div>
                                <img style={{ height: "em", width: "6em" }} src={selectedBadge?.image} alt="" />
                            </div>
                            <div style={{ borderleft: "1px soild #AABAC633", marginLeft: ".5em", paddingLeft: "1em" }}>
                                <h4 style={{ fontFamily: "Poppins", fontSize: "1.2em", fontWeight: "600", color: "#4CCB1F",paddingLeft:"1em" }}>{selectedBadge?.title}</h4>
                                <p className="dashboard-details-elipsis" style={{ fontFamily: "Poppins", fontSize: ".85em", fontWeight: "400", color: "#303030" }}>{selectedBadge?.behaviouralDescription}</p>
                            </div>
                        </div>}
                        {badgeList?.length == 0 && loading == false &&
                            <div style={{ minWidth: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <h4 className="recived-award-image-title" style={{ margin: 'auto', justifyContent: 'center', textAlign: "center" }}>No Kudos Found</h4>
                            </div>
                        }
                    </Spin>
                </div>
                <div style={{ width: "100%", marginBottom: "15px", display: 'flex', flexDirection: "row", justifyContent: 'center', alignItems: "center", gap: '1em', marginTop: "2em" }}>
                    <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', width: "100px" }}
                        disabled={!selectedBadge} onClick={() => handleSubmit()}>
                        Save
                    </Button>
                    <Button className="user-board-welcome-banner-button" style={{ fontSize: "1.1em", marginTop: '.75em', color: "#8998A2", border: '1px solid #8998A2', width: "100px", background: "#fff" }} onClick={() => handleModalClosure()}>
                        Cancel
                    </Button>
                </div>
            </div>

        </>
    )
}

export default GiveKudosView

