// @ts-nocheck
import React from "react";
import { compose } from "../../core";
import '../css/JobFamily.css'
import JobFamilyMainView from "../components/JobFamilyMainView";

const JobFamilyContainer = (props) => {

    const { back_to_home,SetbackToHome } = props
    const [current_jobfamily_tab, SetJobFamilyTab] = React.useState()

    // to set the route to local storage when child route is changed
    React.useEffect(() => {
        if (current_jobfamily_tab) {
            const cached_navigation = JSON.parse( localStorage?.getItem( 'role_definition_navigation' ) )
            let role_definition_navigation = cached_navigation ? 
                {   
                ...cached_navigation, 
                parent_route: { key: 'job_family', label: 'Job Family' }, 
                child_route: current_jobfamily_tab
                }
                : {
                    parent_route: { key: 'job_family', label: 'Job Family' },
                    child_route: current_jobfamily_tab
                }

            localStorage.setItem('role_definition_navigation', JSON.stringify(role_definition_navigation))
        }
    }, [current_jobfamily_tab])

    // to handle child route when user navigate back to role definition
    const jobfamily_navigation = JSON.parse(localStorage?.getItem('role_definition_navigation'))
    if (!current_jobfamily_tab) {
        if (jobfamily_navigation?.child_route?.key?.includes('jobfamily_')) {
            SetJobFamilyTab(jobfamily_navigation?.child_route)
        } else {
            SetJobFamilyTab({ key: "jobfamily_home_view" })
        }
    }

    React.useEffect(()=>{
        if(back_to_home==='jobfamily_home_view'){
            let nav ={
                parent_route: { key: 'job_family', label: 'Job Family' },
                child_route: { key: "jobfamily_home_view" }
            }
            localStorage.setItem('role_definition_navigation', JSON.stringify(nav))
            SetJobFamilyTab({ key: "jobfamily_home_view" })
            SetbackToHome(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[back_to_home])

    return (
        <JobFamilyMainView
            {...props}
            current_jobfamily_tab={current_jobfamily_tab}
            SetJobFamilyTab={(e) => { SetJobFamilyTab(e) }}
        />
    )

}

export default compose()(JobFamilyContainer);