import React from "react"
import casper_icon from "../../assets/casper_icon.svg"
import tips_icon from "../../assets/ai-tips-icon_new.svg"
import { ReactComponent as Close } from "../../assets/close-icon-without-bg.svg"
import "../css/ai-global-button-view.css"
const AIGlobalButtonView = (props) => {
    const { Casper, AiTips, draggable, alt, module, me } = props;
    const [placement, setPlacement] = React.useState("bottom-right")
    const [tipsExpanded, setTipsExpanded] = React.useState(false)
    const [casperExpanded, setCasperExpanded] = React.useState(false)
    const [infoPopupVisible, setInfoPopupVisible] = React.useState(false);

    const handleDragStart = (e) => {
        const img = new Image();
        img.src = '';
        e.dataTransfer.setDragImage(img, 0, 0);
    };
    const handleDragEnd = (e) => {
        const container = e.target.parentNode.parentNode.getBoundingClientRect();
        const leftPos = e.clientX - container.left;

        if (leftPos < container.width / 3) {
            setPlacement('bottom-left');
        } else if (leftPos < (2 * container.width) / 3) {
            setPlacement('bottom-center');
        } else {
            setPlacement('bottom-right');
        }
    }

    React.useEffect(() => {
        const users = localStorage.getItem("casper-first-time-users")?.split("_-_") || []; // Getting all users who using application on this machine
        const modules = localStorage.getItem("casper-integrated-modules")?.split("_-_") || []; // Getting all modules for which we have already shown the tip
        if (Casper && (!(users.length && users.includes(me?.id) && modules.length && modules.includes(module)))) {
            setInfoPopupVisible(true);
            setTimeout(() => { setInfoPopupVisible(false) }, 5000);
            if (!users.includes(me?.id)) {
                users.push(me?.id)
                localStorage.setItem("casper-first-time-users", users.join("_-_"))
            }
            if (!modules.includes(module)) {
                modules.push(module)
                localStorage.setItem("casper-integrated-modules", modules.join("_-_"))
            }
        }
    }, [])

    const handleMouseOver = () => {
        const allUsersWhoVisitedCasper = localStorage.getItem("users-visited-casper")?.split("_-_") || []
        if (allUsersWhoVisitedCasper.length) {
            !allUsersWhoVisitedCasper.includes(me.id) && setInfoPopupVisible(true)
        } else {
            setInfoPopupVisible(true)
        }
    }

    React.useEffect(() => {
        if ((tipsExpanded || casperExpanded) && infoPopupVisible) {
            setInfoPopupVisible(false)
        }
    }, [tipsExpanded, casperExpanded])

    return (
        <>
            {Casper && <Casper tipsExpanded={tipsExpanded} casperExpanded={casperExpanded} placement={placement} setCasperExpanded={setCasperExpanded} {...props} />}
            {AiTips && <AiTips casper={Casper && true} tipsExpanded={tipsExpanded} casperExpanded={casperExpanded} globalPlacement={placement} {...props} />}
            {Casper && <div
                className={`ai-global-button-container ${placement} ${!Casper || !AiTips ? 'half-width' : ""}`}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                draggable={draggable} >
                {<img
                    src={casper_icon}
                    className="global-buttons-icon"
                    alt={alt}
                    onMouseEnter={handleMouseOver}
                    onMouseLeave={() => { infoPopupVisible && setInfoPopupVisible(false) }}
                    onClick={() => {
                        if (casperExpanded) setCasperExpanded(false)
                        else {
                            setTipsExpanded(false);
                            setCasperExpanded(true);
                            const allUsersWhoVisitedCasper = localStorage.getItem("users-visited-casper")?.split("_-_") || []
                            if (!allUsersWhoVisitedCasper.length || !allUsersWhoVisitedCasper.includes(me.id)) {
                                allUsersWhoVisitedCasper.push(me.id)
                                localStorage.setItem("users-visited-casper", allUsersWhoVisitedCasper.join("_-_"))
                            }
                        }
                    }} />}
                {AiTips && <img
                    src={tips_icon}
                    className="global-buttons-icon"
                    alt={alt}
                    onClick={() => {
                        if (tipsExpanded) setTipsExpanded(false)
                        else {
                            setCasperExpanded(false);
                            setTipsExpanded(true);
                        }
                    }} />}
            </div>}
            <div className={`casper-info-popup-container ${infoPopupVisible ? "visible" : ""}`}>
                <div className="casper-popup">
                    <div className="header-container">
                        <div className="header" style={{ display: 'flex' }}>
                            <img className="image-icon" src={casper_icon} alt="" />
                            <div className="title">Casper</div>
                        </div>
                        <Close onClick={() => { setInfoPopupVisible(false) }} />
                    </div>
                    <div className="popup-content">
                        <div className="text-content">
                            Hello! I'm Casper, Your AI Coach.
                            <br />
                            Give effective feedback that ensures behavioural change with personalized guidance from me!
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AIGlobalButtonView;
