import gql from 'graphql-tag';
import { PROFILE_TYPE } from './ProfileType.gql';

export const EDIT_PROFILE = gql`
  ${PROFILE_TYPE}
  mutation editProfile(
    $address: String
    $district: String
    $education: String
    $dob: Date
    $gender: String
    $id: ID!
    $married: Boolean
    $phoneNumber: String
    $preferredLanguage: String
    $residenceDuringCollegeUniversity: String
    $residenceDuringMajorPartOfCareer: String
    $residenceDuringSecondarySchool: String
    $village: String
    $profileImage: String
    $timeZone: String
  ) {
    updateProfile(
      address: $address
      district: $district
      education: $education
      dob: $dob
      gender: $gender
      id: $id
      married: $married
      phoneNumber: $phoneNumber
      preferredLanguage: $preferredLanguage
      residenceDuringCollegeUniversity: $residenceDuringCollegeUniversity
      residenceDuringMajorPartOfCareer: $residenceDuringMajorPartOfCareer
      residenceDuringSecondarySchool: $residenceDuringSecondarySchool
      village: $village
      profileImage: $profileImage
      timeZone:$timeZone
    ) {
      profile {
        ...ProfileType
      }
    }
  }
`;
