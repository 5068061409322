// DO NOT EDIT THIS FILE

import gql from 'graphql-tag';
export const COMPARISON_REQ_SENT = gql`
query allComparisonRequestFormUser($survey:ID,$sender:ID,$endCursor:String) {
    allComparisonRequestFormUser(survey: $survey, sender: $sender,first:10,after:$endCursor) {
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
        edges{
          node{
            id
            message
            requestDate
            accepted
            sharedResponse{
              id
            }
            survey{
              id
              name
            }
            sender{
              id
              firstName
              lastName
              email
              profile{
                id
                profileImage
              }
            }
            receiver{
              id
              firstName
              lastName
              email
              profile{
                id
                profileImage
              }
            }
          }
        }
    }
  }
`;
