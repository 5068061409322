// @ts-nocheck
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Layout, Select, Row, Button, Col, Form, Modal, Spin, message } from 'antd';
import { getIntFromString, SelectFieldSoul } from '../../look';
import { useHistory } from 'react-router-dom';
import homepageContent from '../../page/containers/homePageContent'
import { KNOWLEDGE_PART_1, PERSONALITY_PART_1, STRENGTH_PART_1, diversity_survey_id } from "../../../config";
import Back_icon from '../../assets/soul-back.svg'
import '../css/editSurveyResponse.css'
import SoulSurveySuccessPopup from './SoulSurveySuccessPopup';
import diversity_survey_route from 'modules/diversity-survey/route';
import mapp_learning_path_routes from 'modules/learning-path/routes';
const EditSurveyResponseQuestionBasedNew = (props) => {

    const { Header, Content, Footer, Sider, } = Layout;
    const { Option } = Select;
    const {
        loading,
        qa,
        allOpts,
        editResponse,
        responseId,
        sId,
        sname,
        createResponse,
        location,
        SurveyById,
        soul_survey_completion,
        app_sidebar_expand,
        navigateRoute
    } = props;
    const [submitLoading, setSubmitLoading] = React.useState(false);
    const [updatedAns, setUpdatedAns] = React.useState({});
    const [success_popup, SetSuccessPopup] = React.useState(false)
    const history = useHistory();
    const [form] = Form.useForm();
    const [disabled_choice, Setdisabled_choice] = React.useState([])
    var timer = 0

    React.useEffect(() => {
        if (qa) {

            let answer = {}
            let list = qa?.filter(i => i?.a) || []
            list.forEach(i => {
                answer[getIntFromString(i?.id)] = { value: i?.a }
            })
            setUpdatedAns(answer)
        }
    }, [qa])

    React.useEffect(() => {
        if (updatedAns) {
            let list = Object.values(updatedAns)?.map(i => i?.value?.toLowerCase())
            Setdisabled_choice([].concat(list))
        }
    }, [updatedAns])

    useEffect(() => {
        let timerInterVal = setInterval(() => {
            timer = timer + 1
        }, 1000);
        return () => {
            clearInterval(timerInterVal)
            timer = 0
        }
    }, [loading])


    const goBack = () => {
        if (location?.search) {
            let data = location?.search
            let id = data.split('=')
            if (id[1]) {
                history.push('/soul/values')
            }
        }
        else {
            history.goBack()
        }
    }

    const handleSubmit = async () => {
        setSubmitLoading(true);

        if (responseId) {
            let ansToSend = []
            Object.entries(updatedAns).forEach(item => {
                ansToSend.push({ 'questionId': item[0], answer: item[1]?.value, 'responseDuration': timer })
            })
            ansToSend = ansToSend?.filter(i => i?.questionId && i?.answer)

            let data_list = qa?.map(i => {
                let id = getIntFromString(i?.id)
                let update = ansToSend?.find(j => j?.questionId == id)
                return {
                    questionId: id,
                    answer: update ? update?.answer : i?.a
                }
            })
            data_list = data_list?.filter(i => i?.answer)
            let check_unique = [...new Set(data_list?.map(i => i?.answer))]

            let survey = {
                [PERSONALITY_PART_1]: 'personality',
                [STRENGTH_PART_1]: 'strengths',
                [KNOWLEDGE_PART_1]: 'knowledge and skills',
            }

            if (data_list?.length < 5) {
                message.destroy()
                message.error(`Please fill in all the ${survey[getIntFromString(SurveyById?.id)]}`)
                setSubmitLoading(false);
                return
            }
            if (check_unique?.length < 5) {
                message.destroy()
                message.error(`Each ${survey[getIntFromString(SurveyById?.id)]} must be unique`)
                setSubmitLoading(false);
                return
            }
            try {
                await editResponse({ answers: ansToSend, responseId: getIntFromString(responseId) });
                let param = props.match.params.surveyType;
                if (param) {
                    let data
                    if (param === 'personality') {
                        data = homepageContent['personalities']
                    }
                    else if (param === 'knowledge-and-skills') {
                        data = homepageContent['knowledge_and_skills']
                    }
                    else {
                        data = homepageContent[param]
                    }
                    if (data) {
                        history.goBack()
                        
                    }
                }


            } catch (e) {
                setSubmitLoading(false);
                throw new Error(e.message);
            }

        } else {
            let ansToSend = []
            Object.entries(updatedAns).forEach(item => {
                if (item[1]?.value !== undefined) {
                    ansToSend.push({ 'questionId': item[0], answer: item[1]?.value })
                }
            })
            ansToSend = ansToSend?.filter(i => i?.questionId && i?.answer)
            let data_list = qa?.map(i => {
                let id = getIntFromString(i?.id)
                let update = ansToSend?.find(j => j?.questionId == id)
                return {
                    questionId: id,
                    answer: update ? update?.answer : i?.a
                }
            })
            data_list = data_list?.filter(i => i?.answer)
            let check_unique = [...new Set(data_list?.map(i => i?.answer))]
            let survey = {
                [PERSONALITY_PART_1]: 'personality',
                [STRENGTH_PART_1]: 'strengths',
                [KNOWLEDGE_PART_1]: 'knowledge and skills',
            }

            if (data_list?.length < 5) {
                message.destroy()
                message.error(`Please fill in all the ${survey[getIntFromString(SurveyById?.id)]}`)
                setSubmitLoading(false);
                return
            }

            if (check_unique?.length < 5) {
                message.destroy()
                message.error(`Each ${survey[getIntFromString(SurveyById?.id)]} must be unique`)
                setSubmitLoading(false);
                return
            }

            try {
                await createResponse({ answers: ansToSend, surveyId: getIntFromString(sId), totalDuration: timer });
                let param = props.match.params.surveyType;
                if (param) {
                    let data
                    if (param === 'personality') {
                        data = homepageContent['personalities']
                    }
                    else if (param === 'knowledge-and-skills') {
                        data = homepageContent['knowledge_and_skills']
                    }
                    else {
                        data = homepageContent[param]
                    }
                    if (data) {
                        let status =localStorage.getItem('to_leraning')
                        if(status === 'strengths'||'personality'||'knowledge_skills'){
                            localStorage.setItem('to_leraning', `${status}_completed`)
                            navigateRoute(mapp_learning_path_routes?.soul_learning_path)
                        }
                        else{
                            if (soul_survey_completion && param == 'strengths' && soul_survey_completion?.Personality?.totalCount === 0) {
                                SetSuccessPopup(true)
                            }
                            else if (soul_survey_completion && param == 'personality' && soul_survey_completion?.knowledge?.totalCount === 0) {
                                SetSuccessPopup(true)
                            }
                            else if (soul_survey_completion && param == 'knowledge-and-skills') {
                                SetSuccessPopup(true)
                            }
                            else {
                                history.goBack()
                            }
                        }
                    }
                }
            } catch (e) {
                setSubmitLoading(false);
                throw new Error(e.message);
            }
        }

        setSubmitLoading(false);
    };

    function onSelectChange(id, k) {
        let qid = getIntFromString(id)
        setUpdatedAns(prevInfo => ({ ...prevInfo, [qid]: { 'value': k } }))
    }

    function onInputChange(id, e) {
        if (e.target.value) {
            let qid = getIntFromString(id)
            setUpdatedAns(prevInfo => ({ ...prevInfo, [qid]: { 'value': e.target.value } }))
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const hasIntegers = (value) => {
        return /\d/.test(value);
    };

    const hasSpecialCharacters = (value) => {
        return /[!@#$%^&*().?":{}|<>]/.test(value);
    };

    const hasBlankSpacesOnly = (value) => {
        return /^\s+$/.test(value);
    };

    const validateNoIntegers = (rule, value) => {
        if (value && hasIntegers(value)) {
            return Promise.reject('Integers are not allowed');
        }
        return Promise.resolve();
    };

    const validateNoSpecialCharacters = (rule, value) => {
        if (value && hasSpecialCharacters(value)) {
            return Promise.reject('Special characters are not allowed');
        }
        return Promise.resolve();
    };

    const validateNoBlankSpaces = (rule, value) => {
        if (value && hasBlankSpacesOnly(value)) {
            return Promise.reject('Blank spaces are not allowed');
        }
        return Promise.resolve();
    };

    const goToNextStep = () => {
        let param = props.match.params.surveyType;
        SetSuccessPopup(false)
        if (param == 'strengths') {
            history.push('/soul/personalities')
        }
        else if (param == 'personality') {
            history.push('/soul/knowledge_and_skills')
        }
        else if (param == 'knowledge-and-skills') {
            if (!soul_survey_completion?.diversity?.totalCount) {
                history.push(diversity_survey_route?.diversityHome?.replace(":id", diversity_survey_id))
            } else {
                history.push('/soul/impact-narrative')
            }
        } else {
            history.goBack()
        }
    }

    const getMessage = () => {
        let param = props.match.params.surveyType;
        if (param == 'strengths') {
            return 'Do you want to go to personality'
        }
        else if (param == 'personality') {
            return 'Do you want to go to knowledge and skills'
        }
        else if (param == 'knowledge-and-skills') {
            if (!soul_survey_completion?.diversity?.totalCount) {
                return 'Do you like to take diversity profile survey'
            } else {
                return 'Do you want to go to Impact Narrative'
            }

        }
    }

    return (
        <>
            <Modal
                visible={success_popup}
                footer={false}
                closable={false}
                destroyOnClose={true}
                centered={true}
                style={{ maxWidth: "45em" }}
                className='idp-modal'
                width={"90%"}
            >
                <SoulSurveySuccessPopup
                    message={getMessage()}
                    main_warning={`You have successfully submitted your response.`}
                    onCancel={() => { { SetSuccessPopup(false); history.goBack() } }}
                    onConfirm={() => { goToNextStep() }}
                />
            </Modal>

            <Spin spinning={submitLoading} size="small">

                <Row justify="center" className='main-container-row main-page-card'>

                    <Col span={24} style={{ position: "relative" }}>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <img className="back-icon-survey back-arrow-global" src={Back_icon} alt="" onClick={() => goBack()} />
                            <Row justify='center' align='center' style={{ width: "100%" }}>
                                {props.match.params.surveyType === 'personality' ? <h2 className='soul-survey-edit-head'>My Personality Type</h2> : <h2 className='soul-survey-edit-head'>My <span style={{ color: "#4CCB1F" }}>Top 5</span> {props.match.params.surveyType === 'strengths' ? 'Strengths' : props.match.params.surveyType === 'knowledge-and-skills' ? 'Knowledge & Skills' : null}</h2>}
                            </Row>
                            <img className="back-icon-survey back-arrow-global" src={Back_icon} alt="" onClick={() => goBack()} style={{ visibility: 'hidden' }} />
                        </div>

                        <div className='survey-section-container' align='center' justify='center' style={{ display: "flex", flexDirection: 'column', width: '100%' }}>
                            <Form form={form} name="control-hooks" onFinish={handleSubmit} onFinishFailed={onFinishFailed}>
                                <Row style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '1vw', marginRight: '1vw' }}>

                                    {qa.map((q, idx) => {
                                        return (
                                            <>
                                                <Row align='center' justify='center' style={{ width: '100%', marginTop: '2vh', gap: "5px", flexWrap: "nowrap" }}>
                                                    <h4 className='survey-index' style={{ margin: 0 }}>#{idx + 1}</h4>
                                                    <SelectFieldSoul
                                                        className="survey-select-filed"
                                                        name={q.id}
                                                        label={""}
                                                        otherOption={getIntFromString(SurveyById?.id) == PERSONALITY_PART_1 ? false : true}
                                                        initialValue={q.a}
                                                        showSearch
                                                        rules={[
                                                            {
                                                                validator: validateNoIntegers,
                                                            },
                                                            {
                                                                validator: validateNoSpecialCharacters,
                                                            },
                                                            {
                                                                validator: validateNoBlankSpaces,
                                                            },
                                                            // Add more rules if needed
                                                        ]}
                                                        // onSearch={(input) => choiceFilter(input, allOpts[q.id])}
                                                        disabled_choice={getIntFromString(SurveyById?.id) == PERSONALITY_PART_1 ? [] : disabled_choice}
                                                        choices={allOpts[q.id]}
                                                        onBlur={(v) => { onInputChange(q.id, v) }}
                                                        optionFilterProp="children"
                                                        onChangeSelect={(v, k) => { onSelectChange(q.id, v) }}
                                                        filterOption={(input, option) =>
                                                            option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }

                                                    // updatedAns={updatedAns}
                                                    >
                                                    </SelectFieldSoul>
                                                </Row>
                                                {idx % 2 != 0 && (
                                                    <div style={{ flexBasis: '100%', height: '0' }}>
                                                    </div>
                                                )}
                                            </>
                                        )
                                    })

                                    }

                                </Row>

                                <div className={`one-on-one-bottom-div ${app_sidebar_expand ? 'survey-section-container-margin-left' : ''}`}
                                    style={{ padding: "0px", paddingBottom: "10px", paddingRight: "0px" }}>
                                    <div style={{ width: '100%' }} className={!app_sidebar_expand ? 'custom-alignment' : ''} align="center">
                                        <Button
                                            className='save-step-2-button'
                                            type="primary" htmlType="submit" style={{ marginTop: '1em' }}>Save & Proceed</Button>
                                    </div>

                                </div>
                            </Form>
                        </div>


                    </Col>
                </Row>
            </Spin>
        </>
    )
}
export default EditSurveyResponseQuestionBasedNew;