import { compose } from "modules/core";
import React from "react"
import { withApollo } from '@apollo/client/react/hoc';
import { POC_360_TOKEN_INVITE, POC_RESPONSE_FOR_TOKEN } from "modules/poc-dashboard/graphql/tokenQuery.gql";
import InvitationListViewPOC from "modules/poc-dashboard/components/poc_report/invitationListView";
import { getIntFromString } from "modules/look";


const InvitationListContainerPOC = (props) => {
    const { invitation_list_detail, client } = props
    const [invitation_list, SetInvitationList] = React.useState([])
    const [invitation_list_loader, SetInvitationListLoader] = React.useState(false)
    const [page_info, SetPageInfo] = React.useState()
    let invitation_list_ref = React.useRef([])
    React.useEffect(() => {
        invitation_list_ref.current = invitation_list
    }, [invitation_list])

    React.useEffect(() => {
        if (invitation_list_detail) {
            GetTokenResponses({id:invitation_list_detail?.response_id})
        }
    }, [invitation_list_detail])

    let token_response_list = React.useRef([])
    const GetTokenResponses = async (filterData) => {
        try {
            SetInvitationListLoader(true)
            const { data } = await client.query({
                query: POC_RESPONSE_FOR_TOKEN,
                variables: filterData,
                fetchPolicy: 'network-only'
            });
            if(data?.responseByResponseId){
                let multisource_response_set = data?.responseByResponseId?.multisourceResponseSet
                token_response_list.current = token_response_list.current.concat(multisource_response_set?.edges?.map(({node})=>node))
                if(multisource_response_set?.pageInfo?.hasNextPage){
                    GetTokenResponses({
                        ...filterData,
                        after:multisource_response_set?.pageInfo?.endCursor
                    })
                }else{
                    getSurveyTokenList({})
                }
            }
        
        } catch (error) {
            getSurveyTokenList({})
        }

    }

    const getSurveyTokenList = async (filterData) => {

        try {
            SetInvitationListLoader(true)
            const { data } = await client.query({
                query: POC_360_TOKEN_INVITE,
                variables: {
                    ...filterData,
                    orderBy: ["user__firstName"],
                    first: 10,
                    createdBy: invitation_list_detail?.user_id,
                    survey: invitation_list_detail?.survey?.id,
                    responseSourceFor: invitation_list_detail?.response_id
                },
                fetchPolicy: 'network-only'
            });
            if (data?.getAllTokensList) {
                SetPageInfo(
                    {
                        hasNextPage: data?.getAllTokensList?.pageInfo?.hasNextPage,
                        after: data?.getAllTokensList?.pageInfo?.endCursor
                    }
                )

                let list = data?.getAllTokensList?.edges?.map(({ node }) => {
                    let response = token_response_list.current?.find(i=>getIntFromString(i?.user?.id)===getIntFromString(node?.user?.id))
                    return {
                        ...node,
                        response_id:response?response?.id:null,
                        response_date:response?response?.responseDate:null,
                    }
                })
                if (filterData?.after) {
                    list = invitation_list_ref.current?.concat(list)
                } else {
                    list = [
                        {
                            id: invitation_list_detail?.user_id,
                            respondentType: "SELF",
                            responseSourceFor: { id: invitation_list_detail?.response_id },
                            user: {
                                id: invitation_list_detail?.user_id,
                                email: invitation_list_detail?.email,
                                firstName: invitation_list_detail?.firstName,
                                lastName: invitation_list_detail?.lastName, 
                            },
                            response_id:invitation_list_detail?.response_id,
                            response_date:invitation_list_detail?.responseDate,
                            valid: false,
                            __typename: "custom_added_data"
                        }
                    ].concat(list)
                }  
                SetInvitationList(list)
                SetInvitationListLoader(false)
            }
        } catch (error) {

        }

    }

    return (
        <InvitationListViewPOC {...props} invitation_list={invitation_list} invitation_list_loader={invitation_list_loader} getSurveyTokenList={getSurveyTokenList} page_info={page_info} />
    )
}

export default compose(withApollo)(InvitationListContainerPOC)