export const kudosBadgeColors ={
    dazzel:{
        key:"dazzel",
        backgroundColor:"rgba(46, 85, 107, 1)",
        buttonColor:"rgba(38, 119, 164, 0.61)",
    },
    workethics:{
        key:"work_ethics",
        backgroundColor:"rgba(168, 213, 239, 1)",
        buttonColor:"rgba(105, 181, 247, 1)",
    },
    execution:{
        key:"execution",
        backgroundColor:"rgba(240, 173, 168, 1)",
        buttonColor:"rgba(234, 154, 148, 1)",
    },
    goodcitizen:{
        key:"good_citizen",
        backgroundColor:"rgba(175, 226, 35, 0.63)",
        buttonColor:"rgba(2, 232, 140, 0.54)",
    },
    communication:{
        key:"communication",
        backgroundColor:"rgba(249, 68, 222, 0.63)",
        buttonColor:"rgba(237, 30, 143, 0.57)",
    },
    knowledge:{
        key:"knowledge",
        backgroundColor:"rgba(18, 117, 214, 1)",
        buttonColor:"rgba(112, 179, 245, 1)",
    },
    peopleperson:{
        key:"people_person",
        backgroundColor:"rgba(0, 0, 0, 0.48)",
        buttonColor:"rgba(69, 63, 44, 1)",
    }
}
    
export const smileysEmojis = [
    "😀", "😃", "😄", "😁", "😆", "😅", "😂", "🤣", "😊", "😇",
    "🙂", "🙃", "😉", "😌", "😍", "🥰", "😘", "😗", "😙", "😚",
    "😋", "😜", "😝", "😛", "🤑", "🤗", "🤭", "🤫", "🤔", "🤐",
    "🤨", "😐", "😑", "😶", "😏", "😒", "🙄", "😬", "🤥", "😌",
    "😔", "😪", "🤤", "😴", "😷", "🤒", "🤕", "🤢", "🤮", "🤧","😎",
    "🥵", "🥶", "😶‍🌫️", "😈", "👿", "💀", "☠️", "🤡", "👹", "👺",
    "💩", "👻", "👽", "🤖", "😺", "😸", "😹", "😻", "😼", "😽", "🙀", "😿", "😾",
     "👍", "🎉", "❤️", "🙏", "🔥", "💯"
  ];
  
