// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import arrayMove from 'array-move';
import { LeftOutlined,DownOutlined,UpOutlined,PlusOutlined } from "@ant-design/icons";
import { Row, Col, Button, Card, Divider,Modal,Spin,Tabs ,Dropdown,Menu,Input, Tooltip } from "antd";
import styled from "styled-components";
import { Capitalize, DatePickerField } from '../../look';
import search_icon from '../../assets/search-inverted.svg'
import dropdown_Arrow from '../../assets/dropdown_Arrow.svg'
import dropdown_arrow from '../../assets/one-on-one-dropdown-arrow.svg'
const EmployeeFilterForFeedbacks = props => {
   
    


    
    const {setActiveFilter,allMeetingMembers,setSelectedEmp,activeFilter} = props
    
    const [selectedFilter,setFilter]=React.useState()
    const [filterValue,setfilterValue]=React.useState()
  
    
    React.useEffect(()=>{

        if(activeFilter === "All"){
            setFilter(undefined)
        }

    },[activeFilter])
  

    const Filtermenu =()=> (
        <Menu  style={{maxHeight:'200px',overflow:'auto'}} suffixIcon={<img src={dropdown_Arrow} alt="" style={{width:"10px",height:"10px"}}/>}>
          {
            allMeetingMembers && (
                allMeetingMembers?.map((data)=>
                    <Menu.Item key={data?.id} onClick={()=>{setFilter(data);setSelectedEmp(data?.id);setfilterValue(null)}} >
                        {Capitalize(data?.user?.firstName)} {Capitalize(data?.user?.lastName)}
                    </Menu.Item>
                )
            )
          }
        </Menu>
      );

      
    return (
        <>
            <Row style={{flexWrap:"nowrap"}} onClick={(e)=>setActiveFilter('EmpDropDown')}>
                <Dropdown overlay={Filtermenu}>
                    <DropdownButton>
                        <div style={{width:'100%',overflow:'hidden',textOverflow:'ellipsis',textAlign:'left'}}>
                            {selectedFilter? `${<span className='text-transform-capitalize'>{selectedFilter?.user?.firstName}</span>}` + ' ' + `${<span className='text-transform-capitalize'>{selectedFilter?.user?.lastName}</span>}`:'Select One'}
                            </div>
                        <img src={dropdown_arrow} alt="" />
                    </DropdownButton>
                </Dropdown>
            </Row>
        </>
    )

}

export default  EmployeeFilterForFeedbacks;

const DropdownButton = styled(Button)`
width:100px;
display:flex;
height:36px;
justify-content:space-between;
align-items:center;
gap:5px
`