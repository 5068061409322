// @ts-nocheck
import React, { useState } from 'react';
import back_arrow from '../../assets/back_arrow.svg'
import { Link, useHistory } from "react-router-dom";
import detail_arrow from '../../assets/detail-back-button.svg'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, BarElement, Title } from 'chart.js';
import 'chart.js/auto'
// import '../../../style.css'
import moment from 'moment-timezone';
import { getIntFromString } from 'modules/layout/Layout';
import styled from 'styled-components';
import { ChartBox, ScrollableDoughnutChart } from 'modules/Objective-keyresult/components/IndividualDashboardView';
import { Doughnut } from 'react-chartjs-2';
import { Button, Col, Row } from 'antd';

const DoughntChartNonCascaded = (props) => {

    const { data, me, label, getNextSetOfNonCascadedOkrs, height, loading, topValue, rightValue  ,responsiveTopValue ,
        responsiverightValue, fontSize, responsiveFontSize,isIndividual,managerids  } = props

    const [edgesIterationCount, setEdgesIterationCount] = React.useState(0)
    const [okrCount, setOkrCount] = React.useState(0);
    const [chartData, setchartData] = React.useState();
    const options = {

        responsive: true,
        maintainAspectRatio: true,
        elements: {
            center: {
                text: '50%' 
            }
        },
        animation: {
            duration: 0
        },
        plugins: {
            legend: {
                display: false,
                title: {
                    display: false,
                    padding: 10,

                },
                labels: {
                    usePointStyle: true,
                    boxWidth: 10,
                    font: {
                        size: 14
                    }
                },
                position: 'bottom',


            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        var label = context.label,
                            currentValue = context.raw,
                            total = context.chart._metasets[context.datasetIndex].total;
                        var percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
                        return label!=='Pending'? [`${label}: (${percentage}%)`,`Count: ${currentValue}`]:
                        [`${label}: (${percentage}%)`,`Discard Request: ${chartData?.discard_req_pending}`,`Contribution request: ${chartData?.collab_req_pending}`]
                        
                    }
                }
            }
        },
    }

    React.useEffect(() => {

        if (edgesIterationCount > 0) {
            getNextSetOfNonCascadedOkrs(me?.employee?.id, edgesIterationCount)
        }

    }, [edgesIterationCount])


    React.useEffect(() => {
        if (data) {
            setchartData(null)
            if (data?.totalCount > 100) {
                setOkrCount(data?.edgeCount)
            }
            let chartdata={
                completed:0,
                missed:0,
                Inprogress:0,
                discarded:0,
                discard_req_pending:0,
                collab_req_pending:0
            }
            let currentDate = moment();
            let okrs= data?.edges
            // check for discarded okr
            let discarded = okrs?.filter(({node}) =>
                node?.okr?.isDiscarded
            ).map(({node})=>node?.id)
            chartdata['discarded']=discarded?.length
            okrs=okrs?.filter(({node})=>!discarded?.includes(node?.id)) 
            //pending
            // check for discarded request 
            let pendingDiscardRequest = okrs?.filter(({node}) =>
                node?.okr?.discardRequest?.edges?.length
            ).map(({node})=>node?.id)
            okrs=okrs?.filter(({node})=>!pendingDiscardRequest?.includes(node?.id)) 
           //check for pending assigne and collab request
           let pendingAssignCollReq 
           if(isIndividual){
                pendingAssignCollReq=okrs?.filter(({node})=>
                node?.okr?.contributorSet?.edges?.find(item=>item?.node?.employee?.id===me?.employee?.id &&item?.node?.requestStatus==="PENDING")
                ).map(({node})=>node?.id)
            }
           else{
            //  check for manager
            
            pendingAssignCollReq=okrs?.filter(({node})=>
            node?.okr?.contributorSet?.edges?.find(item=>managerids?.includes(item?.node?.employee?.id) &&item?.node?.requestStatus==="PENDING")
            ).map(({node})=>node?.id)
           }
           okrs=okrs?.filter(({node})=>!pendingAssignCollReq?.includes(node?.id))
       
           chartdata['discard_req_pending']=pendingDiscardRequest?.length
           chartdata['collab_req_pending']=pendingAssignCollReq?.length
           //checked missed okr
           let missedOkrs = okrs?.filter(({node}) =>
                (currentDate.isAfter(node?.okr?.duedate) && node?.progress < 100)
            ).map(({node})=>node?.id)
            okrs=okrs?.filter(({node})=>!missedOkrs?.includes(node?.id)) 
            chartdata['missed']=missedOkrs?.length

            //check Inprogress okr
            let Inprogress = okrs?.filter(({node}) =>
                (moment(node?.okr?.duedate).isAfter(currentDate) && node?.progress >= 0 && node?.progress < 100)
            ).map(({node})=>node?.id)
            okrs=okrs?.filter(({node})=>!Inprogress?.includes(node?.id)) 
            chartdata['Inprogress']=Inprogress?.length

            //check completed
            let completed = okrs?.filter(({node}) =>
                node?.progress === 100
            ).map(({node})=>node?.id)
            chartdata['completed']=completed?.length
           console.log(managerids,chartdata,'okrs non cascade',data?.edges);
           setchartData(chartdata)

        }


    }, [data])


    const dataDoughnutChart = {
        labels: ['Missed', 'In Progress', 'Completed', 'Discarded', 'Pending'],
        datasets: [
            {

                label: label,
                data: [chartData?.missed, chartData?.Inprogress, chartData?.completed, chartData?.discarded, chartData?.discard_req_pending+chartData?.collab_req_pending],
                backgroundColor: [
                    '#EF7878',
                    '#FAC53C',
                    '#9FEA84',
                    '#8A8A8A',
                    '#009AF1'
                ],
                hoverBackgroundColor: [
                    '#EF7878',
                    '#FAC53C',
                    '#9FEA84',
                    '#8A8A8A',
                    '#009AF1'
                ],
                borderWidth: 1,
            },
        ],
    }






    return (
        <>

        
                <Row justify='center' align='middle' style={{flexDirection:'column'}}>
             
                <DoughnutChartContainer height={height} topValue = {topValue} righValue = {rightValue}
                  responsiveTopValue = {responsiveTopValue} responsiveRightValue = {responsiverightValue}
                  fontSize = {fontSize} responsiveFontSize = {responsiveFontSize} >
                {(data?.edges?.length==0 && !loading)?
                <Row align='middle' justify='center' style={{
                    height: height ? height : '300px', width: '100%', maxWidth: height ? height : '300px',
                    background: "#FFFFFF"
                }}>
                    <div className="graph-title Manrope" style={{ textAlign: "center",fontWeight:'400' }}>No Non-Cascaded Data</div>
                </Row>:''
                }

                {
                    data?.edges?.length ?
                    <div style={{ position: 'relative' }}>
                    <Doughnut
                        options={options}
                        data={dataDoughnutChart}
                    />
                    {
                        data?.edges?.length > 0 &&
                        <Button className='Manrope okr-Count' >{data?.edges?.length} objectives</Button>
                    }
                </div>
                :''
                }

            </DoughnutChartContainer>
            
            

            <Row justify='center' style={{ margin: '20px auto 20px auto' }}>
                <LegendCol>
                    <Row align='middle' style={{ padding: '4px' }}>
                        <div className='round legend-missed'></div>
                        <div className=''>Missed</div>
                    </Row>
                    <Row align='middle' style={{ padding: '4px' }}>
                        <div className='round  legend-completed'></div>
                        <div className=''>Completed</div>
                    </Row>
                </LegendCol>
                <LegendCol >
                    <Row align='middle' style={{ padding: '4px' }}>
                        <div className='round legend-In-Progress'></div>
                        <div>
                            In Progress
                        </div>
                    </Row>
                    <Row align='middle' style={{ padding: '4px' }}>
                        <div className='round legend-discarded'></div>
                        <div className=''>Discarded</div>
                    </Row>
                </LegendCol>
                <Col style={{ height: '100%'}}>
                    <Row align='middle' style={{ padding: '5px' }}>
                        <div className='round legend-pending'></div>
                        <div className=''>Pending</div>
                    </Row>
                </Col>
            </Row>
            <div align='center'>
                {
                    data?.totalCount > 100 &&
                    <p>Showing {okrCount} of {data?.totalCount}
                        <span onClick={(e) => setEdgesIterationCount(edgesIterationCount + 1)}>
                            {
                                okrCount !== data?.totalCount &&
                                <p style={{ cursor: 'pointer', color: '#3B8855', textDecoration: 'underline' }}>
                                    Click here to load more...
                                </p>
                            }
                        </span>
                    </p>
                }
            </div>
            </Row>
        </>
    )
}
export default DoughntChartNonCascaded




export const DoughnutChartCanvas = styled(Doughnut)`
canvas{
    width:75% !important;
  
    margin:auto !important;
  }
`


export const DoughnutChartContainer = styled.div`
 background: #FFFFFF;
 display:flex;
 flex-direction:column;
 height: ${props => props.height || '300px'};
 width: 100%;
 max-width:${props => props.height || '300px'};
 position: relative;
 z-index:99;




 .okr-Count{
    position: absolute;
    z-index:-1;
    top: ${props => props.topValue || '135px'};
    right: ${props => props.righValue || '95px'};
    font-weight: 600;
    font-size:${props => props.fontSize || '14px'};
    box-shadow:0px 4px 18px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
 }

@media only screen and (max-width: 1400px) {
   
    height: ${props => props.height || '250px'};
    max-width:${props => props.height || '230px'};

    .okr-Count{
        font-size:${props => props.responsiveFontSize || '14px'};
        top:  ${props => props.responsiveTopValue || '100px'};
        right:${props => props.responsiveRightValue || '58px'};
      }
    
  }

  @media only screen and (min-width: 1401px) {
   
    height: 300px;
    max-width:300px;

    
  }
`;


export const LegendCol = styled(Col)`

  margin-right:20px;

  @media only screen and (max-width: 768px) {
    margin-right:0px;
  }

`;



