import React from 'react';
import Survey360ResponseView from '../components/360SurveyResponseListView';
import { SURVEY_360_TOKEN_LIST, SURVEY_RESPONSE_FOR_360 } from '../graphql/userDashboardQueries.gql';
import { getIntFromString } from 'modules/look';
import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { message } from 'antd';

const Survey360ResponseContainer = (props) => {

    const { me, client, user_permission } = props
    const [survey_360_list, setSurvey360List] = React.useState()
    const [token_loading, setTokenLoading] = React.useState(false)
    const [token_page_info, setTokenPageInfo] = React.useState()
    const [reminder_data, SetRemainderData] = React.useState()
    const [selected_360, setSelected360] = React.useState([])
    const filter_ref = React.useRef({})
    const token_list_ref = React.useRef([])

    const ALL_REMINDER = gql`
    query remindUserSurveyById {
      ${reminder_data?.map(
        (item, index) =>
            `query_${index}: remindUserSurveyById(id:${item?.id},usr:${item?.usr},tlink:"${item?.tlink}")`
    )
            .join('')}
    }
`;

    const [emailReminder] = useLazyQuery(ALL_REMINDER, { onCompleted: (data) => { onCompletedReminder(data) }, onError: (data) => { onErrorReminder(data) } });

    React.useEffect(() => {
        if (reminder_data?.length) {
            setTokenLoading(true)
            emailReminder()
        }
    }, [reminder_data])

    const onCompletedReminder = (data) => {
        message.success('Email reminders has been send')
        setSelected360([])
        setTokenLoading(false)
    }

    const onErrorReminder = (data) => {
        message.error('Failed to send email reminders')
        setTokenLoading(false)
    }

    React.useEffect(() => {
        if (me?.employee) {
            getSurveyTokenList({ createdBy: me?.employee?.user?.id, first: 5 })
        }
    }, [me])

    React.useEffect(() => {
        token_list_ref.current = survey_360_list
    }, [survey_360_list])

    const getSurveyTokenList = async (filter) => {
        filter_ref.current = { ...filter, cursor: null } || {}
        setTokenLoading(true)
        const { data } = await client.query({
            query: SURVEY_360_TOKEN_LIST,
            fetchPolicy: 'network-only',
            variables: { ...filter,createdBy:me?.id }
        });
        if (data?.getAllTokensList) {
            setTokenPageInfo({
                hasNextPage: data?.getAllTokensList?.pageInfo?.hasNextPage,
                cursor: data?.getAllTokensList?.pageInfo?.endCursor
            })
            let token_list = data?.getAllTokensList?.edges?.map(({ node }) => node)
            userResponse(token_list, filter)

        }
    }

    const userResponse = async (list, filter) => {
        let user_In = list?.map(i => getIntFromString(i?.user?.id))
        let survey_In = list?.map(i => getIntFromString(i?.survey?.id))
        let responseSourceFor_In = list?.map(i => getIntFromString(i?.responseSourceFor?.id))
        const { data } = await client.query({
            query: SURVEY_RESPONSE_FOR_360,
            fetchPolicy: 'network-only',
            variables: { user_In, survey_In, responseSourceFor_In }
        });
        if (data?.surveyResponses) {
            setSurvey360List(list)
            let res = data?.surveyResponses?.edges?.map(({ node }) => node)

            let token_list = list?.map(i => {
                let survey_response = res?.find(r => r?.user?.id == i?.user?.id && r?.responseSourceFor?.id == i?.responseSourceFor?.id)
                return {
                    ...i,
                    survey_response
                }
            })
            if (filter?.cursor) {
                token_list = token_list_ref.current.concat(token_list)
            }
            setSurvey360List(token_list)
        } else {
            setSurvey360List(list)
        }
        setTokenLoading(false)
    }

    return (
        <>
            <Survey360ResponseView
                token_loading={token_loading}
                survey_360_list={survey_360_list}
                token_page_info={token_page_info}
                getTokenList={(filter) => getSurveyTokenList({ ...filter_ref.current, ...filter })}
                selected_360={selected_360}
                SetRemainderData={SetRemainderData}
                setSelected360={setSelected360}
               {...props} />
        </>
    )
}

export default Survey360ResponseContainer