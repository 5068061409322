import like_active from '../../assets/kudos-like-active.svg'
import message_circle from '../../assets/kudos-message-circle.svg'
import party from '../../assets/kudos-celebration-icon.svg'
import like from '../../assets/kudos-like-icon.svg'
import heart from '../../assets/kudos-heart-icon.svg'
import kudos_comment_sent from '../../assets/kudos-commnet-sent-icon.svg'
import kudos_emoji_icon from '../../assets/kudos-commnet-emoji-icon.svg'
import party_giff from '../../assets/kudos-celebration-new.gif'
import like_giff from '../../assets/kudos-like-new.gif'
import heart_giff from '../../assets/kudos-heart.gif'
import kudos_heart_inactive from '../../assets/kudos-heart-inactive.svg'
export const KudosIcons ={
    like_active,
    message_circle,
    party,
    like,
    heart,
    kudos_comment_sent,
    kudos_emoji_icon,
    kudos_heart_inactive
}
export const kudosGif = {
    party_giff,
    like_giff,
    heart_giff
}