// queries.js
import { gql } from '@apollo/client';

export const KUDOS_LIST_QUERY = gql`
  query badgeAwards($recipient: ID, $issuer: ID, $badge_Title_Icontains: String,$createdAt_Range: [DateTime],$cursor: String) {
    badgeAwards(recipient: $recipient, issuer: $issuer, badge_Title_Icontains: $badge_Title_Icontains,createdAt_Range:$createdAt_Range,first:15,after:$cursor) {
      pageInfo{
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          issuer {
            id
            user {
              id
              firstName
              lastName
            }
          }
          recipient {
            id
            user {
              id
              firstName
              lastName
            }
          }
          evidence
          expirationDate
          badge {
            id
            image
            badgeFamily {
              id
              title
            }
            title
            behaviouralDescription
          }
        }
      }
    }
  }
`;
