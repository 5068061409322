import React from 'react';
import ScapeDashboardView from '../components/scapeDashboardView';
import { compose } from 'modules/core';
import { withApollo } from '@apollo/client/react/hoc'
import { GET_ALL_SCAPS, PARAMETER_SUBSCRIPTION, PARAMETRIC_GROUP_LISTS, PARAMETRIC_GROUP_SUBSCRIPTION, PARAMETR_LISTS, SCAPE_SUBSCRIPTION } from '../graphql/listingQueries.gql';
import { withDeleteParameter, withDeleteParametricGroup, withDeleteScape } from './scapeReportOperations';

const ScapeDashboardContainer = ( props ) => {

    const { client, me } = props

    const [ scape_list, setScapeList ] = React.useState( [] )
    const [ data_loading, setDataLoading ] = React.useState( false )
    const [ parameter_group_list, setParameterGroupList ] = React.useState( [] )
    const [ parameter_list, setParameterList ] = React.useState( [] )
    const [ group_loading, setGroupLoading ] = React.useState( false )
    const [ choice_loading, setChoiceLoading ] = React.useState( false )
    const ref = React.useRef( [] )
    const parameter_group_ref = React.useRef( {} )
    const parameter_ref = React.useRef( {} )

    const m = React.useRef( true )

    let scapeSub = undefined
    let groupSub = undefined
    let parameterSub = undefined

    React.useEffect( () => {
        return () => {
            if ( scapeSub ) {
                scapeSub.unsubscribe();
            }
        }
    }, [] )
    React.useEffect( () => {
        return () => {
            if ( groupSub ) {
                groupSub.unsubscribe();
            }
        }
    }, [] )
    React.useEffect( () => {
        return () => {
            if ( parameterSub ) {
                parameterSub.unsubscribe();
            }
        }
    }, [] )




    React.useEffect( () => {
        return () => {
            m.current = false
        }
    }, [] )

    React.useEffect( () => {
        m.current = true

    }, [] )

    React.useEffect( () => {
        if ( me ) {
            getScapeLists()
        }
    }, [ me ] )

    React.useEffect( () => {
        ref.current = scape_list
    }, [ scape_list ] )

    React.useEffect( () => {
        parameter_group_ref.current = parameter_group_list
    }, [ parameter_group_list ] )

    React.useEffect( () => {
        parameter_ref.current = parameter_list
    }, [ parameter_list ] )


    const getScapeLists = async ( filter ) => {
        setDataLoading( true )
        const { data } = await client.query( {
            query: GET_ALL_SCAPS,
            fetchPolicy: 'network-only',
            variables: { ...filter }
        } );
        if ( data?.scapes ) {
            let lists = data?.scapes?.edges?.map( ( { node } ) => node )
            setScapeList( lists )
            scapeSub = client.subscribe( {
                query: SCAPE_SUBSCRIPTION,
                variables: { ...filter },
                fetchPolicy: 'network-only',
            } ).subscribe( {
                next( result ) {

                    let CacheData = [].concat( ref.current )
                    switch ( result.data.scapeSubscription.mutation ) {
                        case 'CREATE':
                            const updated = [ result.data.scapeSubscription.scape, ...CacheData ]
                            if ( m.current ) {
                                setScapeList( updated )
                            }

                            break
                        case 'UPDATE':
                            if ( m.current ) {
                                setScapeList(
                                    CacheData.map( item =>
                                        item.id === result.data.scapeSubscription.scape?.id
                                            ? result.data.scapeSubscription.scape
                                            : item
                                    ) )
                            }
                            break
                        case 'DELETE':
                            if ( m.current ) {

                                setScapeList(
                                    CacheData.filter( item =>
                                        item.id === result.data.scapeSubscription.scape?.id
                                            ? false
                                            : true
                                    ) )
                            }
                            break
                        default:
                            break
                    }
                }
                
            } )
            setDataLoading( false )
        }
    }

    const parameterGropupList = async ( id ) => {
        setGroupLoading( true )
        const { data } = await client.query( {
            query: PARAMETRIC_GROUP_LISTS,
            variables: { scape: id },
            fetchPolicy: 'network-only',
        } );
        if ( data?.parametricGroups ) {
            setParameterGroupList( data?.parametricGroups?.edges?.map( ( { node } ) => node ) )
            groupSub = client.subscribe( {
                query: PARAMETRIC_GROUP_SUBSCRIPTION,
                variables: { scape: id },
                fetchPolicy: 'network-only',
            } ).subscribe( {
                next( result ) {
                    let CacheGroup = [].concat( parameter_group_ref.current )
                    switch ( result.data.parametricGroupQuestionSubscription.mutation ) {
                        case 'CREATE':
                            const updated = [ result.data.parametricGroupQuestionSubscription.parametricGroupQuestion, ...CacheGroup ]
                            if ( m.current ) {
                                setParameterGroupList( updated )
                            }

                            break
                        case 'UPDATE':
                            if ( m.current ) {
                                setParameterGroupList(
                                    CacheGroup.map( item =>
                                        item.id === result.data.parametricGroupQuestionSubscription.parametricGroupQuestion?.id
                                            ? result.data.parametricGroupQuestionSubscription.parametricGroupQuestion
                                            : item
                                    ) )
                            }
                            break
                        case 'DELETE':
                            if ( m.current ) {

                                setParameterGroupList(
                                    CacheGroup.filter( item =>
                                        item.id === result.data.parametricGroupQuestionSubscription.parametricGroupQuestion?.id
                                            ? false
                                            : true
                                    ) )
                            }
                            break
                        default:
                            break
                    }
                }
            } )
            setGroupLoading( false )
        }
        
    }

    const parameterList = async ( id ) => {
        setChoiceLoading( true )
        const { data } = await client.query( {
            query: PARAMETR_LISTS,
            variables: { parametricGroup: id },
            fetchPolicy: 'network-only',
        } );
        if ( data?.parameters ) {
            setParameterList( data?.parameters?.edges?.map( ( { node } ) => node ) )
            parameterSub = client.subscribe( {
                query: PARAMETER_SUBSCRIPTION,
                variables: { parametricGroup: id },
                fetchPolicy: 'network-only',
            } ).subscribe( {
                next( result ) {
                    let CacheParameter = [].concat( parameter_ref.current )
                    switch ( result.data.parameterSubscription.mutation ) {
                        case 'CREATE':
                            const updated = [ result.data.parameterSubscription.parameter, ...CacheParameter ]
                            if ( m.current ) {
                                setParameterList( updated )
                            }

                            break
                        case 'UPDATE':
                            if ( m.current ) {
                                setParameterList(
                                    CacheParameter.map( item =>
                                        item.id === result.data.parameterSubscription.parameter?.id
                                            ? result.data.parameterSubscription.parameter
                                            : item
                                    ) )
                            }
                            break
                        case 'DELETE':
                            if ( m.current ) {
                                setParameterList(
                                    CacheParameter.filter( item =>
                                        item.id === result.data.parameterSubscription.parameter?.id
                                            ? false
                                            : true
                                    ) )
                            }
                            break
                        default:
                            break
                    }
                }
            } )
            setChoiceLoading( false )
        }
        
    }

    return (
        <>
            <ScapeDashboardView {...props}
                scape_list={scape_list}
                data_loading={data_loading}
                parameter_group_list={parameter_group_list}
                getParameterGroupList={( id ) => parameterGropupList( id )}
                getParameterList={( id ) => parameterList( id )}
                parameter_list={parameter_list}
                group_loading={group_loading}
                choice_loading={choice_loading}
            />
        </>
    )
}

export default compose( withApollo, withDeleteScape, withDeleteParametricGroup, withDeleteParameter )( ScapeDashboardContainer )
