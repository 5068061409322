import React from "react";
import EngagementBoardView from "../component/engagementBoardView";


const EngagementBoardContainer =(props) =>{
    return (
        <>
            <EngagementBoardView {...props}/>
        </>
    )
}

export default EngagementBoardContainer;