import ROUTE from './route';
import CreateFeedbackContainer from './containers/GiveFeedbackContainer'
import FeedbackDetailedContainer from './containers/feedBackDetailedContainer'
import FeedbackUpadteContainer from './containers/UpdateFeedackContainer'
import NewViewFeedbackContainer from './containers/NewViewFeedbackContainer'
import FeedbackHomPageContainer from './containers/FeedbackHomPageContainer';

export default [
    // {
    //   name: 'feedbackHome',
    //   path: ROUTE.feedback,
    //   component: FeedbackContainer,
    //   exact:true,
    // },
    {
      name: 'feedbackHome',
      path: ROUTE.feedback,
      component: NewViewFeedbackContainer,
      exact:true,
      protect:true
    },
    {
      name: 'createFeedback',
      path: ROUTE.Createfeedback,
      component: CreateFeedbackContainer,
      exact:true,
      protect:true
    },
    {
      name: 'FeedbackDetail',
      path: ROUTE.detail,
      component: FeedbackDetailedContainer,
      protect:true,
    },
    {
      name: 'Feedbackupdate',
      path: ROUTE.update,
      component: FeedbackUpadteContainer,
      exact:true,
      protect:true
    },
    {
      name: 'Feedback Home',
      path: ROUTE.feedbackHome,
      component: FeedbackHomPageContainer,
      exact:true,
      protect:true
    },




]