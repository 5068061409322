import gql from "graphql-tag";

export const BADGE_AWARD_SUBSCRIPTION = gql`

subscription badgeAwardSubscription {
  badgeAwardSubscription {
  mutation
  badgeAward{
    id
    createdAt
    reactions{
        totalCount
    }
    issuer {
      id
      user {
          id
          firstName
          lastName
      }
    }
    recipient {
        id
          memberSet(last: 1, role_Isnull: false) {
            edges {
              node {
                id
                role {
                  id
                  title
                }
              }
            }
          }
          user {
            id
            firstName
            profile {
                id
                profileImage
            }
          }
      }
      evidence
      badge {
          id
          title
          image
          behaviouralDescription
          badgeFamily{
              id
              title
          }
      }
    }
  }
}`;
