import ROUTE from './route'; 
import SurveyQuiz from 'modules/survey/containers/SurveyQuiz';
import userManualMainContainer from './containers/userManualMainContainer';
export default [
  {
    name: 'User Manual lHome',
    path: ROUTE.home,
    component: userManualMainContainer,
    exact: true,
    protect: true
  },
  {
    name: 'User manual Survey',
    path: ROUTE.surveyQuizUserManual,
    component: SurveyQuiz,
    protect: true,
    exact:true
  }
];
