import gql from "graphql-tag"

export const ALL_SURVEY_RESPONSE = gql`
query  SurveyById($gender:ID!,$HighestDegree:ID!,$DiplomasCertificates:ID!,$Specializations:ID!,$YearsOfExperience:ID!,$CurrentNationality:ID!,$value: ID!,$strength: ID!,$personality: ID!,$knowledge: ID!, $responseUser: ID!,$diversity:ID!){
    value: SurveyById(id: $value) {
        id
        name
        groupSet(last:1) {
          edges {
            node {
              id
              questionSet {
                edges {
                  node {
                    id
                    answerSet(response_Survey:$value,response_User: $responseUser,first:1) {
                      edges {
                        node {
                          id
                          answer
                          response {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      strength:SurveyById(id: $strength) {
        id
        name
        groupSet(last:1) {
          edges {
            node {
              id
              questionSet {
                edges {
                  node {
                    id
                    answerSet(response_Survey:$strength,response_User: $responseUser,first:1) {
                      edges {
                        node {
                          id
                          answer
                          response {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      personality: SurveyById(id: $personality) {
        id
        name
        groupSet(last:1) {
          edges {
            node {
              id
              questionSet {
                edges {
                  node {
                    id
                    answerSet(response_Survey:$personality,response_User: $responseUser,first:1) {
                      edges {
                        node {
                          id
                          answer
                          response {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      knowledge: SurveyById(id: $knowledge) {
        id
        name
        groupSet(last:1) {
          edges {
            node {
              id
              questionSet {
                edges {
                  node {
                    id
                    answerSet(response_Survey:$knowledge,response_User: $responseUser,first:1) {
                      edges {
                        node {
                          id
                          answer
                          response {
                            id
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      diversity:surveyResponses(first: 1, user: $responseUser, survey: $diversity, orderBy: ["-responseDate"]) {
        edges {
          node {
            id
           
            gender: answerSet(question: $gender){
              edges {
                node {
                  id
                  answer
                }
              }
            }
     
            HighestDegree: answerSet(question: $HighestDegree){
              edges {
                node {
                  id
                  answer
                }
              }
            }

            DiplomasCertificates: answerSet(question: $DiplomasCertificates){
              edges {
                node {
                  id
                  answer
                }
              }
            }
     
            Specializations: answerSet(question: $Specializations){
              edges {
                node {
                  id
                  answer
                }
              }
            }
            YearsOfExperience: answerSet(question: $YearsOfExperience){
              edges {
                node {
                  id
                  answer
                }
              }
            }
     
            CurrentNationality: answerSet(question: $CurrentNationality){
              edges {
                node {
                  id
                  answer
                }
              }
            }
          }
        }
      }
}
`
//Donot edit this query
export const USER_IMAPACT_NARRATIVE = gql`
query allImpactNarrative($user: ID){
    allImpactNarrative(user:$user,last:1,orderBy:["createdAt"]){
        edges{
            node{
              id
              overview
              decisionMaking 
              communication 
              collaboration 
              relationshipBuilding 
              conflictManagement 
              changeManagement 
              learningStyle 
              execution 
              createdAt
            }
          }
    }
   
  }`

export const CREATE_IMAPACT_NARRATIVE = gql`
mutation createImpactNarrative($impactNarrativeData: ImpactNarrativeInput!){
  createImpactNarrative(impactNarrativeData:$impactNarrativeData){
    impactNarrative{
      id
    }
    }
  }`

export const UPDATE_IMAPACT_NARRATIVE = gql`
mutation updateImpactNarrative($id: ID!,$collaboration: String,$communication: String,$decisionMaking: String,$overview: String,$relationshipBuilding: String){
  updateImpactNarrative(id: $id,collaboration: $collaboration,communication: $communication,decisionMaking: $decisionMaking,overview: $overview,relationshipBuilding: $relationshipBuilding){
    impactNarrative{
      id
    }
    }
  }`

export const GENERATE_AI_IMPACT_NARRATIVE = gql`
query generateAiImpactNarrative {
  generateAiImpactNarrative 
}`

export const IMPACT_NARRATIVE_SUBSCRIPTION =gql`
subscription impactNarrativeSubscription {
  impactNarrativeSubscription {
    mutation
    impactNarrative{
      id
      overview
      decisionMaking 
      communication 
      collaboration 
      relationshipBuilding 
      conflictManagement 
      changeManagement 
      learningStyle 
      execution 
    }
  }
}
`
 
export const LATEST_SURVEY_IMAPACT_NARRATIVE = gql`
query surveyResponses($user: ID,$value: ID,$strength: ID,$knowledge_skill: ID,$personality: ID,$diversity: ID,$orderBy: [String],$last:Int){
  value: surveyResponses(survey:$value,user:$user,orderBy:$orderBy,last:$last){
    edges{
      node{
        id
        responseDate
      }
    }
  }

  strength: surveyResponses(survey:$strength,user:$user,orderBy:$orderBy,last:$last){
    edges{
      node{
        id
        responseDate
      }
    }
  }

  knowledge_skill: surveyResponses(survey:$knowledge_skill,user:$user,orderBy:$orderBy,last:$last){
    edges{
      node{
        id
        responseDate
      }
    }
  }

  personality: surveyResponses(survey:$personality,user:$user,orderBy:$orderBy,last:$last){
    edges{
      node{
        id
        responseDate
      }
    }
  }

  diversity: surveyResponses(survey:$diversity,user:$user,orderBy:$orderBy,last:$last){
    edges{
      node{
        id
        responseDate
      }
    }
  }
}`

export const RUNPOD_HEALTH_STATUS = gql`
query getEndpointHealth($eptype: String!) {
  getEndpointHealth(eptype:$eptype)
}`