import { ThemeProvider } from '../../../look/themeProvider/ThemeProvider';
import React, { useEffect, useState } from 'react';

import { compose } from '../../../core';

import themeConfig from '../../theme/themeConfig';
import { Spin } from 'antd';
import { withApollo } from '@apollo/client/react/hoc';
import { globalPermissionValidator } from 'modules/look';
import { soul_permission } from 'Permissions/soul.permission';
import { ADD_EDIT_TOOL_BY_ID_QUERY } from './fetchtool.gql';
import NoPermissionView from 'modules/kudos-user/component/NoPermissionComponent';
import { SOUL_GENERIC_TOOLS } from 'config';
import { SURVEY_PERMISSION_CHECK } from 'modules/tool/graphql/SurveyPermission.gql';
import { DIVERSITY_PROFILE_IDENTIFIER } from 'config';




const AddEditToolView = props => {
  const { userPermission, permissionLoader, me, client } = props;
  const [items, setItems] = useState([]);
  const [ui, setUi] = useState("undefined");
  const [isImpactNarrative, setIsImpactNarrative] = useState(false);
  const [isSoulTool, setIsSoulTool] = React.useState(true);
  const [soulPermitted, setSoulPermitted] = React.useState(false)
  const [getToolById, setGetToolById] = React.useState();
  const [diversityProfilePermitted, setDiversityProfilePermitted] = React.useState(false);
  const [loading, setloading] = React.useState(true)


  useEffect(() => {
    if (!loading)


      if (getToolById?.uiFlow === "IMPACT_NARRATIVE_TOOL_TWO_COLUMN") {
        setIsImpactNarrative(true)
      }
    setUi(getToolById?.uiFlow || "TOOL_EDIT_TWO_COLUMN");
    setItems(
      [
        ...(getToolById?.textsection?.edges?.map(({ node }) => ({
          ...node,
          type: "textsection",
        })) || []),
        ...(getToolById?.formsection?.edges?.map(({ node }) => ({
          ...node,
          type: "formsection",
        })) || []),
      ].sort((a, b) =>
        a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0
      )
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);


  const getDiversityProfilePermission = async () => {

    try {
      const { data } = await client.query({
        query: SURVEY_PERMISSION_CHECK,
        variables: { identifier:DIVERSITY_PROFILE_IDENTIFIER },
        fetchPolicy: 'network-only'
      });

      if (data?.allSurveys) {
        let count = data?.allSurveys?.totalCount
        
        if (count > 0) {
          setDiversityProfilePermitted(true)
        }
      }

    } catch (e) {
      console.error(e)
    }

  }



  React.useEffect(() => {
    if (me) {
      getDiversityProfilePermission()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])


  React.useEffect(() => {

    if (props.match.params.id) {
      let soulTool = SOUL_GENERIC_TOOLS?.includes(parseInt(props.match.params.id))
      setIsSoulTool(soulTool)
    }
  }, [props.match.params.id])


  React.useEffect(() => {
    if (userPermission && !permissionLoader && isSoulTool) {
      let soulPermission = globalPermissionValidator(soul_permission, userPermission);
      setSoulPermitted(soulPermission?.required_permission)
    }
  }, [userPermission, permissionLoader, isSoulTool])


  React.useEffect(() => {
    if (me && soulPermitted && isSoulTool) {
      getAddEditToolById()
    } else if (!isSoulTool) {
      getAddEditToolById()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me, soulPermitted, isSoulTool])


  const getAddEditToolById = async () => {
    let id = '';
    if (props.match) {
      id = props.match.params.id;
    } else if (props.navigation) {
      id = props.navigation.state.params.id;
    }
    const { data } = await client.query({
      query: ADD_EDIT_TOOL_BY_ID_QUERY,
      variables: { toolId: Number(id || props.id), user: props?.me?.id },
      fetchPolicy: 'network-only'
    });

    if (data?.getToolById) {
      setGetToolById(data?.getToolById)
      setloading(false)
    }
    setTimeout(()=>{
      setloading(false)
    },1000)
    
  }



  return (

    // if tool is impact narrative
    <>
      <div>
        {
          isImpactNarrative &&
          <div>{
            loading ?
              <Spin spinning={loading} size="large">
                <div style={{ minHeight: "70vh" }}>
                </div>
              </Spin>
              :
              (diversityProfilePermitted && soulPermitted && !permissionLoader) ?
                <ThemeProvider
                  {...props}
                  config={themeConfig}
                  ui={ui}
                  items={items}
                  isImpactNarrative={isImpactNarrative}
                  getToolById={getToolById}
                />
                :
                (!diversityProfilePermitted || !soulPermitted) ?
                  <NoPermissionView content={"You Don't Have Permission To View This Tool."}/>
                  : null
          }
          </div>
        }
      </div>

      {/* if tool is soul tool */}
      <div>
        {
          isSoulTool && !isImpactNarrative &&
          <div>
            {
            loading ?
              <Spin spinning={loading} size="large">
                <div style={{ minHeight: "70vh" }}>
                </div>
              </Spin>
              :
              (soulPermitted && !permissionLoader&&getToolById!=null) ?
                <ThemeProvider
                  {...props}
                  config={themeConfig}
                  ui={ui}
                  items={items}
                  isImpactNarrative={isImpactNarrative}
                  getToolById={getToolById}
                />
                :
                ((!soulPermitted && !permissionLoader)||(getToolById==null&&!loading)) ?
                  <NoPermissionView content={"You Don't Have Permission To View This Tool."}/>
                  : null
          }
          </div>
        }
      </div>


      {/* if tool is non soul and non impact narrative */}
      <div>
        {
          !isSoulTool && !isImpactNarrative &&
          <ThemeProvider
            {...props}
            config={themeConfig}
            ui={ui}
            items={items}
            isImpactNarrative={isImpactNarrative}
            getToolById={getToolById}
          />
        }
      </div>

    </>

  );

};

export default compose(withApollo)(AddEditToolView);
