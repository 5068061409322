import {Select, Spin, Upload } from "antd";
import UserCompetencyAssessmentContainer from "modules/home/containers/UserCompetencyAssessmentContainer";
import UserImpactNarrativeContainer from "modules/home/containers/UserImpactNarrativeContainer";
import UserKudosContainer from "modules/home/containers/UserKudosContainer";
import UserOKRStatusContainer from "modules/home/containers/userOKRStatusContainer";
import UserSurveyStatusContainer from "modules/home/containers/userSurveyStatus.container";
import { profile_icon } from "modules/home/dashboard-icons";
import { Capitalize, MetaTags, RenderUploadWithCrop } from "modules/look";
import React from "react";
import camera_icon from '../../../assets/camera-icon-white.svg'
import upload_icon from '../../../assets/upload.svg'
import delete_icon from '../../../assets/delete.svg'
import { CLOUDINARY_CLOUDNAME } from "../../../../config";
import { CLOUDINARY_PRESET } from "../../../../config";
import moment from 'moment-timezone';
import { getSyncItem} from '../../../core';
import styled from "styled-components";
const { Option, OptGroup } = Select;
const MyProfileView = (props) => {

    const { profile_detail, permission,onSubmit,page_loading,changeTimezone } = props
    const [enable_profile_options, setEnableProfileOptions] = React.useState(false)
    const [load, setLoad] = React.useState(false);
    
    const cloudName = `${CLOUDINARY_CLOUDNAME}/image`;
    const unsignedUploadPreset = `${CLOUDINARY_PRESET}`;
  
    const cloudinary_url = 'https://api.cloudinary.com/v1_1/' + cloudName + '/upload';
    const cloudinary_data = { upload_preset: unsignedUploadPreset };

    const [tab, setSideBarTab] = React.useState([
        {
            key: 'okr_status',
            label: 'OKR Status',
            permission: permission?.okr_permission
        },
        {
            key: 'survey_status',
            label: 'Survey status',
            permission: permission?.survey_permission
        },
        {
            key: 'impact_narrative',
            label: 'Impact Narrative ',
            permission: permission?.impact_narrative_permission
        },
        {
            key: 'competency_report',
            label: 'Competency Report',
            permission: permission?.competency_assessment_permission
        },
        {
            key: 'kudos',
            label: 'Kudos',
            permission: permission?.award_badge_permission
        }
    ])

    const [current_tab, SetCurrentTab] = React.useState()

    React.useEffect(() => {
        if (tab?.length) {
            let current_tab = tab?.find(i => i?.permission)
            SetCurrentTab(current_tab)
        }
    }, [tab])
    const content = {
        okr_status: UserOKRStatusContainer,
        survey_status: UserSurveyStatusContainer,
        impact_narrative: UserImpactNarrativeContainer,
        kudos: UserKudosContainer,
        competency_report: UserCompetencyAssessmentContainer
    }



    const CurrentView = content[current_tab?.key]

    const UploadProfile =async(value)=>{
        let response = await onSubmit({profileImage: value})
        if(response?.id){
            setEnableProfileOptions(false)
        }
    }
    const RemoveProfilePic = async()=>{
        let response = await onSubmit({profileImage:''})
        if(response?.id){
            setEnableProfileOptions(false)
        }
    }
    const onChangeHandler = ({ file, fileList }) => {
        if (file.status === 'uploading') {
          setLoad(true);
        }
        if (file.status === 'done') {
          setLoad(false);
          if (file.response) {
            let url = file.response.secure_url;
            if (url) {
              UploadProfile(url)
            }
          }
        } else if (file.status === 'removed') {
          setLoad(false);
        }
      };

      const onTzChange = (tz) => {
        changeTimezone({timeZone:tz})
        
      }

      const renderOptions = () => {
        const timezones = moment.tz.names();
        let mappedValues = {};
        let regions = [];
    
        timezones.map(timezone => {
          const splitTimezone = timezone.split("/");
          const region = splitTimezone[0];
          if (!mappedValues[region]) {
            mappedValues[region] = [];
            regions.push(region);
          }
          mappedValues[region].push(timezone);
        });
        return regions.map(region => {
          const options = mappedValues[region].map(timezone => {
            return <Option key={timezone}>{timezone}</Option>;
          });
          return (
            <OptGroup
              key={region}
              title={<div style={{ fontSize: 30 }}>{region}</div>}
            >
              {options}
            </OptGroup>
          );
        });
      }
    return (
        <>
            <MetaTags title="My profile" description="My profile" />
            {profile_detail?
                <div className='global-root-em'>

                    <div className='profile-card row-wrapper media-col-wrapper' style={{ padding: "2em 2.5em", marginTop: "0.5em" }}>

                        <div className='user-profile-detail'>
                            <div style={{}}>
                                <div className='pro-data-wrapper'>
                                    <div className="pro-data-image-container">
                                        <img src={profile_detail?.user?.profile?.profileImage || profile_icon.profile} alt="profile" />
                                        <div className="pro-data-hover-content" style={{ cursor: "pointer" }}
                                            onClick={() => setEnableProfileOptions(true)}>
                                            <img src={camera_icon} alt="camera_icon" />
                                            <p>Change the<br />
                                                profile picture </p>
                                        </div>

                                    </div>

                                    <div>
                                        <h4 className='profile-name'>{Capitalize(profile_detail?.user?.firstName)}</h4>
                                        <p className='role-name'>{profile_detail?.role?.title || 'No role assigned'}</p>
                                        <div style={{width:'100%',marginTop:"1em"}}>
                                            <CustomSelect
                                                showSearch
                                                onChange={onTzChange}
                                                style={{
                                                    minWidth:"200px",
                                                    width: '100%',
                                                }}
                                                className={"normal-bordered"}
                                                defaultValue={getSyncItem("userRequestedTimezone") || Intl.DateTimeFormat().resolvedOptions().timeZone}
                                            >
                                                {renderOptions()}
                                            </CustomSelect>
                                        </div>
                                    </div>
                                </div>
                                {enable_profile_options && <div className="Profile-pic-option-container">
                                    <Upload
                                        className="profile-pic-upload-button"
                                     action={cloudinary_url}
                                     data={cloudinary_data}
                                     onChange={onChangeHandler}>
                                        <h4><img style={{ height: "1em", width: "1em", borderRadius: '0%', marginRight: "5px" }} src={upload_icon} alt="upload_icon" /> Upload a profile picture</h4>
                                    </Upload>
                                    <h4 onClick={()=>RemoveProfilePic()} style={{cursor:"pointer"}}><img style={{ height: "1em", width: "1em", borderRadius: '0%', marginRight: "5px" }} src={delete_icon} alt="delete_icon" /> Remove profile picute</h4>
                                </div>}
                                {enable_profile_options ? (<div className='overlay-dynamic-island' onClick={() => setEnableProfileOptions(false)} />) : null}
                            </div>
                        </div>
                        <div className='user-surevey-detail'>

                            <div className='col-wrapper'>
                                <div>
                                    <p className='info-label-title'>Personality Type</p>
                                    <h4 className='info-label-content'>{profile_detail?.personality || '-'}</h4>
                                </div>
                                <div>
                                    <p className='info-label-title'>Job Family</p>
                                    <h4 className='info-label-content'>{profile_detail?.job_family?.title || '-'}</h4>
                                </div>
                            </div>

                            <div className='col-wrapper'>
                                <div>
                                    <p className='info-label-title'>Work Experience</p>
                                    <h4 className='info-label-content'>{profile_detail?.experinece >= 0 ? `${profile_detail?.experinece} ${profile_detail?.experinece > 1 ? 'years' : 'year'}` : '-'} </h4>
                                </div>
                                <div>
                                    <p className='info-label-title'>Employee Level</p>
                                    <h4 className='info-label-content'>{profile_detail?.role?.employeeLevel?.name || '-'}</h4>
                                </div>
                            </div>

                            <div className='col-wrapper'>
                                <div>
                                    <p className='info-label-title'>Top Values</p>
                                    <div className='row-wrapper content-wrap' style={{ gap: "1em" }}>
                                        {
                                            profile_detail?.value?.map((i, index) => (
                                                <h4 className='info-label-content' style={{ color: "#3B8855" }}>{index + 1} {i?.answer}</h4>
                                            ))
                                        }
                                        {
                                            !profile_detail?.value?.length ?
                                                <h4 className='info-label-content' style={{ color: "#3B8855" }}>Survey not taken</h4> : ''
                                        }
                                    </div>
                                </div>
                                <div>
                                    <p className='info-label-title'>Top Strengths </p>
                                    <div className='row-wrapper content-wrap' style={{ gap: "1em" }}>
                                        {
                                            profile_detail?.strength?.map((i, index) => (
                                                <h4 className='info-label-content' style={{ color: "#3B8855" }}>{index + 1} {i?.answer}</h4>
                                            ))
                                        }
                                        {
                                            !profile_detail?.strength?.length ?
                                                <h4 className='info-label-content' style={{ color: "#3B8855" }}>Survey not taken</h4> : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='profile-card' style={{ marginTop: "1em" }}>
                        <div className='profile-tab-view'>
                            {
                                tab?.filter(i => i?.permission)?.map(tab => (

                                    <div className={`profile-tab-normal ${(current_tab?.key === tab?.key || (current_tab?.key?.includes(tab?.key))) ? 'profile-tab-active' : ''}`} onClick={() => SetCurrentTab(tab)}>
                                        <h4 className={`profile-tab-label ${(current_tab?.key === tab?.key || (current_tab?.key?.includes(tab?.key))) ? 'profile-tab-active-label' : ''}`}>{tab?.label}</h4>
                                    </div>
                                ))
                            }
                            <div className='border-line'>

                            </div>
                        </div>
                        <div className='user-content-container'>

                            {
                                content[current_tab?.key] && (

                                    <CurrentView current_tab={current_tab} profile_detail={profile_detail} {...props} />
                                )
                            }
                        </div>
                    </div>
                </div> :
                <div align="center" style={{ width: "100%", height: "70vh", display: "flex", justifyContent: "center", alignItems: "center", background: "#FFFFFF" }}>
                    <Spin spinning={true} size='large'></Spin>
                </div>}
        </>
    )
}

export default MyProfileView;
const CustomSelect = styled(Select)`
.ant-select-selector{
    border: 1px solid #AABAC6 !impoortant;
    color:  #000;
    font-weight: 500;
    font-family: Poppins;
    font-size: 1em;
    line-height: normal;
    letter-spacing: 0.01em;
    text-align: left;
    border-radius:.5em !important;
    width:200px !important;
    height: 40px !important;
    padding-top: 4px !important;
}
`;