import React from "react";
import { competency_icons } from "../icon";
import { Button, Divider, Input, Progress, message } from "antd";
import { COMPETENCY_FILE_UPLOAD_URL_ENABE } from "config";
import competency_template from '../../assets/xlxstemplate/competency template.xlsx'
import responsibility_template from '../../assets/xlxstemplate/responsibilities template.xlsx'
import sub_competency_template from '../../assets/xlxstemplate/sub competency template.xlsx'
const CompetencyImportView = (props) => {

    const { uploadfile, fileUploadSuccess, uploadErrors, type, cancel } = props

    const [selectedFiles, setSelectedFiles] = React.useState();
    const [uploading_started, setUploadingStarted] = React.useState(false);
    const [progress_count, setProgressCount] = React.useState(0);
    const [stock_color, setStrockColor] = React.useState('rgba(2, 215, 164, 1)');
    const [show_close, setShowClose] = React.useState(true);
    const [show_prgress, setShowprgress] = React.useState(false);
    const fileInputRef = React.createRef();

    const allowedTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];

    /* Upload success Response */
    React.useEffect(() => {
        if (fileUploadSuccess !== undefined) {
            setProgressCount(100)
            setTimeout(() => {
                setSelectedFiles(undefined)
                setUploadingStarted(false)
                cancel()
            }, 400)
        }
    }, [fileUploadSuccess])

    /* Upload Error Response */
    React.useEffect(() => {
        if (uploadErrors !== undefined) {
            setProgressCount(100)
            setUploadingStarted(false)
            setStrockColor('#FFBABA')
            setShowClose(true)
        }
    }, [uploadErrors])

    /* File Drag and drop functionality */
    const handleDrop = (e) => {
        e.preventDefault();
        if (selectedFiles !== undefined) {
            message.error('Allow only one file at a time');
        }
        else {
            if (!allowedTypes.includes(e.dataTransfer.files[0]?.type)) {
                message.error('You can only upload XLSX and CSV files!');
                return false;
            }
            else {
                setSelectedFiles(e.dataTransfer.files[0]);
            }
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    /* Choose file Fuctionality */
    const handleChooseFileClick = () => {
        fileInputRef.current.click();
    };

    const ChooseFile = (file) => {
        if (selectedFiles !== undefined) {
            message.error('Allow only one file at a time');
        }
        else {
            if (!allowedTypes.includes(file?.type)) {
                message.error('You can only upload XLSX and CSV files!');
                return false;
            }
            else {
                setSelectedFiles(file);
            }
        }

    }

    /*Import file Function call */
    const ImportFile = () => {
        setUploadingStarted(true)
        setShowClose(false)
        setShowprgress(true)
        uploadfile(selectedFiles)


    }

    /* Progress counter */
    React.useEffect(() => {
        if (uploading_started) {
            setTimeout(() => {
                if (progress_count < 90) {
                    setProgressCount(progress_count + 15)
                }
            }, 100)
        }
    }, [uploading_started])

    /*Size Coventered to KB */
    const bytesToKilobytes = (bytes) => {
        return (bytes / 1024).toFixed(2) + ' KB';
    }

    const title = {
        import_main_competency: 'Add Competency',
        responsiblitiy: 'Add Responsibility',
        sub_competency: 'Add Sub Competency'
    }

    const template_file = {
        import_main_competency: {name:"competency template",file:competency_template},
        responsiblitiy: {name:"responsibilities template",file:responsibility_template},
        sub_competency: {name:"sub competency template",file:sub_competency_template}
    }

    const downloadTemplate=()=>{
        window.open(template_file[type].file,'blank')
    }

    return (

        <div className="rd-file-upload-container" style={{ position: "relative" }}>
            <img className='cp-modal-close-icon' style={{ width: '2em', top: "-1em" }} src={competency_icons?.jf_modal_close} alt="" onClick={() => cancel()} />
            <h4 className='rd-input-title' style={{ marginTop: "1em" }}>{title[type]}</h4>
            <p className="template-label">Download the template</p>
            <div style={{ position: 'relative', width: '100%', marginTop: '0.75em' }}>
                <div className="rd-template-container"></div>
               <div className="sample-xlsx-container">
               <div className="sample-xlsx-div">
                    <img src={competency_icons?.file_icon} style={{width:"2em",height:"2em"}} alt="" />
                    <p className="rd-file-name">{template_file[type]?.name}.xlsx</p>
                </div>
               </div>
                <div className="rd-url-upload-container" style={{ width: "9em" }} onClick={()=>{downloadTemplate()}}>
                    <div className="rd-url-upload-label">
                        <p className="rd-url-upload-btn" >Download</p>
                    </div>
                </div>
            </div>

            <div
                className="rd-file-upload-drag"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                style={{ marginTop: '2em' }}
            >
                <p className="ant-upload-drag-icon">
                    <img src={competency_icons?.upload_icon} alt="" />
                </p>
                <p className="rd-file-upload-label" style={{ textAlign: "center" }}>Drag & drop or <span style={{ color: "#0098F0", cursor: "pointer" }} onClick={handleChooseFileClick}>Choose file</span> to upload</p>
                <p className="rd-file-upload-label" style={{ textAlign: "center" }}>XLSX,CSV</p>
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={(e) => ChooseFile(e.target.files[0])}
                />
            </div>

            {selectedFiles !== undefined && <div>
                <div className="rd-uploaded-file-container">
                    <div style={{ display: "flex", flexDirection: "row", gap: '1rem' }}>
                        <img src={competency_icons?.file_icon} alt="" />
                        <div className="rd-file-container">
                            <div>
                                <p className="rd-file-name">{selectedFiles?.name}</p>
                                <p className="rd-file-size">{bytesToKilobytes(selectedFiles?.size)}</p>
                            </div>

                            {show_close && <img style={{ width: "15px", cursor: "pointer" }} onClick={() => setSelectedFiles(undefined)} src={competency_icons?.file_close} alt="" />}
                        </div>
                    </div>
                    {show_prgress && <div style={{ position: 'relative' }}>
                        <p className="rd-rogress-count">{progress_count}%</p>
                        <Progress
                            strokeColor={stock_color}
                            style={{
                                display: "block",
                                borderRadius: "8px"
                            }}
                            showInfo={false}
                            percent={progress_count}
                            status="active"
                        />
                    </div>}
                </div>
            </div>}

            {COMPETENCY_FILE_UPLOAD_URL_ENABE && (
                <>
                    <Divider className="rd-divider">OR</Divider>
                    <div>
                        <h4 className='rd-input-title'>Import from URL</h4>
                        <div style={{ position: 'relative', width: '100%', marginTop: '1em' }}>
                            <Input name='url' type="url" className="rd-text-input" placeholder='Add file URL' style={{ paddingRight: "6.5em" }} />
                            <div className="rd-url-upload-container">
                                <div className="rd-url-upload-label">
                                    <p className="rd-url-upload-btn">Upload</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className='rd-confirmation-action-container' style={{ marginTop: '2em' }}>
                <Button className='global-submit-btn' disabled={selectedFiles === undefined} onClick={() => ImportFile()}>Import</Button>
                <Button className='global-cancel-btn' onClick={() => cancel()}>Cancel</Button>
            </div>
        </div>
    )
}

export default CompetencyImportView