import { compose } from 'modules/core';
import React from 'react'
import { withApollo } from '@apollo/client/react/hoc';
import { MODULE_USAGE_EXPORT_QUERY } from 'modules/poc-dashboard/graphql/moduleusageQuery.gql';
import { Capitalize, displayUtcToUserTimezone, exportToCsv } from 'modules/look';
import moment from 'moment-timezone';
import UserModuleUsageExportView from 'modules/poc-dashboard/components/dashboard/userModuleUsageExportView';

const UsersCompeteModuleUsageContainer =(props)=>{
    const {client}=props
    const [export_data_loading,setExportDataLoading]=React.useState(false)
    const [prgress_count,setProgressCount]= React.useState(1)
    const usage_report_ref =React.useRef([])
    const [export_useage_report_list,setExportUageRport]= React.useState([])
    React.useEffect(()=>{
        usage_report_ref.current = export_useage_report_list
    },[export_useage_report_list])
    const getCompleteUsageData = async(filter)=>{
        setExportDataLoading(true)
        const { data } = await client.query({
            query: MODULE_USAGE_EXPORT_QUERY,
            variables: filter,
            fetchPolicy: 'network-only'
        });
        if(data?.allEmployees){
            let data_list= data?.allEmployees?.edges?.map(({node})=>{
                return {
                    EmployeeName:Capitalize(node?.user?.firstName) +' '+ Capitalize(node?.user?.lastName),
                    Soul:node?.user?.soul?.edges[0]?.node?.responseDate? `${displayUtcToUserTimezone(node?.user?.soul?.edges[0]?.node?.responseDate, 'DD MMM YYYY')}\u00A0`: "-",
                    IDP:node?.idp?.edges[0]?.node?.updatedDt? `${displayUtcToUserTimezone(node?.idp?.edges[0]?.node?.updatedDt, 'DD MMM YYYY')}\u00A0`: "-",
                    Feedback:node?.feedback?.edges[0]?.node?.updatedAt? `${displayUtcToUserTimezone(node?.feedback?.edges[0]?.node?.updatedAt, 'DD MMM YYYY')}\u00A0`: "-",
                    Meeting:node?.meeting?.edges[0]?.node?.updatedAt?`${displayUtcToUserTimezone(node?.meeting?.edges[0].node?.updatedAt, 'DD MMM YYYY')}\u00A0`: "-",
                    OKR:node?.okr?.edges[0]?.node?.updatedAt?`${displayUtcToUserTimezone(node?.okr?.edges[0]?.node?.updatedAt, 'DD MMM YYYY')}\u00A0`: "-",
                    Readiness:node?.Readiness?.edges[0]?.node?.updatedAt?`${displayUtcToUserTimezone(node?.Readiness?.edges[0]?.node?.updatedAt, 'DD MMM YYYY')}\u00A0`: "-",
                    Kudos:node?.kudos?.edges[0]?.node?.createdAt?`${displayUtcToUserTimezone(node?.kudos?.edges[0]?.node?.createdAt, 'DD MMM YYYY')}\u00A0`: "-",
                    Grow:hangleDateComparison(node?.coachee?.edges[0]?.node?.createdAt,node?.coaching?.edges[0]?.node?.createdAt)?`${displayUtcToUserTimezone(hangleDateComparison(node?.coachee?.edges[0]?.node?.createdAt,node?.coaching?.edges[0]?.node?.createdAt), 'DD MMM YYYY')}\u00A0`: "-",
                }
            })
            let list = usage_report_ref.current?.concat(data_list)
            setExportUageRport(list)
            calculatePrgress(data?.allEmployees?.totalCount,list?.length)
            if(data?.allEmployees?.pageInfo?.hasNextPage){
                getCompleteUsageData({offset:list?.length})
            }
            else{
                exportToCsv( "org_employees_modules_usage_report",list)
                setTimeout(()=>{
                    setExportDataLoading(false)
                    setProgressCount(1)
                    setExportUageRport([])
                },500)
                
            }
        }
    }
    const hangleDateComparison=(date_1,date_2)=>{
        if(date_1 && date_2){
            const date1 = moment(date_1);
            const date2 = moment(date_2);
            const greaterDate = date1.isAfter(date2) ? date1 : date2;
            return greaterDate
        }
        else {
            if(date_1){
                return date_1
            }
            else {
                return date_2
            }
        }
    }
    const calculatePrgress =(totalCount,load_count)=>{
        let percentage =Math.round((load_count/totalCount)*100)
        setProgressCount(percentage)
    }
    return (
        <>
        <UserModuleUsageExportView {...props}
        export_data_loading={export_data_loading}
        prgress_count={prgress_count}
        getCompleteUsageData={()=>getCompleteUsageData({offset:0})}
        />
        </>
    )
}

export default compose(withApollo)(UsersCompeteModuleUsageContainer);