export const kudos = 'kudos'
const badge_award="awards"
const badge="badge"
const add="add"
const profile="profile"
const kudos_user_route={
    badge_awards_view:`/${kudos}/${badge_award}/${badge}`,
    add_badge:`/${kudos}/${add}/${badge}`,
    user_detail:`/${kudos}/${badge}/${profile}/:id`
}

export default kudos_user_route