import React from 'react';
import { Card, Row, Col, Switch, Divider, Tooltip, Space, Tag, Modal } from 'antd';
import arrayMove from 'array-move';
import { ReactMarkdown, getIntFromString, DeleteIcon, RenderField, getMarks } from '../../look';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { compose } from '../../core';
import { withApollo } from '@apollo/client/react/hoc';
import { withEditReferenceField, withEditSimpleField } from './FormSectionOperation';
import AddFormSection from './AddFormSection';
import AddSimpleField from './AddSimpleField';
import AddReferenceField from './AddReferenceField';

import { SIMPLE_FIELD_SUBSCRIPTION } from '../graphql/SimpleFieldSubscription.gql';
import { REFERENCE_FIELD_SUBSCRIPTION } from '../graphql/ReferenceFieldTypeConnection.gql';

const SimpleReferenceComponent = props => {
  const {
    node,
    simplefield,
    onFormSectionDelete,
    referencefield,
    setActionLoading,
    editSimpleField,
    editReferenceField,
    enableSorting,
    client,
    me
  } = props;
  const history = useHistory();
  const [enableFieldSorting, setEnableFieldSorting] = React.useState(
    history.location.search === `?inId=${getIntFromString(node.id)}` ? true : false
  );
  const [data, setData] = React.useState(
    [
      ...simplefield?.edges.map(({ node }) => ({ ...node, type: 'simplefield' })),
      ...referencefield?.edges.map(({ node }) => ({ ...node, type: 'referencefield' }))
    ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
  );
  const [helptext, setHelptext] = React.useState(null);

  let simpleFieldSubscription = undefined;
  let referenceFieldSubscription = undefined;
  
  const simpleFieldDataRef = React.useRef(null);
  const referenceFieldDateRef = React.useRef(null);
  const m = React.useRef(true)


  React.useEffect(()=>{

    if(me){
      getSubscribedToSimpleFields()
      getSubscribedToReferenceFields()
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[me])
  


  React.useEffect(() => {
    return () => {
      m.current = false
    }
  }, [])

  React.useEffect(() => {
    m.current = true

  }, [])

  React.useEffect(() => {
    return () => {
      if (simpleFieldSubscription) {
        simpleFieldSubscription.unsubscribe();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  React.useEffect(() => {
    return () => {
      if (referenceFieldSubscription) {
        referenceFieldSubscription.unsubscribe();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])





  const getSubscribedToSimpleFields = async () => {
      await client.subscribe({
      query: SIMPLE_FIELD_SUBSCRIPTION,
      fetchPolicy: 'network-only',
    }).subscribe({
      next(result) {

        switch (result?.data?.toolsSimpleFieldSubscription?.mutation) {
         
          case 'UPDATE':
            
            let indexOfSimpleField = simplefield?.edges?.findIndex((value)=>
            getIntFromString(value?.node?.id) === getIntFromString(result?.data?.toolsSimpleFieldSubscription?.simpleField?.id))
            
            let simpleFieldSet = simplefield;
            if(simpleFieldSet.edges?.length){
              simpleFieldSet.edges[indexOfSimpleField] = { node:{...result?.data?.toolsSimpleFieldSubscription?.simpleField, type: 'simplefield'} }
            }
           
            let updatedData = 
            [
              ...simpleFieldSet?.edges.map(({ node }) => ({ ...node })),
              ...referencefield?.edges.map(({ node }) => ({ ...node, type: 'referencefield' })),
            ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))

            setData(updatedData)
        
            break;
          case 'DELETE':
          
            let newSimpleFieldSet;
            newSimpleFieldSet = simpleFieldDataRef.current?.edges?.filter((node)=>

            getIntFromString(node?.node?.id) !== getIntFromString(result?.data?.toolsSimpleFieldSubscription?.simpleField?.id)
            
            )

      
            let components = [
              ...simpleFieldSet?.edges.map(({ node }) => ({ ...node })),
              ...referencefield?.edges.map(({ node }) => ({ ...node, type: 'referencefield' })),
             
            ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
            setData(components);
    
            simpleFieldDataRef.current = {edges:newSimpleFieldSet};
            
            break
          default:
            break
        }
      }
    })
  }



  const getSubscribedToReferenceFields = async () => {
    await client.subscribe({
      query: REFERENCE_FIELD_SUBSCRIPTION,
      fetchPolicy: 'network-only',
    }).subscribe({
      next(result) {

        switch (result?.data?.toolsReferenceFieldSubscription?.mutation) {
         
          case 'UPDATE':
            
            let indexOfRererenceField = referencefield?.edges?.findIndex((value)=>
            getIntFromString(value?.node?.id) === getIntFromString(result?.data?.toolsReferenceFieldSubscription?.referenceField?.id))
            
            let referenceFieldSet = referencefield;
            if(referenceFieldSet.edges?.length){
              referenceFieldSet.edges[indexOfRererenceField] = { node:{...result?.data?.toolsReferenceFieldSubscription?.referenceField, type: 'referencefield'} }
            }
           
            let updatedData = 
            [
              ...simplefield?.edges.map(({ node }) => ({ ...node })),
              ...referenceFieldSet?.edges.map(({ node }) => ({ ...node, type: 'referencefield' })),
            ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
            
            setData(updatedData)
        
            break;
          case 'DELETE':
          
            let newReferenceFieldSet;
            newReferenceFieldSet = referenceFieldDateRef.current?.edges?.filter((node)=>

            getIntFromString(node?.node?.id) !== getIntFromString(result?.data?.toolsReferenceFieldSubscription?.referenceField?.id)
            
            )

      
            let components = [
              ...simplefield?.edges.map(({ node }) => ({ ...node })),
              ...newReferenceFieldSet?.edges.map(({ node }) => ({ ...node, type: 'referencefield' })),
             
            ].sort((a, b) => (a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0))
            setData(components);
    
            referenceFieldDateRef.current = {edges:newReferenceFieldSet};
            
            break
          default:
            break
        }
      }
    })
  }


  

  const DragHandle = SortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999', margin: '-16px', padding: '16px' }} />
  ));

  function renderEditableFieldTag(node, rest) {
    return node.editable ? (
      rest.editable ? (
        <Tag color="success">Editable</Tag>
      ) : (
        <Tag color="error">Not-editable</Tag>
      )
    ) : (
      <Tag color="error">Not-editable</Tag>
    );
  }

  const SortableItem = SortableElement(
    ({ node: { type, inputType, label, id, value, question, simplefieldchoiceSet, helpText, ...rest } }) =>
      type === 'simplefield' ? (
        <Col span={24}>
          <Row>
            <Col span={1}>{enableFieldSorting && <DragHandle />}</Col>

            <Col span={21}>
              <RenderField
                inputType={inputType}
                id={id}
                label={
                  <Space>
                    <ReactMarkdown>{label}</ReactMarkdown>
                      {renderEditableFieldTag(node, rest)}
                      {helpText && <Tooltip title={'Help Text'}><InfoCircleOutlined onClick={() => setHelptext(helpText)}/></Tooltip>}
                  </Space>
                }
                choices={simplefieldchoiceSet.edges
                  .map(({ node }) => node)
                  .map(c => ({
                    label: (
                      <Space align="baseline">
                        {c?.label}
                        <ReactMarkdown>{c?.graphic}</ReactMarkdown>
                      </Space>
                    ),
                    value: c?.value
                  }))}
                marks={getMarks([
                  Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[0]),
                  Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[1])
                ])}
                min={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[0])}
                max={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[1])}
                step={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[2])}
                defaultValue={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[3])}
              />
            </Col>

            <Col span={2} align="right">
              <AddSimpleField
                btn="edit"
                btnText={'Save'}
                simpleField={{ type, inputType, label, id, value, question, simplefieldchoiceSet, ...rest }}
                onSubmit={e => {
                  // setSimpleField(false);
                  // form.setFieldsValue(
                  //   _.set(form.getFieldsValue(true), 'simplefieldId', [...(form.getFieldValue('simplefieldId') || []), e])
                  // );
                  // setSimpleField(true);
                }}
              />

              <Divider type="vertical" style={{ opacity: 0 }} />
              <DeleteIcon tooltipSuffix="Simple Field" type="link" size="sm" style={{ opacity: 0 }} />
            </Col>
          </Row>
        </Col>
      ) : (
        <Col span={24}>
          <Row>
            <Col span={1}>{enableFieldSorting && <DragHandle />}</Col>

            <Col span={21}>
              <RenderField
                inputType={question?.choiceSet?.edges[0]?.node?.inputType}
                id={id}
                label={
                  <Space align="start">
                    <ReactMarkdown>{question?.questionText}</ReactMarkdown>
                      {renderEditableFieldTag(node, rest)}
                      {helpText && <Tooltip title={'Help Text'}><InfoCircleOutlined onClick={() => setHelptext(helpText)}/></Tooltip>}
                  </Space>
                }
                choices={question?.choiceSet.edges
                  .map(({ node }) => node)
                  .map(c => ({
                    label: (
                      <Space align="baseline">
                        <ReactMarkdown>{c?.label}</ReactMarkdown>
                        <ReactMarkdown>{c?.graphic}</ReactMarkdown>
                      </Space>
                    ),
                    value: c?.value
                  }))}
                marks={getMarks([
                  Number(question?.choiceSet[0]?.value.split(':')[0]),
                  Number(question?.choiceSet[0]?.value.split(':')[1])
                ])}
                min={Number(question?.choiceSet[0]?.value.split(':')[0])}
                max={Number(question?.choiceSet[0]?.value.split(':')[1])}
                step={Number(question?.choiceSet[0]?.value.split(':')[2])}
                defaultValue={Number(simplefieldchoiceSet?.edges[0]?.node?.value?.split(':')[3])}
              />
            </Col>

            <Col span={2} align="right">
              <AddReferenceField
                btn="edit"
                btnText={'Save'}
                referenceField={{ type, inputType, label, id, value, question, simplefieldchoiceSet, ...rest }}
                onSubmit={e => {
                  // setSimpleField(false);
                  // form.setFieldsValue(
                  //   _.set(form.getFieldsValue(true), 'simplefieldId', [...(form.getFieldValue('simplefieldId') || []), e])
                  // );
                  // setSimpleField(true);
                }}
              />
              <Divider type="vertical" style={{ opacity: 0 }} />
              <DeleteIcon tooltipSuffix="Reference Field" type="link" size="sm" style={{ opacity: 0 }} />
            </Col>
          </Row>
        </Col>
      )
  );

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Row /* gutter={[24, 24]} */>
        {items.map((node, index) => (
          <SortableItem key={`item-${node.id}`} index={index} node={node} />
        ))}
      </Row>
    );
  });

  const onSort = async sortedItems => {
    setActionLoading(true);
    try {
      await Promise.all(
        sortedItems.map(async (i, index) => {
          i.type === 'simplefield'
            ? editSimpleField({
                id: getIntFromString(i.id),
                inputType: i.inputType.toLowerCase(),
                sequence: index + 1
              })
            : editReferenceField({ id: getIntFromString(i.id), sequence: index + 1 });
        })
      );
      history.push(`${history.location.pathname}?inId=${getIntFromString(node.id)}`);
      setActionLoading(false);
    } catch (e) {
      setActionLoading(false);
      throw Error(e.message);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setData(arrayMove(data, oldIndex, newIndex));
    onSort(arrayMove(data, oldIndex, newIndex));
  };

  return (
    <Card>
      <Row>
        <Col span={1} align="left">
          {enableSorting && <DragHandle />}
        </Col>

        <Col span={15} align="left">
          <Row gutter={24}>
            <Col>
              <h3>{node.title}</h3>
            </Col>
            <Col>{node.editable ? <Tag color="success">Editable</Tag> : <Tag color="error">Not-editable</Tag>}</Col>
          </Row>
        </Col>

        <Col span={8} align="right">
          <Tooltip title={'Sort Sequence'}>
            <Switch
              checked={enableFieldSorting}
              onChange={e => {
                setEnableFieldSorting(e);
                if (e) {
                  history.push(`${history.location.pathname}?inId=${getIntFromString(node.id)}`);
                } else history.push(`${history.location.pathname}`);
              }}
            />
          </Tooltip>
          <Divider type="vertical" />
          <AddFormSection btn="edit" formSection={node} /* onSubmit={e => handleSubmit()} */ />
          <Divider type="vertical" />
          <DeleteIcon tooltipSuffix="Form Section" type="link" size="sm" onClick={() => onFormSectionDelete(getIntFromString(node.id))} />
        </Col>

        <Col span={24}>
          <ReactMarkdown>{node.description}</ReactMarkdown>
          <br />
        </Col>
        <Col span={24}>
          <SortableList useDragHandle helperClass="row-dragging" items={data} onSortEnd={onSortEnd} />
            <Modal centered visible={helptext} footer={null} onCancel={() => setHelptext(null)}>
              <ReactMarkdown>{helptext}</ReactMarkdown>
            </Modal>
        </Col>
      </Row>
    </Card>
  );
};

export default compose(withEditSimpleField, withEditReferenceField, withApollo)(SimpleReferenceComponent);

