import React from "react";
import AIGlobalButtonView from "../component/ai-global-button-view";


const AIGlobalButtonContainer = (props) => {
    return (
        <AIGlobalButtonView {...props}/>
    )
}

export default AIGlobalButtonContainer
