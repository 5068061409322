// @ts-nocheck
import React from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { ME_QUERY } from '../user/graphql/MeQuery.gql';
import { useHistory } from 'react-router-dom';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import { ACCESS_TOKEN, JWT_AUTHENTICATION } from '../../config';
import { getItem, compose, removeItem } from '../core';
import HOME_ROUTE from '../home/route';
import SALES_ROUTE from '../sales/route';
import SURVEY_ROUTE from '../survey/route';
import TOOL_ROUTE from '../tool/route';
import gql from 'graphql-tag';
import {  globalPermissionValidator } from 'modules/look';
import { kudos_permission } from 'Permissions/kudos.permission';
import SideNavBar from './side-nav'
import idp_route from 'modules/IDP/route';
import MappReviewContainer from 'modules/mapp-review/containers/mappReviewContainer';
export const SURVEY_CONDITION_CHECK = gql`
query me($survey:ID){
    response:me{
        id
        responseSet(survey: $survey,last:1) {
            totalCount
            edges{
              node{
                id
              }
            }
        }
    }
}`

export const THREECS_CONDITION_CHECK = gql`
query answers($survey: ID,$question: ID,$user:ID){
    answers(response_Survey:$survey,question:$question,response_User:$user){
      totalCount
    }
}
`;

const PageLayout = props => {
  const {app_sidebar_expand} = props
  const [jwt, setJwt] = React.useState(null);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const handleLoggedIn = React.useRef(() => { });
 

  handleLoggedIn.current = async () => {
    const storedJwt = await getItem(ACCESS_TOKEN);
    setTimeout(3000, setLoggedIn(storedJwt));
  };


  React.useEffect(() => {
    handleLoggedIn.current();
  }, []);

  React.useEffect(() => {
    if (!window.location.pathname.includes("one-on-one")) {
      localStorage.removeItem("one_on_one_filter")
    }
    if (!window.location.pathname.includes("role-definition")) {
      localStorage.removeItem("role_definition_navigation")
    }
  }, [])

  const noNavbarRoutes = ['/','/user/login', '/intro', '/intro/getintouch', '/intro/soul', '/page/contact-us', '/survey/response/',
    SALES_ROUTE.whoposhoro.replace(':id', ''), SALES_ROUTE.oneonone.replace(':id', ''), SALES_ROUTE.okr.replace(':id', ''),
    SALES_ROUTE.feedback.replace(':id', ''), SALES_ROUTE.coaching.replace(':id', ''), SALES_ROUTE.readiness.replace(':id', ''),"/page/terms-and-condition/privacy","/page/terms-and-condition/terms-and-condition","/page/terms-and-condition","/page/faq",
  ].includes(window.location.pathname.replace(/[0-9]/g, '')) || window.location.pathname?.includes("user/login") ||window.location.pathname?.includes(SURVEY_ROUTE.tokenSurvey.replace(":token","")) || window.location.pathname?.includes("user/activate") || window.location.pathname?.includes("role/idp/manager");

  return (
    <>
      {
        !noNavbarRoutes && (
          <SideNavBar {...props} />
        )
      }
      <div className={noNavbarRoutes ? 'no-navbar-router' : `main-router-container ${app_sidebar_expand ? 'collapsed-router' : ''}`}>
        <BodyDiv>{props.children}</BodyDiv>
      </div>
      <MappReviewContainer {...props}/>
    </>
  );
};

export default compose(withApollo,
  graphql(ME_QUERY, {
    options: () => {
      return {
        fetchPolicy: 'network-only'
      };
    },
    props({ data: { loading, error, me, subscribeToMore, updateQuery } }) {
      
      if (error) {
        throw new Error(error.message);
      }
      if(me){
        let manager_user = {
          ceo:me?.employee?.orgCeoEmployee?.totalCount > 0,
          poc:me?.employee?.orgPocEmployee?.totalCount > 0,
          vertical_head:me?.employee?.verticalHeadEmployee?.totalCount > 0,
          team_manager:me?.employee?.teamManagerEmployee?.totalCount > 0,
          org_user:me?.employee?.organizationSet?.edges?.length?true:false
        }
        localStorage.setItem("manager_user",JSON.stringify({manager_user}))
      }
      return { loading, error, me, subscribeToMore, updateQuery };
    }
  }),
)(PageLayout);
export function getIntFromString(base64String) {
  return typeof base64String === "string"
    ? Number(window.atob(base64String).split(":")[1])
    : base64String;
}

const BodyDiv = styled.div`
  position: relative;
  min-height: '100vh';
  box-sizing: border-box;
`;