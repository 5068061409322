import gql from 'graphql-tag';

export const SCAPE_LIST = gql`
query scapes {
    scapes(active:true) {
        edges {
            node {
                id
                name
            }
        }
    }
}
`
export const ALL_ORGANIZTION_LEVEL_LIST = gql`
query verticals($organization:ID)  {
    verticals(organization:$organization) {
      edges{
        node{
          id
          name
          teamSet{
              edges{
                  node{
                      id
                      name
                      employees{
                          edges{
                              node{
                              id
                              user{
                                  id
                                  firstName
                                  lastName
                                  username
                                  }
                              }
                          }
                      }
                  }
              }
          }
        }
      }
    }
}
  
`
export const ALL_ORGANIZTION_LEVEL_LIST_FOR_CEO_AND_POC = gql`
query organizationById($id:ID!){
  organizationById(id:$id){
    id
    employees{
      edges{
        node{
          id
          user{
            id
            firstName
            lastName
            username
          }
          verticalMembersEmployee{
            edges{
              node{
                id
                name
              }
            }
          }
          teamMembersEmployee{
            edges{
              node{
                id
                name
              }
            }
					}
        }
      }
    }
  }
}
`
export const ALL_EMPLOYEE_LIST = gql`
query me{
    me{
        id
        employee {
            id
            orgPocEmployee {
            edges {
                node {
                id
                name
                employees {
                    edges {
                    node {
                        id
                        user{
                        id
                        firstName
                        lastName
                        }
                    }
                    }
                }
                }
            }
            }
            orgCeoEmployee {
            edges {
                node {
                id
                name
                employees {
                    edges {
                    node {
                        id
                        user{
                            id
                        firstName
                        lastName
                        }
                    }
                    }
                }
                }
            }
            }
            teamManagerEmployee {
            edges {
                node {
                id
                name
                employees {
                    edges {
                    node {
                        id
                        user{
                            id
                        firstName
                        lastName
                        }
                    }
                    }
                }
                }
            }
            }
            teamMembersEmployee{
              edges{
                node{
                  id
                  name
                  employees{
                    edges{
                      node{
                        id
                        user{
                          id
                          firstName
                          lastName
                        }
                      }
                    }
                  }
                }
              }
            }
            verticalHeadEmployee {
            edges {
                node {
                id
                name
                employees {
                    edges {
                    node {
                        id
                        user{
                            id
                        firstName
                        lastName
                        }
                    }
                    }
                }
                }
            }
        }
      }
    }
}
  
`
export const SCAPE_BY_ID_QUERY = gql`
query scapeById($id:ID!){
    scapeById(id: $id) {
        id
        name
        description
        active
        survey {
          id
          name
        }
        parametricgroupSet {
          edges {
            node {
              id
              name
              scape {
                id
              }
              parameterSet {
                edges {
                  node {
                    id
                    show
                    choice {
                      id
                      value
                    }
                  }
                }
              }
            }
          }
        }
    }
}
`
export const TABLE_DATA_NEW_QUERY = gql`
query me($id:ID!,$id_In: String,$after:String,$user_FirstName: String){
    tableDataNew: me {
        id
        username
        employee {
          id
          organizationSet {
            edges {
              node {
                id
                employees(id_In: $id_In,after:$after,first:100,user_FirstName:$user_FirstName) {
                  totalCount
                  pageInfo{
                    hasNextPage
                    endCursor
                  }
                  edges {
                    node {
                      id
                      verticalMembersEmployee{
                        edges{
                          node{
                            id
                          }
                        }
                      }
                      teamMembersEmployee{
                        edges{
                          node{
                            id
                          }
                        }
                      }
                      user {
                        id
                        firstName
                        lastName
                        responseSet(survey:$id, last: 1) {
                          edges {
                            node {
                              id
                              responseDate
                              answerSet {
                                edges {
                                  node {
                                    id
                                    answer
                                    sequence
                                    question{
                                      id
                                      choiceSet(first:1){
                                        edges{
                                          node{
                                            id
                                            inputType
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
    }
}
  
`