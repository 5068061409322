// @ts-nocheck
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';
import userResolvers from '../resolvers';

// Query
import { ME_QUERY } from '../graphql/MeQuery.gql';
import { GET_USER_IMPLICIT } from '../graphql/GetProfileImplicit.gql';
import { MY_RESPONSE } from '../graphql/MyResponse.gql';
import { USER_QUERY } from '../graphql/UserQuery.gql';
import { USERS_QUERY } from '../graphql/UsersQuery.gql';
import { LIST_ALL_USERS_QUERY } from '../graphql/ListAllUsersQuery.gql';
import { ALL_NONEMPLOYEE_USERS } from '../graphql/GetAllNonEmployeeUsers.gql';
import { LIST_USERS_FOR_ORG_QUERY } from '../graphql/ListUsersForOrg.gql';
import { RESEND_ACTIVATION_EMAIL } from '../graphql/ResendActivationEmail.gql';

// Client
import { USER_STATE_QUERY } from '../graphql/UserStateQuery.client.gql';
import { UPDATE_USER_FILTER } from '../graphql/UpdateUserFilter.client.gql';

// Mutation
import { VERIFY_ACCOUNT } from '../graphql/VerifyAccount.gql';
import { REGISTER } from '../graphql/RegisterMutation.gql';
import { LOGIN } from '../graphql/LoginQuery.gql';
import { EDIT_PROFILE } from '../graphql/EditProfile.gql';
import { ARCHIVE_ACCOUNT_MUTATION } from '../graphql/ArchiveAccount.gql';
import { DELETE_ACCOUNT_MUTATION } from '../graphql/DeleteAccount.gql';
import { PASSWORD_RESET_MAIL } from '../graphql/PasswordResetMail.gql';
import { PASSWORD_CHANGE_MUTATION } from '../graphql/PasswordChangeMutation.gql';
import { PASSWORD_RESET } from '../graphql/PasswordReset.gql';
import { PASSWORD_SET } from '../graphql/PasswordSet.gql';
import { PAGINATION_LIMIT } from '../../../config';
import { GET_USER_BY_USERNAME_OR_EMAIL_QUERY } from '../graphql/GetUserByUsernameOrEmailQuery.gql';
import { removeTypename } from '../../core/clientStorage';
import { UNAUTH_VERIFY_TOKEN } from '../graphql/UnAuthTokenVerification.gql'
import { USER_LEVEL_QUERY } from '../graphql/UserLevel.gql';
import { ALL_USER_PERMISSION } from '../graphql/GetUserPermission.gql';
import { message } from 'antd';
import { ADD_USER_TO_ORG } from 'modules/organization/graphql/AllOrganizationQuery.gql';
// Query
export const withGetProfileImplicit = Component =>
  graphql(GET_USER_IMPLICIT, {
    options: props => {
      let id = '';
      if (props?.me) {
        id = props?.me?.profile?.id;
      } 
      return {
        variables: { id: id}
      };
    },
    props({ data: { loading, error, profile, subscribeToMore, updateQuery } }) {
      return { loadingVerifyToken: loading, error, profile, subscribeToMore, updateQuery };
    }
  })(Component);

export const withResendActivationEmail = Component =>
  graphql(RESEND_ACTIVATION_EMAIL, {
    props: ({ mutate }) => ({
      resendActivationEmail: async email => {
        const {
          data: { resendActivationEmail }
        } = await mutate({
          variables: { email }
        });
        return resendActivationEmail;
      }
    })
  })(Component);

export const withUser = Component =>
  graphql(USER_QUERY, {
    options: props => {
      let id = '';
      if (props.match) {
        id = props.match.params.id;
      } else if (props.navigation) {
        id = props.navigation.state.params.id;
      }

      return {
        variables: { id: Number(id) }
      };
    },
    props({ data: { loading, error, user, subscribeToMore, updateQuery } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, user, subscribeToMore, updateQuery };
    }
  })(Component);

export const withUsers = Component =>
  graphql(USERS_QUERY, {
    options: props => {
      return {
        variables: {
          first: PAGINATION_LIMIT
        }
      };
    },
    props({ data }, history) {
      const { loading, error, fetchMore, users, subscribeToMore, updateQuery } = data;
      const loadData = (after, dataDelivery) => {
        return fetchMore({
          variables: {
            after
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const totalCount = fetchMoreResult.users.totalCount;
            const newEdges = fetchMoreResult.users.edges;
            const pageInfo = fetchMoreResult.users.pageInfo;
            const displayedEdges = dataDelivery === 'add' ? [...previousResult.users.edges, ...newEdges] : newEdges;

            return {
              // By returning `cursor` here, we update the `fetchMore` function
              // to the new cursor.
              users: {
                totalCount,
                edges: displayedEdges,
                pageInfo,
                __typename: 'Users'
              }
            };
          }
        });
      };
      return { loading, error, users, subscribeToMore, updateQuery, loadData };
    }
  })(Component);

export const withGetAllNonEmployeeUsers = Component =>
  graphql(ALL_NONEMPLOYEE_USERS, {
    options: ({ filter, orderBy, pagination }) => {
      let filters = filter;
      if (filter.name_Icontains === '') filters = _.omit(filter, 'name_Icontains');
      else filters.nameIcontains = filter.name_Icontains;
      return { variables: { ...pagination, ...filters, orderBy } };
    },
    props({ data: { loading, error, getAllNonEmployeeUsers, subscribeToMore, updateQuery } }) {
      return { loading, error, getAllNonEmployeeUsers, subscribeToMore, updateQuery };
    }
  })(Component);

export const withMe = Component =>
  graphql(ME_QUERY, {
    props({ data: { loading, error, me, subscribeToMore, updateQuery } }) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loading, error, me, subscribeToMore, updateQuery };
    }
  })(Component);

export const withMeLevel = Component =>
  graphql(USER_LEVEL_QUERY, {
    options: props => {
      return {
        variables: {
          first: 1
        },
      };
    },
    props({ data }) {
      const { loading, error, level, subscribeToMore, updateQuery } = data;
      return { loading, error, level, subscribeToMore, updateQuery };
    }
  })(Component);

export const withListAllUsers = Component =>
  graphql(LIST_ALL_USERS_QUERY, {
    options: ({ filter, orderBy, pagination }) => {
      return { variables: { ...pagination, ...filter, orderBy } };
    },
    props({ data }) {
      const { loading, error, listAllUsers, subscribeToMore, updateQuery } = data;
      return { loading, error, listAllUsers, subscribeToMore, updateQuery };
    }
  })(Component);

export const withListUsersForOrg = Component =>
  graphql(LIST_USERS_FOR_ORG_QUERY, {
    options: props => {
      return {
        variables: {
          first: PAGINATION_LIMIT
        }
      };
    },
    props({ data }, history) {
      const { loading, error, fetchMore, listUsersForOrg, subscribeToMore, updateQuery } = data;
      const loadData = (after, dataDelivery) => {
        return fetchMore({
          variables: {
            after
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const totalCount = fetchMoreResult.listUsersForOrg.totalCount;
            const newEdges = fetchMoreResult.listUsersForOrg.edges;
            const pageInfo = fetchMoreResult.listUsersForOrg.pageInfo;
            const displayedEdges =
              dataDelivery === 'add' ? [...previousResult.listUsersForOrg.edges, ...newEdges] : newEdges;

            return {
              // By returning `cursor` here, we update the `fetchMore` function
              // to the new cursor.
              listUsersForOrg: {
                totalCount,
                edges: displayedEdges,
                pageInfo,
                __typename: 'ListUsersForOrg'
              }
            };
          }
        });
      };
      return { loading, error, listUsersForOrg, subscribeToMore, updateQuery, loadData };
    }
  })(Component);

export const withGetUserByUsernameOrEmail = Component =>
  graphql(GET_USER_BY_USERNAME_OR_EMAIL_QUERY, {
    options: props => {
      return {
        variables: props.me && (props.me.email ? { email: props.me.email } : { username: props.me.username })
      };
    },
    props: ({ data }) => {
      // eslint-disable-next-line no-unused-vars
      const { loading, error, users, subscribeToMore, updateQuery } = data;
      return { loading, users, subscribeToMore, /* loadData, */ updateQuery };
    }
  })(Component);

export const withMyResponse = Component =>
  graphql(MY_RESPONSE, {
    options: ({ filter, orderBy, pagination }) => {
      return {
        variables: {
          ...filter,
          orderBy,
          ...pagination
        },
        fetchPolicy: "cache-and-network"
      };
    },
    props({ data }) {
      const { loading, error, fetchMore, allResponsesByImplicitUserId, subscribeToMore,  updateQuery } = data;
      const loadData = (pagination, dataDelivery) => {
        return fetchMore({
          variables: {
            ...pagination
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const totalCount = fetchMoreResult.allResponsesByImplicitUserId.totalCount;
            const newEdges = fetchMoreResult.allResponsesByImplicitUserId.edges;
            const pageInfo = fetchMoreResult.allResponsesByImplicitUserId.pageInfo;
            const displayedEdges =
              dataDelivery === 'add' ? [...previousResult.allResponsesByImplicitUserId.edges, ...newEdges] : newEdges;

            return {
              // By returning `cursor` here, we update the `fetchMore` function
              // to the new cursor.
              allResponsesByImplicitUserId: {
                totalCount,
                edges: displayedEdges,
                pageInfo,
                __typename: 'AllResponsesByImplicitUserId'
              }
            };
          }
        });
      };
      return { loading, error, allResponsesByImplicitUserId, subscribeToMore, updateQuery, loadData };
    }
  })(Component);

// Mutation
export const withRegister = Component =>
  graphql(REGISTER, {
    props: ({ mutate }) => ({
      register: async values => {
        const {
          data: { register }
        } = await mutate({
          variables: { ...values }
        });

        return register;
      }
    })
  })(Component);

export const withLogin = Component =>
  graphql(LOGIN, {
    props: ({ mutate }) => ({
      login: async values => {
        const {
          data: { tokenAuth }
        } = await mutate({
          variables: { ...values }
        });
        return tokenAuth;
      }
    })
  })(Component);

export const withVerifyToken = Component =>
  graphql(UNAUTH_VERIFY_TOKEN, {
    options: props => {
      let token = '';
      if (props.match) {
        token = props.match.params.token;
      } else if (props.navigation) {
        token = props.navigation.state.params.token;
      }

      return {
        variables: { token }
      };
    },
    props({ data: { loading, error, verifyToken, subscribeToMore, updateQuery } }) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      return { loadingVerifyToken: loading, error, verifyToken, subscribeToMore, updateQuery };
    }
  })(Component);

export const withVerifyAccount = Component =>
  graphql(VERIFY_ACCOUNT, {
    props: ({ mutate }) => ({
      verifyAccount: async token => {
        const {
          data: { verifyAccount }
        } = await mutate({
          variables: { token }
        });
        return verifyAccount;
      }
    })
  })(Component);

export const withArchiveAccount = Component =>
  graphql(ARCHIVE_ACCOUNT_MUTATION, {
    props: ({ mutate }) => ({
      archiveAccount: async values => {
        const {
          data: { archiveAccount }
        } = await mutate({
          variables: { ...values }
        });
        return archiveAccount;
      }
    })
  })(Component);

export const withDeleteAccount = Component =>
  graphql(DELETE_ACCOUNT_MUTATION, {
    props: ({ mutate }) => ({
      deleteAccount: async values => {
        const {
          data: { deleteAccount }
        } = await mutate({
          variables: { ...values }
        });
        return deleteAccount;
      }
    })
  })(Component);

export const withPasswordResetMail = Component =>
  graphql(PASSWORD_RESET_MAIL, {
    props: ({ mutate }) => ({
      passwordResetMail: async email => {
        const {
          data: { sendPasswordResetEmail }
        } = await mutate({
          variables: { email }
        });

        return sendPasswordResetEmail;
      }
    })
  })(Component);

export const withPasswordChange = Component =>
  graphql(PASSWORD_CHANGE_MUTATION, {
    props: ({ mutate }) => ({
      passwordChange: async values => {
        const {
          data: { passwordChange }
        } = await mutate({
          variables: { ...values }
        });

        return passwordChange;
      }
    })
  })(Component);

  export const withPasswordSet = Component =>
  graphql(PASSWORD_SET, {
    props: ({ mutate }) => ({
      passwordSet: async values => {
        try{
          const {
            data: { passwordSet }
          } = await mutate({
            variables: { ...values }
          });
          if(passwordSet?.errors){
            message.destroy();
            let error_list = []
            if(passwordSet?.errors?.nonFieldErrors?.length){
              error_list = passwordSet?.errors?.nonFieldErrors?.map(i=>i?.message)
            }
            if(passwordSet?.errors?.newPassword2?.length){
              error_list = error_list?.concat(passwordSet?.errors?.newPassword2?.map(i=>i?.message))
            }
            let message_text = error_list[1]? error_list[1] : error_list[0]
            message.error(message_text || 'failed to set password')
          }
          else{
            return passwordSet;
          }
        }
        catch(error){
          console.error('catch',error);
        }
      }
    })
  })(Component);

export const withPasswordReset = Component =>
  graphql(PASSWORD_RESET, {
    props: ({ mutate }) => ({
      passwordReset: async values => {
        const {
          data: { passwordReset }
        } = await mutate({
          variables: { ...values }
        });

        return passwordReset;
      }
    })
  })(Component);

export const withEditProfile = Component =>
  graphql(EDIT_PROFILE, {
    props: ({ mutate }) => ({
      editProfile: async values => {
        try {
          const {
            data: { updateProfile }
          } = await mutate({
            variables: {
              ...values
            }
          });
          return updateProfile.profile;
        } catch (e) {
          console.error(e);
        }
      }
    })
  })(Component);

// Client
export const withUserState = Component =>
  graphql(USER_STATE_QUERY, {
    props({ data }) {
      const { orderBy, ...rest } = data.userState;
      const userState = { ...removeTypename(rest), orderBy };
      return { ...userState, stateLoading: data.loading };
    }
  })(Component);

export const withUserFilterUpdating = Component =>
  graphql(UPDATE_USER_FILTER, {
    props: ({ mutate }) => ({
      onOrderByChange(orderBy) {
        mutate({ variables: { orderBy } });
      },
      onPaginationChange(pagination) {
        mutate({ variables: { pagination }}) 
      },
      onFiltersRemove() {
        mutate({ variables: { ...userResolvers.defaults.userState } });
      },
      onUsernameChange(username_Icontains) {
        mutate({ variables: { filter: { username_Icontains } } });
      },
      onFirstNameChange(firstName_Icontains) {
        mutate({ variables: { filter: { firstName_Icontains } } });
      },
      onLastNameChange(lastName_Icontains) {
        mutate({ variables: { filter: { lastName_Icontains } } });
      },
      onEmailChange(email_Icontains) {
        mutate({ variables: { filter: { email_Icontains } } });
      }
    })
  })(Component);


  export const userPermission = Component =>
  graphql(ALL_USER_PERMISSION, {
    props({ data: { loading, error, getPermissions, subscribeToMore, updateQuery } }) {
      // if (error) {
      //   throw new Error(error.message);
      // }
      let userPermission= getPermissions?.edges?.map(({node})=>node?.codename)
      return { loading, error, userPermission, subscribeToMore, updateQuery };
    }
  })(Component);

  export const withAddUserToOrg = Component =>
    graphql(ADD_USER_TO_ORG, {
      props: ({ mutate }) => ({
        createMember: async values => {
          const {
            data: { createMember }
          } = await mutate({
            variables: { ...values }
          });
  
          return createMember.member;
        }
      })
    })(Component);
