import gql from 'graphql-tag';

export const ALL_USER_PERMISSION = gql`
  query getPermissions {
    me {
      id
      badge: userPermissions(codename_Iendswith: "badge") {
        edges {
          node {
            id
            codename
          }
        }
      }
      badgefamily: userPermissions(codename_Iendswith: "badgefamily") {
        edges {
          node {
            id
            codename
          }
        }
      }
      badgeaward: userPermissions(codename_Iendswith: "badgeaward") {
        edges {
          node {
            id
            codename
          }
        }
      }
      meeting: userPermissions(codename_Iendswith: "meeting") {
        edges {
          node {
            id
            codename
          }
        }
      }
      meetingnotes: userPermissions(codename_Iendswith: "meetingnotes") {
        edges {
          node {
            id
            codename
          }
        }
      }
      actionitem: userPermissions(codename_Iendswith: "actionitem") {
        edges {
          node {
            id
            codename
          }
        }
      }
      meetingfeedback: userPermissions(codename_Iendswith: "meetingfeedback") {
        edges {
          node {
            id
            codename
          }
        }
      }
      okr: userPermissions(codename_Icontains: "okr") {
        edges {
          node {
            id
            codename
          }
        }
      }
      discardrequest: userPermissions(codename_Iendswith: "discardrequest") {
        edges {
          node {
            id
            codename
          }
        }
      }
      tracking: userPermissions(codename_Iendswith: "tracking") {
        edges {
          node {
            id
            codename
          }
        }
      }
      contributor: userPermissions(codename_Iendswith: "contributor") {
        edges {
          node {
            id
            codename
          }
        }
      }
      okrcompetency: userPermissions(codename_Iendswith: "okrcompetency") {
        edges {
          node {
            id
            codename
          }
        }
      }
      competency: userPermissions(codename_Iendswith: "competency") {
        edges {
          node {
            id
            codename
          }
        }
      }
      feedback: userPermissions(codename_Iendswith: "feedback") {
        edges {
          node {
            id
            codename
          }
        }
      }
      soul: userPermissions(codename_Iendswith: "soul") {
        edges {
          node {
            id
            codename
          }
        }
      }
      employeelevel: userPermissions(codename_Iendswith: "employeelevel") {
        edges {
          node {
            id
            codename
          }
        }
      }
      qualifyinglevel: userPermissions(codename_Iendswith: "qualifyinglevel") {
        edges {
          node {
            id
            codename
          }
        }
      }
      role: userPermissions(codename_Iendswith: "role") {
        edges {
          node {
            id
            codename
          }
        }
      }
      jobfamily: userPermissions(codename_Iendswith: "jobfamily") {
        edges {
          node {
            id
            codename
          }
        }
      }
      vertical: userPermissions(codename_Iendswith: "vertical") {
        edges {
          node {
            id
            codename
          }
        }
      }
      responsibility: userPermissions(codename_Iendswith: "responsibility") {
        edges {
          node {
            id
            codename
          }
        }
      }
      eligibility: userPermissions(codename_Iendswith: "eligibility") {
        edges {
          node {
            id
            codename
          }
        }
      }
      education: userPermissions(codename_Iendswith: "education") {
        edges {
          node {
            id
            codename
          }
        }
      }
      behaviourallyanchoredrating: userPermissions(codename_Iendswith: "behaviourallyanchoredrating") {
        edges {
          node {
            id
            codename
          }
        }
      }
      user: userPermissions(codename_Iendswith: "user") {
        edges {
          node {
            id
            codename
          }
        }
      }
      employee: userPermissions(codename_Iendswith: "employee") {
        edges {
          node {
            id
            codename
          }
        }
      }
      organization: userPermissions(codename_Iendswith: "organization") {
        edges {
          node {
            id
            codename
          }
        }
      }
      tip: userPermissions(codename_Iendswith: "tip") {
        edges {
          node {
            id
            codename
          }
        }
      }
      readiness: userPermissions(codename_Iendswith: "readiness") {
        edges {
          node {
            id
            codename
          }
        }
      }
      readinesslevel: userPermissions(codename_Iendswith: "readinesslevel") {
        edges {
          node {
            id
            codename
          }
        }
      }
      scapeReport: userPermissions(codename_Iendswith:"scape") {
        edges {
          node {
            id
            codename
          }
        }
      }
      coachingEngagement: userPermissions(codename_Iendswith:"conversation") {
        edges {
          node {
            id
            codename
          }
        }
      }
      story: userPermissions(codename_Iendswith:"story") {
        edges {
          node {
            id
            codename
          }
        }
      }
      RatingScale: userPermissions(codename_Iendswith:"ratingscale") {
        edges {
          node {
            id
            codename
          }
        }
      }
      member:userPermissions(codename_Iendswith:"member") {
        edges {
          node {
            id
            codename
          }
        }
      }
      competencyassessment:userPermissions(codename_Iendswith:"competencyassessment") {
        edges {
          node {
            id
            codename
          }
        }
      }
      parametricGroup: userPermissions(codename_Iendswith:"parametricgroup") {
        edges {
          node {
            id
            codename
          }
        }
      }
      parameter: userPermissions(codename_Iendswith:"parameter") {
        edges {
          node {
            id
            codename
          }
        }
      }

      impactnarrative: userPermissions(codename_Iendswith:"impactnarrative") {
        edges {
          node {
            id
            codename
          }
        }
      }

      survey: userPermissions(codename_Iendswith:"survey") {
        edges {
          node {
            id
            codename
          }
        }
      }
      
      idp: userPermissions(codename_Iendswith:"idp") {
        edges {
          node {
            id
            codename
          }
        }
      }

      skill: userPermissions(codename_Iendswith:"skill") {
        edges {
          node {
            id
            codename
          }
        }
      }

      competencycategory: userPermissions(codename_Iendswith:"competencycategory") {
        edges {
          node {
            id
            codename
          }
        }
      }
      response:userPermissions(codename_Iendswith:"response") {
        edges {
          node {
            id
            codename
          }
        }
      }
      notification: userPermissions(codename_Iendswith:"notification") {
        edges {
          node {
            id
            codename
          }
        }
      }
      token:userPermissions(codename_Iendswith:"_token") {
        edges {
          node {
            id
            codename
          }
        }
      }
      answer:userPermissions(codename_Iendswith:"answer") {
        edges {
          node {
            id
            codename
          }
        }
      }
      choice:userPermissions(codename_Iendswith:"choice") {
        edges {
          node {
            id
            codename
          }
        }
      }
      group:userPermissions(codename_Iendswith:"group") {
        edges {
          node {
            id
            codename
          }
        }
      }
      responsecomparerequest:userPermissions(codename_Iendswith:"responsecomparerequest") {
        edges {
          node {
            id
            codename
          }
        }
      }
      team: userPermissions(codename_Iendswith: "team") {
        edges {
          node {
            id
            codename
          }
        }
      }

      license : userPermissions(codename_Iendswith: "license") {
        edges {
          node {
            id
            codename
          }
        }
      }
      aiTipSettings : userPermissions(codename_Iendswith:"tipsettings"){
        edges {
          node {
            id
            codename
          }
        }
      } 
      runpodtips: userPermissions(codename_Iendswith:"runpodtips"){
        edges {
          node {
            id
            codename
          }
        }
      } 
      notificationsettings: userPermissions(codename_Iendswith:"notificationsettings"){
        edges {
          node {
            id
            codename
          }
        }
      }
      badgeawardcomment: userPermissions(codename_Iendswith:"badgeawardcomment"){
        edges {
          node {
            id
            codename
          }
        }
      }
      badgeawardcommentreaction: userPermissions(codename_Iendswith:"badgeawardcommentreaction"){
        edges {
          node {
            id
            codename
          }
        }
      }
      badgeawardreaction: userPermissions(codename_Iendswith:"badgeawardreaction"){
         edges {
          node {
            id
            codename
          }
        }
      }
      casper: userPermissions(codename_Iendswith:"_caspermessage"){
        edges {
          node {
            id
            codename
          }
        }
      }
      review: userPermissions(codename_Iendswith:"review"){
        edges {
          node {
            id
            codename
          }
        }
      }
    }
  }
`;


