import { SEND_MESSAGE_MUTATION } from '../query/casper-query.gql';
import { UPDATE_MESSAGE_MUTATION } from '../query/casper-query.gql';
import { graphql } from '@apollo/client/react/hoc';
import { message } from "antd";
import _ from 'lodash';

export const withSendMessage = Component =>
    graphql(SEND_MESSAGE_MUTATION, {
        props: ({ mutate, history }) => ({
            createMessage: async values => {
                try {
                    const {
                        data: { createMessage }
                    } = await mutate({
                        variables: {
                            ...values
                        }
                    });
                    message.destroy();
                    return createMessage;
                } catch (e) {
                    message.destroy();
                    message.error("Couldn't perform the action");
                    console.error(e);
                }
            }
        })
    })(Component);

    export const withUpdateMessage = Component =>
        graphql(UPDATE_MESSAGE_MUTATION, {
            props: ({ mutate, history }) => ({
                updateMessage: async values => {
                    try {
                        const {
                            data: { message }
                        } = await mutate({
                            variables: {
                                ...values
                            }
                        });
                        message && message.destroy();
                        return message;
                    } catch (e) {
                        message.destroy();
                        message.error("Couldn't perform the action");
                        console.error(e);
                    }
                }
            })
        })(Component);
