
import React from "react";
import { dashboard_home_icons } from "../../icons";
import { Button, message, Progress, Spin } from "antd";
import PocDashboardAssessmentContainer from "../../containers/dashboard/pocDasboardSurveyAssessmentContainer";
import poc_dashboard_routes from "modules/poc-dashboard/routes";
import UsersCompeteModuleUsageContainer from "modules/poc-dashboard/containers/dashboard/usage_module/usersCompeteModuleUsageContainer";
const PocDashboardHomeView = (props) => {

    const { view_dashboard_cards, org_employee_level_count, banner_card_loading, navigateRoute, licenses, licenses_loading, analystic_list, analytics_loading } = props
    const [banner_cards, setBannerCards] = React.useState([
        {
            icon: dashboard_home_icons.total_vertical_icon,
            title: "Total Verticals",
            key: "total_verticals",
            visibility: view_dashboard_cards.total_verticals
        },
        {
            icon: dashboard_home_icons.total_team_icon,
            title: "Total Teams",
            key: "total_teams",
            visibility: view_dashboard_cards.total_teams
        },
        {
            icon: dashboard_home_icons.total_managers_icon,
            title: "Total Managers",
            key: "total_mangers",
            visibility: view_dashboard_cards.total_managers
        },
        {
            icon: dashboard_home_icons.total_reportee_icon,
            title: "Total Reportees",
            key: "total_reporties",
            visibility: view_dashboard_cards.total_reporties
        }
    ])
    React.useEffect(() => {
        if (org_employee_level_count) {
            let count_card = banner_cards.map(item => {
                return {
                    ...item,
                    count: org_employee_level_count[item?.key]
                }
            })
            setBannerCards(count_card)
        }
    }, [org_employee_level_count])

    const licenceProgressCount = (progress, fontSize, hide_use = false) => {
        return (<div>
            <h4 className="poc-licence-sub-title" style={{ fontWeight: "600", margin: "0px", fontSize: "1em" }}>{progress}%</h4>
            {
                !hide_use && (<p className="poc-licence-sub-title" style={{ color: "#AABAC6", margin: "0px", fontSize: fontSize || ".5em" }}>Used</p>)
            }

        </div>)
    }

    const navigateToUsageReport = (tab, data) => {
        if (data?.key || tab === "mapp_review") {
            let url = poc_dashboard_routes.module_usage?.replace(":tab", tab)
            if (data) {
                url = `${url}?module=${data?.key}`
            }
            navigateRoute(url)
        } else {
            message.destroy()
            message.error("Detailed report not available")
        }
    }

    const getAnalyticsOverview=(list)=>{
        let percentage = list?.map(i=>i?.percentage)?.reduce((a,b)=>a+b,0)
        return Math.round(percentage/(list?.length))
    }

    return (
        <div className="poc-dahboard-root">
            <Spin spinning={banner_card_loading}>
                <div className="dashboard-home-banner-cards">
                    {banner_cards?.map(item => (
                        <div className="dashboard-banner-card-continer">
                            <div className="dasboard-banner-card">
                                <img className="poc-banner-icon" src={item?.icon} alt="" />
                                <h4 className="poc-banner-title" style={{ marginTop: ".5em" }}>{item?.title}</h4>
                                <h4 className="poc-banner-count">{item?.count}</h4>
                            </div>
                        </div>
                    ))}
                </div>
            </Spin>
            <div className="poc-card-flex">
                <div style={{ width: "100%", height: "100%" }}>
                    {view_dashboard_cards.analysis ? <div className="dasboard-banner-card card-height" >
                        <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"row",gap:"1em",width:'100%',flexWrap:"wrap"}}>
                            <h4 className="poc-dashboard-card-title">Analytics</h4>
                            <UsersCompeteModuleUsageContainer {...props}/>
                        </div>
                        
                        {
                           analytics_loading && (
                                <div style={{
                                    width:"100%",
                                    height:"15em",
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center"
                                }}>
                                    <Spin spinning={analytics_loading}></Spin>
                                </div>
                           ) 
                        }
                        
                        {
                            analystic_list?.length ?
                                <div className="poc-analystic">
                                    <div style={{ display: "flex", flexDirection: "row", gap: "1em", alignItems: "center" }}>
                                        <Progress style={{cursor:"pointer"}} onClick={() => navigateToUsageReport("mapp_review")} type="circle" percent={getAnalyticsOverview(analystic_list)} width={"100px"} format={() => licenceProgressCount(getAnalyticsOverview(analystic_list), "1em")} strokeColor={{ from: '#4CCB1F', to: '#4CCB1F' }} strokeWidth={10} />
                                        <div>
                                            <h4 style={{ fontSize: "2em", fontWeight: "600",cursor:"pointer" }} onClick={() => navigateToUsageReport("mapp_review")} >MApp</h4>
                                            <h4>{analystic_list[0]?.total} Employees</h4>
                                        </div>
                                    </div>
                                    <h4 style={{ fontSize: "1.25em", fontWeight: "500", marginTop: "1em" }}>Top Modules used</h4>
                                    <div className="analystic-main-container">
                                        {
                                            analystic_list?.map(i => (
                                                <div className="analystic-container" onClick={() => navigateToUsageReport("module_usage", i)}>
                                                    <Progress type="circle" width={"80px"} percent={i?.percentage} format={() => licenceProgressCount(i?.percentage, "1em", true)} strokeColor={{ from: '#4CCB1F', to: '#4CCB1F' }} strokeWidth={10} />
                                                    <div>
                                                        <h4>{i?.label}</h4>
                                                        <h4 style={{ color: "rgba(137, 152, 162, 1)" }}>{i?.used}/{i?.total} Employees</h4>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div> : ""
                        }
                        

                    </div>
                        : view_dashboard_cards.invite_employees &&
                        <Spin spinning={banner_card_loading}>
                            <div className="dasboard-banner-card">
                                <h4 className="poc-dashboard-card-title">Invite Employees</h4>
                                <div className="poc-align-spacebetween">

                                    <div style={{ position: "relative" }}>
                                        <img style={{ height: "3em", width: "3em", borderRadius: "50%", position: "absolute", left: `1em`, top: "-18px", cursor: "pointer" }} src={dashboard_home_icons.add_user} alt="add_user" />
                                    </div>
                                    <div>
                                        {!banner_card_loading && <Button className="poc-active-btn" disabled={org_employee_level_count?.total_verticals === 0} onClick={() => navigateRoute(poc_dashboard_routes?.create_invitation)}>INVITE EMPLOYEES</Button>}
                                    </div>
                                </div>
                            </div>
                        </Spin>
                    }
                </div>
                <div style={{ width: "100%", height: "100%" }}>
                    {view_dashboard_cards.licence && <div className="dasboard-banner-card">
                        <h4 className="poc-dashboard-card-title">License</h4>
                        {(licenses && !licenses_loading) && (
                            <div className="poc-align-spacebetween">
                                <div>
                                    <h4 className="poc-licence-sub-title">Total Licenses - <span style={{ fontWeight: "600" }}>{licenses?.total_license}</span></h4>
                                    <h4 className="poc-licence-sub-title" style={{ marginTop: ".5em" }}>Used Licenses - <span style={{ fontWeight: "600" }}>{licenses?.usage}</span></h4>
                                </div>
                                <div>
                                    <Progress type="circle" percent={licenses?.percentage} format={() => licenceProgressCount(licenses?.percentage)} strokeColor={{ from: '#4CCB1F', to: '#4CCB1F' }} strokeWidth={10} />
                                </div>
                                {/* <Button className="poc-active-btn">REQUEST RENEWAL </Button> */}
                            </div>
                        )}
                        {(!licenses && !licenses_loading) && (
                            <h4 style={{ fontSize: "1.25em", marginTop: "1em" }}>No License Detail Found</h4>
                        )}
                    </div>}
                    {view_dashboard_cards.analysis && view_dashboard_cards.invite_employees &&
                        <Spin spinning={banner_card_loading}>
                            <div className="dasboard-banner-card" style={{ marginTop: "2em" }}>
                                <h4 className="poc-dashboard-card-title">Invite Employees</h4>

                                <div className="poc-align-spacebetween" style={{ marginTop: "1em", paddingBottom: "1em" }}>
                                    <div style={{ position: "relative" }}>
                                        <img style={{ height: "3em", width: "3em", borderRadius: "50%", position: "absolute", left: `1em`, top: "-18px", cursor: "pointer" }} src={dashboard_home_icons.add_user} alt="add_user" />
                                    </div>
                                    <div>
                                        {!banner_card_loading && <Button className="poc-active-btn" onClick={() => navigateRoute(poc_dashboard_routes?.create_invitation)} disabled={org_employee_level_count?.total_verticals === 0}>INVITE EMPLOYEES</Button>}
                                    </div>
                                </div>
                            </div>
                        </Spin>}
                </div>
            </div>
            <div style={{ marginTop: "2em" }}>
                <PocDashboardAssessmentContainer {...props} />
            </div>
        </div>
    )
}

export default PocDashboardHomeView;