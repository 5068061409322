import React from 'react';
import { Result, Button } from 'antd';
import HOME_ROUTE from '../../home/route';
import { SEND_LOG_TO_BACKEND } from '../graphql/LogError.gql';
import { withApollo } from '@apollo/client/react/hoc';
import { compose } from 'modules/core';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  async sendLogToBackend(msg) {
    await this.props.client.query({
      query: SEND_LOG_TO_BACKEND,
      variables: { msg: msg },
      fetchPolicy: 'network-only',
    });
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    try {
      if (JSON.stringify(errorInfo).includes("CreateInvitationMultippleEmployeeView")) {
        localStorage.setItem("err_at", "multi_invite");
        const formData = localStorage.hasOwnProperty("formData") ? JSON.parse(localStorage.formData) : null;
        const msg = JSON.stringify({ TYPE: "UI_ERROR", ERROR: error?.message, DATA: formData, ERROR_STACK: errorInfo });
        this.sendLogToBackend(msg)
      } else {
        localStorage.setItem("err_at", "multi_invite");
        const msg = JSON.stringify({ TYPE: "UI_ERROR", ERROR: error?.message, ERROR_STACK: errorInfo });
        this.sendLogToBackend(msg)
      }
    } catch (error) {
      console.error("ErrorBoundry", error)
    }
  }

  render() {
    if (!this.state.error) {
      return this.props.children;
    } else {
      return (
        <div className={'HVCenter'}>
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={
              <Button href={HOME_ROUTE.home} type="primary">
                Back Home
              </Button>
            }
          />
        </div>
      );
    }
  }
}

export default compose(withApollo)(ErrorBoundary);
