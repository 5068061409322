import React from 'react';
import { Row, Col, Button ,Modal} from "antd";
import styled from 'styled-components';
import ReadinessAssessmentContainer from '../containers/ReadinessAssessmentContainer'
import { MetaTags } from "../../look";
import readiness_route from '../routes/index'
import ReadinessNavbar from './ReadinesNavbar';
import SelfReadinessAssessmentMainView from './SelfAssessmentMainView';
import SideBulbToolContainer from 'modules/look-v2/Container/SideBulbToolContainer';
import { READINESS_ADD_OR_EDIT_TOOL_ID } from 'config';
import ReadinessAiTipContainer from 'modules/ai-tips/container/readiness-ai-tip-container';
const RedinessHomeView = props => {
    const {
        navigateRoute,
        match,
        permission,
        SetAITrigger,
        ai_trigger
    } = props;
    

    let tab_list = {
        assessments:{
            key: 'assessments',
            label: 'Assessments',
        },
        self_assessments:{
            key: 'self_assessments',
            label: 'Self Assessments',
        }
    }
    
    const [current_tab, setCurrentTab] = React.useState(
        tab_list[match?.params?.type] ||
        {
            key: 'assessments',
            label: 'Assessments',
        }
    )
    const [toolReturn, setToolReturn] = React.useState()

  
    const views = {
        assessments: ReadinessAssessmentContainer,
        self_assessments: SelfReadinessAssessmentMainView,
    }
    const CurrentView = views[current_tab?.key]
      const [visible, setVisible] = React.useState(false);
    
      // Function to show the modal
      const showModal = () => {
        setVisible(true);
      };
    
      // Function to close the modal
      const handleCancel = () => {
        setVisible(false);
      };
    

    return (
        <>
            <MetaTags title="Readiness Zone" />
            <div style={{marginTop:"20px",marginBottom:"20px", display:'flex', flexDirection:'row', gap:'15px'}}>
            <Button className='add-btn' style={{alignSelf:'center'}} onClick={() => navigateRoute(readiness_route?.addReadiness+`?tab=${current_tab?.key}`)}>Add Readiness</Button>
            <div style={{alignSelf:'center'}}>
                            <span onClick={showModal} style={{ cursor: 'pointer', color: 'blue' ,fontSize:'16px',fontWeight:'500'}}>
                                Demo
                            </span>

                            <Modal
                                title="Add Readiness guide"
                                visible={visible}
                                onCancel={handleCancel}
                                footer={null}
                                closable={true}
                                width={800}
                            >
                                <img src="https://res.cloudinary.com/mapp-brainayan-app/image/upload/v1737544549/MApp/UI/Videos/Learning%20Paths/Readiness%20zone/add_readiness_zone_cldc1u.gif" alt="process vedio" style={{width:"100%"}} />
                            </Modal>
                            </div>
                            </div>
                
            <div style={{ position: 'relative' }}>
                <SideBulbToolContainer
                    {...props}
                    widthOfContainer={24}
                    containerBorder={""}
                    spacing={'0px'}
                    hideTopDivider={true}
                    keepDesign={true}
                    top={"104px"}
                    toolReturn={e => setToolReturn(e)}
                    tool_id={READINESS_ADD_OR_EDIT_TOOL_ID}
                />
            </div>
            <div className="rd-root rd-root-style" >

                <div className="rd-sidebar-container">
                    <ReadinessNavbar current_tab={current_tab} setCurrentTab={(e) => { setCurrentTab(e) }} {...props} />
                </div>
                <div style={{padding: "0em 2em"}}>
                        <CurrentView {...props} SetAITrigger={SetAITrigger} ai_trigger={ai_trigger} />
                </div>
            </div>
            {permission&&permission?.runpod_permission&&<ReadinessAiTipContainer {...props}/>}
        </>
    );
};
export default RedinessHomeView;
const RowDiv = styled(Row)`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:20px
`
const ColScroll = styled(Col)`
::-webkit-scrollbar {
  height: 0px;
  width: 4px;
  z-index: -1;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e0e0e0;
  border-radius: 10px;
  padding-right: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color:#B0CB1F;
}
`