import React from "react";
import MappReviewView from "../components/mappReviewView";
import '../css/review.css'
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { withCreateReview } from "./reviewOperations";
import { message } from "antd";
const MappReviewContainer = (props)=>{
    const {me,createReview,closeReview}=props
    const onSubmitReview = async(data)=>{
        let reviewData ={
            submittedBy:me?.id,
            module:data?.module?.toLowerCase(),
            rating:data?.rating,
            additionalFeedback:data?.comment
        }
        try {
            const response =await createReview({ reviewData: [reviewData] })
            if(response[0]?.id){
                message.success("Thanks for the feedback")
                closeReview()
            }
        }
        catch(e){

        }
    }
    return (
        <>
            <MappReviewView 
            onSubmitReview={(value)=>onSubmitReview(value)}
            {...props}
            />
        </>
    )
}

export default compose(withApollo,withCreateReview)(MappReviewContainer);