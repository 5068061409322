import { message } from "antd";
import { SUBMIT_REVIEW_MUTATION } from "../gaphql/review-quries.gql";
import { graphql } from '@apollo/client/react/hoc';

export const withCreateReview = Component =>
    graphql(SUBMIT_REVIEW_MUTATION, {
      props: ({ mutate, history }) => ({
        createReview: async values => {
          try {
            const {
              data: { createReview }
            } = await mutate({
              variables: {
                ...values
              }
            });
  
            message.destroy();
          //   message.success('Submitted response!!');
            return createReview.reviews;
          } catch (e) {
            message.destroy();
            message.error("Couldn't perform the action");
            console.error(e);
          }
        }
      })
    })(Component);