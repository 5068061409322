// @ts-nocheck
import React, { useState } from "react";
import { Row, Spin, Button, message, Modal, Checkbox, Tooltip } from "antd";
import '../Css/impact-narrative.css'
import { MetaTags } from "../../look";
import AddToolToRecent from "./AddToolToRecent";
import { useHistory } from "react-router-dom";
import './uiFlowTool.css';
import impact_logo from '../../assets/impact_logo.svg'
import User_profile from '../../assets/user-profile.svg'
import edit_green from '../../assets/in_edit_green.svg'
import edit_blue from '../../assets/in_edit_blue.svg'
import TeamNarrativeUI from "./TeamNarrativeView";
import impact_mobile_logo from '../../assets/impact-mobile-logo.svg'
import ImpactNarrativeMobileTab from "./ImpactNarrativeMobileTab";
import { getItem } from "modules/core";
import mapp_learning_path_routes from "modules/learning-path/routes";
import SoulAiTipContainer from "modules/ai-tips/container/soul-ai-tip-container";
import { runpod_warning_message } from "../../../config";
import new_back_arrow from '../../assets/new_back_arrow.svg'
const ImpactNarrativeUI = (props) => {
  const { narrativeloading, user_narrative, generateImpactNarrative, getuserImpactNarrative, data_clean_up, generating_loader, OnUpdateAction, app_sidebar_expand, navigateRoute, permission, survey_updation_date, runpod_health} = props;

  const [headerItems, setHeaderItems] = useState();
  const [sub_content_items, setSubContentItems] = useState([]);

  const [headerItems_prev, setHeaderItemsPrev] = useState();
  const [sub_content_items_prev, setSubContentItemsPrev] = useState([]);
  const [selectedTab, setselectedTab] = useState('MY_NARRATIVE');
  let [fistEmployeeId, setFistEmployeeId] = React.useState()
  let [secondEmployeeId, setSecondEmployeeId] = React.useState()
  const [visible, setVisibile] = React.useState(false)
  const [edit_detail, setEditDetail] = useState();
  
  const history = useHistory();
  React.useEffect(() => {
    data_clean_up('first')
    data_clean_up('second')
    setFistEmployeeId(null)
    setSecondEmployeeId(null)
  }, [selectedTab])

  React.useEffect(() => {
    if (fistEmployeeId) {
      getuserImpactNarrative(fistEmployeeId?.empid, 'first')
    } else {
      data_clean_up('first')
    }
  }, [fistEmployeeId])

  React.useEffect(() => {
    if (secondEmployeeId) {
      getuserImpactNarrative(secondEmployeeId?.empid, 'second')
    } else {
      data_clean_up('second')
    }
  }, [secondEmployeeId])


  const key_values = {
    decisionmaking: { label: 'Decision Making', order: 1 },
    communication: { label: 'Communication', order: 2 },
    collaboration: { label: 'Collaboration', order: 3 },
    relationshipbuilding: { label: 'Relationship Building', order: 4 },
    conflictmanagement: { label: 'Conflict Management', order: 5 },
    changemanagement: { label: 'Change Management', order: 6 },
    learningstyle: { label: 'Learning Style', order: 7 },
    execution: { label: 'Execution', order: 8 }
  }

  React.useEffect(() => {
    if (edit_detail) {
      if (edit_detail?.key == "overview") {
        let overview_detial = { ...headerItems }
        overview_detial['edit_text'] = overview_detial?.text
        setHeaderItems(overview_detial)
      } else {
        let list = sub_content_items.concat([])
        let detial = list?.find(i => i?.key == edit_detail?.key)
        if (detial) {
          detial['edit_text'] = detial?.text
          setSubContentItems(list)
        }

      }
    }
  }, [edit_detail])

  React.useEffect(() => {
    if (user_narrative) {
      let unwanted_fields = ['id', 'overview', '__typename', 'createdAt']
      let sub_list = []
      Object.entries(user_narrative).forEach(i => {
        if (!unwanted_fields?.includes(i[0])) {
          let key = i[0]?.split("_")?.join("")?.toLowerCase()
          sub_list.push({
            text: i[1],
            key: i[0],
            title: key_values[key]?.label || i[0]?.replaceAll("_", " "),
            order: key_values[key]?.order
          })
        }
      })
      sub_list = sub_list?.sort((a, b) => a?.order - b?.order)
      setSubContentItems(sub_list)
      if (user_narrative?.overview) {
        setHeaderItems({
          text: user_narrative?.overview,
          key: "overview",
          title: 'Impact Narrative'
        })
      } else {
        setHeaderItems(null)
      }

    }
  }, [user_narrative])

  const renderHeaders = (section, can_edit = true) => {
    return (
      <>
        <div className="toolcontent impact-header-card" >
          <div className="header-border-div">
            <div className="border-inner-content">
              <img className="profile-img" src={User_profile} alt="" />
              <h4 className="header-border-label">
                {
                  (section.key == edit_detail?.key && can_edit) ? <span className="header-border-label">Edit </span> : <span className="header-border-label">My </span>
                }
                {section?.title}</h4>

              {
                (section.key !== edit_detail?.key && can_edit) ? (
                  <img className="in-edit-icon" onClick={() => { setEditDetail(section) }} src={edit_green} alt="" />
                ) : ''
              }


            </div>
          </div>

          <div>
            {
              section.key !== edit_detail?.key && (
                <h4>{section?.text}</h4>
              )
            }
            {
              section.key === edit_detail?.key && (
                <div style={{ width: "100%" }}>
                  <textarea className="in-textarea" onChange={(e) => changeText(section, e?.target?.value)} value={section?.edit_text}></textarea>
                  <div className="row-btn-container">
                    <Button className="green-action-btn" style={{ margin: "0.75em" }} onClick={() => onSave(section)}>Save</Button>
                    <Button className="grey-action-btn" style={{ margin: "0.75em" }} onClick={() => setEditDetail(null)}>Cancel</Button>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </>
    )
  }


  const RenderCards = (section, can_edit = true) => {
    return (
      <>
        <div className="toolcontent card-gap impact-card" style={{ width: "49%" }}>
          <div className="sub-border-div">
            <h4 className="sub-border-label">
              {
                (section.key == edit_detail?.key && can_edit) ? (
                  <span className="sub-border-label">Edit </span>
                ) : ''
              }
              {section?.title}</h4>
            {
              (section.key != edit_detail?.key && can_edit) ? (
                <img className="in-edit-icon" onClick={() => { setEditDetail(section) }} src={edit_blue} alt="" />
              ) : ''
            }

          </div>
          <div className='overflow-card'>
            {
              section.key !== edit_detail?.key && (
                <h4>{section?.text}</h4>
              )
            }
            {
              section.key === edit_detail?.key && (
                <div style={{ width: "100%" }}>
                  <textarea className="in-textarea" onChange={(e) => changeText(section, e?.target?.value)} value={section?.edit_text}></textarea>
                  <div className="row-btn-container">
                    <Button className="green-action-btn" style={{ margin: "0.75em" }} onClick={() => onSave(section)}>Save</Button>
                    <Button className="grey-action-btn" style={{ margin: "0.75em" }} onClick={() => setEditDetail(null)}>Cancel</Button>
                  </div>
                </div>
              )
            }
          </div>
        </div>

      </>
    )
  }


  const changeText = (section, text) => {
    if (section?.key == "overview") {
      let overview_detial = { ...headerItems }
      overview_detial['edit_text'] = text
      setHeaderItems(overview_detial)
    } else {
      let list = sub_content_items.concat([])
      let detial = list?.find(i => i?.key == section?.key)
      if (detial) {
        detial['edit_text'] = text
        setSubContentItems(list)
      }

    }
  }

  const onSave = (section) => {
    let update_text
    if (section?.key == "overview") {
      let overview_detial = { ...headerItems }
      if (overview_detial['edit_text'] && overview_detial['edit_text']?.trim() != "") {
        overview_detial['text'] = overview_detial['edit_text']
        update_text = overview_detial['edit_text']
        setHeaderItems(overview_detial)
        setEditDetail(null)
      } else {
        message.error('Please update the data')
      }

    } else {
      let list = sub_content_items.concat([])
      let detial = list?.find(i => i?.key == section?.key)
      if (detial) {
        if (detial['edit_text'] && detial['edit_text']?.trim() != "") {
          detial['text'] = detial['edit_text']
          update_text = detial['edit_text']
          setSubContentItems(list)
          setEditDetail(null)
        }
        else {
          message.error('Please update the data')
        }
      }

    }

    if (user_narrative?.id && update_text) {
      let value = {
        id: user_narrative?.id
      }
      value[section.key] = update_text
      OnUpdateAction(value)
    }
  }

  const RegenerateImpactNarrative = async () => {
    // setSubContentItems([]);
    // setHeaderItems(null);
    setEditDetail(null);
    generateImpactNarrative()
  }

  const checkGenerateCondition = () => {
    if (runpod_health && runpod_health?.workers?.idle > 0 && survey_updation_date?.can_regenerate) {
      RegenerateImpactNarrative()
    }
  }

  

  return (
    <>


      <MetaTags title="Impact Narrative" description="Impact Narrative" />
      <Spin spinning={narrativeloading || generating_loader} size="large" className={`impact-narrative-spin ${app_sidebar_expand ? 'impact-narrative-spin-left-align' : ''}`} tip={generating_loader ? `You will see your brand new impact narrative in just a few seconds.` : ""}>
        <Row className="impact-body-padding"  >
          <AddToolToRecent />
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: "1em" }}>
            <h4 className="impat-tool-titile">
              <h4 className="soul-item-title">Impact Narrative </h4>
            </h4>

            {/* <div style={{width:"10px",height:"10px"}}></div> */}
          </div>

          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }} align="center" className="tool-body">

              <div className="tool-content">
                <img className="tool-logo web-screen" src={impact_logo} alt="" />
                <img className="tool-logo mobile-screen" src={impact_mobile_logo} alt="" />

                <div className="tab-container " style={{ display: "flex", flexDirection: "row" }}>
                  <Button onClick={() => { setselectedTab('MY_NARRATIVE') }} className={`tab-btn ${selectedTab === 'MY_NARRATIVE' ? 'selected-tab-btn' : ''}`}>My Narrative</Button>
                  <Button onClick={() => { setselectedTab('TEAM_NARRATIVE') }} className={`tab-btn mobile-screen ${selectedTab === 'TEAM_NARRATIVE' ? 'selected-tab-btn' : ''}`}>Team Narratives</Button>
                  <Button onClick={() => { setselectedTab('TEAM_NARRATIVE') }} className={`tab-btn web-screen ${(selectedTab !== 'MY_NARRATIVE') ? 'selected-tab-btn' : ''}`}>Team Narratives</Button>
                  <Button onClick={() => { setselectedTab('COMPARE') }} className={`tab-btn mobile-screen ${selectedTab === 'COMPARE' ? 'selected-tab-btn' : ''}`}>Compare</Button>
                </div>

                {
                  (runpod_health && survey_updation_date && survey_updation_date?.can_regenerate && !narrativeloading && !generating_loader && selectedTab === 'MY_NARRATIVE') ?
                    <div align="center" style={{ width: "100%" }}>
                      <Tooltip title={runpod_health?.workers?.idle <= 0 ? runpod_warning_message : ""}>
                        <div className={`generate-btn ${runpod_health?.workers?.idle <= 0 ? "generate-btn-disabled" : ""}`} style={{ marginTop: "1em" }} onClick={() => { checkGenerateCondition() }}>
                          <span style={{ margin: "0px", padding: "0px" }}>{user_narrative ? "Regenerate Impact Narrative" : "Generate Impact Narrative"}</span>
                        </div>
                      </Tooltip>
                    </div> : ""
                }

                {
                  selectedTab !== 'MY_NARRATIVE' ?
                    <>
                      <TeamNarrativeUI {...props} selectedTab={selectedTab} fistEmployeeId={fistEmployeeId} setFistEmployeeId={(e) => { setFistEmployeeId(e) }} secondEmployeeId={secondEmployeeId} setSecondEmployeeId={(e) => { setSecondEmployeeId(e) }} key_values={key_values} />
                    </> :
                    <>
                      <div className="in-compare-section">


                        <div style={{ width: '100%' }}>
                          {
                            headerItems && (
                              <>
                                <div className="web-screen" style={{ width: "100%" }}>
                                  {renderHeaders(headerItems, true)}
                                </div>
                                <div className="mobile-screen" style={{ width: "100%" }}>
                                  {renderHeaders(headerItems, true)}
                                </div>
                              </>
                            )
                          }
                          {
                            sub_content_items?.length ?
                              <div className="card-wrapper web-screen" style={{ width: "100%" }} >
                                {
                                  sub_content_items?.map((value) => (

                                    RenderCards(value, true)

                                  ))
                                }
                              </div>
                              :
                              null
                          }

                          {
                            sub_content_items?.length ?
                              <div style={{ width: "100%" }} className="mobile-screen">
                                <ImpactNarrativeMobileTab content={sub_content_items} {...props} />
                              </div>
                              :
                              null
                          }
                        </div>

                      </div>
                    </>
                }

              </div>

            </div>
          </div>


        </Row>
      </Spin>

    </>
  );
};

export default ImpactNarrativeUI;