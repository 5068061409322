import React from "react";
import { compose } from "../../core";

import CommitmentsListView from "../components/CommitmentsListView";
import {
  withAddCoachingCommitment,
  withAddSelectDefaultToolCommitment,
  withDeleteCustomUserCommitment,
  withDeleteDefaultToolCommitment,
} from "./DashboardOperations";
import { withGetCommitmentRecommendations } from "../../tool/containers/ToolOperations";
import { withMe } from "../../user/containers/UserOperations";
import { getIntFromString } from "../../look";
import {
  withAddCoachingCommitmentProgress,
  withDeleteCoachingCommitmentProgress,
} from "../../manager/containers/ManagerOperations";
import { subscribeToCoachingCommitmentProgress } from "modules/manager/containers/ManagerSubscription";

const CommitmentsList = (props) => {
  const {
    allDefaultCommitmentsProgress,
    createCoachingCommitmentProgress,
    addSelectDefaultToolCommitment,
    me,
    deleteDefaultToolCommitment,
    deleteCustomUserCommitment,
    deleteCoachingCommitmentProgress,
    getCommitmentRecommendations,
    dashboard,
    forUser,
    subscribeToMore
  } = props;

  React.useEffect(() => {
    const subscribe =subscribeToCoachingCommitmentProgress(subscribeToMore /* , props.filter */)
    return () => subscribe();
  });

  const handleAdd = async (values) => {
    try {
      // const response = await createCoachingCommitment(values);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleDeleteDefaultToolCommitment = async (id) => {
    try {
      await deleteDefaultToolCommitment({id:id});
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleDeleteCoachingCommitmentProgress = async (id) => {
    try {
      await deleteCoachingCommitmentProgress({id:id});
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleDeleteCustomUserCommitment = async (id) => {
    try {
      await deleteCustomUserCommitment({id:id});
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleCreateCoachingCommitment = async (id) => {
    try {
      const coachingCommitmentProgressData = {
        userId: getIntFromString(me?.id),
        coachingcommitmentId: id,
        forUserId: forUser,
      };
      await createCoachingCommitmentProgress({
        coachingCommitmentProgressData,
      });
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const handleSelectDefaultToolCommitment = async (commitmentId) => {
    try {
      const defaultCommitmentProgressData = {
        userId: me && getIntFromString(me.id),
        commitmentId,
      };
      await addSelectDefaultToolCommitment({ defaultCommitmentProgressData });
    } catch (err) {
      throw new Error(err.message);
    }
  };


  return (
    <CommitmentsListView
      onAdd={handleAdd}
      onDeleteDefaultToolCommitment={
        dashboard === "manager-personal"
          ? handleDeleteCoachingCommitmentProgress
          : handleDeleteDefaultToolCommitment
      }
      onDeleteCustomUserCommitment={handleDeleteCustomUserCommitment}
      handleSelectDefaultToolCommitment={
        dashboard === "manager-personal"
          ? handleCreateCoachingCommitment
          : handleSelectDefaultToolCommitment
      }
      commitmentRecommendations={
        dashboard === "manager-personal"
          ? allDefaultCommitmentsProgress
          : getCommitmentRecommendations
      }
      {...props}
    />
  );
};

export default compose(
  withMe,
  withAddCoachingCommitment,
  withDeleteDefaultToolCommitment,
  withDeleteCustomUserCommitment,
  withAddSelectDefaultToolCommitment,
  withGetCommitmentRecommendations,
  withDeleteCoachingCommitmentProgress,
  withAddCoachingCommitmentProgress
)(CommitmentsList);
