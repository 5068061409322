import React from "react";
import { compose } from "modules/core";
import { withApollo } from '@apollo/client/react/hoc';
import { READINESS_MODULE_USAGE } from "modules/poc-dashboard/graphql/moduleusageQuery.gql";
import CommonTableView from "modules/poc-dashboard/components/common/tableView";
import { Capitalize, displayUtcToUserTimezone, getIntFromString, getUserBase64StringFromInt } from "modules/look";
import moment from "moment-timezone";
import { gql, useLazyQuery } from "@apollo/react-hooks";


const ReadinessModuleUsageContainer = (props) => {
    const { client, permission,date_range_filter,search } = props
    const [data_list_loading, setDataListLoading] = React.useState(false)
    const [list, SetList] = React.useState([])
    const [page_info, SetPageInfo] = React.useState()
    const list_ref = React.useRef([])
    const [employee_list, SetEmployeeList] = React.useState()

    React.useEffect(() => {
        list_ref.current = list
    }, [list])

    const header_list = [
        {
            label: "Employee Name"
        },
        {
            label: "Self Assessments"
        },
        {
            label: "Received Assessments"
        },
        {
            label: "Last Accessed Date"
        }
    ]
    React.useEffect(() => {
        if (permission?.required_permission) {
            ModuleUsage({})
        }
    }, [permission])

    React.useEffect(() => {
        ModuleUsage({})
    },[date_range_filter])

    const timeout_ref =React.useRef()

    React.useEffect(()=>{
        clearTimeout(timeout_ref.current)
        timeout_ref.current = setTimeout(()=>{
            ModuleUsage({})
        },600)
    },[search])

    const readinessQuery = gql`
    query allReadines {
      ${employee_list?.map(
        (item, index) =>
            `selfassessment_${getIntFromString(item?.user_id)}: 
             allReadines(orderBy:["-updatedAt"],first:1,readinessTo:"${item?.employee_id}",readinessFrom:"${item?.employee_id}",${date_range_filter?.start_date && date_range_filter?.end_date ? 
            `,createdAt_Range: ["${moment(date_range_filter.start_date).toISOString()}","${moment(date_range_filter.end_date).toISOString()}"]` : ''}){
                totalCount
                edges{
                    node{
                            id
                            updatedAt
                        }
                    }
            }
            recievedassessment_${getIntFromString(item?.user_id)}:allReadines(orderBy:["-updatedAt"],first:1,readinessTo:"${item?.employee_id}",readinessFrom_Ne:"${item?.employee_id}",${date_range_filter?.start_date && date_range_filter?.end_date ? 
            `,createdAt_Range: ["${moment(date_range_filter.start_date).toISOString()}","${moment(date_range_filter.end_date).toISOString()}"]` : ''}){
                totalCount
                edges{
                node{
                    id 
                    updatedAt
                }
                }
            }
            `
    )
            .join('')}
    }
`;

    React.useEffect(() => {
        if (employee_list) {
            triggerReadinessQuery()
        }
    }, [employee_list])

    const [triggerReadinessQuery] = useLazyQuery(readinessQuery, {fetchPolicy:'no-cache', onCompleted: (data) => { onCompleted(data) }, onError: (data) => { onError(data) } });

    const readiness_filter = React.useRef({})
    const employee_ref = React.useRef({})

    const onCompleted = (data) => {  
        let employee_list = Object.keys(data)?.filter(i => i?.includes("selfassessment"))
        let list = employee_list?.map(i => {
            let user_id = i?.split("_")[1]
            let user = employee_ref?.current?.[getUserBase64StringFromInt(i?.split("_")[1])] 
            
            let date = []
            if (data[`recievedassessment_${user_id}`]?.edges[0]?.node?.updatedAt) {
                date.push(data[`recievedassessment_${user_id}`]?.edges[0]?.node?.updatedAt)
            }
            if (data[`selfassessment_${user_id}`]?.edges[0]?.node?.updatedAt) {
                date.push(data[`selfassessment_${user_id}`]?.edges[0]?.node?.updatedAt)
            }

            if (date?.length) {
                date = date?.sort((a, b) => moment(b).unix() - moment(a).unix())
            }

            let last_accessed = date?.length ? displayUtcToUserTimezone(moment(date[0]), 'DD/MM/YYYY') : "-"
            let row_data = user? [
                {
                    type: "label",
                    value: `${Capitalize(user?.user?.firstName)} ${Capitalize(user?.user?.lastName) || ""}`
                },
                {
                    type: "label",
                    value: data[`selfassessment_${user_id}`]?.totalCount
                },
                {
                    type: "label",
                    value: data[`recievedassessment_${user_id}`]?.totalCount
                },
                {
                    type: "label",
                    value: last_accessed
                }
            ] : []
            return row_data
        })
        if (readiness_filter?.current?.after) {
            list = list_ref?.current?.concat(list)
        }
       
        SetList(list?.filter(i=>i?.length))
        setDataListLoading(false)
    }

    const onError = (data) => {
        setDataListLoading(false)
    }

    const ModuleUsage = async (filter) => {
       try {
        setDataListLoading(true)
        const { data } = await client.query({
            query: READINESS_MODULE_USAGE,
            variables: { ...filter, first: 10,user_FirstName:search },
            fetchPolicy: 'network-only'
        });
        if (data?.allEmployees) {

            data.allEmployees.edges.forEach(({ node }) =>
                employee_ref.current[node.user.id] = node
            )
            readiness_filter.current = filter
            SetEmployeeList(data?.allEmployees?.edges?.map(({ node }) => { return { user_id: node?.user?.id, employee_id: node?.id } }))
            SetPageInfo(data?.allEmployees?.pageInfo)
         
        }
       } catch (error) {
        
       }
    }

    const onPagination = () => {
        if (page_info?.hasNextPage) {
            ModuleUsage({ after: page_info?.endCursor })
        }
    }

    return (
        <>
            <CommonTableView {...props} data_list_loading={data_list_loading} header_list={header_list} list={list} page_info={page_info} onPagination={onPagination} />
        </>
    )
}

export default compose(withApollo)(ReadinessModuleUsageContainer)