// @ts-ignore
import ROUTE from './route'
import DiversityHome from './containers/diversityHome';
export default [
    {
        name: 'DiversityHome',
        path: ROUTE.diversityHome,
        component: DiversityHome,
        exact: true,
        protect:true
    },
]