import React from "react";
import { user_manual_icon } from "../user-manual-icon";
import UserManualResponseContainer from "../containers/userManualResponseContainer";
import { Spin } from "antd";

const MyUserManualView = (props) => {
    const { userManual,user_manual_loading } = props 
    
    return (
        <Spin spinning={user_manual_loading}>
        <div className="user-manual-main-body">
            {/* banner section of user manual */}
            <div className="user-manual-banner">
                <h4 className="user-manual-label">Your <span style={{color:"rgba(76, 203, 31, 1)"}}>User Manual</span></h4>
                <img src={user_manual_icon.banner_icon} alt="user_manual_banner" className="user-manual-team-logo" />
            </div>
            {
                userManual && (
                    userManual?.map((i,index)=>(
                        // component to handle the answer card view
                       <UserManualResponseContainer {...props}  answer={i?.node} can_edit={true} question_no={index+1} response_id={props.match.params.response_id}/>
                    ))
                )
            }
        </div>
        </Spin>
    )
}

export default MyUserManualView;
