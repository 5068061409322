import React from 'react';
import { Form, Input } from 'antd';
import { Tooltip } from 'antd';

const TextField = props => {
  const { type = 'input', value, onChange, disabled, offset = 1, onBlur,margin,placeholder,widthscale ,...rest } = props;
  const FormItem = Form.Item;
  const { TextArea } = Input;

  return (
    <div style={{ marginBottom: margin?margin:'5vh', width:widthscale?widthscale:'100%' }}>
      <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24, offset }} {...rest}>
        {type === 'textarea' ? (
          <TextArea name='notASearchField' autoComplete='off' className="test-input" defaultValue={value} rows={6} onChange={onChange} disabled={disabled} />
        ) : type === 'number' ? (
          <NumericInput className="test-input" autoComplete='off' name='notASearchField' defaultValue={value} onChange={onChange} disabled={disabled} type='number' pattern="[0-9]+"
            inputmode="numeric" />
        ) : (
          <Input name='notASearchField' autoComplete='off'  autoCorrect="off" className="test-input" defaultValue={value}  placeholder={placeholder||''} onChange={onChange} onBlur={onBlur} disabled={disabled} />
        )}
      </FormItem>
    </div>
  );
};

export default TextField;

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const reg = /^[0-9]+$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(Number(value));
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    let valueTemp = value;
    if (!(typeof value === 'number' && isFinite(value))) {
      if (value?.charAt(value?.length - 1) === '.' || value === '-') {
        valueTemp = value?.slice(0, -1);
      }
      onChange(valueTemp?.replace(/0*(\d+)/, '$1'));
      if (onBlur) {
        onBlur();
      }
    }
  };

  render() {
    const { value,placeholder } = this.props;
    const title = value ? (
      <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    ) : (
      'Input a number'
    );
    return (
      <Tooltip trigger={['focus']} title={title} placement="topLeft" overlayClassName="numeric-input">
        <Input autoComplete='off'{...this.props} value={value}  placeholder={placeholder} onChange={this.onChange} onBlur={this.onBlur}  maxLength={25} />
      </Tooltip>
    );
  }
}

function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}
