import { graphql } from '@apollo/client/react/hoc';
import { message } from 'antd';
import moment from 'moment-timezone';
import { CREATE_OKR_MESSAGE_MUTATION, OKR_MESSAGES } from '../graphql/OkrMessages.gql';
import { MINE_OKRS, PAST_OKRS } from '../graphql/OkrTable.gql';

export const withMineOKr = Component =>
  graphql(MINE_OKRS, {
    options: props => {
      let id = '';
      if (props?.me) {
        id = props?.me?.employee?.id
      }
      
      return {
        variables: { employee: id }
      };
    },
    props({ data: { loading, error, mineOkrs, subscribeToMore, updateQuery, refetch } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, mineOkrs, subscribeToMore, updateQuery, refetch };
    }
  })(Component);


  export const withPastOKr = Component =>
  graphql(PAST_OKRS, {
    options: props => {
      let id = '';
      if (props?.me) {
        id = props?.me?.id
      }
      let currentDate = moment().endOf("day");
      
      return {
        variables: { duedate_Date_Lt:currentDate, user: id }
      };
    },
    props({ data: { loading, error, pastOkrs, subscribeToMore, updateQuery, refetch } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, pastOkrs, subscribeToMore, updateQuery, refetch };
    }
  })(Component);


  export const withOkrMessages = Component =>
  graphql(OKR_MESSAGES, {
    options: props => {

      return {
        variables: { okr:"b2tyVHlwZTo2" }
      };
    },
    props({ data: { loading, error, okrMessages, subscribeToMore, updateQuery, refetch } }) {
      if (error) {
        throw new Error(error.message);
      }
      return { loading, error, okrMessages, subscribeToMore, updateQuery, refetch };
    }
  })(Component);

  
  

  export const withCreateOkrMessage = Component =>
  graphql(CREATE_OKR_MESSAGE_MUTATION, {
    props: ({ mutate, history }) => ({
      createOkrMessage: async values => {
        try {
          
          const {
            data: { createOkrMessage }
          } = await mutate({
            variables: {
              ...values
            }
          });

          message.destroy();
          // message.success('Submitted response!!');
          return createOkrMessage.message;
        } catch (e) {
          message.destroy();
          message.error("Couldn't perform the action");
        }
      }
    })
  })(Component);


 