import { Button, Card, Col, message, Row, Select, Spin, Table, Tooltip } from "antd";
import { organization_icons } from "modules/poc-dashboard/icons";
import React from "react";
import * as html2pdf from 'html2pdf.js'
import styled from "styled-components";
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend,
} from 'chart.js';
import { ReactMarkdown, displayUtcToUserTimezone, getIntFromString } from "modules/look";
import poc_dashboard_routes from "modules/poc-dashboard/routes";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Legend
);
const AssessmentReportView = (props) => {
    const { navigateRoute, SurveyReport, permission, user_details, response_loading } = props

    const [loading, setloading] = React.useState(true);
    const [ReportList, setReportList] = React.useState([]);
    React.useEffect(() => {
        if (SurveyReport) {
            if (SurveyReport?.report360?.self?.length && !SurveyReport?.direct_ui_render) {
                let report = SurveyReport?.report360
                let selfList = SurveyReport?.report360?.self
                selfList.forEach(element => {
                    element['overAll'] = report?.overall.filter(item => element?.answer__question__group__id === item?.answer__question__group__id)
                    element['respondents'] = report?.respondents.filter(item => element?.answer__question__group__id === item?.answer__question__group__id)
                });
                setReportList(selfList)
            }
            setloading(false)
        }
    }, [SurveyReport])

    const ToDecimal = (value) => {
        if (value % 1) {
            return value.toFixed(1)
        }
        else {
            return value
        }
    }
    const chartData = (chart) => {

        let Values = []
        let labels = []
        let dataSource = []
        const columns = [
            {
                title: <Tooltip title="Type of Respondent">TYPE</Tooltip>,
                key: 'TYPE',
                render: (text, record) => record &&
                    <Tooltip title={record?.type}>
                        <p style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginTop: '5px' }}>{record?.type}</p>
                    </Tooltip>
            },
            {
                title: <Tooltip title="Average">AVG</Tooltip>,
                key: 'AVG',
                render: (text, record) => record &&
                    <p style={{ marginTop: '5px' }}>{record?.AVG}</p>
            },
            {
                title: <Tooltip title="Standard Deviation">SD</Tooltip>,
                key: 'SD',
                render: (text, record) => record &&
                    <p style={{ marginTop: '5px' }}>{record?.SD}</p>
            },

            {
                title: <Tooltip title="High">HI</Tooltip>,
                key: 'HI',
                render: (text, record) => record &&
                    <p style={{ marginTop: '5px' }}>{record?.HI}</p>
            },
            {
                title: <Tooltip title="Low">LO</Tooltip>,
                key: 'LO',
                render: (text, record) => record &&
                    <p style={{ marginTop: '5px' }}>{record?.LO}</p>
            }
        ]
        if (chart?.overAll?.length) {
            labels.push('OverAll')
            const Avg = chart?.overAll.reduce((a, b) => a + b?.Avg_response, 0)
            Values.push(Avg)
            let tableOverall = {
                type: 'OverAll',
                AVG: ToDecimal(Avg),
                SD: ToDecimal(chart?.overAll.reduce((a, b) => a + b?.StdDev_response, 0)),
                HI: ToDecimal(chart?.overAll.reduce((a, b) => a + b?.Max_response, 0)),
                LO: ToDecimal(chart?.overAll.reduce((a, b) => a + b?.Min_response, 0)),
            }
            dataSource.push(tableOverall)
        }

        if (chart?.respondents?.length) {

            chart.respondents.forEach(element => {
                const Avg = element?.Avg_response
                labels.push(element?.respondentType)
                Values.push(Avg)
                let tablerespondents = {
                    type: element?.respondentType,
                    AVG: ToDecimal(Avg),
                    SD: ToDecimal(element.StdDev_response),
                    HI: ToDecimal(element?.Max_response),
                    LO: ToDecimal(element.Min_response),
                }
                dataSource.push(tablerespondents)
            });
        }



        labels.push('Self')
        Values.push(chart?.Avg_response)
        let tableSelf = {
            type: 'Self',
            AVG: ToDecimal(chart?.Avg_response),
            SD: ToDecimal(chart?.StdDev_response),
            HI: ToDecimal(chart?.Max_response),
            LO: ToDecimal(chart?.Min_response),
        }
        dataSource.push(tableSelf)
        const chartData = {
            labels: labels,
            datasets: [{
                data: Values,
                backgroundColor: "rgba(76, 203, 31, 1)",
                borderColor: "rgba(76, 203, 31, 1)",

            }]
        };
        let maxValue = ((parseInt(Math.max(...Values) / 10) + 1) * 10)

        const options = {
            responsive: true,
            scales: {
                x: {
                    min: 0,
                    max: maxValue > 100 ? maxValue : 100
                }
            },
            indexAxis: 'y',
            elements: {
                bar: {
                    borderWidth: 1,
                    borderHeight: 1
                },
            },
            plugins: {
                legend: {
                    position: 'bottom',
                    display: false
                },
                title: {
                    display: false,
                }
            },
        };
        return (
            <>
                <div className='report-chart-table-container'>
                    <div className='report-grap-container'>
                        <Bar data={chartData} options={options} height={dataSource?.length * 30} />
                    </div>
                    <div className='report-table-container'>
                        <MyTable
                            size={'middle'}
                            loading={loading}
                            showHeader={true}
                            pagination={false}
                            style={{ width: "100%" }}
                            dataSource={
                                dataSource
                                    ? dataSource
                                    : []
                            }
                            columns={columns.map(c => ({ ...c, sortDirections: ['descend', 'ascend', 'descend'] }))}

                        />
                    </div>

                </div>

            </>
        )
    }

    const downloadPdf = () => {
        let class_section = document.getElementsByClassName('whole-360-report-container') ;
   
        
        if (class_section) { 
            let main_section = document.createElement("div")
            let title_section = document.getElementById("user_title_section").cloneNode(true)
            if(title_section){
                main_section.appendChild(title_section)
            }
            let report_section = class_section[0].cloneNode(true)
            if(!report_section){
                message.error("Failed to download report")
                return
            }
            main_section.appendChild(report_section) 
            
            var options = {
                margin:1,
                filename: `${user_details?.firstName}_${user_details?.lastName}_whole_360_report.pdf`,
                image: { type: 'jpeg', quality: 2 },
                html2canvas: { scale: 1 ,useCORS: true,},
                jsPDF: {
                    unit: 'cm',
                    format: 'a4',
                    orientation: 'portrait',
                    compress: false,
                    putOnlyUsedFonts: true
                },
                pagebreak: {
                    // mode: ['avoid-all', 'css', 'legacy'],
                    // after: ['h1', 'h3', '.whole-360-report-container p'],
                },
            };
            
            html2pdf().from(main_section).set(options).save().then(() => {
                message.success("Report downloaded")
                
            }).catch((e) => {
                message.error("Failed to download report")
            });
        }
    }

    return (
        <Spin spinning={loading}>
            <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center", gap: "1em",flexDirection:"row",flexWrap:"wrap" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center" }}>
                    <img src={organization_icons?.back_arrow} alt="back_arrow" style={{ width: "25px", cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
                    <h4 className="poc-dashboard-card-title" style={{ fontWeight: "600" }}>360 Report for {SurveyReport?.survey?.name}</h4>
                </div>
                <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
                    {
                        permission && (
                            <Button className="poc-active-btn" style={{ textTransform: "capitalize", fontWeight: "500",margin:"0.5em"}} onClick={() => navigateRoute(poc_dashboard_routes?.team_assessment_report.replace(':user_id', props?.match?.params?.user_id).replace(':survey_id', getIntFromString(SurveyReport?.survey?.id)))}>Team’s 360 Report</Button>
                        )
                    }
                    {
                        SurveyReport?.direct_ui_render?
                        <Button className="poc-active-btn" style={{ textTransform: "capitalize", fontWeight: "500",margin:"0.5em" }} onClick={()=>{downloadPdf()}} >Download Report</Button>:""
                    }
                    
                </div>

            </div>
            <div id="report-360-container" className="poc-dahboard-root poc-org-root-style" style={{ marginTop: "1em", padding: '1em' }}>
                <div id="user_title_section" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "1em" }}>
                    <div>
                        <h4 className="poc-dashboard-card-title" style={{ fontWeight: "600" }}><span className="text-transform-capitalize">{user_details?.firstName}</span> <span className="text-transform-capitalize">{user_details?.lastName}</span></h4>
                        <p className="poc-dashboard-card-title" style={{ fontWeight: "400", color: "rgba(137, 152, 162, 1)", fontSize: ".75em" }}>Date : {displayUtcToUserTimezone(SurveyReport?.survey?.responseSet?.edges[0]?.node?.responseDate, 'DD MMMM YYYY')}</p>
                    </div>
                    {/* <Select
                        className='org-poc-select-filed'
                        showSearch={true}
                        style={{ width: "100%", marginTop: "1em", maxWidth: "20em" }}
                        placeholder="Select type"
                    >
                        <Select.Option className="org-option" key="Comparative Report" value="Comparative Report" >Comparative Report</Select.Option>
                    </Select> */}
                </div>
                {
                    SurveyReport && (
                        !SurveyReport?.direct_ui_render ?
                            <div>
                                {ReportList?.length ?
                                    <div style={{ marginTop: '50px' }}>

                                        {
                                            ReportList.map(chart =>
                                                // <div className="assesment-card">
                                                <div className="assesment-card">
                                                    <div className='assessment-report-container'>
                                                        <div className='assessment-report-text-container' style={{ width: "50%" }}>
                                                            <h4 className="poc-dashboard-card-title" style={{ fontWeight: "normal", fontSize: "1.25em" }}><ReactMarkdown>{chart?.answer__question__group__label}</ReactMarkdown></h4>
                                                            <h4 className="poc-dashboard-card-title" style={{ fontWeight: "400", fontSize: "1em", whiteSpace: "wrap" }}><ReactMarkdown>{chart?.answer__question__group__help_text}</ReactMarkdown></h4>
                                                        </div>
                                                        <div style={{ width: "fit-content" }}>
                                                            {chartData(chart)}
                                                        </div>
                                                    </div>
                                                </div>
                                                // </div>
                                            )
                                        }

                                    </div>
                                    :
                                    !loading && <h3 style={{ textAlign: 'center', marginTop: '200px' }}>NO 360 REPORTS FOUND</h3>
                                }
                            </div>
                            :
                            SurveyReport?.report360?.includes("Whole") ?
                                <ReactMarkdown>{JSON.parse(SurveyReport?.report360)}</ReactMarkdown> :
                                <h3 style={{ textAlign: 'center', marginTop: '200px' }}>NO 360 REPORTS FOUND</h3>
                    )
                }

            </div>
        </Spin>
    )
}

export default AssessmentReportView;

export const MyTable = styled(Table)`
  table {
    border-collapse: collapse !important;
    th{
        text-align:center !important;
         border: 1px solid rgba(170, 186, 198, 1)!important;
        font-weight:600;
        font-family:Poppins,
        font-size:1em;
        padding-bottom:5px !important;
        background:#fff !important;
    }
    tbody {
            border: 1px solid rgba(170, 186, 198, 1)!important;
            background: rgba(213, 229, 242, 0.2);
        tr{
        border: 1px solid rgba(170, 186, 198, 1)!important;
        border-style: solid none !important;
        }
      td{
        font-size:1em;
        text-align:center !important;
        font-weight:400;
        font-family:Poppins;
        border: 1px solid rgba(170, 186, 198, 1)!important;
      } 
    p{
    margin:0px !important;
    }
    .ant-table-cell {
    padding: 5px 10px !important;

  }
`;
