
import React from "react";
import { compose } from "../../core";
import { withApollo } from '@apollo/client/react/hoc'; 
import MyUserManualView from "../components/myUserManualView";
import { RESPONSE_BY_RESPONSE_ID } from "../graphql/responseByResponseId.gql";
import { getResponsesBase64StringFromInt } from "modules/look";
 
const MyUserManualContainer = (props) => { 
    const {selected_tab,client} = props
    const [userManual, SetUserManual] = React.useState()
    const [user_manual_loading, setUserManualLoading] = React.useState(true)

    React.useEffect(()=>{
        if(selected_tab?.key=="user_manual"){
            getUserManual()
        }
    },[selected_tab])

    const getUserManual = async () => {
        setUserManualLoading(true)
        try {
            const { data } = await client.query({
                query: RESPONSE_BY_RESPONSE_ID,
                variables: { id: getResponsesBase64StringFromInt(props.match.params.response_id) },
                fetchPolicy: "network-only"
            });
            if (data?.responseByResponseId) {
                SetUserManual(data?.responseByResponseId?.answerSet?.edges)
                setUserManualLoading(false)
            } 
        }
        catch (e) { 
        }
    }

    return (
        <MyUserManualView {...props} userManual={userManual} user_manual_loading={user_manual_loading}/>
    )
}

export default compose(withApollo)(MyUserManualContainer);
