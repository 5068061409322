import React, { useState } from 'react';
import { Button, DatePicker, Input, Modal, message, Spin, Popover } from 'antd';
import '../css/idpCreate.css'
import MultiSelectFormDropdown from 'modules/look-v2/Components/MultiSelectFormDropdown';
import SubCompetencyAddContainer from '../../role_definition/containers/AddSubCompetencyContainer'
import { idp_add_icons } from '../idpIcons';
import CKEditorFieldOneOnOne from 'modules/look/components/form-components/CKEditor-one-on-one';
import ManagerSelectionContainer from 'modules/look-v2/Container/ManagerSelectionContainer';
import moment from 'moment-timezone';
import { Capitalize, getIntFromString, withMarkdownFieldValue } from 'modules/look';
import { idp_status } from '../idp.config';
import IdpAiTipContainer from 'modules/ai-tips/container/idp-ai-tip-container';
import { ai_trigger_content } from 'modules/ai-tips/ai-trigger-content';
const IDPCreateView = props => {
    const { SetSelectedManager, selected_manager, role_specific_competency_list, SetRoleSpecificCompList, getCompetencyList, OnSubmitIDP, loading, idp_detail, navigateRoute, SetAITrigger, permission } = props
    const [add_competency, setAddCompetency] = React.useState(false)
    const [manager_popup, SetManagerPopup] = React.useState(false)
    const [title_limit_warning, setTitleLimit] = React.useState(false)
    const [selected_role_specific_competency, SetSelectedRoleSpecificCompetency] = React.useState([])
    const [selected_tab, setSelectedTab] = useState(
        {
            key: "Short",
            goalType: "SHORT",
            label: "Short-term",
            note: "Prioritize and commit to what's immediately needed to be successful."
        }
    )
    const [idp_form, SetIDPForm] = React.useState({
        goal: "",
        goalType: "Short",
        activity: "",
        duedate: "",
        resources: "",
        milestone: "",
        skillData: []
    })

    const [tabs, SetTabs] = useState([
        {
            key: "Short",
            goalType: "SHORT",
            label: "Short-term",
            note: "Prioritize and commit to what's immediately needed to be successful."
        },
        {
            key: "Long",
            goalType: "LONG",
            label: "Long-term",
            note: "Align with long term upskilling, growth, career goals. Priortize for max top two aspirations."
        }
    ])

    React.useEffect(() => {
        if (idp_detail) {
            let tab = tabs?.find(i => i?.goalType == idp_detail?.goalType)
            let skillData = idp_detail?.skillSet?.map(i => i?.competency)
            let skills = skillData?.map(i => {
                return {
                    competencyId: getIntFromString(i?.id)
                }
            })
            let form = {
                goal: idp_detail?.goal,
                goalType: tab?.key,
                activity: idp_detail?.activity,
                duedate: idp_detail?.duedate,
                resources: idp_detail?.resources,
                milestone: idp_detail?.milestone,
                skillData: skills
            }
            SetIDPForm(form)
            setSelectedTab(tab)
            SetSelectedRoleSpecificCompetency(skillData)
        }
    }, [idp_detail])



    // handle data after creation of competency
    const CreateRoleSpecificCompetencyResponse = (data) => {
        setAddCompetency(false)
        if (data) {
            let competency_list = [data].concat(role_specific_competency_list)
            SetRoleSpecificCompList(competency_list)
            let selected_competency = selected_role_specific_competency.concat([{ label: data?.title, ...data }])
            let value = selected_competency?.map(i => {
                return {
                    competencyId: getIntFromString(i?.id)
                }
            })
            updateForm({ label: "skillData", value })
            SetSelectedRoleSpecificCompetency(selected_competency)
        }
    }

    // to change the selected role and competency
    const changeDropdownSelection = (data, type) => {
        if (type === 'role_specific_competency') {
            let list = selected_role_specific_competency.concat([])
            let doc = list?.find(item => item?.id === data?.id)
            if (doc) {
                list = list.filter(item => item?.id != data?.id)
            } else {
                list.push({ ...data })
            }

            let value = list?.map(i => {
                return {
                    competencyId: getIntFromString(i?.id)
                }
            })
            updateForm({ label: "skillData", value })

            SetSelectedRoleSpecificCompetency(list)
        }
    }

    const ai_tip_triggered_ref = React.useRef(false)
    const updateForm = (data) => {
        if (data?.label == "duedate") {
            data.value = moment(data?.value)?.endOf('day').utc().toISOString()
        }

        let form = { ...idp_form }

        form[data?.label] = data?.value

        SetIDPForm(form)
        if (data?.label == "goal") {
            if (data?.value.length >= 200) {
                setTitleLimit(true)
            }
            else {
                setTitleLimit(false)
            }
        }
    }

    const handleManagerSelection = (data) => {
        SetManagerPopup(false)
        SetSelectedManager(data)
    }

    const saveIDP = (status) => {
        let invalid_data = Object?.values(idp_form)?.filter(i => !i)
        if (invalid_data?.length) {
            message.error('Please fill in the required fields')
            return
        }
        if (!idp_form?.skillData?.length) {
            message.error('Please select skills')
            return
        }
        if (!selected_manager?.user_id && !selected_manager?.email_user && !idp_detail) {
            message.error('Please select your manager')
            return
        }
        let skillData = idp_form?.skillData
        let IdpData = {
            ...idp_form,
            status
        }
        delete IdpData.skillData
        if (selected_manager?.user_id) {
            IdpData["approverId"] = getIntFromString(selected_manager?.user_id)
        } else {
            IdpData["sendTo"] = selected_manager?.email_user
        }
        OnSubmitIDP({
            IdpData,
            skillData
        })
    }

    const hide = () => {
        setTitleLimit(false)
    }

    let previous_trigger_data = React.useRef({ goal: "" })
    const onInputBlur = (e) => {
        let goal = e?.target?.value?.trim()
        if (goal && goal?.trim() != "" && previous_trigger_data?.current?.goal != goal) {
            previous_trigger_data.current = { goal }
            if (SetAITrigger) {
                let data = {
                    trigger: ai_trigger_content.create_idp_tip,
                    dynamic_data: [selected_tab?.label, goal],
                    optype: "CREATE"
                }
                SetAITrigger({
                    ...data
                })
            }
        }

    }
    return (
        <>

            {
                (permission && permission?.runpod_permission) && (
                    <IdpAiTipContainer {...props} />
                )
            }
            <Spin spinning={loading}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", gap: "1em", alignItems: "center" }}>
                    <img src={idp_add_icons?.back_arrow} alt="back_arrow" style={{ width: "25px", cursor: "pointer" }} onClick={() => navigateRoute("-1")} />
                    <h4 className="team-idp-main-head">{idp_detail ? "Edit IDP" : "New Goal"}</h4>
                </div>
                <div className='idp-home-root idp-card'>

                    <div className='idp-create-body'>

                        <div>
                            <div className="idp-switch-tab">
                                {tabs?.map(i => (
                                    <div
                                        className={`idp-switch-tab-text ${selected_tab.key === i?.key ? "idp-switch-tab-selected" : ""}`}
                                        onClick={() => { ai_tip_triggered_ref.current = false; setSelectedTab(i); updateForm({ label: "goalType", value: i?.key }) }}
                                    >
                                        {i?.label}
                                    </div>
                                ))}
                            </div>
                            <p className='idp-note'>{selected_tab?.note}</p>
                        </div>

                        <div >
                            <h4 className='idp-form-label'><span style={{ color: "#E86161" }}>*</span>Goal</h4>
                            <Popover
                                content={<a onClick={hide}>Close</a>}
                                title="Character Limit Exceeded"
                                trigger="click"
                                visible={title_limit_warning}
                                placement="topRight"
                            >
                                <Input className='idp-input' defaultValue={idp_detail?.goal} onBlur={(e) => onInputBlur(e)} maxLength={200} onChange={(e) => { updateForm({ label: 'goal', value: e?.target?.value }) }} style={{ marginTop: "0.75em" }} placeholder='Enter Your Goal'></Input>
                            </Popover>
                        </div>

                        <div >
                            <h4 className='idp-form-label'><span style={{ color: "#E86161" }}>*</span>Skills</h4>
                            {/* <p className='idp-note'>The following list is not a complete list of all skills</p> */}

                            {
                                selected_role_specific_competency?.length ?
                                    <div className='idp-chip-wraper'>
                                        {
                                            selected_role_specific_competency.map(ele => (
                                                <div className='custom-dropdown-insider-ele overflow-ellipse'>
                                                    <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{ele?.title}</span>
                                                    <img src={idp_add_icons.chip_close_icon} onClick={() => { changeDropdownSelection(ele, 'role_specific_competency') }} alt="close" />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    : ''
                            }

                            <div style={{ width: "100%", marginTop: "1em" }}>
                                <MultiSelectFormDropdown
                                    onSelect={(e) => { changeDropdownSelection(e, 'role_specific_competency') }}
                                    selected_data={selected_role_specific_competency?.map(comp => comp?.id)}
                                    onSearch={(e) => { getCompetencyList({ title_Icontains: e }) }}
                                    placeholder={'Select one or more skills'}
                                    choice_list={role_specific_competency_list?.map(item => { return { ...item, label: item?.title } })}
                                    add_btn="Add New Skill (Can't find your skill? Add it here)"
                                    on_add={(e) => { setAddCompetency(true) }}
                                />
                            </div>

                        </div>

                        <div >
                            <h4 className='idp-form-label'><span style={{ color: "#E86161" }}>*</span>Developmental Activities</h4>
                            <p className='idp-note'>Articulate as a SMART goal</p>

                            <textarea name="" className='idp-textarea' value={idp_form?.activity} onChange={(e) => { updateForm({ label: 'activity', value: e?.target?.value }) }} id=""></textarea>

                            {/* <div className="idp-ck-editor" style={{ border: "1px solid #AABAC6", borderRadius: "0.5em" }}>
                                <CKEditorFieldOneOnOne
                                    name={'activity'}
                                    height={250}
                                    initialValue={idp_detail?.activity}
                                    changeHandler={(e) => { updateForm({ label: 'activity', value: e }) }}

                                />
                            </div> */}

                        </div>

                        <div >
                            <h4 className='idp-form-label'><span style={{ color: "#E86161" }}>*</span>Next Milestone</h4>
                            <div className='idp-row-wrapper idp-mobile-col' style={{ marginTop: "0.75em" }}>
                                <Input className='idp-input' defaultValue={idp_detail?.milestone} onChange={(e) => { updateForm({ label: 'milestone', value: e?.target?.value }) }} placeholder=''></Input>
                                <h4 className='idp-form-label'>By</h4>
                                <DatePicker
                                    defaultValue={idp_detail ? moment(idp_detail?.duedate) : ''}
                                    className='generic-survey-date-picker-custom idp-create-date'
                                    dropdownClassName={'generic-date-picker-date'}

                                    clearIcon={false}
                                    suffixIcon={<img src={idp_add_icons?.calender} />}
                                    format={'DD/MM/YYYY'}
                                    disabledDate={(e) => moment(e).isSameOrBefore(moment().endOf('day'))}
                                    onChange={(e) => { updateForm({ label: 'duedate', value: e }) }} />
                            </div>
                        </div>

                        <div >
                            <h4 className='idp-form-label'><span style={{ color: "#E86161" }}>*</span>Resources</h4>
                            <textarea name="" className='idp-textarea' value={idp_form?.resources} onChange={(e) => { updateForm({ label: 'resources', value: e?.target?.value }) }} style={{ marginTop: "1em" }} id=""></textarea>
                            {/* <div className="idp-ck-editor" style={{ border: "1px solid #AABAC6", borderRadius: "0.5em", marginTop: "0.75em" }}>
                                <CKEditorFieldOneOnOne
                                    name={'resources'}
                                    height={250}
                                    initialValue={idp_detail?.resources}
                                    changeHandler={(e) => { updateForm({ label: 'resources', value: e }) }}
                                    links={['Link']}
                                />
                            </div> */}

                        </div>

                        {
                            !idp_detail && (
                                <div>
                                    <h4 className='idp-form-label'><span style={{ color: "#E86161" }}>*</span>Current Reporting and Mentoring Manager   <span style={{ color: "#009AF1", cursor: "pointer" }} onClick={() => { SetManagerPopup(true) }}><u>{selected_manager ? "Change" : "Add"}</u></span></h4>
                                    <h4 className='idp-form-label' style={{ fontSize: "1.5em", marginTop: "1.5em" }}>{Capitalize(selected_manager?.label)}</h4>
                                </div>
                            )
                        }


                        <div>
                            <Button className='idp-submit idp-btn' onClick={() => { saveIDP(idp_status.Pedning_Approval.for_mutation) }}>
                                {
                                    (!idp_detail || idp_detail?.status == idp_status?.Draft?.for_query || idp_detail?.status == idp_status?.Revise?.for_query) ?
                                        'Submit for Approval' : 'Save'
                                }
                            </Button>
                            {
                                (!idp_detail || idp_detail?.status == idp_status?.Draft?.for_query) && (
                                    <Button className='idp-submit idp-btn' onClick={() => { saveIDP(idp_status.Draft.for_mutation) }}>Save as Draft</Button>
                                )
                            }

                            <Button className='idp-cancel idp-btn' onClick={() => navigateRoute("-1")} >Cancel</Button>
                        </div>
                    </div>
                    {/* to add role specific competency */}
                    <Modal
                        visible={add_competency}
                        footer={false}
                        closable={false}
                        destroyOnClose={true}
                        centered={true}
                        className='rd-modal cp-modal-max-width'
                        width={"90%"}
                    >
                        <SubCompetencyAddContainer {...props} onCancel={() => setAddCompetency(false)} onSuccess={(res) => { CreateRoleSpecificCompetencyResponse(res) }} />
                    </Modal>
                    <Modal
                        visible={manager_popup}
                        footer={false}
                        closable={false}
                        destroyOnClose={true}
                        centered={true}
                        className='idp-modal cp-modal-max-width'
                        width={"90%"}
                    >
                        <ManagerSelectionContainer {...props} onClose={() => { SetManagerPopup(false) }} onSubmit={(e) => handleManagerSelection(e)} />
                    </Modal>
                </div>
            </Spin>
        </>
    );
};

export default IDPCreateView;
