import { navigationIcons } from "./navigation-icons";
import { SOUL_HOME_TOOL_ID,ROLEGOAL_HOME_TOOL_ID, SURVEY_ID_3CS, USER_MANUAL_SURVEY_ID, SURVEY_WHOLE_IDENTITY } from "../../config";
import readiness_route, { readiness_zone } from '../readiness-level/routes/index'
import managing_performance_route, { managing_performance } from '../competency-assessment/route/index'
import home_route,{home,dashboard,my_team} from '../home/route/index'
import page_route,{ soul } from "modules/page/route";
import { soul_type } from "modules/page/containers/homePageContent";
import okr_route, { okr } from "modules/Objective-keyresult/route";
import one_on_one_route, { one_on_one } from "modules/one-on-one/route";
import feedback_route,{feedback} from "modules/feedback/route";
import coaching_engagement_route, { coaching_engagement } from "modules/grow-model/route";
import survey_route, { three_cs } from "modules/survey/route";
import scape_report_route, { scape_report } from "modules/Scape-Report/route";
import bars_route, { bars } from "modules/Bars-module/route";
import role_definition_route, { role_definition } from "modules/role_definition/route";
import survey_module_route, { report, survey, survey_list } from "modules/survey-module/routes";
import { module_config } from "module_config";
import { user_manual } from "modules/user-manual/route";
import idp_route, { idp } from "modules/IDP/route";
import kudos_user_route, {kudos} from "modules/kudos-user/route";
import poc_dashboard_routes, { poc_admin, poc_dashboard, poc_organization, poc_report, poc_token } from "modules/poc-dashboard/routes";
import { path } from "d3";
import mapp_learning_path_routes from "modules/learning-path/routes";

export const menu_type = {
  normal:"normal",
  survey:"survey",
  tool:"tool"
}

export const navigations = [
  {
    key: home,
    title: "Home",
    path:home_route?.userdashboard,
    icon: navigationIcons.home_icon,
    menu_type:menu_type?.normal,
    module_name:module_config.home,
    child: [
      {
        key: dashboard,
        title: "Dashboard",
        icon: navigationIcons.dasboard_non_active,
        icon_active: navigationIcons.dasboard_active,
        path: home_route?.userdashboard,
        menu_type:menu_type?.normal,
        module_name:module_config.dashboard,
      },
      {
        key: my_team,
        title: "My Team",
        icon: navigationIcons.teams_non_active,
        icon_active: navigationIcons.teams_active,
        path: home_route?.my_team,
        menu_type:menu_type?.normal,
        module_name:module_config.my_team,
        arrow_icon:navigationIcons?.nav_drop,
        is_expand:true
      },
    ],
  },
  {
    key: soul,
    title: "Soul",
    icon: navigationIcons.soul_icon,
    path:page_route.home?.replace(":type",soul_type.values),
    menu_type:menu_type?.normal,
    module_name:module_config.soul,
    child: [
      {
        key: soul_type.learning_path,
        title: "Learning Path",
        icon: navigationIcons.soul_learning_inactive,
        icon_active: navigationIcons.soul_learning_active,
        path: mapp_learning_path_routes?.soul_learning_path,
        custom_icon_class: "custom_nav_icon_size",
        menu_type:menu_type?.normal,
        module_name:module_config.learning_path,
      },
      {
        key: soul_type.values,
        title: "Values",
        icon: navigationIcons.value_inactive,
        icon_active: navigationIcons.value_active,
        path: page_route.home?.replace(":type",soul_type.values),
        menu_type:menu_type?.normal,
        module_name:module_config.values,
      },
      {
        key: soul_type.strengths,
        title: "Strengths",
        icon: navigationIcons.strength_inactive,
        icon_active: navigationIcons.strength_active,
        path: page_route.home?.replace(":type",soul_type.strengths),
        menu_type:menu_type?.normal,
        module_name:module_config.strengths,
      },
      {
        key: soul_type.personalities,
        title: "Personalities",
        icon: navigationIcons.personality_inactive,
        icon_active: navigationIcons.personality_active,
        path: page_route.home?.replace(":type",soul_type.personalities),
        menu_type:menu_type?.normal,
        module_name:module_config.personalities,
      },
      {
        key: soul_type.knowledge_and_skills,
        title: "Knowledge & Skills",
        icon: navigationIcons.knowledge_and_skill_inactive,
        icon_active: navigationIcons.knowledge_and_skill_active,
        path: page_route.home?.replace(":type",soul_type.knowledge_and_skills),
        menu_type:menu_type?.normal,
        module_name:module_config.knowledge_skills,
      },
      {
        key: soul_type.impact_narrative,
        title: "Impact Narrative",
        icon: navigationIcons.impact_narrative_inactive,
        icon_active: navigationIcons.impact_narrative_active,
        path: page_route.home?.replace(":type",soul_type.impact_narrative),
        menu_type:menu_type?.normal,
        module_name:module_config.impact_narrative,
      },
      {
        key: "user",
        title: "Soul Identity",
        icon: navigationIcons.dasboard_non_active,
        icon_active: navigationIcons.dasboard_active,
        path: page_route.soul_report,
        menu_type:menu_type?.normal,
        module_name:module_config.soul_user_report,
      },
    ],
  },
  {
    key: "role",
    title: "Role",
    icon: navigationIcons.goal_icon,
    path: `${readiness_route?.readinessHome?.replace(":type","assessments")}`,
    menu_type:menu_type?.normal,
    module_name:module_config.role,
    child: [
      {
        key:okr,
        title:"OKR",
        icon: navigationIcons.okr_inactive,
        icon_active: navigationIcons.okr_active,
        path:okr_route?.home,
        menu_type:menu_type?.normal,
        module_name:module_config.okr,
      },
      {
        key:readiness_zone,
        title:"Readiness zone",
        icon: navigationIcons.readiness_inactive,
        icon_active: navigationIcons.readiness_active,
        path:`${readiness_route?.readinessHome?.replace(":type","assessments")}`,
        menu_type:menu_type?.normal,
        module_name:module_config.readiness
      },
      {
        key:one_on_one,
        title:"1 on 1",
        icon: navigationIcons.one_on_one_inactive,
        icon_active: navigationIcons.one_on_one_active,
        path:one_on_one_route?.oneOnOneHome?.replace(":type","upcoming"),
        menu_type:menu_type?.normal,
        module_name:module_config.one_on_one
      },
      {
        key:feedback,
        title:"Feedback",
        icon: navigationIcons.feedback_inactive,
        icon_active: navigationIcons.feedback_active,
        path:feedback_route?.feedback?.replace(":type","received"),
        menu_type:menu_type?.normal,
        module_name:module_config.feedback,
      },
      {
        key:coaching_engagement,
        title:"Coaching engagement",
        icon: navigationIcons.coaching_engagement_inactive,
        icon_active: navigationIcons.coaching_engagement_active,
        path:coaching_engagement_route?.grow_home,
        menu_type:menu_type?.normal,
        module_name:module_config.coaching_engagement
      },
      {
        key:managing_performance,
        title:"Managing Performanace",
        icon: navigationIcons.managing_performance_inactive,
        icon_active: navigationIcons.managing_performance_active,
        path:managing_performance_route.competency_assessment_home,
        menu_type:menu_type?.normal,
        module_name:module_config.managing_performance
      },
      {
        key:idp,
        title:"IDP",
        icon: navigationIcons.idp_inactive,
        icon_active: navigationIcons.idp_active,
        path:idp_route.home,
        menu_type:menu_type?.normal,
        module_name:module_config.idp
      }
    ]
  },
  {
    key: "shoal",
    title: "Shoal",
    icon: navigationIcons.shoal_icon,
    path: `${survey_route?.surveyQuiz3cs?.replace(":id",SURVEY_ID_3CS?.toString())}`,
    menu_type:menu_type?.normal,
    module_name:module_config.shoal,
    child:[
      {
        key:three_cs,
        title:"3C",
        icon: navigationIcons.teams_non_active,
        icon_active: navigationIcons.teams_active,
        path:"",
        check:true,
        survey_id:SURVEY_ID_3CS,
        menu_type:menu_type?.survey,
        module_name:module_config.three_cs
      },
      {
        key:user_manual,
        title:"User manual",
        icon: navigationIcons.teams_non_active,
        icon_active: navigationIcons.teams_active,
        path:"",
        check:true,
        survey_id:USER_MANUAL_SURVEY_ID,
        menu_type:menu_type?.survey,
        module_name:module_config.user_manual,
      },
      {
        key:scape_report,
        title:"Team Dynamics",
        icon: navigationIcons.teams_non_active,
        icon_active: navigationIcons.teams_active,
        path:scape_report_route?.scapeReportHome,
        menu_type:menu_type?.normal,
        module_name:module_config.scape_report,
      }
    ]
  },
  {
    key: "whole",
    title: "Whole",
    icon: navigationIcons.whole_icon,
    path: "",
    check:true,
    survey_id:SURVEY_WHOLE_IDENTITY,
    menu_type:menu_type?.survey,
    module_name:module_config.whole
  },
  {
    key: "poll",
    title: "Poll",
    icon: navigationIcons.poll_icon,
    is_coming_soon:true,
    menu_type:menu_type?.normal,
    path: "",
    module_name:module_config.poll
  },
  {
    key: survey,
    title: "Survey",
    icon: navigationIcons.survey_icon,
    path: survey_module_route.org_survey,
    menu_type:menu_type?.normal,
    module_name:module_config.survey,
    child: [
      {
        key:survey_list,
        title:"Survey list",
        alternative_title:"Survey",
        icon: navigationIcons.survey_inactive_icon,
        icon_active: navigationIcons.survey_active_icon,
        path:survey_module_route.org_survey,
        menu_type:menu_type?.normal,
        module_name:module_config.survey_list
      },
      {
        key:report,
        title:"Report",
        icon: navigationIcons.reports_inactive,
        icon_active: navigationIcons.reports_active,
        path:survey_module_route.report,
        menu_type:menu_type?.normal,
        module_name:module_config.report
      }
    ]
  },
  {
    key: kudos,
    title: "Kudos",
    icon: navigationIcons.kudos_icon,
    path: kudos_user_route?.badge_awards_view,
    module_name:module_config.kudos,
  },
  {
    key: "hr",
    title: "HR",
    icon: navigationIcons.goal_icon,
    path: bars_route.bars_home,
    module_name:module_config.hr,
    child: [
      {
        key:bars,
        title:"BARS",
        icon: navigationIcons.bars_inactive,
        icon_active: navigationIcons.bars_active,
        path:bars_route.bars_home,
        module_name:module_config.bars
      },
      {
        key:role_definition,
        title:"Role Definition",
        icon: navigationIcons.role_definition_inactive,
        icon_active: navigationIcons.role_definition_active,
        path:role_definition_route.role_definition_home,
        module_name:module_config.role_definition
      },
      {
        key:"role_relation",
        title:"Role Relation",
        icon: navigationIcons.role_relation_inactive,
        icon_active: navigationIcons.role_relation_active,
        is_coming_soon:true,
        path:"",
        module_name:module_config.role_relation
      },
      {
        key:"hiring",
        title:"Hiring",
        icon: navigationIcons.hiring_inactive,
        icon_active: navigationIcons.hiring_active,
        is_coming_soon:true,
        path:"",
        module_name:module_config.hiring
      }
    ]
  },
  {
    key: poc_admin,
    title: "Admin",
    path:poc_dashboard_routes?.dashboard_home,
    icon: navigationIcons.poc_admin_inactive,
    icon_active:navigationIcons.poc_admin_active,
    menu_type:menu_type?.normal,
    module_name:module_config.poc_admin,
    child: [
      {
        key: poc_dashboard,
        title: "POC Dashboard",
        icon: navigationIcons.dasboard_non_active,
        icon_active: navigationIcons.dasboard_active,
        path: poc_dashboard_routes?.dashboard_home,
        menu_type:menu_type?.normal,
        module_name:module_config.poc_dashboard,
      },
      {
        key: poc_organization,
        title: "Organization",
        icon: navigationIcons.org_inactive,
        icon_active: navigationIcons.org_active,
        path: poc_dashboard_routes?.poc_organization,
        menu_type:menu_type?.normal,
        module_name:module_config.poc_organization,
      },
      {
        key: poc_report,
        title: "Reports",
        icon: navigationIcons.reports_inactive,
        icon_active: navigationIcons.reports_active,
        path: poc_dashboard_routes?.poc_report,
        menu_type:menu_type?.normal,
        module_name:module_config.poc_report, 
      },
      {
        key: poc_token,
        title: "Token",
        icon: navigationIcons.token_inactive,
        icon_active: navigationIcons.token_active,
        path: poc_dashboard_routes?.poc_token,
        menu_type:menu_type?.normal,
        module_name:module_config.poc_token,
        is_coming_soon:true
      }

    ],
  }
];


export const bread_crums = [
  {
    path:home_route.employee_profile,
    crums:["Employee Detail"],
  check_param:false
  },
  {
    path:`${okr_route.discardRequest}?tab=collaboration`,
    crums:["Request","Collaboration Request"],
    check_param:true
  },
  {
    path:`${okr_route.discardRequest}?tab=assignment`,
    crums:["Request","Assignment Request"],
    check_param:true
  },
  {
    path:okr_route.discardRequest,
    crums:["Request","Discard Request"],
    check_param:false
  },
  {
    path:okr_route.discardDetail,
    crums:["Request","Discard Request"],
    check_param:false
  },
  {
    path:okr_route.requestDetail.replace(':type','assignment'),
    crums:["Request","Assignment Request"],
    type:'assignment',
    check_param:false
  },
  {
    path:okr_route.requestDetail.replace(':type','collaboration'),
    crums:["Request","Collaboration Request"],
    type:'collaboration',
    check_param:false
  },
  {
    path:poc_dashboard_routes.poc_vertical_detail,
    crums:["Survey Status"],
    check_param:false
  },
  {
    path:poc_dashboard_routes.assessment_report,
    crums:["Survey Status"],
    check_param:false
  },
  {
    path:poc_dashboard_routes.team_assessment_report,
    crums:["Survey Status"],
    check_param:false
  },
  {
    path:mapp_learning_path_routes.learning_path,
    crums:["Dashboard","Learning Path"],
    check_param:false
  },
  {
    path:mapp_learning_path_routes.soul_learning_home,
    crums:["Dashboard","Learning Path"],
    check_param:false
  }
  
]