// @ts-nocheck
import React from "react";
import '../user-manual.css'
import { Spin, Button, Modal } from "antd";
import myUserManualContainer from "../containers/myUserManualContainer";
import TeamUserManualContainer from "../containers/TeamUserManualContainer";
import SurveyComparisonRequestContainer from '../../look-v2/Container/SurveyComparisonRequest'


const UserManualMainView = (props) => {
    const { permission,survey_by_id,allowComparison } = props
    const [user_manual_request_visible,SetUserManualRequestVisible] = React.useState(false)
    // state to store the selected tab
    const [selected_tab, setSelectedTab] = React.useState(
        {
            label: "Your user manual",
            key: "user_manual",
            component: myUserManualContainer
        }
    )
    // state to store the list of tab available 
    const [usermanual_tab, SetUserManualTap] = React.useState([
        {
            label: "Your user manual",
            key: "user_manual",
            component: myUserManualContainer
        },
        {
            label: "Team user manual",
            key: "team_user_manual",
            component: TeamUserManualContainer
        }
    ])

    // method to change the selected tab
    const OnChangeTab = (tab) => {
        setSelectedTab(tab)
    }

    // variable to manage the selected component to render the ui
    const CurrentView = selected_tab?.component || myUserManualContainer

    return (

        <div className="user-manual-root">
            <div className="user-manual-align-action-section">
                <div className="user-manual-switch-tab">
                    {usermanual_tab?.map(i => (
                        <div onClick={() => OnChangeTab(i)} className={`user-manual-switch-tab-text ${selected_tab.key === i?.key ? "user-manual-switch-tab-selected" : ""}`} >
                            {i?.label}
                        </div>
                    ))}
                </div>
                {
                    (permission?.comparison_permission && allowComparison===false && survey_by_id) && (
                        <Button className="user-manual-request-btn" onClick={()=>{SetUserManualRequestVisible(true)}}>Request to compare</Button>
                    )
                }
                
            </div>
            <CurrentView {...props} selected_tab={selected_tab} />
            <Modal
                visible={user_manual_request_visible}
                footer={false}
                closable={false}
                className="comparison-modal"
                destroyOnClose={true}
                centered={true}
            >
                <SurveyComparisonRequestContainer {...props} survey={survey_by_id}  onClose={() => SetUserManualRequestVisible(false)} />
            </Modal>
        </div>
    )
}

export default UserManualMainView;