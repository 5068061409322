import gql from "graphql-tag";

export const SEND_MESSAGE_MUTATION = gql`
mutation createMessage($messageInput: MessageInput!){
  createMessage(messageInput: $messageInput){
    messages
  }
}
`

export const UPDATE_MESSAGE_MUTATION = gql`
mutation updateMessage($id: ID!, $readTimestamp: String!){
  updateMessage(id: $id, readTimestamp: $readTimestamp){
    messages {
      id
    }
  }
}
`

export const ALL_MESSAGES = gql`
query allMessages{
  allMessages{
    pageInfo{
      hasNextPage,
      hasPreviousPage,
      startCursor,
      endCursor
    }
    edges {
      node{
        id
        user{
          id
          firstName
          lastName
        }
        userMsg
        replyMsg
        msgRecievedTimestamp
        readTimestamp
        isAudio
        syncStatus
        replyToId{
          id
        }
        replyTo
      }
      cursor
    }
    totalCount,
    edgeCount
  }
}
`