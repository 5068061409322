import React from "react"
import grey_star from "../../assets/grey_star.svg"
import green_star from "../../assets/green_star.svg"
import { displayUtcToUserTimezone, DownloadRunpodTip, getIntFromString } from "modules/look"
import ai_tips_icon from "../../assets/ai-tips-icon.svg"
import { Select, Button, DatePicker, Spin, Modal } from "antd"
import drop_icon from '../../assets/idb-dropdown-arrow.svg'
import moment from "moment-timezone"
const TipListView = (props) => {
    const { tip_list, tip_loading, getAiTip, page_info_tip, updateRunpodtips } = props
    const Option = Select.Option
    const [bookmarked, SetBookmarked] = React.useState(false)
    const [selected_filter, setSelectedFilter] = React.useState("all")
    const [tip_lists, setTipLists] = React.useState()
    const [selected_date, setSelectedDate] = React.useState()
    const [selected_tip, setSelectedTip] = React.useState()
    const [show_tip_detail, setShowTipDetail] = React.useState(false)
    React.useEffect(() => {
        if (tip_list) {
            setTipLists(tip_list)
        }
    }, [tip_list])

    let module_list = [
        {
            key: "all",
            label: "All Tips"
        },
        {
            key: "IDP",
            label: "IDP Tips"
        },
        {
            key: "OKR",
            label: "OKR Tips"
        },
        {
            key: "Soul",
            label: "Soul"
        },
        {
            key: "Feedback",
            label: "Feedback Tips"
        },
        {
            key: "Readiness",
            label: "Readiness Tips"
        },
        {
            key: "Meeting",
            label: "Meeting Tips"
        },
        {
            key: "CoachingConversation",
            label: "Coaching Conversation Tips"
        }
    ]

    React.useEffect(() => {
        if (bookmarked) {
            getAiTip({ favourite: true })
        }
        else {
            getAiTip({ favourite: null })
        }
    }, [bookmarked])

    const moduleFilter = (key) => {
        if (key == 'all') {
            getAiTip()
            setSelectedDate()
        }
        else if (key == 'Soul') {
            getAiTip({ tipModule_In: ["Value", "Strength", "Personality", "Knowledge_Skills"] })
        }
        else {
            getAiTip({ tipModule_In: [key] })
        }
    }

    const onViewMore = () => {
        let filter = { after: page_info_tip?.after }
        getAiTip({ ...filter })
    }
    const changeDate = (date) => {
        setSelectedDate(date)
        let selected_date = moment(date)
        const startOfDay = selected_date.startOf('day').toISOString();
        const endOfDay = selected_date.endOf('day').toISOString();
        if (startOfDay && endOfDay) {
            getAiTip({ createdAt_Range: [startOfDay, endOfDay] })
        }
        else {
            getAiTip({ createdAt_Range: [] })
        }

    }
    const bookmarkTip = async (value, type) => {
        try {
            let response = await updateRunpodtips({ favourite: value?.saved, id: getIntFromString(value.id) });
            if (response?.id) {
                const newSections = tip_lists?.map((section) =>
                    section.id === value?.id ? { ...section, is_saved: value?.saved } : section
                );
                setTipLists(newSections)
                if (selected_tip) {
                    let find = tip_lists?.find(s => s?.id == value?.id)
                    setSelectedTip({ ...find, is_saved: value?.saved })
                }
            }
            // message.success(`${value?.saved ? 'Bookmarked' : 'Unbookmarked'} successfully!!`);
        }
        catch (error) {

        }

    }
    return (
        <>
            <div style={{ marginBottom: "16px", display: "flex", flexDirection: "row", gap: "8px", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>
                <div style={{ width: "200px" }}>
                    <Select
                        className="tip-module-select"
                        style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", width: "100%" }}
                        placeholder="Select Status"
                        suffixIcon={<img src={drop_icon} alt="drop_icon" />}
                        showSearch={false}
                        filterOption={false}
                        dropdownClassName="content-dropdown"
                        defaultValue={selected_filter}
                        onChange={(e) => { moduleFilter(e); setSelectedFilter(e) }}
                    >

                        {
                            module_list?.map(i => (
                                <Option
                                    className='rd-search-menu-title' style={{ color: '#BBBBBB' }}
                                    key={i?.key}
                                    value={i?.key}
                                >
                                    {i?.label}
                                </Option>
                            ))
                        }

                    </Select>
                </div>
                <div style={{ display: "flex", flexDirection: "row", gap: "8px", justifyContent: "center", alignItems: "center" }}>
                    <Button className={`tip-bookmark-btn-filter ${bookmarked ? "tip-bookmark-active" : ""}`} onClick={() => { SetBookmarked(!bookmarked) }}>Bookmarked</Button>
                    <DatePicker allowClear={true} value={selected_date} className="tip-date-picker" onChange={(e) => changeDate(e)} />
                </div>
            </div>
            <Spin spinning={tip_loading}>
                <div className="tip-list-root">
                    {
                        tip_lists?.map(i => (
                            <div className="list-ai-card-container">
                                <div className="list-ai-tip-card" style={{ cursor: "pointer" }}>
                                    <h4 style={{ margin: "0px", fontSize: "1em", fontWeight: "700", color: "rgba(170, 186, 198, 1)" }}>{i?.module}</h4>
                                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: 'start' }}>
                                        <img src={ai_tips_icon} style={{ width: "60px" }} alt="" />
                                        <h3 style={{ margin: '0px', paddingTop: "10px" }}>{i?.title}</h3>
                                    </div>
                                    <h4 style={{ cursor: "pointer" }} onClick={() => { setShowTipDetail(true); setSelectedTip(i) }}>{i?.content}</h4>
                                    {
                                        i?.is_saved ?
                                            <div className="bookmark-container" style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", justifyContent: "space-between" }}>
                                                <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                                                    <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", cursor: "pointer", fontSize: ".8em" }} onClick={() => DownloadRunpodTip(i)}>Download</p>
                                                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", cursor: "pointer" }}
                                                        onClick={() => bookmarkTip({ saved: false, id: i?.id })}>
                                                        <img src={green_star} style={{ cursor: "pointer" }} alt="" />
                                                        <p style={{ margin: "0px", color: "#0DD671", fontSize: ".8em", cursor: "pointer" }}>Bookmarked</p>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", gap: ".5em", alignItems: "center" }}>
                                                    <div className="border-sepeartion"></div>
                                                    <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", fontSize: ".8em" }}>{displayUtcToUserTimezone(i?.created_at, "DD-MM-yyyy")}</p>
                                                    <div className="border-sepeartion"></div>
                                                    <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", fontSize: ".8em" }}>{displayUtcToUserTimezone(i?.created_at, "hh:mm a")}</p>
                                                </div>
                                            </div> :
                                            <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                                                <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", cursor: "pointer", fontSize: ".8em" }} onClick={() => DownloadRunpodTip(i)}>Download</p>
                                                <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", cursor: "pointer" }}
                                                    onClick={() => bookmarkTip({ saved: !i?.is_saved, id: i?.id })}>
                                                    <img src={grey_star} style={{ cursor: "pointer" }} alt="" />
                                                    <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", fontSize: ".8em", cursor: "pointer" }}>Bookmark</p>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        ))
                    }
                    {
                        (page_info_tip && page_info_tip?.hasNextPage && tip_list?.length && !tip_loading) ?
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2em" }}>
                                <h4 onClick={() => onViewMore()} className='pagination-label' style={{ cursor: "pointer" }}>View More</h4>
                            </div>
                            : ''
                    }
                    {
                        (!tip_list?.length && !tip_loading) ?
                            <div className='rd-nodata'>
                                <h4 className='rd-nodata-label'>No Tips Found</h4>
                            </div> : ''
                    }
                </div>
            </Spin>
            <Modal
                visible={show_tip_detail}
                centered
                destroyOnClose={true}
                footer={false}
                onCancel={() => { setShowTipDetail(null); setSelectedTip(null) }}>
                <div className="list-ai-tip-card" style={{ background: "#fff", borderRadius: "0px", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "start" }}>
                        <img src={ai_tips_icon} style={{ width: "60px" }} alt="" />
                        <h3 style={{ paddingTop: "10px" }}>{selected_tip?.title}</h3>
                    </div>
                    <h4>{selected_tip?.content}</h4>
                    {
                        selected_tip?.is_saved ?
                            <div className="bookmark-container" style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                                    <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", cursor: "pointer", fontSize: ".8em" }} onClick={() => DownloadRunpodTip(selected_tip)}>Download</p>
                                    <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", cursor: "pointer" }}
                                        onClick={() => { bookmarkTip({ saved: !selected_tip?.is_saved, id: selected_tip?.id }) }}>
                                        <img src={green_star} style={{ cursor: "pointer" }} alt="" />
                                        <p style={{ margin: "0px", color: "#0DD671", fontSize: ".8em", cursor: "pointer" }}>Bookmarked</p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", gap: ".5em", alignItems: "center" }}>
                                    <div className="border-sepeartion"></div>
                                    <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", fontSize: ".8em" }}>{displayUtcToUserTimezone(selected_tip?.created_at, "DD-MM-yyyy")}</p>
                                    <div className="border-sepeartion"></div>
                                    <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", fontSize: ".8em" }}>{displayUtcToUserTimezone(selected_tip?.created_at, "hh:mm a")}</p>
                                </div>
                            </div> :
                            <div style={{ display: "flex", flexDirection: "row", gap: "12px" }}>
                                <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", cursor: "pointer", fontSize: ".8em" }} onClick={() => DownloadRunpodTip(selected_tip)}>Download</p>
                                <div style={{ display: "flex", flexDirection: "row", gap: "4px", alignItems: "center", cursor: "pointer" }}
                                    onClick={() => { bookmarkTip({ saved: !selected_tip?.is_saved, id: selected_tip?.id }) }}>
                                    <img src={grey_star} style={{ cursor: "pointer" }} alt="" />
                                    <p style={{ margin: "0px", color: "rgba(170, 186, 198, 1)", fontSize: ".8em", cursor: "pointer" }}>Bookmark</p>
                                </div>
                            </div>
                    }
                </div>
            </Modal>
        </>
    )

}

export default TipListView
