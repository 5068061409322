import React from "react";
import { withCreateResponseOptimized, withSurveyByIdNewOptimized } from './SurveyOperations'
import SoulSurveySubmissionView from '../components/soulValueSubmissionView'
import { compose } from "../../core";
import NotFound from '../../page/containers/NotFound'
import { withApollo } from '@apollo/client/react/hoc';
import { COMPLETE_SOUL_SURVEY } from "modules/page/graphql/CompletedSurvey.gql";
import { getSurveyBase64StringFromInt } from "modules/look";
import { VALUE_SUERVEY_PART_1,STRENGTH_PART_1,PERSONALITY_PART_1,KNOWLEDGE_PART_1 } from "../../../config";

const SoulSurveySubmissionContainer = (props) => {
    const {client,me} = props
    const selected = JSON.parse(localStorage.getItem('selectedSoulValue'))
    const formValues = JSON.parse(localStorage.getItem('formValues'))
    const [soul_survey_completion,SetSoulSurveyCompletion] = React.useState()

    React.useEffect(()=>{
        if(me){
            GetSoulSurveysCompletedCount(me?.employee?.user?.id)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[me])

    const GetSoulSurveysCompletedCount = async (userid) => {
        try {
          const { data } = await client.query({
            query: COMPLETE_SOUL_SURVEY,
            variables: {
              user: userid,
              value: getSurveyBase64StringFromInt(VALUE_SUERVEY_PART_1),
              strength: getSurveyBase64StringFromInt(STRENGTH_PART_1),
              personality: getSurveyBase64StringFromInt(PERSONALITY_PART_1),
              knowledge: getSurveyBase64StringFromInt(KNOWLEDGE_PART_1)
            },
            fetchPolicy: 'network-only'
          });
      
          if (data) {
            SetSoulSurveyCompletion(data)
          }
        } catch (error) {
          
        }
      }

    if(!selected || !formValues){
        return (
            <>
            <NotFound></NotFound>
            </>
          )
    }
    else{
        let edgeData = [] 
        selected.values.forEach(element => {
            let question = props?.SurveyById?.groupSet?.edges?.find(seq=>seq?.node?.label===element)
      
            if(question){
              edgeData.push(question)
              let totalanswer= 0
              let responseTime = 0
              let totalRange = 0
              question.node.questionSet.edges.forEach( qus => {
                  let ans = formValues.submitValues.find(sur=>sur.questionId===qus.node.id)
                    if(ans){
                        let range = parseInt(qus?.node?.choiceSet.edges[0].node.value.split(':')[1])
                        totalRange = totalRange +range
                        totalanswer = totalanswer + parseInt(ans.answer)
                        responseTime = responseTime + ans.responseDuration
                    }
              })
              let percent = Math.round((totalanswer/totalRange)*100)
              let submitted ={totalanswer,responseTime,totalRange,percent}
              question.node['submitted']=submitted
            }
          });
          let LocalData ={
            selected : selected,
            formValues : formValues,
            submissionData : edgeData
        }
       
    
        return (
            <>
                
                   <SoulSurveySubmissionView {...props} data={LocalData} soul_survey_completion={soul_survey_completion}></SoulSurveySubmissionView>
                
            </>
        )
    
    }
   
}
export default compose(withSurveyByIdNewOptimized, withCreateResponseOptimized,withApollo)(SoulSurveySubmissionContainer)

