// DO NOT EDIT THIS FILE

import gql from 'graphql-tag';

export const USER_MANUAL_EMPLOYEE_LIST = gql`
  query userlist($search:String,$endCursor:String) {
    me {
      id
        employee {
          id
          organizationSet {
            edges {
              node {
                id
                employees(user_FirstName:$search,first:10,after:$endCursor) {
                  totalCount
                  pageInfo {
                    hasNextPage
                    endCursor
                  }
                  edges {
                    node {
                      id
                      user{
                        id
                        firstName
                        lastName
                        email
                        profile{
                          id
                          profileImage
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }
`;


export const USER_MANUAL_ALL_EMPLOYEE_LIST = gql`
  query allEmployees($nameIcontains:String,$first:Int) {
    allEmployees(nameIcontains:$nameIcontains,first:$first){
      edges{
      node{
        id
        user{
          id
          firstName
          lastName
        }
      }
    }
    }
  }
`;


export const USER_MANUAL_COMPARE_RESPONSE = gql`
  query allComparisonRequestFormUser($accepted:Boolean,$first:Int,$survey: ID,$receiver_FirstName_Icontains:String) {
    allComparisonRequestFormUser(accepted:$accepted,survey:$survey,receiver_FirstName_Icontains:$receiver_FirstName_Icontains,first:$first){
    edges{
      node{
        id 
        receiver{
          id
          employee{
            id
          }
          firstName
          lastName
        }
  
      }
    }
    }
  }
`;